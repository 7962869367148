<template>
  <div class="command-situation-media dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon" />
      <div class="dialog-header__title">历史图片</div>
      <div class="right_header_box">
        <div class="iconfont icon-a-xiazai2px" style="margin-right: 16px;" @click="handle_click"></div>
        <div class="dialog-header__close" @click="flight_task_chage_view(1)">
          关闭
        </div>
      </div>
    </div>
    <div class="box dialog-content">
      <div class="left">
        <img class="leftimg" :src="selectImg" />
      </div>
      <div class="right">
        <div class="upIcon center" @click="move('up')">
          <img src="~@/assets/images/command/up.svg" />
        </div>
        <div class="centerimg">
          <img
            v-for="(item, index) in historyImage"
            :key="index"
            @click="handleImg(index)"
            class="rightimg"
            :src="imgApi + '/uploads'+ item.fileKey"
            :class="active == index ? 'active' : ''"
          />
        </div>
        <div class="upIcon center" @click="move('down')">
          <img src="~@/assets/images/command/down.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgApi: process.env.VUE_APP_IMG_URL,
      selectImg: null,
      active: 0,
    };
  },
  props: ["historyImage"],
  inject: ["flight_task_chage_view"],
  mounted() {
    this.selectImg = this.imgApi + '/uploads'+this.historyImage[0].fileKey;
  },
  methods: {
    handle_click() {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        image = new Image();
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = this.selectImg;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0, image.width, image.height);

        let base64 = canvas.toDataURL("image/jpeg", 0.8);

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = base64;
        a.download = "图片";
        a.click();
      };
    },
    handleImg(index) {
      this.active = index;
      this.selectImg = this.imgApi + '/uploads' + this.historyImage[index].fileKey;
    },
    move(val) {
      var div = document.getElementsByClassName("centerimg")[0];
      if (val == "up") {
        if (div.scrollTop != null) {
          div.scrollTop -= 118;
        }
      } else {
        if (div.scrollTop != null) {
          div.scrollTop += 118;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
.command-situation-media {
  z-index: 1000;
  position: absolute;
  left: 500px;
  top: 250px;
  width: 935px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .right_header_box {
    display: flex;
    justify-content: space-around;
  }
  .iconfont {
    color: #00aeff;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }
  .box {
    display: flex;
    justify-content: space-around;
    .left {
      width: 700px;
      height: 400px;
      border: 2px solid #025d88;
      .leftimg {
        width: 700px;
        height: 400px;
        border: 0;
      }
    }
    .right {
      margin-left: 10px;
      width: 176px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .upIcon {
        width: 100%;
        height: 16px;
        background: #13212b;
        border: 1px solid #0096d4;
        box-shadow: inset 0 0 7px 1px rgba(23, 127, 255, 0.55);
        img {
          width: 22px;
          height: 22px;
        }
      }
      .centerimg {
        overflow-y: auto;
        overflow-x: hidden;
        width: 176px;
        height: 360px;
        ::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .rightimg {
          margin-right: 5px;
          width: 160px;
          height: 112px;
          cursor: pointer;
          padding: 2px;
        }
        .active {
          border: 2px solid #00c6d4;
        }
      }
    }
  }
}
</style>
