export default {
    //crc校验字节生成函数
    cal_crc_table(array) {
        let crc = 0;
        let i = 1;
        let len = array[2];
        while (len--) {
            crc = this.crc_table[crc ^ array[i]];
            i++;
        }
        return crc;
    },
    // 播放/暂停音频
    gimbal_audio_play_ctl() {
        let value;
        if (!this.shoutStatus) {
            value = 2
            console.log("开始播放");
        } else {
            value = 0
            console.log("停止播放");

        }
        this.shoutStatus = !this.shoutStatus
        let buff = new Array(0xa5, 0x22, 0x03);
        buff[3] = value;
        buff[4] = this.cal_crc_table(buff);
        let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
        this.controInfor.data.cmdBuffer = newbuf.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('ShoutFn', this.controInfor)
        this.$message.success('操作成功')
    },
    //下一曲 
    gimbal_audio_play_next_ctl() {
        console.log(11);
        let buff = new Array(0xa5, 0x23, 0x03, 0x01);
        buff[4] = this.cal_crc_table(buff);
        let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
        this.controInfor.data.cmdBuffer = newbuf.join(',');;
        this.$message.success('操作成功')
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('ShoutFn', this.controInfor)

    },
    //   切换速度
    gimbal_change_ctrl_speed(change_speed) {
        this.control_speed = change_speed;
    },
    // 模拟轮盘上下
    gimbal_pitch_ctrl(speed) {
        let realspeed = ((speed * this.control_speed) >>> 0) & 0xff;
        let buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = this.cal_crc_table(buff);
        let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
        this.controInfor.data.cmdBuffer = newbuf.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('ShoutFn', this.controInfor)
        if (speed == 0) {
            this.pitch_move = false;
        } else {
            this.pitch_move = true;
        }
    },
}
