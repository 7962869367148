

var viewlink_cmdID = {
  A1C1E1: 0X30,
  A1C1E1S1: 0X32,
  A2C2E2: 0X31,
  A2C2E2S2: 0X33,
  A1: 0X1A,
  A2: 0X2A,
  C1: 0X1C,
  C2: 0X2C,
  E1: 0x1E,
  E2: 0X2E,
  S1: 0X16,
  S2: 0X26,
  U: 0X01,
  V: 0X02,
  M_AHRS: 0XB1,
  HEART_BEAT: 0X10,
  SHAKE_HAND: 0X00,
  T1F1B1D1: 0x40,
  T2F2B2D2: 0X41,
  FW: 0XFF
}
//CRC 校验表
var crc_table = new Array(
  0x00, 0x31, 0x62, 0x53, 0xc4, 0xf5, 0xa6, 0x97, 0xb9, 0x88, 0xdb, 0xea, 0x7d, 0x4c, 0x1f, 0x2e,
  0x43, 0x72, 0x21, 0x10, 0x87, 0xb6, 0xe5, 0xd4, 0xfa, 0xcb, 0x98, 0xa9, 0x3e, 0x0f, 0x5c, 0x6d,
  0x86, 0xb7, 0xe4, 0xd5, 0x42, 0x73, 0x20, 0x11, 0x3f, 0x0e, 0x5d, 0x6c, 0xfb, 0xca, 0x99, 0xa8,
  0xc5, 0xf4, 0xa7, 0x96, 0x01, 0x30, 0x63, 0x52, 0x7c, 0x4d, 0x1e, 0x2f, 0xb8, 0x89, 0xda, 0xeb,
  0x3d, 0x0c, 0x5f, 0x6e, 0xf9, 0xc8, 0x9b, 0xaa, 0x84, 0xb5, 0xe6, 0xd7, 0x40, 0x71, 0x22, 0x13,
  0x7e, 0x4f, 0x1c, 0x2d, 0xba, 0x8b, 0xd8, 0xe9, 0xc7, 0xf6, 0xa5, 0x94, 0x03, 0x32, 0x61, 0x50,
  0xbb, 0x8a, 0xd9, 0xe8, 0x7f, 0x4e, 0x1d, 0x2c, 0x02, 0x33, 0x60, 0x51, 0xc6, 0xf7, 0xa4, 0x95,
  0xf8, 0xc9, 0x9a, 0xab, 0x3c, 0x0d, 0x5e, 0x6f, 0x41, 0x70, 0x23, 0x12, 0x85, 0xb4, 0xe7, 0xd6,
  0x7a, 0x4b, 0x18, 0x29, 0xbe, 0x8f, 0xdc, 0xed, 0xc3, 0xf2, 0xa1, 0x90, 0x07, 0x36, 0x65, 0x54,
  0x39, 0x08, 0x5b, 0x6a, 0xfd, 0xcc, 0x9f, 0xae, 0x80, 0xb1, 0xe2, 0xd3, 0x44, 0x75, 0x26, 0x17,
  0xfc, 0xcd, 0x9e, 0xaf, 0x38, 0x09, 0x5a, 0x6b, 0x45, 0x74, 0x27, 0x16, 0x81, 0xb0, 0xe3, 0xd2,
  0xbf, 0x8e, 0xdd, 0xec, 0x7b, 0x4a, 0x19, 0x28, 0x06, 0x37, 0x64, 0x55, 0xc2, 0xf3, 0xa0, 0x91,
  0x47, 0x76, 0x25, 0x14, 0x83, 0xb2, 0xe1, 0xd0, 0xfe, 0xcf, 0x9c, 0xad, 0x3a, 0x0b, 0x58, 0x69,
  0x04, 0x35, 0x66, 0x57, 0xc0, 0xf1, 0xa2, 0x93, 0xbd, 0x8c, 0xdf, 0xee, 0x79, 0x48, 0x1b, 0x2a,
  0xc1, 0xf0, 0xa3, 0x92, 0x05, 0x34, 0x67, 0x56, 0x78, 0x49, 0x1a, 0x2b, 0xbc, 0x8d, 0xde, 0xef,
  0x82, 0xb3, 0xe0, 0xd1, 0x46, 0x77, 0x24, 0x15, 0x3b, 0x0a, 0x59, 0x68, 0xff, 0xce, 0x9d, 0xac
);
var D1_CMD = {
  SENSOR: 0, //bit0-2
  IR_DZOOM: 0,//bit3-6
  IR_WHITE_BLACK: 0,//bit7 
  res: 0x00,
  recstatus: 0,// bit0-1
  ir_gray_color: 0,//bit2-5
  eo_dzoom: 0,//bit6-9
  res: 0,//bit10-15
  lrf_value: 0,//byte 5-6
  v_fov: 0,
  h_fov: 0,
  eo_zoom: 0
}
var B1_CMD = {
  GIMrollangle_8_11: 0,
  servestatus: 0x01,
  GIMrollangle_0_7: 0x00,
  GIMrollangle: 0,
  GIMyawangle: 0,
  GIMpitchangle: 0
}
var B1_servo_status = {
  motorsw: 0x00,
  manualcontrol: 0x01,
  followyaw: 0x03,
  homeposition: 0x04,
  trackmode: 0x06,
  turntorelativeangle: 0x09,
  lockyaw: 0x0a,
  turntoframeangle: 0x0b,
  RCcontrol: 0x0d,
  fingerto: 0x0e,
  error: 0x0f
}
export default {
  cal_crc_table(array) {
    var crc = 0;
    var i = 1;
    var len = array[2];
    while (len--) {
      crc = crc_table[crc ^ array[i]];
      i++;
    }
    return crc;
  },
  set_payload_data(dataArray) {
    // console.log(dataArray,"dataArray");
    if(dataArray){
      if (
        dataArray[0] === 0xA5 &&
        this.cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
      ) {
        if (dataArray[1] == 0x02) {
          // console.log('0x02');
  
        //   var pitch = get_float_from_4byte(data.slice(3, 7));
        //   var roll = get_float_from_4byte(data.slice(7, 11));
        //   var yaw = get_float_from_4byte(data.slice(11, 15));
        //   console.log(pitch, roll, yaw, "xxxxxxxxx");
        }
        if (dataArray[1] == 0x4f) {
          // console.log(123456789);
          this.gcs_transmit_ctrl(dataArray);
        }
        if(dataArray[1] == 0x0c){
          // console.log('0x0c');
            this.msg_euler2(dataArray);
        }
      }
    }
  },
 msg_euler2(dataArray) {
    var pitch = this.g_convert_float16_to_native_float(dataArray[3] | (dataArray[4] << 8));
    var roll = this.g_convert_float16_to_native_float(dataArray[5] | (dataArray[6] << 8));
    var yaw = this.g_convert_float16_to_native_float(dataArray[7] | (dataArray[8] << 8));
    this.set_euler_angle(pitch, roll, yaw);
    //console.log(pitch + "  " + roll + "  " + yaw);
},
set_euler_angle(pitch, roll, yaw) {
  // console.log(pitch, roll, yaw,'pitch, roll, yaw');
},
g_convert_float16_to_native_float(value) {
  var buffer1 = new ArrayBuffer(4);
  var magic = new Uint32Array(buffer1);
  var magicf = new Float32Array(buffer1);

  var buffer2 = new ArrayBuffer(4);
  var was_inf_nan = new Uint32Array(buffer2);
  var was_inf_nanf = new Float32Array(buffer2);

  var buffer3 = new ArrayBuffer(4);
  var out = new Uint32Array(buffer3);
  var outf = new Float32Array(buffer3);


  magic[0] = (254 - 15) << 23;
  was_inf_nan[0] = (127 + 16) << 23;

  out[0] = (value & 0x7FFF) << 13;
  outf[0] *= magicf[0];
  if (outf[0] >= was_inf_nanf[0]) {
      out[0] = 255 << 23;
  }
  out[0] |= (value & 0x8000) << 16;

  return Number(outf[0]);
},
  viewlink_checksum(array) {
    var checksum = array[3];
    var i = 4;
    var len = (array[3] & 0x3f) - 2;
    while (len--) {
      checksum = checksum ^ array[i];
      i++;
    }
    return checksum;
  },
  gcs_transmit_ctrl(rdata) {
    //  console.log("@@@@@@:" + rdata);
    var i = 0;
    var viewlinkframelen = 0;
    if (rdata[3] == 0x55 && rdata[4] == 0xaa && rdata[5] == 0xdc) {
      if (rdata[2] == (rdata[6] & 0x3f) + 5) {
        viewlinkframelen = (rdata[6] & 0x3f) + 3;
        var readdata = new Uint8Array(viewlinkframelen);
        for (i = 0; i < viewlinkframelen; i++) {
          readdata[i] = rdata[i + 3];
        }
     
        if (this.viewlink_checksum(readdata) == readdata[viewlinkframelen - 1]) {
          switch (rdata[7]) {
            case viewlink_cmdID.SHAKE_HAND:
              // modeltype = msg_shake_hand(readdata);
              break;
            case viewlink_cmdID.HEART_BEAT:
              // modeltype = msg_shake_hand(readdata);
              break;
            case viewlink_cmdID.T1F1B1D1:
              this.msg_TIF1B1D1_parse(readdata);
              //   console.log("msg_TIF1B1D1_parse:" + readdata);
              break;
          }
        }
      }
    }
  },
  msg_TIF1B1D1_parse(rdata) {
    var msgdata = new Uint8Array(rdata);
    var T1buf = new Uint8Array(22);
    var F1buf = new Uint8Array(1);
    var B1buf = new Uint8Array(6);
    var D1buf = new Uint8Array(12);
    var i = 0;

    for (i = 0; i < 22; i++) {
      T1buf[i] = msgdata[i + 5];
    }
    for (i = 0; i < 1; i++) {
      F1buf[i] = msgdata[i + 27];
    }
    for (i = 0; i < 6; i++) {
      B1buf[i] = msgdata[i + 28];
    }
    for (i = 0; i < 12; i++) {
      D1buf[i] = msgdata[i + 34];
    }
    this.msg_D1_parse(D1buf)
    this.msg_B1_parse(B1buf);
    
  },
 msg_D1_parse(rdata) { //品灵云台d1包解包函数
  D1_CMD.SENSOR = rdata[0] & 0x07;
  D1_CMD.IR_DZOOM = (rdata[0] & 0x78) >> 3;
  D1_CMD.IR_WHITE_BLACK = rdata[0] >> 7;
  D1_CMD.recstatus = rdata[3] & 0x03;
  D1_CMD.ir_gray_color = (rdata[3] & 0x3c) >> 2;
  D1_CMD.eo_dzoom = ((rdata[3] & 0xc0) >> 6) | (rdata[2] & 0x03) << 2;
  D1_CMD.lrf_value = rdata[4] << 8 | rdata[5];
  D1_CMD.v_fov = (rdata[6] << 8 | rdata[7]) * 0.01;
  D1_CMD.h_fov = (rdata[8] << 8 | rdata[9]) * 0.01;
  D1_CMD.eo_zoom = (rdata[10] << 8 | rdata[11]) * 0.1;
  var mode = D1_CMD.recstatus;
  this.eo_zoom = D1_CMD.eo_zoom
  // console.log(this.eo_zoom);
  // set_LRF_distance(D1_CMD.lrf_value / 10);
},
msg_B1_parse(rdata) { //品灵云台B1包解包函数
  B1_CMD.GIMrollangle_8_11 = rdata[0] & 0x0F;
  B1_CMD.servestatus = rdata[0] >> 4;
  B1_CMD.GIMrollangle_0_7 = rdata[1];
  B1_CMD.GIMrollangle = (B1_CMD.GIMrollangle_8_11 << 8 | B1_CMD.GIMrollangle_0_7) * 180 / 4095;
  B1_CMD.GIMrollangle = B1_CMD.GIMrollangle - 90;
  B1_CMD.GIMyawangle = (rdata[2] << 8 | rdata[3]) * 360 / 65536;
  B1_CMD.GIMpitchangle = (rdata[4] << 8 | rdata[5]) * 360 / 65536;
  B1_CMD.GIMyawangle = (B1_CMD.GIMyawangle + 540) % 360 - 180;
  B1_CMD.GIMpitchangle = (B1_CMD.GIMpitchangle + 540) % 360 - 180;
  this.picth = B1_CMD.GIMpitchangle
  this.yaw = B1_CMD.GIMyawangle
  // console.log(B1_CMD.GIMpitchangle,B1_CMD.GIMrollangle,B1_CMD.GIMyawangle,"B1_CMD.GIMyawangle");
//俯仰2偏航
  // set_euler_angle(B1_CMD.GIMpitchangle, B1_CMD.GIMrollangle, B1_CMD.GIMyawangle);
}
}