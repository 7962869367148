<!-- 挂载--Z40S fckernel -->
<template>
  <div class="cf MMC_GimbalList">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">{{ flag ? '多气体检测历史记录' : '多气体检测详情' }}</div>
      </div>
      <div>
        <div @click="close" class="close">{{ flag ? '关闭' : '返回' }}</div>
      </div>
    </div>
    <div class="hb" v-if="flag">
      <!-- :picker-options="pickerOptions" -->
      <header class="tc mt10 jcsb pl20 pr20">
        <div class="jcsb">
          <el-date-picker
            v-model="params.startTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始时间"
          ></el-date-picker>
          <span class="dib lh30">-</span>
          <el-date-picker
            class="ml5"
            v-model="params.endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="结束时间"
          ></el-date-picker>
        </div>
        <div class="btn w80" @click="reportList">搜索</div>
      </header>
      <div class="mt10">
        <div class="ctx-box">
          <div class="head mt7">
            <div class="text w200">检查报告</div>
            <div class="text con">日期</div>
            <div class="time">操作</div>
          </div>
          <div class="list-box mt7">
            <div class="item-box" v-for="(item, index) in tableData" :key="index">
              <div class="text-box">
                <div class="type-box w200">
                  <span class="type tc">{{ item.reportName }}</span>
                </div>
                <div class="text">{{ item.startTime }}</div>
                <div class="time">
                  <div class="btn w80" @click="showFn(item)">查看</div>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            class="fr pa right0 bottom30 dib w300 mt10"
            layout="prev,pager,next"
            @current-change="handle_current_change"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div class="hb" v-else>
      <div class="mt10">
        <div class="ctx-box">
          <div class="head mt7">
            <div class="text w200">气体名称</div>
            <div class="text con">单点值最高</div>
            <div class="text con">单点值最低</div>

            <div class="w100 tc">下载</div>
          </div>
          <div class="list-box h290 mt7">
            <div class="item-box" v-for="(item, index) in list" :key="index">
              <div class="text-box">
                <div class="type-box w200">
                  <span class="type tc">{{ item.typeName }}</span>
                </div>
                <div class="text">{{ item.pointMax }}</div>
                <div class="text">{{ item.pointMin }}</div>
                <div class="w50 ml10 mr10 jcsb">
                  <el-tooltip effect="dark" content="word下载" placement="top">
                    <img
                      class="w21 w21 dib cp"
                      @click="downLoadFn(item)"
                      src="@/assets/images/observe/fckernel/Word.svg"
                      alt
                    />
                  </el-tooltip>
                  <el-tooltip effect="dark" content="excel下载" placement="top">
                    <img
                      class="w19 h19 dib cp"
                      @click="downloadExcel(item)"
                      src="@/assets/images/observe/fckernel/excel.svg"
                      alt
                    />
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import API from "@/api";
import Popup from "./message/popup.js";
let handler = null;
let gsaListEntities = [];
import { saveAs } from "file-saver";
export default {
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      taskSituation: "CO(ppm)",
      reportId: null,
      flag: true,
      time: null,
      airDataType: {
        NO2: "NO2(ppm)",
        "PM2.5": "PM2.5(ug/m3)",
        SO2: "SO2(ppm)",
        PM10: "PM10(ug/m3)",
        CO: "CO(ppm)",
        Ox: "Ox(ppm)",
        "PM1.0": "PM1.0(ug/m3)",
      },
      tableData: [],
      list: [],
      total: 50,
      params: {
        deviceId: "",
        startTime: null,
        endTime: null,
        pageNo: 1,
        pageSize: 10,
      },
      popupList: [], //弹出框实例列表
      move_id_arr: [],
    };
  },
  components: {},
  computed: {},
  beforeDestroy() {
    this.removeEntities();
    if (handler) {
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler = null;
    }
    if (this.popupList.length > 0) {
      this.popupList.forEach((item) => {
        item.close();
      });
      this.popupList = [];
    }
  },
  created() {},
  mounted() {
    // 弹窗
    this.click_billboard();
    let { id } = this.device;
    this.params.deviceId = id;
    this.reportList();
  },
  methods: {
    click_billboard() {
      let viewer = window.viewer;
      const _this = this;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x + 15,
          movement.position.y + 10
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          let data = pickedLabel.id;

          let arr = _this.move_id_arr;
          try {
            if (!data._properties._customProperty) {
              return false;
            }
            if (data._name.indexOf("entity_gasi") < 0) {
              return false;
            }
            console.log(data._name, "data._name)");
            if (arr.indexOf(data._name) < 0) {
              _this.move_id_arr.push(data._name);
              // 调用弹窗方法
              let popup = new Popup({
                viewer: viewer,
                geometry: cartesian,
                data: data,
                arr: _this,
              });
              // console.log('创建弹窗', popup)
              _this.popupList.push(popup);
            }
          } catch (error) {
            // console.log("创建弹窗异常:", error);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      handler.setInputAction((movement) => {
        // console.log('滚动', _this.popupList)
        //滚动关闭所有信息框
        _this.popupList.forEach((item) => {
          item.close();
        });
        _this.popupList = [];
      }, Cesium.ScreenSpaceEventType.WHEEL);
    },
    // 分页切换
    handle_current_change(val) {
      this.params.pageNo = val;
      this.reportList();
    },
    Cartesian3_to_WGS84_Arr(point) {
      let cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z);
      let cartographic = Cesium.Cartographic.fromCartesian(cartesian33);
      let lat = Cesium.Math.toDegrees(cartographic.latitude);
      let lng = Cesium.Math.toDegrees(cartographic.longitude);
      let alt = cartographic.height;
      return [lng, lat, alt];
    },
    // 根据数字获取颜色
    // 计算颜色
    color_from_value(num) {
      let value = num;
      if (
        this.taskSituation == "CO(ppm)" ||
        this.taskSituation == "SO2(ppm)" ||
        this.taskSituation == "Ox(ppm)"
      ) {
        value = num / 5;
      } else if (this.taskSituation == "NO2(ppm)") {
        value = num * 2;
      } else if (
        this.taskSituation == "PM1.0(ug/m3)" ||
        this.taskSituation == "PM2.5(ug/m3)" ||
        this.taskSituation == "PM10(ug/m3)"
      ) {
        value = num / 2;
      }
      function interpolateColor(color1, color2, ratio) {
        if (color1 && color2) {
          let r1 = parseInt(color1.substr(1, 2), 16);
          let g1 = parseInt(color1.substr(3, 2), 16);
          let b1 = parseInt(color1.substr(5, 2), 16);
          let r2 = parseInt(color2.substr(1, 2), 16);
          let g2 = parseInt(color2.substr(3, 2), 16);
          let b2 = parseInt(color2.substr(5, 2), 16);

          let r = Math.round(r1 + (r2 - r1) * ratio);
          let g = Math.round(g1 + (g2 - g1) * ratio);
          let b = Math.round(b1 + (b2 - b1) * ratio);

          let hex =
            "#" +
            (r < 16 ? "0" : "") +
            r.toString(16) +
            (g < 16 ? "0" : "") +
            g.toString(16) +
            (b < 16 ? "0" : "") +
            b.toString(16);
          return hexToRgb(hex);
        }
      }
      function hexToRgb(hex) {
        hex = hex.replace("#", "");
        let r = parseInt(hex.substr(0, 2), 16);
        let g = parseInt(hex.substr(2, 2), 16);
        let b = parseInt(hex.substr(4, 2), 16);
        return Cesium.Color.fromBytes(r, g, b);
      }
      function getHexColor(number) {
        if (number === 0) {
          return "#47a447";
        } else if (number === 100) {
          return "#fb4747";
        } else if (number >= 0 && number <= 100) {
          // 计算相邻颜色的索引
          let lowerIndex = Math.floor(number / 10);
          let upperIndex = Math.ceil(number / 10);

          // 根据索引获取相邻颜色
          let lowerColor = getColorByIndex(lowerIndex);
          let upperColor = getColorByIndex(upperIndex);
          // 计算颜色之间的渐变比例
          let ratio = (number - lowerIndex * 10) / 10;
          if (!upperColor) {
            upperColor = lowerColor;
          }
          return interpolateColor(lowerColor, upperColor, ratio);
        } else {
          return "Invalid number. Please enter a number from 0 to 100.";
        }
      }

      function getColorByIndex(index) {
        let colors = [
          "#47a447",
          "#47c147",
          "#47de47",
          "#4bfb47",
          "#88fb47",
          "#c5fb47",
          "#fbf847",
          "#fbc847",
          "#fb9a47",
          "#fb4747",
        ];
        return colors[index];
      }

      // 示例用法
      let inputNumber = value;
      let colorCode = getHexColor(inputNumber);
      // 返回 RGB 颜色值
      return colorCode;
    },
    // 创建正方形区域
    createRectangle(viewer, item, typeName, key) {
      let num = item.data.airData[typeName];
      let color = this.color_from_value(num);
      let positions = item.positions;
      try {
        let entity = viewer.entities.add({
          name: `entity_gasi_${key}`,
          position: item.position,
          polygon: {
            hierarchy: positions,
            material:color.blue? color: Cesium.Color.fromCssColorString(color), // 设置颜色和透明度
            height: 0, // 设置高度
            outlineWidth: 1.0,
          },
          show: true,
          properties: {
            customProperty: item.data,
          },
        });
        let label = new Cesium.LabelGraphics({
          text: num + "", // 设置显示的文字
          font: "16px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#282c34"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: false,
          outlineWidth: 0,
          scaleByDistance: new Cesium.NearFarScalar(800, 2.0,900, 0.6),

          pixelOffset: new Cesium.Cartesian2(0, -10),
        });
        entity.label = label;
        gsaListEntities.push(entity);
      } catch (error) {
        console.log(error);
      }
    },
    // 飞入 正方形 并导出报告
    async downLoadFn(item) {
      if (gsaListEntities.length > 0) {
        this.removeEntities();
        this.popupList = [];
      }
      let areaInfo = JSON.parse(item.areaInfo);
      let typeName = this.airDataType[item.typeName];
      this.taskSituation = typeName;
      if (areaInfo) {
        areaInfo.forEach((element, key) => {
          this.createRectangle(window.viewer, element, typeName, key);
        });
        setTimeout(() => {
          if (gsaListEntities.length > 0) {
            let model = gsaListEntities[0];
            var ellipsoid = window.viewer.scene.globe.ellipsoid;
            var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
            var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
            var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
            var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);
            viewer.camera.flyTo({
              duration: 0.1,
              destination: Cesium.Cartesian3.fromDegrees(lng, lat, 2340),
              maximumHeight: 100,
            });
          }
        }, 400);
        setTimeout(() => {
          this.screenshot(item);
        }, 1400);
      } else {
        this.$message.warning("暂无数据");
      }
    },
    downloadExcel(item) {
      API.FCKERNEL.reportExport({
        reportId: this.reportId,
        typeId: item.typeId,
        fileType: 1,
      }).then((res) => {
        const excelBlob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(excelBlob, "气体检测报告.xlsx");
      });
    },
    async screenshot(item) {
      console.log(item);
      function dataURItoBlob(dataURI) {
        let byteString = atob(dataURI.split(",")[1]);
        let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      }
      let canvas = window.viewer.scene.canvas;
      let image = canvas.toDataURL("image/png");
      let blob = dataURItoBlob(image);
      let formData = new FormData();
      // 接口
      formData.append("uploadFiles", blob, `下载.jpeg`);
      let res = await API.MAPMANAGE.fileUpload(formData);
      if (res.length > 0) {
        API.FCKERNEL.reportExport({
          imageUrl: res[0].storage || null,
          reportId: this.reportId,
          typeId: item.typeId,
          fileType: 0,
        }).then((res) => {
          const wordBlob = new Blob([res], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          saveAs(wordBlob, "气体检测报告.docx");
        });
      } else {
        this.$message.error(res?.message);
      }
    },
    // 打开下载列表
    async showFn(item) {
      this.reportId = item.id;
      this.flag = false;
      let res = await API.FCKERNEL.reportdownList(item.id);
      this.list = res;
    },
    //气体检测历史记录表
    async reportList() {
      //  时间
      let res = await API.FCKERNEL.reportList(this.params);
      this.tableData = res.records;
      this.total = res.total;
    },
    close() {
      if (!this.flag) {
        this.flag = true;
      } else {
        // 关闭弹窗
        this.$store.commit("device/SET_MMCGimbalGASList_FLAG", false);
      }
    },
    // 移除实体
    removeEntities() {
      for (let i = 0; i < gsaListEntities.length; i++) {
        viewer.entities.remove(gsaListEntities[i]);
      }
      gsaListEntities = [];
    },
  },
};
</script>
  
<style lang="scss" scoped>
.MMC_GimbalList {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 763px;
  top: calc(50% - 250px);
  height: 400px;
  margin-right: -250px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  box-sizing: border-box;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .hb {
    overflow-y: auto;

    .btn {
      cursor: pointer;
      height: 32px;
      text-align: center;
      line-height: 32px;
      margin: 0 auto;
      margin-top: 4px;
      background: url("~@/assets/yingkong1027/btn_lan.png") no-repeat;
      background-size: 100% 100%;
    }

    .ctx-box {
      height: calc(100% - 120px);
      // flex: 1;
      // overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: 0 16px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: SourceHanSansCN-Bold;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 1px;
        line-height: 22px;
        font-weight: 700;

        .text,
        .time {
          width: 20%;
          text-align: center;
        }

        .time {
          width: 35%;
        }

        .con {
          width: 50%;
        }
      }

      .list-box {
        overflow: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        height: 183px;
        overflow-y: auto;

        .item-box {
          display: flex;
          align-items: center;
          color: #fff;
          padding: 11px 0;

          .text-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .type-box {
              width: 20%;
              height: 20px;
              text-align: center;
              font-family: SourceHanSansCN-Medium;
              font-size: 14px;
              color: #ebf9ff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;

              // &.ordinary {
              //   background-color: #298ad3;
              // }

              // &.emerg {
              //   background-color: #ff3c3c;
              // }

              .type {
                width: 100px;
                text-align: center;
                font-size: 10px;
                white-space: nowrap;
              }
            }

            .text {
              width: 50%;
              text-align: center;
              font-family: SourceHanSansCN-Medium;
              font-size: 14px;
              color: #ebf9ff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }

            .time {
              width: 35%;
              text-align: center;
              font-family: SourceHanSansCN-Medium;
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 500;
            }
          }
        }

        .item-box:nth-of-type(2n - 1) {
          background: rgba(73, 135, 210, 0.2);
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 265px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #02173d;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-pagination button:disabled {
    background-color: #02173d;
  }

  .el-pagination .btn-next {
    background-color: #02173d;
    color: #c0c4cc;
  }

  .el-pagination .btn-prev {
    background-color: #02173d;
    color: #c0c4cc;
  }

  .el-pager li {
    background-color: #02173d;
    //  color: #fff;
  }

  .el-input {
    width: 204px;
    margin-right: 5px;

    .el-input__inner {
      background: #02173d;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .butto {
    width: 35px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    background: #004fff;
    color: #000;
    border-radius: 5px;
  }

  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
</style>