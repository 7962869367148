<template>
  <div class="obstacle">
    <div class="obstacle-top">
      <div class="obstacle-bar-content">
        <div class="obstacle-bar-block" v-for="i in 4" :key="i">
          <template v-for="j in 3">
            <img
              v-if="j <= blockCount[`${1}-${i}`]"
              :key="j"
              class="obstacle-bar__item"
              :class="`bar${i}-${j}`"
              :src="require(`@/assets/images/observe/fckernel/obstacle/bar${i}-${j}.png`)"
            />
          </template>
        </div>
      </div>
      <div class="obstacle__distance" v-if="minDistance[1] !== -1">{{minDistance[1]}}m</div>
    </div>
    <div class="obstacle-right">
      <div class="obstacle-bar-content">
        <div class="obstacle-bar-block" v-for="i in 4" :key="i">
          <template v-for="j in 3">
            <img
              v-if="j <= blockCount[`${2}-${i}`]"
              :key="j"
              class="obstacle-bar__item"
              :class="`bar${i}-${j}`"
              :src="require(`@/assets/images/observe/fckernel/obstacle/bar${i}-${j}.png`)"
            />
          </template>
        </div>
      </div>
      <div class="obstacle__distance" v-if="minDistance[2] !== -1">{{minDistance[2]}}m</div>
    </div>
    <div class="obstacle-bottom">
      <div class="obstacle-bar-content">
        <div class="obstacle-bar-block" v-for="i in 4" :key="i">
          <template v-for="j in 3">
            <img
              v-if="j <= blockCount[`${3}-${i}`]"
              :key="j"
              class="obstacle-bar__item"
              :class="`bar${i}-${j}`"
              :src="require(`@/assets/images/observe/fckernel/obstacle/bar${i}-${j}.png`)"
            />
          </template>
        </div>
      </div>
      <div class="obstacle__distance" v-if="minDistance[3] !== -1">{{minDistance[3]}}m</div>
    </div>
    <div class="obstacle-left">
      <div class="obstacle-bar-content">
        <div class="obstacle-bar-block" v-for="i in 4" :key="i">
          <template v-for="j in 3">
            <img
              v-if="j <= blockCount[`${4}-${i}`]"
              :key="j"
              class="obstacle-bar__item"
              :class="`bar${i}-${j}`"
              :src="require(`@/assets/images/observe/fckernel/obstacle/bar${i}-${j}.png`)"
            />
          </template>
        </div>
      </div>
      <div class="obstacle__distance" v-if="minDistance[4] !== -1">{{minDistance[4]}}m</div>
    </div>
  </div>
</template>

<script>
//每一个显示块对应避障数组的映射关系
let angleMap = new Map();
angleMap.set("1-1", [32, 33]);
angleMap.set("1-2", [34, 35]);
angleMap.set("1-3", [0, 1]);
angleMap.set("1-4", [2, 3, 4]);
angleMap.set("2-1", [5, 6]);
angleMap.set("2-2", [7, 8]);
angleMap.set("2-3", [9, 10]);
angleMap.set("2-4", [11, 12, 13]);
angleMap.set("3-1", [14, 15]);
angleMap.set("3-2", [16, 17]);
angleMap.set("3-3", [18, 19]);
angleMap.set("3-4", [20, 21, 22]);
angleMap.set("4-1", [23, 24]);
angleMap.set("4-2", [25, 26]);
angleMap.set("4-3", [27, 28]);
angleMap.set("4-4", [29, 30, 31]);

//避障角度与显示块的对应关系
let angleToBlock = new Map();
angleMap.forEach((value, key) => {
  value.forEach((index) => {
    angleToBlock.set(index, key); //angleToBlock.set(0, '1-3)
  });
});

// console.log("angleToBlock", angleToBlock);

window.angleMap = angleMap;

export default {
  data() {
    //转化value为{indexs: [], count: 0}结构
    const blockCount = {};
    angleMap.forEach((value, key) => {
      blockCount[key] = 0;
    });

    return {
      blockCount, //每一区显示的块数
      minDistance: {
        //每一区最短距离
        1: -1,
        2: -1,
        3: -1,
        4: -1,
      },
    };
  },
  computed: {
    obstacle() {
      return this.$store.state.fckernel.obstacle;
    },
  },
  watch: {
    obstacle: {
      immediate: true,
      handler() {
        if(!this.obstacle){
          return;
        }
        //显示块出发等级
        const level = (this.obstacle.obsDistance * 100) / 3;
        Object.keys(this.minDistance).forEach(i => {
          this.minDistance[i] = -1;
        })
        //匹配每个10度对应的显示块
        this.obstacle.distances.forEach((value, i) => {
          //取得当前角度对应的显示块
          const index = angleToBlock.get(i);

          if (value <= level) {
            this.blockCount[index] = 3;
          } else if (value <= level * 2) {
            this.blockCount[index] = 2;
          } else if (value <= level * 3) {
            this.blockCount[index] = 1;
          } else {
            this.blockCount[index] = 0;
          }

          let block = index.split("-")[0]; //当前区
          
          // console.log('block', block)
          if (this.blockCount[index] > 0) {
            //计算最近距离，单位米
            if (
              this.minDistance[block] === -1 ||
              this.minDistance[block] > value
            ) {
              if((value / 100).toFixed(2) == '0.00'){
                // console.log('this.obstacle.distances', this.obstacle.distances)
                // debugger
              }
              this.minDistance[block] = (value / 100).toFixed(2);
            }
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.obstacle {
  left: 0;
  top: 0;
}

.obstacle__distance {
  font-family: YouSheBiaoTiHei;
  font-size: 12px;
  padding: 0px 5px;
  background-image: linear-gradient(to right, #eb0d0d33, red, #eb0d0d33);
  color: #fff;
}

.obstacle-top {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 25px;

  .obstacle__distance {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 16px);
  }

  .obstacle-bar-content {
    transform: rotatex(57deg) rotatey(180deg) rotate(180deg)
      translate(-19px, -93px);
  }
}

.obstacle-right {
  z-index: 1;
  position: absolute;
  right: -53px;
  transform: translate(0%, -50%);
  top: 50%;

  .obstacle__distance {
    position: absolute;
    left: -187px;
    top: -28px;
  }

  .obstacle-bar-content {
    transform: rotateY(44deg) rotateX(180deg) rotate(-90deg)
      translate(-41px, -267px);
  }
}

.obstacle-bottom {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 5px;

  .obstacle__distance {
    position: absolute;
    left: 50%;
    transform: translate(-22px, -93px);
    top: 0px;
  }

  .obstacle-bar-content {
    transform: rotatex(57deg) rotatey(180deg) rotate(0deg)
      translate(-20px, -213px);
  }
}

.obstacle-left {
  z-index: 1;
  position: absolute;
  left: -94px;
  transform: translate(0%, -50%);
  top: 50%;

  .obstacle__distance {
    position: absolute;
    right: -183px;
    top: -26px;
    transform: translateY(-50%);
  }

  .obstacle-bar-content {
    transform: rotateY(44deg) rotateX(180deg) rotate(90deg)
      translate(5px, -263px);
  }
}

.obstacle-bar-content {
  display: flex;
  justify-content: center;
  transform: rotatex(57deg);

  .obstacle-bar-block {
    display: flex;
    flex-direction: column;
    position: relative;

    .obstacle-bar__item {
      position: absolute;
      margin: 1px;
      // height: 10px;
      width: 60px;
      background-size: 100% 100%;
      overflow: hidden;

      &.bar1-1 {
        top: 77px;
        left: -79px;
        width: 50px;
      }

      &.bar1-2 {
        top: 66px;
        left: -71px;
        width: 45px;
      }

      &.bar1-3 {
        left: -64px;
        top: 59px;
        width: 42px;
      }

      &.bar2-1 {
        top: 100px;
        left: -31px;
        width: 50px;
      }

      &.bar2-2 {
        top: 89px;
        left: -26px;
        width: 45px;
      }

      &.bar2-3 {
        left: -23px;
        top: 78px;
        width: 42px;
      }

      &.bar3-1 {
        top: 102px;
        left: 20px;
        width: 50px;
      }

      &.bar3-2 {
        top: 91px;
        left: 20px;
        width: 45px;
      }

      &.bar3-3 {
        left: 20px;
        top: 80px;
        width: 42px;
      }

      &.bar4-1 {
        top: 82px;
        left: 68px;
        width: 50px;
      }

      &.bar4-2 {
        top: 73px;
        left: 65px;
        width: 45px;
      }

      &.bar4-3 {
        left: 62px;
        top: 65px;
        width: 42px;
      }
    }
  }
}

.hidden {
  visibility: hidden;
}
</style>