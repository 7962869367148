<template>
  <div class="face dialog1027">
    <div class="dialog-header" v-interact>
        <div class="dialog-header__icon" />
        <div class="dialog-header__title">
            人脸识别
        </div>
        <div class="dialog-header_close" style="color:#fff" @click="()=>$emit('close')">关闭</div>
    </div>
    <div class="dialog-content">
      <template v-if="list.length > 0">
        <div class="result" v-for="(item, i) in list" :key="i">
          <div class="rate-box">
            <div class="rate-img">
              <img :src="imgUrl + item.picture_uri_get" />
            </div>
            <div class="rate-round">
              <div class="rate-round__value">{{ Number(item.similarity).toFixed(2) }}%</div>
              <div class="rate-routd__text" @click="onDetail(item)">详情</div>
            </div>
            <div class="rate-img rate-img--contrary">
              <img :src="imgUrl + item.face_image_uri_get" />
            </div>
          </div>
          <div class="rate-form" v-if="item.show">
            <div class="rate-form-item">
              <div class="rate-form-item__label">
                姓名：
              </div>
              <div class="rate=form-item__value">
                {{ item.NAME || '暂无' }}
              </div>
            </div>
            <div class="rate-form-item">
              <div class="rate-form-item__label">
                出生年份：
              </div>
              <div class="rate=form-item__value">
                {{ item.born_year || '暂无' }}
              </div>
            </div>
            <!-- <div class="rate-form-item">
              <div class="rate-form-item__label">
                发现时间：
              </div>
              <div class="rate=form-item__value">
                {{ item.occurTime || '暂无' }}
              </div>
            </div> -->
            <!-- <div class="rate-form-item">
              <div class="rate-form-item__label">
                发现地点：
              </div>
              <div class="rate=form-item__value">
                {{ item.addr || item.address || '暂无' }}
              </div>
            </div> -->
            <div class="rate-form-item">
              <div class="rate-form-item__label">
                身份证号：
              </div>
              <div class="rate=form-item__value">
                {{ item.person_id || '暂无' }}
              </div>
            </div>
            <!-- <div class="rate-form-item">
              <div class="rate-form-item__label">
                AI识别：
              </div>
              <div class="rate=form-item__value">
                <template v-if="item.labels && item.labels.length">
                  <div class="round-border" v-for="(label, j) in item.labels" :key="j" >
                    {{label}}
                  </div>
                </template>
                <template v-else>
                  暂无
                </template>
              </div>
            </div> -->
          </div>
        </div>
      </template>
      <template v-else>
        <div style="text-align: center; width: 100%;">暂无数据</div>
      </template>
    </div>
  </div>
</template>

<script>
import API from '@/api';

export default {
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_URL,
      list: [],
    }
  },
  created(){
    this.getFaceList();
  },
  methods: {
    async getFaceList(){
      // this.pageNo++
      // let res = await API.HOME.getFaceuavvideoMsg({
      //   uavId: this.uavId,
      //   pageNo: this.pageNo,
      //   pageSize: this.pageSize
      // })
      for(let i = 0; i < list.length; i++){
        let item = res[i];
        if(item.longi){
          let address = await API.MAP.AiRegeo({
            location: `${item.longi},${item.lati}`
          })
          item.addr = address.province.value + address.city.value + address.dist.value + address.road.roadname + address.poi;
        }
        this.list.push(res[i])
      }
      console.log(this.list,"list");
    },
    
    onDetail(item){
      this.$set(item, 'show', !item.show);
    }
  }
}
</script>

<style lang="scss" scoped>
.face{
  position: fixed;
  z-index: 999;
  width: 268px;
  left: calc(50% - 134px);
  top: 310px;
}
.dialog-content{
  padding: 14px;
  cursor: initial;
  max-height: 461px;
  overflow-y: auto;
}

.result{
  width: 100%;
  margin-top: 27px;
  &:first-child {
    margin-top: 0;
  }
}

.rate-box{
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .rate-img{
    flex: 1;
    height: 75px;
    background-image: url('~@/assets/images/observe/faceAI_border1.png');
    background-size: 100% 100%;
    padding: 7px;
    display: flex;
    align-items: center;

    &.rate-img--contrary {
      background-image: url('~@/assets/images/observe/faceAI_border2.png');
      justify-content: right;
    }

    img {
      width: 70px;
      height: 70px;
      border-radius: 7px;
      overflow: hidden;
    }
  }

  .rate-round{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .rate-round__value{
      color: #fb799d;
      font-size: 14px;
      font-family: MicrosoftYaHei;
    }

    .rate-routd__text{
      cursor: pointer;
      font-size: 12px;
      color: #b9d7f0;
      font-family: MicrosoftYaHei;
      transform: scale(0.9);
      margin-top: 3px;
    }
  }
}

.rate-form{
  margin-top: 10px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #fff;

  .rate-form-item {
    margin-bottom: 12px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    .rate-form-item__label {
      width: 70px;
      text-align: right;
      flex-shrink: 0;
    }

    .rate-form-item__value {

    }
  }
}

.round-border{
  padding: 2px 8px;
  border-radius: 11px;
  border: 1px solid #ff4a4a;
  color: #ff4a4a;
  font-size: 14px;
  font-family: MicrosoftYaHei;
}
</style>