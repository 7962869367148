<template>
  <div class="w700 h180 takeOverBox">
    <div class="h32 lh32 headerBox jcsb pl16 pr16">
      <div class="title">
        <span class="w1 dib mr10 h16 mt4" style="background: #FFBD36;border:1px solid #FFBD36"></span>
        <span>接管记录</span>
      </div>
      <div class="cp FFBD36 fwb" @click="$emit('exit')">关闭</div>
    </div>
    <div class="pl16 pr16 pb16 contentBox mt16">
      <div class="hd">
        <div>无人机名称</div>
        <div>无人机所属单位</div>
        <div>接管状态</div>
        <div>当前控制单位</div>
        <div>操作</div>
      </div>
      <div class="bd" v-for="(item,index) in deviceList" :key="index">
        <div>{{item.deviceName}}</div>
        <div>{{item.org}}</div>
        <div class="" style="color:#FFBD36 ;" v-if="item.status=='被接管'">{{item.status}}</div>
        <div class="" v-else style="color:#31DB24 ;">{{item.status}}</div>
        <div>{{item.takeOverOrg}}-{{item.takeOverUser}}</div>
        <div class="cp ">
          <el-tooltip :content="item.status" placement="bottom">
            <div class="w20 h20  opretion2" v-if="item.status=='被接管'"></div>
          </el-tooltip>
          <el-tooltip content="退出接管" placement="bottom">
            <div class="w20 h20  opretion" v-if="item.status!='被接管'" @click="exit(item)"></div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import API from "@/api";
  export default {
    data() {
      return {
        deviceList: []

      };
    },
    created() {
      this.init()
    },

    methods: {
      async init() {
        let res = await API.FCKERNEL.getAllTakeOverList()
        if (res.code == 200) {
          this.deviceList = res.deviceList
        }
      },
      exit(item) {
        this.$confirm('请确认是否进行退出接管操作?', '安全确认', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          customClass: "uav_controlPane",
          showClose: false
        }).then(async () => {
          let res = await API.FCKERNEL.exitTaskOver({
            deviceHardId: item.deviceHardId
          })
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.init()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });

      }
    },
    props: {
      device: {
        type: Object,
        default: () => ({})
      },
    }
  };
</script>
<style lang="scss" scoped>
  .takeOverBox {
    background: rgba(74, 6, 6, 0.7);
    border: 1px solid #fb4a2d;
    position: absolute;
    left: 50%;
    top: 120px;
    transform: translateX(-50%);
    .success {
      color: green;
    }

    .FFBD36 {
      color: #ffbd36;
    }

    .headerBox {
      background-image: linear-gradient(180deg,
          #ffbe91 0%,
          rgba(153, 94, 45, 0.22) 40%,
          #1a0f05 100%);
      border: 1px solid #fb4a2d;
      box-shadow: inset 0 0 10px 2px #fb4a2d;

      .title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #ffe55c;
        letter-spacing: 0;
        font-weight: 700;
      }
    }

    .contentBox {
      overflow-y: auto;
      height: 100px;

      .hd {
        display: flex;
        justify-content: space-between;
        font-family: MicrosoftYaHei-Bold;
        font-size: 14px;
        color: #d5d5d5;
        letter-spacing: 0;
        font-weight: 700;
        margin-bottom: 16px;

        div {
          width: 150px;
          text-align: center;
        }
      }

      .bd {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        color: #fff;
        font-size: 14px;

        div {
          width: 150px;
          text-align: center;
        }

        .opretion {
          display: inline-block;
          margin: 0 auto;
          background: url("~@/assets/images/observe/fckernel/exit.png") no-repeat;
          background-size: 100% 100%;
        }

        .opretion2 {
          display: inline-block;
          margin: 0 auto;
          background: url("~@/assets/images/observe/fckernel/exit2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
</style>
