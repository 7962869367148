<template>
  <div class="angleControlBox mt24">
    <div class="angleControlBg w100 h100 dib">
      <div class="dib wih100 ht100 pr">
        <div
          :style="`transform: rotate(${90-rotate}deg);`"
          class="angleControlLB tc w40 ml33 mt30 h40"
        />
        <img
          class="pa top0 left27 w50 h50"
          src="@/assets/images/PagerP1/LBT.png"
          alt=""
        />
        <div class="iconfont icon-zuo1 pa top40 left10 c08c"></div>
        <div class="rolate iconfont icon-zuo1 pa top40 right10 c08c"></div>
      </div>
    </div>

    <div class="mt26 cf f16">
      <img
        class="dib w24 h24 mr10"
        src="@/assets/images/PagerP1/jd.png"
        alt=""
      /><span class="dib vas">角度调节</span>
    </div>
    <div class="h80 wih100 mt16 bg tc">
      <div class="AngleControl dib w120 h80 tc pr">
        <div
          class="left12 top23 pa w30 lh30 h30 left cp"
          @mousedown="rotateFn(1)"
          @mouseup="stopFn()"
        >
          <div class="iconfont icon-zuo1"></div>
        </div>
        <div
          class="pa right-10 top23 w30 lh30 h30 right cp"
          @mousedown="rotateFn(-1)"
          @mouseup="stopFn()"
        >
          <div class="rolate iconfont icon-zuo1"></div>
        </div>
      </div>
    </div>
    <div class="mt26 cf f16">
      <img
        class="dib w24 h24 mr10"
        src="@/assets/images/PagerP1/Voice.png"
        alt=""
      />
      <div class="dib vas">音量调节</div>
      <div class="jcsb pl20 mr10">
        <div
          class="dib rightBtn"
          @mousedown="handleChangeVolume(-1)"
          @mouseup="stophandleChangeVolume()"
        >
          -
        </div>
        <el-progress
          class="w120 lh1.3"
          :text-inside="true"
          :percentage="volume || 0"
          :stroke-width="17"
        ></el-progress>
        <div
          class="dib rightBtn"
          @mousedown="handleChangeVolume(1)"
          @mouseup="stophandleChangeVolume()"
        >
          +
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CmdSetSystemVolume from "@/utils/PagerP1/cmd_set_system_volume"; //设置系统音量
import CmdServoAngle from "@/utils/PagerP1/cmd_servo_angle"; //设置偏转角
export default {
  data() {
    return {
      volume: 0,
      rotate: 0,
      time: "",
      handleChangeVolumeTime: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.volume = this.PagerP1.volume
      this.rotate = this.PagerP1.angle
      }, 1000);
  },
  inject: ["PagerP1", "PoliceKey"],
  methods: {
    stopFn() {
      clearInterval(this.time);
    },
    rotateFn(type) {
      if (type == 1) {
        let message = {
          data: this.rotate ,
          type: 2314,
        };
        this.mqtt_publish(message);
        this.time = setInterval(() => {
          if (this.rotate < 60) {
            this.rotate++;
          }
        }, 100);
      } else {
        let message = {
          data: this.rotate,
          type: 2314,
        };
        this.mqtt_publish(message);
        this.time = setInterval(() => {
          if (this.rotate > 0) {
            this.rotate--;
          }
        }, 100);
      }
    },
    // 控制音量 start
    handleChangeVolume(cate) {
      this.stophandleChangeVolume();
      this.handleChangeVolumeTime = setInterval(() => {
        if (
          (this.volume == 0 && cate == -1) ||
          (this.volume == 100 && cate == 1)
        ) {
          return;
        }
        this.volume += cate;
        let message = {
          data: this.volume,
          type: 2315,
        };
        this.mqtt_publish(message);
      }, 100);
    },
    stophandleChangeVolume() {
      clearInterval(this.handleChangeVolumeTime);
    },
    async mqtt_publish(message) {
      if (this.PoliceKey()) {
        let key = await this.PoliceKey();
        this.PagerP1.mqtt.client.publish(
          `SPEAK/OBTAIN/${key}-01`,
          new TextEncoder().encode(JSON.stringify(message)),
          {
            qos: 0,
          },
          (e) => {
            console.log("发送指令成功:", message);
          }
        );
      }
    },
    mqtt_json2uint8array(data) {
      if (!data) {
        return data;
      }
      let { encoder } = this.PagerP1.mqtt;
      if (!encoder) {
        encoder = new TextEncoder();
      }
      let uint8array = encoder.encode(JSON.stringify(data));
      return uint8array;
    },
    // 控制音量 end
  },
};
</script>

<style lang="scss" scoped>
.angleControlBox {
  margin: 0 auto;
  text-align: center;
  .angleControlBg {
    background-image: url("~@/assets/images/PagerP1/bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    .angleControlLB {
      // transform-origin: 21px 46px;
      background-image: url("~@/assets/images/PagerP1/LB.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .rightBtn {
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #08c2d1;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    padding-left: 1px;

    &:hover {
      opacity: 0.7;
    }
  }
  .AngleControl {
    background-image: url("~@/assets/images/PagerP1/jdbj.png");
    background-repeat: no-repeat;
    background-size: 100%;
    .left {
      border-radius: 50%;
      color: #3d4654;

      background: #9aabbd;
    }
    .left:hover {
      color: #08c2d1;
    }
    .right {
      border-radius: 50%;
      color: #3d4654;
      background: #9aabbd;
    }
    .right:hover {
      color: #08c2d1;
    }
  }
}
.rolate{
  transform: rotateY(180deg);
}
.c08c {
  color: #08c2d1;
}
/deep/ .el-progress-bar__innerText{
  color: #000 ;
}
</style>
