<template>
    <div class="w270 h270" id="ymfzDom"></div>
</template>

<script>
    let echarts = require("echarts");
    export default {
        data() {
            return {
                data: 20,
                option: {
                    series: [
                        {
                            startAngle: -80, // 饼图的角度
                            hoverAnimation: false,  // 取消饼图放大效果
                            name: '访问来源',
                            type: 'pie',
                            radius: ['50%', '60%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {

                            },
                            labelLine: {
                                show: false
                            },
                            data: [

                                {
                                    value: 20, name: '',
                                    itemStyle: {
                                        normal: { // 渐变色操作
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 1, color: '#2D0180' },  // 
                                                { offset: 0.7, color: '#0094D6' },
                                                { offset: 0.4, color: '#00EBFF' },
                                                { offset: 0, color: '#FFCB00' }
                                            ])  //
                                        }
                                    },
                                },
                                {
                                    value: 80, name: '',
                                    itemStyle: {
                                        normal: { // 渐变色操作
                                            color: "rgba(0,0,0,0.66)"
                                        }
                                    },
                                },
                                {
                                    value: 50, name: '',
                                    itemStyle: {
                                        normal: { // 渐变色操作
                                            color: "transparent"
                                        }
                                    },
                                },

                            ]
                        }
                    ]
                },
            }
        },
        props: {
            channelData: {
                type: Object,
                default: () => ({}),
            },
        },
        watch: {
            'channelData': {
                handler(value) {
                    if (value?.accelerator !== undefined) {
                        this.updateDom(value.accelerator)
                    }
                },
                deep: true,
            },
        },
        computed: {},
        mounted() {
            this.init();
            window.$this.youMen = this;
        },
        methods: {
            init() {
                let chartDom = document.getElementById("ymfzDom");
                let myChart = echarts.init(chartDom);
                myChart.setOption(this.option);
            },
            updateDom(value) {
                let chartDom = document.getElementById("ymfzDom");
                let myChart = echarts.init(chartDom);
                let value1 = value / 10
                let value2 = 100 - value1

                let linear = [
                    { offset: 1, color: '#2D0180' },  // 
                    { offset: 0.7, color: '#0094D6' },
                    { offset: 0.4, color: '#00EBFF' },
                    { offset: 0, color: '#FFCB00' }
                ]
                if(value > 600){
                    linear = [
                        { offset: 1, color: '#2D0180' },  // 
                        { offset: 0.7, color: '#0094D6' },
                        { offset: 0.4, color: '#00EBFF' },
                        { offset: 0, color: '#f30909' }
                    ]
                }
                myChart.setOption({
                    series: [
                        {
                            data: [

                                {
                                    value: value1, name: '',
                                    itemStyle: {
                                        normal: { // 渐变色操作
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, linear)  //
                                        }
                                    },
                                },
                                {
                                    value: value2, name: '',
                                    itemStyle: {
                                        normal: { // 渐变色操作
                                            color: "rgba(0,0,0,0.66)"
                                        }
                                    },
                                },
                                {
                                    value: 50, name: '',
                                    itemStyle: {
                                        normal: { // 渐变色操作
                                            color: "transparent"
                                        }
                                    },
                                },
                            ]
                        },
                    ],
                });
            },
        },

    };
</script>

<style>
    #ymfzDom {
        position: absolute;
        left: -100px;
        top: -32x;
    }
</style>