<template>
  <div class="ovh mountControllerBox">
    <div class="wih00 fix h40 mountControllerTitle" v-interact>
      <div class="fl ml20 lh40 mr30 cfc fw700">探照灯</div>
      <div class="fr lh40 cfc mr20 cp" @click="$emit('close')">关闭</div>

    </div>
    <div class="jcsb mt20">
      <div class="ml70">
        <div class="form mt40">
          <div class="form_item">
            <span>常规模式：</span>
            <el-switch
              v-model="constant_bright_ctrlFlag"
              @change="constant_bright_ctrl"
              active-color="#2AEFED "
              inactive-color="#c6c8c8"
            >
            </el-switch>
          </div>
          <div class="form_item" v-if="!constant_bright_ctrlFlag">
            <span>闪光模式：</span>
            <el-switch
              @change="flicker_ctrl"
              v-model="flicker_status"
              active-color="#2AEFED "
              inactive-color="#c6c8c8"
            >
            </el-switch>
          </div>
          <div
            class="form_item"
            @change="very_bright_ctrl"
            v-if="constant_bright_ctrlFlag"
          >
            <el-checkbox v-model="checked">
              <span class="f17" style="color: #c7d3e9">极亮</span>
            </el-checkbox>
          </div>
          <div class="form_item">
            <span>回中开关：</span>
            <el-switch
              @change="middle_ctrl"
              v-model="middle_status"
              active-color="#2AEFED "
              inactive-color="#c6c8c8"
            >
            </el-switch>
          </div>
        </div>
      </div>
      <div class="mr30">
        <div class="form_item">
          <div class="f16" style="color: #cbd0eb">俯仰操作</div>
        </div>
        <div class="caozuoImg w150 mt10 h150 pr">
          <div class="rocker">
            <div id="static"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import data from "./data/index.js";
import methods from "./methods/index.js";

export default {
  inject: ["mountControllerThis"],
  data() {
    return {
      ...data,
      checked: false,
      constant_bright_ctrlFlag: false,
      value2: null,
      value3: null,
      ...data,
      // 控制挂载信息体
      controInfor: {
        type: 200,
        systemCode: "mmc",
        state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
        username: "",
        height: 0,
        idlingTime: 0,
        data: {
          cmdControlType: 7000, //550
          cmdFunction: null,
          cmdState: null,
          cmdValue: null,
          cmdName: "MMC_Gimbal_L50",
          cmdBuffer: "",
        },
        deviceHardId: this.mountControllerThis.deviceHardId,
      },
    };
  },
  mounted() {
    this.controInfor.username = this.user_info && this.user_info.username;
    this.joystickFn();
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    ...methods,
    joystickFn() {
      let _this = this;
      joystick({
        zone: document.getElementById("static"),
        mode: "static",
        position: { left: "50%", top: "50%" },
        color: "#9bacbe",
        onstart: function (type) {
          switch (type) {
            case "up":
              _this.gimbal_pitch_ctrl(1);
              break;
            case "right":
              // _this.gimbal_yaw_ctrl(1);
              break;
            case "left":
              // _this.gimbal_yaw_ctrl(-1);
              break;
            case "down":
              _this.gimbal_pitch_ctrl(-1);
          }
        },
        onend: function () {
          _this.gimbal_pitch_ctrl(0);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
      .mountControllerBox{
    background: rgba(0, 23, 79, 0.7);
    border-radius: 10px;
  }
.form {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 40px;
  color: #dce9ff;
  letter-spacing: 1px;
  box-sizing: border-box;
  .form_item {
    display: flex;
    margin-bottom: 15px;
    .form_item_title {
      display: block;
      width: 85px;
    }

    .mono_box {
      width: 28px;
      height: 13px;
      border-radius: 3px;
      margin-top: 2px;
      cursor: pointer;
    }

    .active {
      background: url("~@/assets/images/accident/mono_box.png") no-repeat !important;
      background-size: 100%;
    }

    .mono-short,
    .mono-long,
    .slider,
    .text {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    .mono-left {
      margin-left: 2px;
    }

    .mono-right {
      margin-right: 2px;
    }

    .mono-short {
      width: 111px;
      height: 17px;
      background: url("~@/assets/images/mount_short.png") no-repeat;
      background-size: 100%;
      margin-bottom: 5px;
    }

    .mono-long {
      width: 168px;
      height: 17px;
      background: url("~@/assets/images/mount_long.png") no-repeat;
      background-size: 100%;
      margin-bottom: 5px;
    }

    .imgs {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      & > img {
        cursor: pointer;
        width: 32px;
        height: 32px;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .slider_box {
      width: 140px;
      margin: -7px 5px 0;
    }

    .symbol {
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid #08c2d1;
      width: 24px;
      height: 24px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      font-size: 20px;
      padding-left: 1px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .img_bottom {
    display: flex;
    width: 320px;
    justify-content: space-between;

    & > img {
      cursor: pointer;
      width: 130px;
      height: 130px;
    }
  }

  ::v-deep {
    .el-select,
    .el-input {
      width: 170px;
      height: 26px;
    }

    .el-input__inner {
      width: 170px;
      height: 26px;
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }

    .el-select .el-input .el-select__caret {
      line-height: 26px;
    }

    // 修改input清除按钮样式
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-icon-circle-close:before {
          line-height: 16px;
          position: absolute;
          top: 5px;
          right: 3px;
        }
      }
    }

    .el-button {
      margin-left: 10px;
      padding: 0;
      background: #2aefed;
      border-radius: 2px;
      width: 43px;
      height: 24px;
      outline: none;
      color: #000000;
      border: none;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.caozuoImg {
  background-image: url("~@/assets/images/accident/operation.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.bfsx {
  background-image: url("~@/assets/images/accident/shunxu.png");
  background-position: 49px 30px;
  width: 37px;
}
.mountControllerTitle{
  background: rgba(16,65,215,0.20);
box-shadow: inset 0 0 15px 0 rgba(0,182,255,0.60);
border-radius: 10px 10px 0 0;
}
</style>