import camera from "./camera.js"
export default {
    camera,
    activeBox1: 1,
    activeBox3: 0, //相机模式 0录像 2 拍照
    speed: null,
    pitch: null, //俯仰
    yaw: null,
    zoom:1,
    gotversion:false,
    yaw_move:false,
    pitch_move:false,
    control_speed: 3,
    click_mode_status:1,
    IAMODE : 0,
    altitude:0,
    latitude:0,
    longitude:0,
    videoFlag:false,
    holderModelDom:1,
    update_status_gimbal_mode:0,
    gimbal_mode:1,
   goteuler2msg :false
}