import mqtt from "mqtt";
import dayjs from "dayjs";
const {
  VUE_APP_FCMQTT_URL
} = process.env;
import API from "@/api";
export default {
  uint8array2json(uint8array) {
    if (!uint8array || !uint8array.length) {
      return uint8array;
    }
    let {
      decoder
    } = this.mqtt;
    if (!decoder) {
      decoder = new TextDecoder("utf-8");
    }
    let content = decoder.decode(uint8array);
    return content ? JSON.parse(content) : content;
  },

  json2uint8array(data) {
    if (!data) {
      return data;
    }
    let {
      encoder
    } = this.mqtt;
    if (!encoder) {
      encoder = new TextEncoder();
    }
    let uint8array = encoder.encode(JSON.stringify(data));
    return uint8array;
  },
  circulate() {
    clearTimeout(this.uav.offlineTime)
    this.uav.offline = false
    this.uav.offlineTime = setTimeout(() => {
      this.uav.offline = true
      this.mqtt_inserWebLogs()
    }, 3000);
  },
  circulate_gas(deviceHardId, data) {
    clearTimeout(this.uav.circulate_gas_time)
    this.uav.circulate_gas = true
    let mounted = {
      "connected": true,
      "gimbalName": "MMC_Gimbal_GAS",
      "mountId": 27,
      "mountType": 1051,
      "type": 0,
      "widgetHeight": 505,
      "widgetWidth": 500
    }
    // 挂载数据
    this.uav_update_mount({
      deviceHardId
    }, mounted);
    this.uav.circulate_gas_time = setTimeout(() => {
      this.uav.circulate_gas = false

    }, 3000);
  },
  // 记录数据d断开日志
  async inserWebLogs() {
    let day = dayjs();
    let {
      user_info
    } = this;
    let {
      deviceName,
      deviceHardId
    } = this.uav.control.device
    const time = day.format("YYYY-MM-DD HH:mm:ss");
    let errorData =
      "登录用户：" +
      user_info.name +
      "，所属机构：" +
      deviceName +
      ",无人机id：" +
      deviceHardId +
      ",断开时间" +
      time;
    let errorType = '无人机应用'
    let res = await API.FCKERNEL.inserWebLogs({
      errorType,
      errorData
    })
  },
  init() {
    let {
      user_info,
      uav
    } = this;
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    // const crypto = window.crypto || window.webkitCrypto || window.mozCrypto || window.oCrypto || window.msCrypto;
    // let uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    let url = VUE_APP_FCMQTT_URL;
    // user_info.id


    let protocol = url.replace(/(.+)\:\/\/.*/, "$1");
    this.mqtt.client = mqtt.connect(url, {
      protocol,
      clientId: user_info.id,
    });

    this.mqtt.client.on("connect", () => {
      console.log("mqtt is connected");
    });
    this.mqtt.client.on("reconnect", function (reconnect) {
      console.log(reconnect, "reconnect");
    });
    this.mqtt.client.on("`error`", (error) => {
      console.log("mqtt is `error`", error);
    });
    this.mqtt.client.on("message", (topic, payload, packet) => {
      let data = this.mqtt_uint8array2json(payload);
      let deviceHardId = packet.topic.split("/")[2];
      // window.$log("observe/fckernel/nest/methods/mqtt.js message", data);
      try {
        data.data.locationCoordinate3D.altitude += 10; //由于3D地图悬浮于2D地图表面，需要增加无人机模型的海拔高度，不然会被3D地图遮挡
      } catch (e) { }
      // console.log(uav.videos[0].deviceHardId,topic,'topic');
      // 判断当前无人机和传过去的无人机是否相等
      // let ycTopic = `APRON/RECEIVE/${uav.videos[0].deviceHardId}`;
      // console.log(this.uavId.deviceHardId,'deviceHardIddeviceHardId');
      //  console.log(deviceHardId,'nest.data.deviceHardId');
      //
      // console.log(this.uavId.deviceHardId,'this.uavId.deviceHardId');
      if (this.uavId.deviceHardId == deviceHardId) {
        if (data.type == 273) {
          this.NXdata = data.data
          if (data?.data?.messageID == 2058) {
            this.nxFlagData = data.data?.data
            let val = "";
            // QingliuFlag
            if (this.nxFlagData?.RtmpFlag) {
              val = "livenvr";
            } else {
              val = "QingLiu";
            }
            this.$store.commit("fckernel/SET_STREAM", val);
          }
        }
        if (data.type === 258) {
          this.$emit("getUavData", data.data)
          this.mqtt_circulate()
          // 更新无人机位置信息
          this.uav_update_location({
            deviceHardId
          }, data.data);
          // 更新控制面板数据
          this.uav_update_control({
            deviceHardId
          }, data.data);
        }
        if (data.type === 268) {
          // window.$falg = localStorage.getItem('$falg') == 1 ? true : false;
          // if(window.$falg == true){
          //   console.log(data.data.accelerator, "油门")
          // }
        }
        //健康管理
        if (data.type == 275) {
          this.healthData = data.data
          this.$emit("getHealth", data.data)
        }
        if (data.type === 261) {
          // 等待航线上传成功在执行
          if (this.takeOff == true) {
            this.fly_take_off();
          }
        }
        //航线执行进度
        if (data.type == 265) {
          this.scheduleData = data.data
        }
        if (data.type === 263) {
          console.log(data, '航线进度');
        }
        if (data.type === 268) {
          this.$store.commit("fckernel/SET_CHANNEL7", data.data.channel7);
          this.uav_update_channelData({
            deviceHardId
          }, data.data);
        }
        if (data.type === 257) {
          this.$emit("getBattery", data.data)
          // 无人机电池数据
          this.uav_update_control_battery({
            deviceHardId
          }, data.data);
        }
        if (data.type === 514) {
          // 挂载数据
          this.uav_update_mount({
            deviceHardId
          }, data.data);


        }
        if (data.type === 259) {
          this.$store.commit("fckernel/set_state", {
            key: "mount",
            value: { ...data.data, deviceHardId }
          });
          this.uav_update_mount({
            deviceHardId
          }, data.data);

          this.uav_update_mountData({
            deviceHardId
          }, data.data)
          // 挂载数据
        }
        // 无人机故障信息
        if (data.type === 269) {
          this.$message.info(data.data);
        }
        if (data.type == 270) {
          this.uav.wrjType = data.data;
          this.uav.msg_list.unshift({
            type: data.data.code,
            text: data.data.text,
            time: this.mqtt_getstartDate()
          });
          // console.log(data,"msg_list");
          if (data.data.code == 10043) {
            this.$message.success(data.data.text);
          } else if (data.data.code == 10053) {
            this.$message.success(data.data.text);
          }
          if (data.data.cmd == "-1") {
            this.$message.error(data.data.text);
          }
        }
        // 无人机提示信息
        if (data.type === 260) {
          if (data.data.severity == 99) {
            this.warnShow = true
            this.warnMessage = data.data.msg
          }
          this.uav.msg_list.unshift({
            type: "警告",
            grade: data.data.severity,
            text: data.data.msg,
            time: this.mqtt_getstartDate()
          });
          this.uav_update_control_info({
            deviceHardId
          }, data.data);
        }
        if (data.type === 500 && data.msgnum === 4132) {
          // 设备上线
          this.uav_update_device_report_status(1, deviceHardId);
        }
        if (data.type === 500 && data.msgnum === 4121) {
          // 设备离线
          this.uav_update_device_report_status(0, deviceHardId);
        }
        if (data.type === 500 && data.msgnum === 4121) {
          // 设备离线
          this.uav_update_device_report_status(0, deviceHardId);
        }
        if (data.type === 264) {
          // 航线上传
          this.airway_upload_message(data.data, packet.topic);
        }
        if (data.type === 272) {
          //避障信息
          this.$store.commit('fckernel/SET_OBSTACLE', {
            ...data.data,
            deviceHardId
          });
        }
      }
      //  气体检测数据
      let GAStopic = this.$store.state.device.gastopic;
      if (topic == GAStopic) {
        this.mqtt_circulate_gas(this.uavId.deviceHardId, data)
      }
    });

  },
  getstartDate() {
    let date = new Date();
    let y = date.getFullYear();
    let m =
      date.getMonth() + 1 < 10 ?
        "0" + (date.getMonth() + 1) :
        date.getMonth() + 1;
    let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let mi =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    let startDateTime =
      y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
    return startDateTime
  },

  /**
   * 订阅
   * @param {*} topic
   * @param {*} callback
   */
  subscribe(topic, callback) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      console.log("订阅数据a", topic);
      client.subscribe(topic, callback);
    }
  },

  /**
   * 取消订阅
   * @param {*} topic
   * @returns
   */
  unsubscribe(topic) {
    if (!topic) {
      return topic;
    }
    let {
      client
    } = this.mqtt;
    if (client) {
      client.unsubscribe(topic);
    }
  },

  /**
   * 发布
   * QoS 0：消息最多传递一次，如果当时客户端不可用，则会丢失该消息。
   * QoS 1：消息传递至少 1 次。
   * QoS 2：消息仅传送一次。
   * @param {*} publication
   * @param {*} callback
   * @param {*} options
   * @param {*} type
   */
  publish(topic, message, callback, options = {
    qos: 0
  }) {
    let {
      client
    } = this.mqtt;
    // console.log(JSON.stringify(message), "发送数据---------------");
    // this.mqtt_json2uint8array(message)
    client.publish(
      topic,
      new TextEncoder().encode(JSON.stringify(message)),
      options,
      callback
    );
  },
};