<template>
  <div class="taskListBox w380" :class="{yidong:taskstyle == false}">
    <div class="header wih100 h32">
      <div class="title ml10">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">任务库</div>
      </div>
    </div>

    <div v-show="taskType == 'itemA'" class="pt38 jianju pr">
      <div class="flexs">
        <span class="cf">任 务 库</span>
        <!-- <el-select size="mini" class="w300 mt10 ml10" v-model="flyTypeId" placeholder="请选择任务">
        <el-option v-for="item in TaskList" :key="item.id" :label="item.taskTitle" :value="item.id">
        </el-option>
        </el-select>-->
        <el-tooltip :content="clew" placement="top">
          <el-cascader
            filterable
            popper-class="cpt-observe-mspace-dashboard-airway"
            size="mini"
            class="w276 ml10 cpt-observe-mspace-dashboard-airway"
            v-model="flyTypeId"
            :options="airway_list"
            clearable
            :show-all-levels="false"
            placeholder="请选择任务"
            :props="{
              children: 'children',
              label: 'taskTitle',
              value: 'id',
              emitPath: false,
            }"
            @change="v_value"
            :disabled="taskCate == 3 ? true : false"
          >
            <template slot-scope="{ data }">
              <el-tooltip
                :disabled="data.taskTitle.length < 11"
                class="item"
                effect="dark"
                :content="data.taskTitle"
                placement="top-start"
              >
                <span>{{ data.taskTitle }}</span>
              </el-tooltip>
            </template>
          </el-cascader>
        </el-tooltip>
        <div class="cf mt20 tc" style="display: flex">
          <span class="line_height">
            航
            <i style="opacity: 0">任</i> 线
          </span>
          <el-tooltip content="航线名称" placement="top">
            <el-input
              v-if="flightFlag"
              disabled
              v-model="flight[0].flightName"
              placeholder="请选择航线"
              class="ml10 w276 mrg_left6"
              size="mini"
            ></el-input>
            <el-button v-else @click="lineshow = true" class="kbt_button">选择航线</el-button>
            <!-- <el-select
              v-else
              size="mini"
              class="w276 ml10 mt10"
              v-model="flightId"
              placeholder="请选择航线"
            >
              <el-option
                v-for="item in flightList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>-->
          </el-tooltip>
        </div>
        <div>
          <div></div>
          <div class="btn cf ml18 cp mt40" @click="handClickTask">一键任务</div>
          <div class>
            <span
              v-if="!flightFlag"
              style="color: #43deff; bottom: -18px"
              class="lh30 dib cp f8 right16 pa"
              @click="craeteRoute"
            >
              <span class="f8"></span> 创建航线
            </span>
          </div>
        </div>
      </div>
    </div>
    <LineList v-if="lineshow" @changeLine="changeLine" @close="lineshow = false"></LineList>
  </div>
</template>
<script>
import API from "@/api";
import LineList from "../lineList";
import methods from "./methods";
import { mapGetters } from "vuex";
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  name: "taskList",
  components: { LineList },
  data() {
    return {
      taskType: "itemA",
      rwList: [],
      TaskList: [],
      // 任务id
      flyTypeId: "",
      flight: [
        {
          flightName: "",
        },
      ],
      // 航线数据
      flightList: [],
      // 航线id
      flightId: "",
      flightIdv2: "",
      // 航线是否可选
      flightFlag: true,
      // 航线数据
      flightLinefkData: "",
      airway_list: [],
      lineshow: false,
      clew: "任务库", // 飞控任务库提示语
      // taskstyle: false,
      flightSortic: null,
      taskCate: 1,
      username: null,
      distanceLine: 0,
    };
  },
  props: {
    wsShow: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    taskstyle: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["uav_This"],
  async created() {
    this.username = this.user_info.username;
    await this.list_airway();
    await this.initTask();
    // 飞控选择其他模块时，关闭航线
    // 关键字：关闭预设航线
    Bus.$on("uav_Route", () => {
      this.close_the_router();
    });
    // 等待航线上传成功在执行
    Bus.$on("take_off", () => {
      this.upload_complete();
    });
    // 修改飞控 无人机 左边'任务库'的位置
    // Bus.$on("ydh", (status) => {
    //   if (status == false) {
    //     this.taskstyle = false;
    //   } else {
    //     this.taskstyle = true;
    //   }
    // });
  },
  methods: {
    ...methods,
    craeteRoute(){
      this.close_the_router();
      this.$emit('craeteRoute')
    },
    v_value(e) {
      if (e == null) {
        this.clew = "任务库";
        sessionStorage.removeItem("waypointList");
      }
    },
    changeLine(id) {
      this.flightId = id;
    },
    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    async initTask() {
      let res = await API.FCKERNEL.getTaskAndUserRelation({
        sourceType: 0,
        deviceHardId: this.device.deviceHardId,
      });
      if (res.code == 200 && res.list) {
        this.list = res.list;
        // taskCateId 2  1警情 3临时
        this.airway_list[this.list.taskCateId]?.children.push({
          id: res.list.taskId,
          flightName: res.list.flightName,
          flightLineId: res.list.flightId,
          flightCourseJson: res.list.flightCourseJson,
          userId: res.list.userId,
          taskStatus: res.list.taskStatus,
          taskTitle: res.list.taskTitle,
          taskCateId: res.list.taskCateId,
        });
        this.showRoute(res.list);
        this.flyTypeId = res.list.taskId;
        this.taskCate = res.list.taskStatus;
        this.flightIdv2 = res.list.flightId;
        this.saveFlightLineId(res.list.flightId);
      } else {
        sessionStorage.removeItem("waypointList");
        this.taskCate = 1;
        this.flight[0].flightName = null;
        this.clew = "任务库";
        this.flyTypeId = null;
      }
    },
    async handClickTask() {
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!flag) return;
      if (this.flightFlag && this.flyTypeId) {
      if (this.uav_This.uav.control.device.stationType == 1) {
        this.$emit("getSelf");
      }else{
        this.handClickTask2()
      }

      }else {
        this.$message.error("请选择任务");
      }
    },
    async handClickTask2() {
      // this.$emit("uav-location");
      // let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      // if (!flag) return;
      // 航线
      // if (this.flightFlag && this.flyTypeId) {
        // 局部预警
        let checkBeforeTakeOff = await API.DEVICE.checkBeforeTakeOff({
          taskId: this.flyTypeId,
        });
        if (!checkBeforeTakeOff.code) {
          this.$emit("Lsdom", checkBeforeTakeOff);
          return;
        }
        // this.$confirm("请确认是否进行一键任务操作?", "安全确认", {
        //   cancelButtonText: "取消",
        //   confirmButtonText: "确定",
        //   customClass: "uav_controlPane",
        //   showClose: false,
        // })
        //   .then(async () => {
            this.getTrue();
          // })
          // .catch(() => {
          //   this.$message({
          //     type: "info",
          //     message: "已取消操作",
          //   });
          // });
      // } else {
      //   this.$message.error("请选择任务");
      // }
    },
    async getTrue() {
      // console.log(this.flightLinefkData,"this.flightLinefkData.labelName");
        // this.$emit("changeLableName",this.flightLinefkData.labelName)
      // this.$emit("uav-location", 1);
      // return
        // // 生成架次號
        let getFlightSortic = await API.DEVICE.getFlightSortic({
          taskId: this.flyTypeId,
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
        this.flightSortic = getFlightSortic;
      if (this.uav_This.uav.control.device.stationType == 1) {
        //飞控中心链路
        // 上传航线指令
        this.$emit("fn", {
          type: 521,
          data: {
            ...this.flightLinefkData,
            taskId: this.flyTypeId,
            flightSortiesID: getFlightSortic,
          },
        });
        // 等待航线上传成功在执行
        // 判断是否以一键任务
        Bus.$emit("off_take");
      } else {
        //地面站链路
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2113,
            cmdValue: this.flightId || this.flightIdv2, //航线id
            taskId: this.flyTypeId + "",
            flightSortieName:getFlightSortic
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
        // this.upload_complete()
      }
    },
    // 航线上传成功再执行
    async upload_complete() {
      if (this.uav_This.uav.control.device.stationType == 1) {
        // 生成架次號
        // let getFlightSortic = await API.DEVICE.getFlightSortic({
        //   taskId: this.flyTypeId,
        //   deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        // });
        // 告诉飞控开始任务,并且把架次号和 任务id传过去
        this.startFlight({
          taskId: this.flyTypeId,
          flightSortiesID: this.flightSortic,
        });
        // }
        // 更改任务状态 status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
        this.task_update(3);
        // if(this.uav_This.uav.control.device.stationType == 1){
        // 记录任务
        await API.FCKERNEL.addTaskAndUserRelation({
          taskId: this.flyTypeId,
          sourceType: 0, //鹰巢为1 无人机0
          deviceHardId: this.device.deviceHardId,
        });

        let _this = this;
        setTimeout(() => {
          _this.$emit("fn", {
            data: 0,
            type: 522,
          });
        }, 3000);
      } else {
        console.log(
          this.uav_This.uav.control.device.deviceHardId,
          this.flyTypeId,
          "4741852963"
        );
        //地面站链路
        //解锁无人机
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2110,
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
        //预览航线
        // this.$emit("fun", {
        //   type: 200,
        //   systemCode: "mmc",
        //   state: 1,
        //   username: this.username,
        //   data: {
        //     cmdControlType: 900,
        //     cmdFunction: 9001
        //   },
        //   deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        // });
        //一键起飞
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2111,
            cmdValue: 50, //高度
            taskId: this.flightId || this.flightIdv2,
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
        // status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
        let task = await API.DEVICE.task_update({
          id: this.flyTypeId,
          status: 3,
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
        // 记录任务
        await API.FCKERNEL.addTaskAndUserRelation({
          taskId: this.flyTypeId,
          sourceType: 0, //鹰巢为1 无人机0
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
        // 一键起飞任务监听
        let res = await API.TASK.flytaskLisener({
          taskId: this.flyTypeId,
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
        //航线模式
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2115,
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        });
      }
      this.taskCate = 3;
      this.$message.success("操作成功");
      this.$emit("iconShow");
    },

    async saveFlightLineId(flightLineId) {
      console.log("暂无航线_a",flightLineId);
      if (flightLineId) {
        // this.$emit("changeLableName","河道巡检")
        let res = await API.AIRWAY.GetAirwayInfo(flightLineId);
        this.flightLinefkData = res.data;
        // console.log(res.data,"res.data");
        // delete this.flightLinefkData.flightJson
        // console.log(JSON.parse(res.data.flightJson));
        let lineData = null
        // console.log(JSON.parse(res.data.flightJson),"JSON.parse(res.data.flightJson)");
        
        if(JSON.parse(res.data.flightJson).points[0].ailist){
          this.flightLinefkData.headingMode="USING_WAYPOINT_HEADING"
        }
        if(JSON.parse(res.data.flightJson).waypoints){
          lineData = JSON.parse(res.data.flightJson).waypoints
          this.flightLinefkData.waypointList = lineData
        }else{
          lineData = JSON.parse(res.data.flightJson).points
         this.flightLinefkData.waypointList.forEach((val,i) => {
           lineData.forEach((val2,j)=>{
             if(i == j){
               val.waypointActions = val2.actions 
             }
           })
         });
        }
        delete this.flightLinefkData.flightJson
        console.log(this.flightLinefkData,"flightLinefkData");
        if (this.flightLinefkData) {
          this.$emit("changeLableName","")
          console.log("zheli");
          try {
            sessionStorage.setItem(
              "waypointList",
              JSON.stringify({
                uavDeviceId: this.uav_This.uav.control.device.deviceHardId,
                wayLineObj: this.flightLinefkData,
                flyTypeId: this.flyTypeId,
              })
            );
          } catch (error) {
            sessionStorage.removeItem("waypointList");
          }
        }
      }
    },

    async getFlightLine(id) {
      let flightLineData = await API.USER.routesListqq(id);
      this.flight[0] = flightLineData;
      // 预览航线
      this.showRoute(flightLineData);
      // 自动生成任务
      let task = await API.AIRWAY.addNestAutoTask({
        lineId: id,
        type: 1,
      });
      // 重新请求航线
      await this.list_airway();
      this.flyTypeId = task.id;
      console.log(10);
      return;
      let flightLinefkData = await API.AIRWAY.GetAirwayInfo(id);
      this.flightLinefkData = flightLinefkData.data;
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  watch: {
    device: function (value) {
      this.initTask();
    },
    flyTypeId: function (value) {
      if(window.location.hash.includes("accident")){
        this.$store.commit('fckernel/SET_TASK_ID_YJ', value);
      }else{
        this.$store.commit("fckernel/SET_TASK_ID", value);
      }
      if (value) {
        if (value == "z1322" || value == "") {
          this.flightFlag = false;
          this.flightId = "";
          this.clew = "任务库";
        } else {
          this.flightFlag = true;

          let arr = [];
          if (this.airway_list[1]?.children) {
            arr.push(...this.airway_list[1].children);
          }
          if (this.airway_list[2]?.children) {
            arr.push(...this.airway_list[2].children);
          }
          if (this.airway_list[3]?.children) {
            arr.push(...this.airway_list[3].children);
          }
          if (arr.length > 0) {
            let item = arr.find((item) => item.id === value);
            // 提示语的信息
            this.clew = item.taskTitle;
            this.flight = [item];
            if (item.flightLineId) {
              this.flightIdv2 = item.flightLineId;
              this.saveFlightLineId(item.flightLineId);
              this.showRoute(item);
            }
          }
        }
      }
    },
    flightId: function (value) {
      if (value) {
        // this.saveFlightLineId(value)
        this.getFlightLine(value);
      }
      // this.handleAirLinePreview(this.flight[0], true);
    },
    "uavData.gps.fixType": function (value) {
      if (value == "LOCK_3D_RTK_GPS_LOCK_FIXED") {
        this.$message.success("RTK已就绪");
        this.fixType = true;
      } else {
        this.fixType = false;
      }
    },
  },
};
</script>
<style>
.el-cascader-menu__list {
  background: rgba(9, 32, 87, 1) !important;
  max-height: 300px;
  max-width: 327px;
}

.el-cascader-node {
  background: rgba(9, 32, 87, 1) !important;
}
</style>
<style lang="scss" scoped>
.yidong {
  left: 544px !important;
}
.taskListBox {
  position: absolute;
  left: 80px;
  top: 85px;
  min-height: 254px;
  background: rgba(9, 32, 87, 0.7);
  // border: 1px solid #70daf9;
  border-radius: 10px;
  transition: 0.3s;

  .header {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    .title {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .active {
      background-image: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.22) 40%,
        #05091a 100%
      );
      border: 1px solid #70daf9;
      box-shadow: inset 0 0 10px 2px #3f9dff;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      color: #70daf9;
      letter-spacing: 0;
      font-weight: 700;
    }

    .default {
      background-image: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.22) 40%,
        #05091a 100%
      );
      border: 1px solid #70daf9;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      color: rgba(112, 218, 249, 0.5);
      letter-spacing: 0;
      font-weight: 700;
    }
  }
  .jianju {
    // background: rgba(9, 32, 87, 0.7);
    border-radius: 10px 0 0 10px;
    display: flex;
    justify-content: center;
  }

  .taskList {
    overflow-y: auto;

    .rwtitle {
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      color: #b5e5ff;
      padding: 5px 0;
      letter-spacing: 0;
      font-weight: 700;
      background: rgba(87, 96, 138, 0.2);
      border: 1px solid rgba(207, 234, 255, 0.33);
    }

    .rwinfo {
      color: #ffffff;
      background: url("~@/assets/images/observe/fckernel/nest/listBg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 33px;
      line-height: 33px;
      padding-left: 4px;
    }
  }

  .btn {
    width: 122px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    margin: 0 auto;
    margin-bottom: 10px;
    // background-image: linear-gradient(
    //   180deg,
    //   #9198ff 0%,
    //   rgba(45, 81, 153, 0.22) 40%,
    //   #05091a 100%
    // );
    // border: 1px solid #70daf9;
    // box-shadow: inset 0 0 10px 2px #3f9dff;
    background: url("~@/assets/yingkong1027/btn_lan.png") no-repeat;
    background-size: 100% 100%;
  }

  .Titlehideen {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.el-cascader-menu {
  width: 200px;
}
.mrg_left6 {
  margin-left: 10px;
}
.kbt_button {
  margin-left: 10px;
  width: 80%;
  height: 30px;
  padding: 0;
}
.line_height {
  line-height: 28px;
}
</style>
