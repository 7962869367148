<template>
  <div class="car dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon" />
      <div class="dialog-header__title">
        快速建模
      </div>
      <div class="dialog-header_close" style="color:#fff" @click="() => $emit('close')">关闭</div>
    </div>
    <div class="dialog-content w267">
      <el-cascader size="mini" filterable popper-class="cpt-observe-mspace-dashboard-airway_popper" v-model="taskCateId"
        :options="airway_list" clearable :show-all-levels="false" placeholder="请选择任务" :props="{
          children: 'children',
          label: 'taskTitle',
          value: 'id',
          emitPath: false,
        }">
        <template slot-scope="{  data }">
          <el-tooltip :disabled="data.taskTitle.length < 11" class="item" effect="dark" :content="data.taskTitle"
            placement="top-start">
            <span>{{ data.taskTitle }}</span>
          </el-tooltip>
        </template>
      </el-cascader>
      <el-select class="mt10" v-model="type" size="mini" placeholder="请选择类型">
        <el-option label="图片" :value="0"></el-option>
        <el-option label="视频" :value="1"></el-option>
      </el-select>
      <div class="jcsb w267 h30 mt30">
        <div class="dec mt5">1</div><span class="dib cf ml10 lh30"> 快速建模</span>
        <div class="btn fr cf h30 lh30 w80 cp" @click="jmFn(1)">启动</div>
      </div>
      <div class="jcsb mt18 w267 h30">
        <div class="dec mt5">2</div><span class="dib cf lh30 ml10">数据处理</span>
        <div class="btn fr cf h30 lh30 w80 cp" @click="jmFn(2)">启动</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api';

export default {
  props: {
    device: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_URL,
      list: [],
      type: null,
      taskCateId: null,
      airway_list: []
    }
  },
  created() {
    this.list_airway();

  },
  methods: {

    jmFn(num) {
      let { deviceHardId } = this.device
      if (num == 1) {
        let a = document.createElement("a");
            a.href = `MMCEagleEye:// `
            a.click()
      }
      else {
        if(!this.taskCateId) return this.$message.warning('请选择任务！')
        if(this.type==null  ) return this.$message.warning('请选择类型！')
        let a = document.createElement("a");
      a.href = `MMCPosTool://&deviceId=${deviceHardId}enddeviceId&taskId=${this.taskCateId}endtaskId&type=${this.type}endtype`
      a.click()
      }

    },
    async list_airway() {
      let res = await API.AIRWAY.getApprovedTask();
      if (res) {
        let jqList = [],
          ctList = [],
          lsList = [];
        for (let i = 0; i < res.length; i++) {
          if (res[i].taskCateId == 1) {
            jqList.push(res[i]);
          }
          if (res[i].taskCateId == 2) {
            ctList.push(res[i]);
          }
          if (res[i].taskCateId == 3) {
            lsList.push(res[i]);
          }
        }
        let airway_list = [
          {
            id: "警情任务",
            taskTitle: "警情任务",
            children: jqList,
          },
          {
            id: "常态任务",
            taskTitle: "常态任务",
            children: ctList,
          },
          {
            id: "临时任务",
            taskTitle: "临时任务",
            children: lsList,
          },
        ];
        this.airway_list = airway_list;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.car {
  width: 268px;
}

.dialog-content {
  padding: 20px 14px 12px;
  max-height: 461px;
  overflow: auto;

  .dec {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #06199b;
  }

  .btn {
    text-align: center;
    border: 1px #315ec7 solid;
    border-radius: 3px;
    background-color: #02173d;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #D2D9FF;
    cursor: pointer;

  }

  .btn:hover {
    background: #06199b;

  }
}

.car-img {
  position: relative;
  width: 100%;
  height: 135px;

  .car-img__img {
    width: 100%;
    height: 100%;
  }

  .car-img__label {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #aee9ff;
    font-family: MicrosoftYaHeiUI;
  }
}

.car-form {
  padding: 10px 0 0;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #fff;

  >div {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.car-item {
  margin-bottom: 24px;
}
</style>