<template>
  <!-- 清流融合 -->
  <div class="wih100 ht100">
    <div :class="{ 'full-video': isStatus }" ref="wrap">
      <div
        :class="infoflag ? 'interact-wrap' : 'interact_wrap_full'"
        @dblclick="screen"
      >
        <div
          class="cpt_video"
          :class="big"
          @click="fn"
          v-if="flag"
          ref="video"
          v-interact
        >
          <Obstacle v-if="!isStatus"></Obstacle>
          <div class="video">
            <div class="name" v-clipboard:copy="vUrl.vUrl">
              {{ data.deviceName }}
            </div>
            <!-- <div class="type" v-if="networkType">{{ networkType }}</div> -->
            <!-- <div class="types">{{ streamName }}</div> -->
            <div v-show="!isStatus" class="close" @click="close(data)">
              关闭
            </div>
          </div>
          <el-tooltip content="模式切换" placement="bottom">
            <div
              v-if="streamSelect !== 'QingLiu'"
              class="cp pa iconfont icon-moshiqiehuan cf modelStyle"
              @click="change"
            ></div>
          </el-tooltip>
          <videoModelChange
            :data="data"
            @fn="videoModelChangeFn"
            v-if="streamSelect == 'QingLiu' && !isStatus"
            class="cp pa cf modelStyle"
            @click="VideoModelChange"
            :uavData="uavData"
          ></videoModelChange>

          <components
            :is="playerCom"
            :videoFlag="videoFlag"
            :isStatus="isStatus"
            ref="player"
            :LivePlayerInfor="false"
            :fpvUrl="fpvUrl"
            :data="vUrl"
            :device="device"
            :controls="false"
            :className="'fkLivePlayer'"
            :infoflag="infoflag"
            :deviceName="data.deviceName"
            :raw_msg="raw_msg"
            :isInfoShow="isInfoShow"
            @close="infoflag = false"
          />
          <!-- @entryCross="entryCross" -->
          <div class="info" v-if="infoshow && streamSelect == 'QingLiu'">
            <div class="de">
              <div class="title">bitrate:</div>
              <div class="detail">{{ raw_msg ? raw_msg / 1000 : "0" }}kbps</div>
            </div>
            <div class="de">
              <div class="title">venc_avg:</div>
              <div class="detail">
                {{ infoData ? infoData.venc_avg / 1000 : "0" }}ms
              </div>
            </div>
            <div class="de">
              <div class="title">venc_last:</div>
              <div class="detail">
                {{ infoData ? infoData.venc_last / 1000 : "0" }}ms
              </div>
            </div>
            <!-- <div class="de">
            <div class="title">push_path:</div>
            <div class="detail"></div>
          </div>
          <div class="de">
            <div class="title">recv_path:</div>
            <div class="detail"></div>
            </div>-->
            <div class="de">
              <div class="title">pushrtt_avg:</div>
              <div class="detail">
                {{ infoData ? infoData.pushrtt_avg[0] / 1000 : "0" }}ms
              </div>
            </div>
            <div class="de">
              <div class="title">pushrtt_last:</div>
              <div class="detail">
                {{ infoData ? infoData.pushrtt_last[0] / 1000 : "0" }}ms
              </div>
            </div>
            <div class="de">
              <div class="title">recvrtt_avg:</div>
              <div class="detail">
                {{ infoData ? infoData.recvrtt_avg[0] / 1000 : "0" }}ms
              </div>
            </div>
            <div class="de">
              <div class="title">recvrtt_last:</div>
              <div class="detail">
                {{ infoData ? infoData.recvrtt_last[0] / 1000 : "0" }}ms
              </div>
            </div>
            <div class="de">
              <div class="title">pushretrans_avg:</div>
              <div class="detail">
                {{
                  infoData
                    ? (infoData.pushretrans_avg[0] * 0.1).toFixed(2)
                    : "0"
                }}%
              </div>
            </div>
            <div class="de">
              <div class="title">pushretrans_last:</div>
              <div class="detail">
                {{
                  infoData
                    ? (infoData.pushretrans_last[0] * 0.1).toFixed(2)
                    : "0"
                }}%
              </div>
            </div>
            <div class="de">
              <div class="title">recvretrans_avg:</div>
              <div class="detail">
                {{
                  infoData
                    ? (infoData.recvretrans_avg[0] * 0.1).toFixed(2)
                    : "0"
                }}%
              </div>
            </div>
            <div class="de">
              <div class="title">recvretrans_last:</div>
              <div class="detail">
                {{
                  infoData
                    ? (infoData.recvretrans_last[0] * 0.1).toFixed(2)
                    : "0"
                }}%
              </div>
            </div>
            <!-- <div class="de">
            <div class="title">pzvtdelay:</div>
            <div class="detail">{{infoData ? infoData.pzvtdelay : "0"}}</div>
            </div>-->
            <div class="de">
              <div class="title">vdec:</div>
              <div class="detail">
                {{ infoData ? infoData.vdec / 1000 : "0" }}ms
              </div>
            </div>
            <div class="de">
              <div class="title">vbuffer:</div>
              <div class="detail">
                {{ infoData ? infoData.vbuffer / 1000 : "0" }}ms
              </div>
            </div>
            <!-- <div class="de">
            <div class="title">abuffer:</div>
            <div class="detail"></div>
            </div>-->
          </div>
          <!-- 瞄准 -->
          <div class="kedu" :style="backgroundStyle" v-if="ShowCenter">
            <div class="num">
              <div class="first">{{ num.one }}</div>
              <div class="first">{{ num.two }}</div>
              <div class="first">{{ num.three }}</div>
              <div class="first">{{ num.four }}</div>
              <div class="first">{{ num.five }}</div>
              <div class="first">{{ num.six }}</div>
              <div class="first">{{ num.seven }}</div>
            </div>
            <div class="center">
              <img src="@/assets/images/mount/center.png" alt />
            </div>
            <div class="longLine">
              <img src="@/assets/images/mount/line2.png" alt />
            </div>
            <!-- <img :style="{transform: `translateY(${uavData && uavData.gps ? uavData.gps.relativeAlt : 120}%)`}" class="line" src="@/assets/images/mount/line.png" alt=""> -->
            <div class="text">
              <span class>
                {{ uavData && uavData.gps ? uavData.gps.relativeAlt : 0 }}
              </span>
              <span class style="color: black">m</span>
            </div>
          </div>

          <div class="play">
            <el-tooltip
              v-if="!data.videos.osd && !isStatus"
              class="pa left120 ml24 w20"
              content="无人机信息"
              placement="bottom"
            >
              <div
                class="cp"
                @click="isInfoShow = !isInfoShow"
                style="transform: scale(1)"
              >
                <img src="@/assets/uav.png" alt />
                <!-- <el-switch v-model="isInfoShow" active-color="#13ce66" @change="getUavInfo"></el-switch> -->
              </div>
            </el-tooltip>
            <el-tooltip
              v-if="data.videos.osd && !isStatus"
              class="pa left120 ml24 w20"
              content="OSD无人机信息"
              placement="bottom"
            >
              <div class="cp" style="transform: scale(1)" @click="getOSD">
                <img src="@/assets/osd.png" alt />
                <!-- <el-switch v-model="osdFlage" active-color="#13ce66" @change="getOSD"></el-switch> -->
              </div>
            </el-tooltip>
            <el-tooltip
              v-if="streamSelect == 'QingLiu'"
              class="left ml24 mt5 w20"
              content="视频信息"
              placement="bottom"
              :style="isStatus ? 'margin-left:100px' : ''"
            >
              <div class="cp" @click="getInfo" style="transform: scale(1.4)">
                <img src="@/assets/info.png" alt />
              </div>
            </el-tooltip>

            <div v-else class="left ml24"></div>
            <el-tooltip
              content="是否启动瞄准镜"
              placement="bottom"
              v-if="!isStatus"
            >
              <div
                class="cf ml60 pa top11 cp"
                style="zindex: 10"
                @click="showCenterFn"
              >
                <img src="@/assets/images/mount/center.png" alt />
              </div>
            </el-tooltip>
            <el-tooltip
              v-if="!isStatus"
              :content="
                ygisCenterFlag
                  ? `摇杆位于中位 ${(ygValue && ygValue.toFixed(2)) || ''}`
                  : `摇杆未在中位 ${(ygValue && ygValue.toFixed(2)) || ''}`
              "
              placement="bottom"
            >
              <img
                :class="ygisCenterFlag ? '' : 'play_ymzw'"
                src="~@/assets/images/observe/ymzw.svg"
                class="cf pa left100"
              />
            </el-tooltip>

            <div class="right">
              <div class="search mr22" v-if="!isStatus">
                <el-select
                  class="video_type mr24"
                  v-model="streamSelect"
                  placeholder="切换源"
                >
                  <el-option
                    v-for="item in streamOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-select
                  class="big_type"
                  v-model="sizeSelect"
                  placeholder="比例"
                  @change="changeBig"
                >
                  <el-option
                    v-for="item in sizeOptions2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div
                class="pointer iconfont icon-gengxin cf f16 mr40"
                @click="reset"
              ></div>
              <div
                class="pointer iconfont icon-quanping cf mr3 f16"
                @click="screen"
                v-if="!isStatus"
              ></div>
            </div>
          </div>

          <el-tooltip content="拍照" placement="bottom">
            <div class="cp pa right10 cf plate" @click="photojz">
              <img src="@/assets/newImage/ai.png" />
            </div>
          </el-tooltip>
          <el-tooltip
            class="cp pa right10 cf ai"
            content="AI识别"
            placement="bottom"
          >
            <div @click="aiVisible = !aiVisible">
              <img src="@/assets/newImage/car.png" />
            </div>
          </el-tooltip>
          <el-tooltip v-if="!record" content="开始录像" placement="bottom">
            <div class="cp pa right10 cf record" @click="getrecord">
              <img src="@/assets/newImage/record.png" />
            </div>
          </el-tooltip>
          <el-tooltip v-else content="停止录像" placement="bottom">
            <div class="cp pa right10 cf record" @click="getrecord">
              <img src="@/assets/newImage/stop.png" />
            </div>
          </el-tooltip>
          <el-tooltip content="截图" placement="bottom">
            <div class="cp pa right10 cf photojz" @click="photo">
              <img src="@/assets/newImage/photojz.svg" />
            </div>
          </el-tooltip>
          <div class="cp pa right6 cf point" @click="startLinePoint">
            <img src="@/assets/images/observe/point_small.svg" />
            <!-- <span class="dib f8">航点动作</span> -->
          </div>
          <div class="faceAndcar cp pa right100 cf" v-if="aiVisible">
            <el-tooltip
              v-for="(item, index) in aiIdentifyList"
              :key="index"
              :content="item.title"
              placement="bottom"
            >
              <div
                class="cp pa cf"
                :class="item.class"
                @click="aiIdentifyType(item.aiType)"
              >
                <img class="img_src" :src="item.Img" />
              </div>
            </el-tooltip>
            <!-- <el-tooltip content="人脸识别" placement="bottom">
              <div class="plan" @click="handle('a')">
                <img src="~@/assets/images/observe/faceAI2.png" />
              </div>
            </el-tooltip>
            <el-tooltip content="车牌识别" placement="bottom">
              <div class="plan" @click="handle('b')">
                <img src="~@/assets/images/observe/carAI2.png" />
              </div>
            </el-tooltip>
            <el-tooltip content="人流识别" placement="bottom">
              <div class="plan" @click="frame()">
                <img src="~@/assets/images/observe/trafficAI.png" />
              </div>
            </el-tooltip> -->
          </div>
        </div>
        <PointList
          :uavData="uavData"
          :mountData="mountDatas"
          v-if="pointListFlag"
          @close="$parent.pointListFlag = false"
        ></PointList>
      </div>
      <!-- <PointList :uavData="uavData" :mountData="mountData" v-if="pointListFlag"></PointList> -->
    </div>
    <FaceAI v-if="faceAiShow" :uavId="pid" @closeface="faceAiShow = false" />
    <CarAI
      v-if="carAiShow"
      :uavId="device.deviceHardId"
      :list="carList"
      @closecar="carAiShow = false"
    />
    <Traffic
      v-if="trafficShow"
      :trafficData="trafficData"
      @close="trafficShow = false"
    />
  </div>
</template>

<script>
import API from "@/api";
import dayjs from "dayjs";
import FaceAI from "@/components/observe/uav/faceai";
import CarAI from "@/components/observe/uav/carai";
import Traffic from "@/components/observe/uav/traffic";
import LiveNVRPlayer from "./livenvr/index.vue";
import QingLiuPlayer from "@/components/newVideo/qingliu";
import SRSPlayer from "./srs";
import { saveAs } from "file-saver";
import { to_moveMount } from "@/utils/to_moveMount.js";
import Bus from "@/assets/ligature.js";
import { newDate } from "@/utils/formatDate";
import Obstacle from "./obstacle";
import PointList from "./pointList";
import videoModelChange from "@/components/videoModelChange";
import methods from "./methods";
import fkutils from "@/pages/observe/fckernel/utils";

export default {
  name: "Float-Playerfk",
  components: {
    QingLiuPlayer,
    LiveNVRPlayer,
    FaceAI,
    CarAI,
    Traffic,
    PointList,
    Obstacle,
    SRSPlayer,
    videoModelChange,
  },
  props: {
    scheduleData: {
      type: Object,
      default: () => ({}),
    },
    mountDatas: {
      type: Object,
      default: () => ({}),
    },
    NXdata: {
      type: Object,
      default: () => {},
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    _index: Number,
    lineLableName: {
      type: String,
      default: () => "",
    },
    keyFlag: {
      type: Boolean,
      default: () => false,
    },
    pointListFlag: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      aiPopup: null,
      timerList: [],
      timers: [],
      aiNameList: [],
      backgroundStyle: {
        "background-position-y": 0,
      },
      aiIdentifyList: [
        {
          title: "河边垂钓",
          aiType: 12,
          class: "right26 top40",
          Img: require("@/assets/newImage/河边垂钓.svg"),
        },
        {
          title: "人员河边玩耍",
          aiType: 9,
          class: "right-11",
          Img: require("@/assets/newImage/人员河边玩耍.svg"),
        },
        {
          title: "岸线垃圾",
          aiType: 7,
          class: "right-48 top40",
          Img: require("@/assets/newImage/岸线垃圾.svg"),
        },
        {
          title: "河道漂浮物",
          aiType: 6,
          class: "right-11 top40",
          Img: require("@/assets/newImage/河道漂浮物.svg"),
        },
        // {
        //   title: "跌倒检测",
        //   aiType: 5,
        //   class: "right-11 top40",
        //   Img: require("@/assets/newImage/跌倒检测.svg")
        // },
        {
          title: "游泳检测",
          aiType: 4,
          class: " right-48",
          Img: require("@/assets/newImage/游泳检测.svg"),
        },
        // {
        //   title: "车流识别",
        //   aiType: 4,
        //   class: " right-48",
        //   Img: require("@/assets/newImage/车牌检测.svg"),
        // },
      ],
      setIntervalzoom: null,
      ygisCenterFlag: false,
      ygValue: null,
      showCenter: false,
      fuseFlag: true, //清流是否进行视频融合true为融合
      controlflag: false, //清流 是否需要画中画主次画面切换，true为切换
      record: false,
      infoshow: false, //清流视频信息展示
      infoData: null, //清流信息
      raw_msg: 0,
      imgshow: false, //·
      imgshowurl: "", //弹出的图片路径
      carList: [], //车牌识别结果
      aiVisible: false, //打开ai
      pid: null,
      faceAiShow: false,
      carAiShow: false,
      trafficShow: false,
      trafficData: {},
      flag: true,
      isPlay: true,
      videoFlag: true,
      isStatus: false,
      big: "",
      streamSelect: "", //选择的流
      sizeSelect: 2, //选择的大小
      sizeOptions2: [
        //大小可选项
        {
          value: 1,
          label: "X1",
        },
        {
          value: 2,
          label: "X2",
        },
        {
          value: 3,
          label: "X3",
        },
      ],
      infoflag: false,
      mountData: {},
      num: {
        one: 15,
        two: 10,
        three: 5,
        four: 0,
        five: -5,
        six: -10,
        seven: -15,
      },
      isInfoShow: false,
      osdFlage: true,
      AISetInterval: null,
      aiLable: "",
      aiFlag: false,
    };
  },
  computed: {
    /**
     * 播放器组件名
     */
    playerCom() {
      /* if (this.streamSelect === "QingLiu") {
        return "QingLiuPlayer";
      }
      return "LiveNVRPlayer"; */
      // return 'SRSPlayer';
      if (this.vUrl?.vUrl?.includes("pzsp://")) {
        return "QingLiuPlayer";
      }
      return "LiveNVRPlayer";
    },
    /**
     * 流类型名
     */
    streamName() {
      let find = this.streamOptions.find((item) => {
        return item.value === this.streamSelect;
      });
      return find?.label || "";
    },

    taskId() {
      if (window.location.hash.includes("accident")) {
        return this.$store.state.fckernel.YJtaskId;
      } else {
        return this.$store.state.fckernel.taskId;
      }
    },
    ShowCenter() {
      return this.$store.state.fckernel.ShowCenter;
    },
    mapisStatus() {
      return this.$store.state.mapmanage.mapisStatus;
    },
    lineInfo() {
      return this.$store.state.fckernel.lineInfo;
    },

    /**
     * 网络类型
     * @returns 专网 | 公网
     */
    networkType() {
      // console.log('网络判断', this.data.videos)
      let name = "";
      try {
        if (this.data.videos.checkOnLine == 1) {
          name = "公网";
        } else {
          if (this.uavData?.link) {
            if (this.uavData?.link[0]?.priority == 2) {
              if (this.uavData?.link[0]?.using) {
                name = "专网";
              } else {
                name = "公网";
              }
            }
            if (this.uavData?.link[0]?.priority == 3) {
              if (this.uavData?.link[0]?.using) {
                name = "公网";
              } else {
                name = "专网";
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }

      return name;
    },
    /**
     * 流集合
     */
    streams() {
      let networkKey1 = "";
      let networkKey2 = "";
      if (this.networkType === "专网") {
        networkKey1 = "private";
        networkKey2 = "public";
      } else {
        networkKey1 = "public";
        networkKey2 = "private";
      }

      let streams = this.data.videos.list[networkKey1];
      if (this.networkType == "" && !streams) {
        streams = this.data.videos.list[networkKey2];
      }
      // console.log('networkKey1', networkKey1, networkKey2, this.data.videos.list[networkKey1], this.data.videos.list[networkKey2])
      return streams;
    },
    /**
     * 流选项
     * @returns [{
          value: "RTC",
          label: "低延迟"
        }]
     */
    streamOptions() {
      let streamOptions = [];
      // this.streamSelect = "hlsUrl";
      //清流
      if (this.streams?.qingLiuUrl) {
        window.$Bus.$on("qoeinfo", (e) => {
          this.infoData = e.data;
        });
        window.$Bus.$on("raw_msg", (e) => {
          this.raw_msg = e.data;
          // this.qoeInform(this.raw_msg);
        });
        streamOptions.push({
          value: "QingLiu",
          label: "原画",
        });
        // this.streamSelect = "QingLiu";
      }

      //WS-FLV
      if (this.streams?.socketUrl) {
        streamOptions.push({
          value: "hlsUrl",
          label: "流畅",
        });
      }

      //WebRtc
      if (this.streams?.webUrl) {
        streamOptions.push({
          value: "WebRtc",
          label: "低延迟",
        });
      }
      return streamOptions;
    },
    /**
     * 播放的视频流
     */
    vUrl() {
      let vUrl = "";
      switch (this.streamSelect) {
        case "QingLiu":
          vUrl = this.streams?.qingLiuUrl;
          break;

        case "hlsUrl":
          if (this.device.goodsName == "M300") {
            vUrl = this.streams?.socketUrl;
          } else {
            vUrl = "http://32.128.6.46:10800" + this.streams?.hlsUrl;
          }
          break;

        case "WebRtc":
          vUrl = this.streams?.webUrl;
          break;
      }

      return {
        vUrl,
      };
    },
    fpvUrl() {
      return {
        vUrl: this.streams?.fpvUrl || "",
      };
    },
  },

  watch: {
    pointListFlag(val) {
      if (val) {
        let width = this.$refs.video.clientWidth;
        let height = this.$refs.video.clientHeight;
        let fullWidth = window.innerWidth;
        let fullHeight = window.innerHeight - 100;

        let centerX = fullWidth / 2 - width / 2;
        let centerY = fullHeight / 2 - height / 2;
        this.$refs.wrap.setAttribute("data-x", centerX);
        this.$refs.wrap.setAttribute("data-y", centerY);
        this.$refs.wrap.setAttribute(
          "style",
          `transform: translate(${centerX}px, ${50}px)`
        );
        this.big = "big_02";
      }
    },
    // 获取航点
    scheduleData(val) {
      console.log("获取航点", val);
      let data = {
        deviceHardId: this.device.deviceHardId,
        mqttType: false,
        id: this.device.id,
      };
      this.lineAi(val, data);
    },
    NXdata(val) {
      if (val.messageID == 2043) {
        // console.log(val,"valnx")
        if (val.data.osdstatus == "false") {
          this.osdFlage = false;
        } else {
          this.osdFlage = true;
        }
      }
    },
    lineLableName(val) {
      this.aiFlag = true;
      this.aiLable = val;
      console.log(val, "lineLableName");
    },
    "$store.state.device.ygisCenterFlag"(val) {
      this.ygisCenterFlag = val;
    },
    "$store.state.device.ygValue"(val) {
      this.ygValue = val;
    },
    "$store.state.device.eo_zoom"(val) {
      if (val > 8) {
        if (this.setIntervalzoom) {
          clearInterval(this.setIntervalzoom);
        }
        this.setIntervalzoom = setInterval(() => {
          this.frame();
        }, 3000);
      }
    },
    device: function (val) {
      this.clearInter();
      console.log(val, "deviceval");
    },
    infoData: function () {
      // this.qoeInform(this.raw_msg);
    },
    raw_msg: function (newval1) {
      // this.qoeInform(this.raw_msg);
    },
    uavData: function (newval) {
      // console.log(this.scheduleData,"scheduleData");
      let data = {
        data: {
          messageID: 1043,
          data: {},
        },
        type: 528,
      };
      if (!newval.gps) {
        return;
      }
      this.backgroundStyle = {
        "background-position-y": newval.gps.relativeAlt + "px",
      };
      this.num.one = Number(newval.gps.relativeAlt.toFixed(0)) + 15;
      this.num.two = Number(newval.gps.relativeAlt.toFixed(0)) + 10;
      this.num.three = Number(newval.gps.relativeAlt.toFixed(0)) + 5;
      this.num.four = Number(newval.gps.relativeAlt.toFixed(0)) + 0;
      this.num.five = Number(newval.gps.relativeAlt.toFixed(0)) - 5;
      this.num.six = Number(newval.gps.relativeAlt.toFixed(0)) - 10;
      this.num.seven = Number(newval.gps.relativeAlt.toFixed(0)) - 15;
      // }
    },
    "$store.state.mapmanage.mapisStatus": function (newVal) {
      if (newVal.type == "wrj") {
        // window.kbt_player_resize();
        if (this.videoItem == this.index) {
          this.isStatus = newVal.flag;
        }
      }
    },
    "$store.state.device.move_data": {
      handler(value) {
        this.mountData = value;
      },
      deep: true,
    },
    streamOptions: {
      handler(value) {
        //当流选项变化时，如果未选择流类型，则自动选择第一个流类型
        if (this.streamSelect == "" && this.streamOptions.length > 0) {
          this.streamSelect = this.streamOptions[0].value;
        }
      },
      deep: true,
      immediate: true,
    },
    streamSelect: function (val) {
      // console.log(val, "valval");
      // if(val != "QingLiu"){
      //   this.$emit("changeLableName","河道巡检")
      // }
      // this.$store.commit("fckernel/SET_STREAM", val.trim());
    },
    networkType() {
      let find = this.streamOptions.find((item) => {
        return item.value == this.streamSelect;
      });
      if (!find) {
        this.streamSelect = ""; //网络类型改变时，未有当前选择的流类型， 则重置
      }
    },
    // 结束任务时触发
    "$store.state.fckernel.uav_task_status"(val) {
      console.log("清除识别点2");
      if (val && this.aiPopup) {
        // 清除AI识别标记点
        this.aiPopup.removeAll();
      }
    },
    deep: true,
  },
  async mounted() {
    setTimeout(() => {
      let streamData = {
        data: {
          messageID: 1058,
          data: {},
        },
        type: 528,
      };
      this.$emit("fn", streamData);
    }, 2000);
    window.$uavPlayer = this;
    this.wrapCenter();
    //视屏截图
    Bus.$on("uav_take_photo", ({ callback }) => {
      let blob = this.screenShot();
      callback && callback(blob);
    });
    // this.$store.commit("fckernel/SET_STREAM", this.streamSelect);
    window.addEventListener("resize", this.fullScreenChangeFn);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    this.ws && this.ws.close();
    this.ws = null;
    window.removeEventListener("resize", this.fullScreenChangeFn);
  },
  methods: {
    ...methods,
    ...fkutils,
    startLinePoint() {
      if (
        this.uavData &&
        this.uavData.gps &&
        this.uavData.gps.relativeAlt > 20
      ) {
        this.$emit("startLinePoint");
      } else {
        this.$message.warning("当前高度不可创建航点动作！");
      }
    },
    setAll() {
      let data = {
        deviceHardId: this.device.deviceHardId,
        mqttType: false,
      };
      this.timers.forEach((val) => {
        data.aiType = val;
        this.lineAi(this.scheduleData, data);
      });
    },
    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    getUavInfo() {
      this.isInfoShow = !this.isInfoShow;
    },
    //OSD无人机信息
    async getOSD() {
      let res = await this.checkUseOperateFn(this.device.deviceHardId);
      if (!res) {
        return false;
      }
      this.osdFlage = !this.osdFlage;
      let data = null;
      if (this.osdFlage) {
        data = {
          data: {
            messageID: 1042,
            data: {
              status: "true",
            },
          },
          type: 528,
        };
        this.$message.success("开启OSD");
      } else {
        data = {
          data: {
            messageID: 1042,
            data: {
              status: "false",
            },
          },
          type: 528,
        };
        this.$message.success("关闭OSD");
      }
      console.log(data, "data---eeeeeeeee");
      this.$emit("fn", data);
    },
    setFlyInfo() {
      if (
        this.device.anotherName &&
        this.device.anotherName.indexOf("-") > -1
      ) {
        let arr = this.device.anotherName.split("-");
        this.device.ername = arr[0];
        this.device.sanname = arr[1];
      } else {
        this.device.ername = this.device.anotherName;
      }
      let nxdata = {
        city: "盐城市",
        county: this.device.ername,
        // "policeStation":this.device.sanname || '',
        flyname: this.data.deviceName,
      };
      if (this.device.sanname) {
        nxdata.policeStation = this.device.sanname;
      }
      let data = {
        data: {
          messageID: 1041,
          data: nxdata,
        },
        type: 528,
      };
      this.$emit("fn", data);
    },
    // entryCross(val){
    //   let data = {
    //     data: {
    //       messageID: 1036,
    //       data: {
    //         streamInputType: 1,
    //         "line-crossing-Entry": val.entryAll,
    //         "line-crossing-Exit": val.crossAll,
    //         "line-crossing-classId": "0;1;2;3",
    //         "line-crossing-enable": 1,
    //         classList: [
    //           {
    //             className: "person",
    //             classID: 0,
    //           },
    //           {
    //             className: "car",
    //             classID: 1,
    //           },
    //         ],
    //       },
    //     },
    //     type: 528,
    //   };
    //   console.log(data,"entry");
    //   this.$emit("fn", data);
    // },
    setAiMessage() {
      let data = {
        data: {
          messageID: 1036,
          data: {
            streamInputType: 1,
            "line-crossing-Entry": "1200;50;1200;800;120;360;1800;360;",
            "line-crossing-Exit": "1000;830;1000;90;120;400;1830;400;",
            "line-crossing-classId": "0;1;2;3",
            "line-crossing-enable": 1,
            classList: [
              {
                className: "person",
                classID: 0,
              },
              {
                className: "car",
                classID: 1,
              },
            ],
          },
        },
        type: 528,
      };
      this.$emit("fn", data);
      let data2 = {
        data: {
          messageID: 1037,
          data: {},
        },
        type: 528,
      };
      this.$emit("fn", data2);
    },
    qoeInform(raw_msg) {
      let { infoData } = this;
      let {
        deviceHardId,
        udpStatus,
        cateId,
        cateName,
        deviceName,
        flightState,
        goodsName,
        reportStatus,
        socketStatus,
        state,
        stationType,
        store,
        firmId,
        firmName,
      } = this.device;
      let data = {
        uavId: deviceHardId,
        udpStatus,
        cateId,
        cateName,
        deviceName,
        flightState,
        goodsName,
        reportStatus,
        socketStatus,
        state,
        stationType,
        store,
        firmId,
        firmName,
        info: {
          bitrate: raw_msg ? raw_msg / 1000 + "kbps" : "0kbps",
          venc_avg: infoData ? infoData.venc_avg / 1000 + "ms" : "0ms",
          venc_last: infoData ? infoData.venc_last / 1000 + "ms" : "0ms",
          pushrtt_avg: infoData ? infoData.pushrtt_avg[0] / 1000 + "ms" : "0ms",
          pushrtt_last: infoData
            ? infoData.pushrtt_last[0] / 1000 + "ms"
            : "0ms",
          recvrtt_avg: infoData ? infoData.recvrtt_avg[0] / 1000 + "ms" : "0ms",
          recvrtt_last: infoData
            ? infoData.recvrtt_last[0] / 1000 + "ms"
            : "0ms",
          pushretrans_avg: infoData
            ? (infoData.pushretrans_avg[0] * 0.1).toFixed(2) + "%"
            : "0%",
          pushretrans_last: infoData
            ? (infoData.pushretrans_last[0] * 0.1).toFixed(2) + "%"
            : "0%",
          recvretrans_avg: infoData
            ? (infoData.recvretrans_avg[0] * 0.1).toFixed(2) + "%"
            : "0%",
          recvretrans_last: infoData
            ? (infoData.recvretrans_last[0] * 0.1).toFixed(2) + "%"
            : "0%",
          vdec: infoData ? infoData.vdec / 1000 + "ms" : "0ms",
          vbuffer: infoData ? infoData.vbuffer / 1000 + "ms" : "0ms",
        },
      };
      API.QINGLIU.qoeInform(data);
    },
    videoModelChangeFn(streamData) {
      this.$emit("fn", streamData);
    },
    showCenterFn() {
      this.$store.commit("fckernel/SET_ShowCenter", !this.ShowCenter);
      console.log(this.mapisStatus, "mapisStatus");
    },
    // // fuseFlag 是否进行视频融合 true为融合
    // // controlflag 是否需要画中画主次画面切换 true 为切换
    //     let streamData = {
    //   data: {
    //     data: {
    //       fuseFlag,
    //       controlflag,
    //     },
    //     messageID: 1018,
    //   },
    //   type: 528,
    // };
    // this.$emit("fn", streamData);
    async VideoModelChange() {
      let res = await API.FCKERNEL.checkUseOperate({
        deviceHardId: this.device.deviceHardId,
      });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        console.log(1111);
      }
    },
    getInfo() {
      this.infoshow = !this.infoshow;
    },
    async change() {
      this.videoFlag = !this.videoFlag;
    },
    //监听屏幕是否全屏
    fullScreenChangeFn(e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.infoflag = false;
      } else {
        this.infoflag = true;
      }
    },
    close(data) {
      this.$emit("close", data);
    },
    screen() {
      if (this.playerCom === "LiveNVRPlayer") {
        this.infoflag = !this.infoflag;
        this.$refs.player.init(this.infoflag);
      } else {
        this.infoflag = !this.infoflag;
        this.$refs.player.fullScreen(this.infoflag);
      }
    },
    play() {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].pause();
        this.isPlay = false;
      }
    },
    pause() {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].play();
        this.isPlay = true;
      }
    },
    async reset() {
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      // if (this.taskId) {
      //   let dataRun = {
      //     taskId: this.taskId,
      //     typeId: 3,
      //     deviceId: this.device.id
      //   };
      //   let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      // }
    },
    changeBig(id) {
      this.big = "big_0" + id;
      this.wrapCenter();
    },
    //人流密度
    async frame() {
      this.flow(this.device.deviceHardId);
    },
    /**
     * 截图
     */
    screenShot() {
      return this.$refs.player.screenShot();
    },
    async getrecord() {
      if (!this.keyFlag) return this.$message.error("录像失败，板载通信异常！");
      this.recordNX(this.device.id, this.stream, 0);
    },
    async photojz() {
      console.log(11111);
      if (!this.keyFlag) return this.$message.error("拍照失败，板载通信异常！");
      this.photoNX(this.device.id, this.stream, 0);
    },
    clearInter() {
      clearInterval(this.AISetInterval);
    },
    //游泳识别
    async aiIdentifyType(aiType) {
      if (aiType == 4) {
        window.open(
          "http://183.62.225.251:9091/?grid=1x1&id=31d68628-dfea-468a-a859-97d9e08133fc"
        );
      } else {
        const data = {
          deviceHardId: this.device.deviceHardId,
          mqttType: true,
          aiType,
          id: this.device.id,
        };
        this.aiIdentify(data, true);
      }
    },
    async photoGuide() {
      // if (!this.taskId) {
      //   this.$message.error("暂无任务！");
      //   return;
      // }
      let blob = this.screenShot();
      console.log(blob, "blob");
      saveAs(blob, "交通拍照.png");
      let fd = new FormData();
      fd.append("uploadFile", blob, `拍照.png`);
      if (this.taskId) {
        fd.append("taskId", this.taskId);
      }
      fd.append("deviceHardId", this.device.deviceHardId);
      let res = await API.FCKERNEL.Upload(fd);
      if (this.taskId) {
        let dataRun = {
          taskId: this.taskId,
          typeId: 1,
          deviceId: this.device.id,
        };
        // 运行日志
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
      console.log(this.uavData);
      let { latitude, longitude } = this.uavData.locationCoordinate3D;
      console.log(this.uavData, "uavData");
      let address = await API.MAP.AiRegeo({
        location: `${longitude},${latitude}`,
      });
      let addr =
        address.province.value +
        address.city.value +
        address.dist.value +
        address.road.roadname +
        address.poi;
      this.$message.success("截图成功!");
      let imgshowurl = process.env.VUE_APP_IMG_URL + "/uploads" + res.fileKey;
      const img = "opt/uploads" + res.fileKey;
      if (this.taskId) {
        await API.FCKERNEL.addTrafficTrouble({
          images: img,
          taskId: this.taskId,
          types: 0,
          position: addr || "",
          arrivalTime: dayjs().format("YYYY/MM/DD hh:mm:ss"),
        });
      }
      this.$emit("imgUrl", imgshowurl);
    },
    async photo() {
      // this.device.id,
      this.photoSelf(this.device.deviceHardId);
    },
    // 人脸车牌识别
    handle(command) {
      // this.getcanvas(command);
      this.getcanvasFaceCar(command, this.device.deviceHardId, this.device.id);
    },
    /**
     * 居中
     */
    wrapCenter() {
      console.log(this.$refs.video, "this.$refs.video");
      this.$nextTick(() => {
        let width = this.$refs.video.clientWidth;
        let height = this.$refs.video.clientHeight;
        let fullWidth = window.innerWidth;
        let fullHeight = window.innerHeight - 100;

        let centerX = fullWidth / 2 - width / 2;
        let centerY = fullHeight / 2 - height / 2;
        this.$refs.wrap.setAttribute("data-x", centerX);
        this.$refs.wrap.setAttribute("data-y", centerY);
        this.$refs.wrap.setAttribute(
          "style",
          `transform: translate(${centerX}px, ${centerY}px)`
        );
      });
    },
    /**
     * 视频指点
     */
    async fn(el) {
      // let mountData =this.$store.state.device.move_data
      let { device, mountData } = this;
      // if mountStatus =1 代表启用 屏幕指点功能
      if (
        mountData.mountStatus == 1 &&
        device.deviceHardId == mountData.deviceHardId &&
        mountData.moveType == "wrj"
      ) {
        let evt = window.event || el;
        var div = null;

        if (this.streamSelect == "QingLiu") {
          // div = document.getElementById("canvas_layer0") ;
          div = document.getElementsByClassName("qingliu")[0];
        } else {
          div = document.getElementsByClassName("vjs-tech")[0];
        }
        let data = {
          x: (evt.offsetX * 1920) / div.offsetWidth - 960,
          y: -((evt.offsetY * 1080) / div.offsetHeight) + 540,
          w: 1920,
          h: 1080,
        };
        let buffer = null;
        // console.log(evt.offsetX,"fndata");
        if (mountData.name == "MMC_Gimbal_Z30Pro") {
          // z30pro
          buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y);
        } else if (mountData.name == "MMC_Gimbal_Z40S") {
          //z40s
          buffer = await to_moveMount.move_or_focus_ctrl(data);
        } else if (
          mountData.name == "MMC_Gimbal_Z60S" ||
          mountData.name == "MMC_Gimbal_ZT60R"
        ) {
          buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y, 3);
        } else if (mountData.name == "DJI_H20T") {
          // buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y, 3);
        } else {
          buffer = await to_moveMount.gcs_click_coordinates(data);
        }
        let mounteList = { data: buffer, ...mountData };
        if (buffer) {
          this.$emit("directiveFn", mounteList);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.noStyle {
  opacity: 0.3;
}
.cpt_video {
  cursor: default !important;
  border: 1px solid #fff;
  width: 730px;
  height: 450px;
  background: #333;
  position: fixed;

  .video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    .name {
      position: absolute;
      top: 0;
      left: 0;
      // width: 122px;
      height: 26px;
      border-radius: 1px;
      line-height: 26px;
      text-align: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #69ceff;
      background: rgba(0, 0, 0, 0.4);
    }
    .type {
      position: absolute;
      top: 0;
      right: 112px;
      width: 56px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #fc8959;
      border-radius: 13px;
      opacity: 0.58;
      backdrop-filter: blur(6.7px);
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .types {
      position: absolute;
      top: 0;
      right: 50px;
      width: 56px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #4d9dff;
      border-radius: 13px;
      opacity: 0.58;
      backdrop-filter: blur(6.67px);
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 46px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #000000;
      border-radius: 1px;
      opacity: 0.6;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
  }
  .info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    padding: 0 10px;
    padding-top: 30px;
    height: calc(100% - 75px);
    background: rgba($color: #000000, $alpha: 0.6);
    .de {
      margin-top: 8px;
      display: flex;
      color: #fff;
      font-size: 14px;
      .detail {
        margin-left: 8px;
      }
    }
  }
  .play {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 46px;
    background: #000000;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .play_ymzw {
      opacity: 0.5;
    }
    .left {
      color: #fff;
      span {
        font-size: 20px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .search {
        .video_type {
          width: 96px;
          height: 26px;
        }
        .big_type {
          width: 70px;
          height: 26px;
        }
        /deep/.el-input--suffix {
          width: 100%;
          height: 100%;
          .el-input__inner {
            width: 100%;
            height: 100%;
          }
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.big_01 {
  width: 416px;
  height: 274px;
}
.big_03 {
  width: 1000px;
  height: 600px;
}
.big_04 {
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
}
.ai {
  bottom: 53%;
  z-index: 1;
}
.plate {
  bottom: 80%;
  z-index: 1;
}
.record {
  bottom: 71%;
  z-index: 1;
}
.photojz {
  bottom: 63%;
  z-index: 1;
}
.point {
  // width: 50px;
  // height: 50px;
  // // padding: 10px 5px;
  // background: rgba(9, 32, 87, 0.7);
  // border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: scale(0.76);
  bottom: 43.4%;
  border-radius: 19px;
}

.full-video ::v-deep {
  transform: none !important;
  .interact-wrap {
    transform: translate(0px, -30px) !important;
  }
  .interact_wrap_full {
    transform: translate(0px, -144px) !important;
  }
  .cpt_video {
    // margin-top: 150px;
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
    // height: calc(100vh - 70px);
    height: 100vw;
    z-index: 0 !important;
    .info {
      line-height: 30px;
      font-size: 24px;
      left: 500px;
      height: 550px;
      top: 100px;
    }

    .ai {
      top: 640px;
      right: 15px;
      height: 36px;
    }

    .faceAndcar {
      top: 643px;
      height: 22px;
    }

    .plate {
      top: 520px;
      right: 15px;
      height: 36px;
    }
    .record {
      top: 560px;
      right: 15px;
      height: 36px;
    }
    .photojz {
      top: 600px;
      right: 15px;
      height: 36px;
    }
    .point {
      top: 682px;
      right: 10px;
      height: 36px;
    }
  }
}
.modelStyle {
  bottom: 15px;
  right: 28px;
  z-index: 3;
  font-size: 13px;
}
// position: relative;
.faceAndcar {
  z-index: 1;
  width: 59px;
  bottom: 54.5%;
  display: flex;
  justify-content: space-between;
  .plan {
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0 5px;
    background: rgba(9, 32, 87, 0.7);
  }
  .img_src {
    background: rgba(9, 32, 87, 0.7);
    // width: 35px;
    // height: 35px;
    width: 22px;
    height: 22px;
    padding: 5px;
  }
}
.kedu {
  z-index: 1001;
  top: calc(50% - 25%);
  left: calc(50% - 25%);
  width: 50%;
  height: 50%;
  position: absolute;
  // background: yellow;
  background: url("~@/assets/images/mount/line.png");
  background-size: 100% 100%;
  transition: 0.5s;

  // overflow-y: hidden;
  .num {
    position: absolute;
    left: -30px;
    z-index: 1002;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .line {
    // transform: translateY(-250px);
    position: absolute;
    bottom: calc(50%);
    width: 100%;
    height: 100%;
  }
  .longLine {
    position: absolute;
    // top: calc(50% - 25px);
    // left: calc(50% - 25px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .text {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% + 25px);
    width: 75px;
    height: 25px;
    background: url("~@/assets/images/mount/textbg.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 25px;
    color: red;
    font-weight: bold;
  }
  .center {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }
}
</style>
