<template>
  <div class="itemAMaxBox">
    <!-- <textarea
      class="dib mt24 w432 h175"
      placeholder="请输入想要发送的文字"
      :disabled="write"
      v-model="text"
    ></textarea> -->
    <el-input
      type="textarea"
      placeholder="请输入想要发送的文字"
      :disabled="write"
      v-model="text"
      maxlength="70"
      show-word-limit
    >
    </el-input>
    <div class="jcsb selectBox pl24 pr24 mt70">
      <div>
        <span> 语音:</span>
        <el-select
          size="mini"
          class="dib w100"
          v-model="value"
          placeholder="请选择"
          :disabled="write"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span> 速度:</span>
        <el-select
          size="mini"
          class="dib w100"
          v-model="value2"
          placeholder="请选择"
          popper-append-to-body
          :disabled="write"
        >
          <el-option
            v-for="item in options2"
            :key="item.value2"
            :label="item.label2"
            :value="item.value2"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div>
        <span> 重复:</span>
        <el-select
          size="mini"
          class="dib w100"
          v-model="ttsCycle"
          placeholder="请选择"
          @change="changeType"
          @change="changeType"
        >
          <el-option
            v-for="item in options3"
            :key="item.ttsCycle"
            :label="item.label3"
            :value="item.ttsCycle"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>
    <div class="bottomBtnBox jcsb mt10 pl24 pr24">
      <!-- <div class="cf f14">
        <div class="w35 h35 btnStyle lh35 tc">
          <div class="w15 h15 ml5 iconfont icon-jilu"></div>
        </div>
        <div>记录</div>
      </div> -->
      <div class="cf f14" style="transform: scale(0.7)">
        <div class="w45 h45 btnStyle tc" @click="handleSendTTSText">
          <!-- <div class="w5 h5 mt13 ml26 iconfont icon-shang2 bf"></div> -->
          <div
            class="w5 h5 mt13 ml15 iconfont icon-a-bofang2px"
            v-if="flag"
          ></div>
          <div
            class="w20 h20 mt13 ml14 iconfont icon-a-zanting2px"
            v-else
          ></div>
        </div>
        <div>播放</div>
      </div>
      <!-- <div class="cf f14">
        <div class="w45 h45 btnStyle tc">
          <div class="w5 h5 mt13 ml26 iconfont icon-shang2 bf"></div>
        </div>
        <div>暂停</div>
      </div> -->
      <!-- <div class="cf f14">
        <div class="w35 h35 btnStyle" @click="handleSendsupse">
          <div class="w5 h5 mt10 ml9 iconfont icon-baocun1"></div>
        </div>
        <div>暂停</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import CmdTtsText from "@/utils/PagerP1/cmd_tts_text";
import CMD_TTS_STATE from "@/utils/PagerP1/cmd_record_state";
import MavLink20Processor from "@/utils/PagerP1/mavlink20_processor";
import CmdReadFileList from "@/utils/PagerP1/cmd_read_file_list";
import CmdRecordName from "@/utils/PagerP1/cmd_record_name";
import CmdSetSystemVolume from "@/utils/PagerP1/cmd_set_system_volume";
import CmdStreamFunction from "@/utils/PagerP1/cmd_stream_function";
import CmdStreamUpData from "@/utils/PagerP1/cmd_stream_up_data";
import CmdSaveFile from "@/utils/PagerP1/cmd_save_file";
export default {
  data() {
    return {
      text: "",
      write: false,
      ttsState: 10,
      options: [
        {
          value: "1",
          label: "普通话女",
        },
        {
          value: "0",
          label: "普通话男",
        },
        // {
        //   value: "xiaomei",
        //   label: "广东话",
        // },
        // {
        //   value: "xiaoqiang",
        //   label: "湖南话",
        // },
        // {
        //   value: "xiaorong",
        //   label: "四川话",
        // },
        // {
        //   value: "xiaoqian",
        //   label: "东北话",
        // },
        // {
        //   value: "xiaokun",
        //   label: "河南话",
        // },
        // {
        //   value: "xiaoying",
        //   label: "陕西话",
        // },
      ],
      value: "1",

      options2: [
        {
          value2: "50",
          label2: "正常",
        },
        {
          value2: "100",
          label2: "最快",
        },
        {
          value2: "75",
          label2: "较快",
        },
        {
          value2: "25",
          label2: "较慢",
        },
        {
          value2: "0",
          label2: "最慢",
        },
      ],
      value2: "50",

      options3: [
        {
          ttsCycle: 10,
          label3: "单次",
        },
        {
          ttsCycle: 11,
          label3: "循环",
        },
        // {
        //   ttsCycle: -1,
        //   label3: "未初始化",
        // },
      ],
      ttsCycle: 10,
      flag: true,
    };
  },
  mounted(){
    //   setInterval(() => {
    //   this.ttsCycle = this.PagerP1.ttsCycle
    // }, 100);
  },
  watch:{
    ttsState: {
      handler(val){
        if(val == 10){
          this.flag = true
          this.write = false
          }else{
          this.flag = false
          this.write = true
        }
      }
    }
  },
  inject: ["PagerP1", "PoliceKey"],
  beforeDestroy() {
    // this.handleSendsupse();
  },
  methods: {
    changeType(e){
      let data = {
        data: e,
        type: 2307
      }
      this.mqtt_publish(data);
    },
    handleSendTTSText() {
      if(this.text == "") return this.$message.info("文字不能为空！")
      this.flag = false;
      if(this.ttsState == 11) return
      if (!this.flag) {
        // this.write = true;
        let message = null;
        let start = {
          data: 11,
          type: 2306
        }
        this.mqtt_publish(start);
        message = {
          data: {
            text: this.text,
            speed: this.value2,
            voice: this.value,
          },
          type: 2305,
        };
        this.mqtt_publish(message);
      } else {
        // this.handleSendsupse();    
      }
    },
    handleSendsupse() {
      // this.flag = true
      // this.write = false;
      let message = {
        data: 10,
        type: 2306,
      };
      this.mqtt_publish(message);
    },
    async mqtt_publish(message) {
      let key = await this.PoliceKey();
      // console.log(`SPEAK/OBTAIN/${key}-01`, "发送主题");
      this.PagerP1.mqtt.client.publish(
        `SPEAK/OBTAIN/${key}-01`,
        new TextEncoder().encode(JSON.stringify(message)),
        {
          qos: 0,
        },
        (e) => {
          console.log("发送指令成功:", message);
        }
      );
      this.PagerP1.mqtt.client.on("message", (topic, payload, packet) => {
        const data = JSON.parse(packet.payload.toString());
        this.ttsState = data.ttsState;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.itemAMaxBox {
 width: 100%;
  min-height: 393px;
  text-align: center;
  .selectBox {
    color: #b0b3be;
    /deep/el-select {
      .el-input--mini {
        background: #3768b3;
      }
    }
  }
  .bottomBtnBox {
    .btnStyle {
      cursor: pointer;
      border-radius: 50%;

      background: #9bacbe;
      color: #3e495b;
      border: 10px solid #000;
      .bf {
        transform: rotate(90deg);
      }
    }
    .btnStyle:hover {
      color: #43deff;
    }
  }
}
/deep/ .el-textarea {
  margin-top: 30px;
  width: 90%;
  height: 120px;
  resize: none;
  outline: none;
  background: rgba(24, 39, 76, 0.7);
  border: 1px solid #43deff;
  border-radius: 10px;
  color: #d6d9df;
  text-indent: 1em;
  .el-textarea__inner{
  border: 1px solid #43deff;
  background: rgba(24, 39, 76, 0.7) !important;
}
  textarea{
    height: 120px;
  }
  .el-input__count{
    background: transparent;
    color: #fff;
  }
  // padding-top: 10px;
}

textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #d6d9df;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d6d9df;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d6d9df;
}
textarea::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #d6d9df;
}
</style>
<style lang="scss">
.itemAMaxBox {
  .selectBox {
    color: #b0b3be;
    .el-select {
      .el-input__inner {
        border: 1px solid #3768b3;
        background: #132550;
        border-radius: 4px 4px 0 0;
        color: #fff;
      }
    }
  }
}

</style>
