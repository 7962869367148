<template>
  <div class="content">
    <div class="content_head" v-interact>
      <div class="left">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">人流识别</div>
      </div>
      <div class="right" @click="() => $emit('close')">关闭</div>
    </div>
    <div class="content_box">
      <div class="items">
      <div class="car_item">
        <div class="image">
          <img :src="baseUrl + trafficData.imagePath" alt="" />
        </div>
        <div class="info">
          <div class="row">
            <div class="title">发现人员：</div>
            <div class="props">{{ trafficData.total || "0" }}人</div>
          </div>
        </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import data from "./data";
export default {
  props:{
    trafficData:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // ...data,
      baseUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  methods: {
    
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: fixed;
  z-index: 101;
  top: calc(50% - 120px);
  left: calc(50% + 80px);
  margin-right: 16px;
  width: 468px;
  max-height: 635px;
  margin-bottom: 190px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  backdrop-filter: blur(2px);
  border-radius: 10px 10px 0px 0px;
  .content_head {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    padding: 0 16px;
    .left {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .right {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .content_box {
    width: 100%;
    max-height: 600px;
    // padding: 0 16px;
    // overflow: auto;
    position: relative;
    .items {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      .car_item {
        margin: 11px auto;
        width: 90%;
        height: 300px;
        border: 1px solid #43deff;
        padding: 4px 4px 0 4px;

        .image {
          position: relative;
          width: 100%;
          height: 90%;
          img {
            width: 100%;
            height: 100%;
          }
          .tip {
            position: absolute;
            bottom: 0;
            width: 204px;
            height: 16px;
            background: #000000;
            opacity: 0.8;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #b7bfc8;
            text-align: center;
            line-height: 16px;
          }
        }
        .info {
          width: 204px;
          height: 53px;
          // border: 1px solid #43deff;
          background: url("~@/assets/newImage/info.png");
          .row {
            margin-left: 9px;
            padding-top: 7px;
            display: flex;
            .title {
              font-size: 14px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #b7cae2;
            }
            .props {
              // width: 150px;
              // height: 19px;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #b7cae2;
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          // &:hover {
          //   background: url("~@/assets/newImage/infos.png");
          // }
        }
      }
    }
    .detail {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      .image {
        position: relative;
        width: 436px;
        height: 255px;
        img {
          width: 436px;
          height: 255px;
        }
        .tip {
          position: absolute;
          bottom: 0;
          width: 436px;
          height: 31px;
          background: #000000;
          opacity: 0.8;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #b7bfc8;
          text-align: center;
          line-height: 31px;
        }
      }
      .info {
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        .row {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .col {
            display: flex;
          }
          .title {
            // flex: 1;
            flex-shrink: 0;
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 21px;
          }
          .props {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #c5ebff;
            line-height: 21px;
          }
        }
      }
      .btn {
        margin: 20px 0;
        // position: absolute;
        // bottom: 0;
        align-self: center;
        width: 79px;
        height: 32px;
        background: rgba(23, 70, 216, 0.2);
        box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
          inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
          inset 0px 0px 4px 0px #00a7ff;
        border-radius: 6px;
        // border: 1px solid;
        border-image: linear-gradient(
            180deg,
            rgba(138, 218, 255, 1),
            rgba(82, 179, 255, 0)
          )
          1 1;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #00ffff;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}
</style>
