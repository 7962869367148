<!-- 鹰击下级hover -->
<template>
  <div>
    <!-- <div class="btn" @click="handleClick"></div> -->
    <div class="cpt-home_header">
      <div class="home-header_inner">
        <!-- <HeaderLeft /> -->
        <div class="header-bg_box">
          <div class="yingji" @click="$router.push('/home')"></div>
          <div class="flex gundongtiao mt10">
            <div class="flex" style="align-items: center">
              <!-- <div class="zuojian" @mouseover="zuoyi" @mouseout="zuoyichu" @click="zuotiao">
                <img :src="zuo" alt="" />
              </div>-->
              <div class="gundong">
                <ul class="ul">
                  <li
                    class="tap"
                    @mouseover="tapyi(item.id)"
                    @mouseout="tapyichu"
                    v-for="item in optionsLeft"
                    :class="{ jindi: item.id == jinId }"
                    :key="item.id"
                    @click="tap_click(item)"
                  >
                    <!-- <img :src="item.id == imgId ? tapJin : tap" alt="" /> -->
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
              <!-- <div class="youjian" @mouseover="youyi" @mouseout="youyichu" @click="youtiao">
                <img :src="you" alt="" />
              </div>-->
            </div>
            <!-- <tianqi class="jianju"></tianqi> -->
            <!-- <dvi class="header-text"></div> -->
            <div
              class="right wih100"
              style="align-items: center"
              v-if="device && !wsShow"
            >
              <div class="mode_nameBox cp ml12">
                <el-dropdown trigger="click" @command="handle_change_uav_mode">
                  <span class="dib">
                    <span class="mode_name">{{ FlightMode }}</span>
                    <i class="el-icon-caret-bottom"></i>
                  </span>
                  <div class="el-popperBox">
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="item in modeList"
                        v-show="!item.disabled"
                        :key="item.id"
                        :command="item.data"
                        >{{ item.label }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </div>
                </el-dropdown>
              </div>
            </div>

            <div
              class="right wih100"
              style="align-items: center"
              v-if="device && wsShow"
            >
              <div class="mode_nameBox cp ml12">
                <el-dropdown
                  trigger="click"
                  @command="handle_change_uavws_mode"
                >
                  <span class="dib">
                    <span class="mode_name">{{ flightModews }}</span>
                    <i class="el-icon-caret-bottom"></i>
                  </span>
                  <div class="el-popperBox">
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="item in modeListws"
                        v-show="!item.disabled"
                        :key="item.id"
                        :command="item.data"
                        >{{ item.label }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </div>
                </el-dropdown>
              </div>
            </div>
            <HeaderRight :data="user" class="jl" />
          </div>
        </div>

        <!-- <HeaderRight :data="user" /> -->
      </div>
    </div>
    <!-- <div class="tou">
      
    </div>-->
  </div>
</template>

<script>
import HeaderLeft from "@/components/header-left/index_v5.vue";
import HeaderRight from "@/components/header-right/index_v5.vue";
//箭头
import zuo from "@/assets/newImage/zuojian.png";
import zuoJin from "@/assets/newImage/zuojianJin.png";
import you from "@/assets/newImage/youjian.png";
import youJin from "@/assets/newImage/youjianJin.png";
//tap标签背景
import tapLan from "@/assets/newImage/taplan.png";
import tapJin from "@/assets/newImage/tapJin.png";
// bus
import Bus from "@/assets/ligature";
// 引入 天气状况
import tianqi from "@/components/header-left/index_v5.vue";
import { mapGetters } from "vuex";
import API from "@/api";
export default {
  props: {
    title: {
      type: String,
      default: () => "标题",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    flag: {
      type: Number | String,
      default: () => 1,
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      },
    },
  },
  data() {
    return {
      jinId: 99, //tap高亮id
      zuo: zuo,
      you: you,
      listBoxFlag: false,
      // tap标签引用的是两个路径地址,这样可以动态加载图片
      tap: tapLan,
      tapJin: tapJin,
      imgId: "",
      optionsLeft: [
        { id: 5, title: "首页", path: "/eagleStrike" },
        { id: 1, title: "交警实战", path: "/accident?page=1", type: "traffic" },
        {
          id: 0,
          title: "巡特警实战",
          path: "/accident?page=0",
          type: "patrol",
        },
        { id: 2, title: "食药环实战", path: "/accident?page=2", type: "food" },
        { id: 3, title: "网安实战", path: "/accident?page=3", type: "net" },
        { id: 4, title: "水警实战", path: "/accident?page=4", type: "water" },
        { id: 6, title: "技侦实战", path: "/accident?page=6", type: "crimes" },
        // { id: 8, title: "飞鹰喷火器", path: "/observe?page=8" },
        // { id: 9, title: "飞鹰战术发射器", path: "/fckernel" },
        // { id: 7, title: "霹雳火", path: "/fckernel" },
        // { id: 10, title: "飞鹰抓捕网", path: "/fckernel" }
      ],
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: true },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "定点模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "保持模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: true },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "跟随模式",
          disabled: false,
        },
      ],
      modeListws: [
        { id: 1, data: "11", label: "安全降落", disabled: false },
        { id: 2, data: "12", label: "返航模式", disabled: false },
        { id: 3, data: "13", label: "航线模式", disabled: false },
        { id: 4, data: "17", label: "gps模式", disabled: false },
      ],
      flightModews: "",
    };
  },
  watch: {
    "$route.path": {
      handler(router) {
        console.log(router, "router");
        if (router == "/accident") {
          this.listBoxFlag = true;
        } else {
          this.listBoxFlag = false;
        }
      },
      immediate: true,
    },
    uavData: function (val, old) {
      if (val && this.wsShow) {
        this.flightModews = val.flightMode;
      }
    },
    ModeStatus: function (newval) {
      this.$emit("ModeStatus", newval);
    },
  },
  created() {
    // console.log(this.$route.query.page,'okkkkk');
    // 将数组序列化方便处理
    let b = JSON.parse(JSON.stringify(this.optionsLeft));
    // 获取route.query里的值找到数组指定对象的下标
    let a = b.findIndex((arr) => arr.id == this.$route.query.page);
    // 根据下标获取数组里的对象
    let c = b.splice(a, 1);
    // 将获取的对象重新插入到数组中的指定位置(首页后面:下标:1)
    b.splice(1, 0, c[0]);
    // 重新赋值给数组
    this.optionsLeft = b;
    console.log(this.optionsLeft, "optionsLeft");
    // 渲染金色背景
    this.jinId = this.$route.query.page;
    // 飞控 无人机 自动=>航线模式
    // 飞控 无人机 手动=>定点模式
    // 关键字：无人机模式修正
    Bus.$on("uavState", (data) => {
      this.handle_change_uav_mode(data);
    });
    // 无人机列表返航模式
    // 关键字：无人机返航
    Bus.$on("uavFan", (data) => {
      this.handle_change_uav_mode(data);
    });
  },
  components: { HeaderLeft, HeaderRight, tianqi },
  computed: {
    ...mapGetters(["user_info"]),
    FlightMode() {
      let { uavData, modeList } = this;
      let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
      // 用vuex将当前的模式存起来 飞控 无人机列表
      this.$store.commit("shoudong/compose_mode", flightMode?.label);
      return flightMode?.label || "离线状态";
    },
    // 网络判断 公网|专网
    ModeStatus() {
      let { uavData } = this;
      let name = null;
      if (uavData && uavData.link) {
        for (let i = 0; i < uavData.link.length; i++) {
          const k = uavData.link[i];
          if (k.priority == 2 && k.using) {
            return "专网";
          } else if (k.priority == 3 && k.using) {
            return "公网";
          } else if (k.priority == 1 && k.using) {
            return "图传";
          }
        }
      }
      return name || "离线";
    },
  },
  methods: {
    async handle_change_uav_mode(data) {
      // 查看是否有权限接管无人机
      let res = await this.checkUseOperateFn(this.device.deviceHardId);
      // 判断当前状态 true为一接管
      if (res == false) {
        return;
      }
      let mode = this.modeList.find((item) => item.data === data);
      if (mode) {
        this.$emit("fn", { type: 513, data: mode.data });
        this.$message.success("操作成功");
      }
      // console.log('返航成功------------------')
    },

    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      if (res.code == 201) {
        this.$message.warning("用户无权限");
        return false;
      } else {
        return true;
      }
    },
    handle_change_uavws_mode(item) {
      this.$emit("control_ws", item);
    },
    // 箭头事件
    zuoyi() {
      this.zuo = zuoJin;
    },
    zuoyichu() {
      this.zuo = zuo;
    },
    youyi() {
      this.you = youJin;
    },
    youyichu() {
      this.you = you;
    },
    tapyi(id) {
      this.imgId = id;
    },
    tapyichu() {
      this.imgId = "";
    },
    // 箭头跳转,思路,输出数组第一个对象,然后push数组最后面,右箭头反之
    zuotiao() {
      const allLength = this.optionsLeft.length * 134;
      const boxLength = document.querySelector(".gundong").clientWidth;
      if (allLength < boxLength) return;
      const listEl = document.querySelector(".ul");
      const leftMove = parseInt(window.getComputedStyle(listEl, null)?.left);
      if (leftMove + boxLength < boxLength) {
        // 滚到
        listEl.style.left = "0px";
      } else if (leftMove < 0) {
        listEl.style.left = leftMove + 134 + "px";
      }
      // let a = JSON.parse(JSON.stringify(this.optionsLeft));
      // let b = a.splice(0, 1);
      // a.push(b[0]);
      // this.optionsLeft = a;
    },
    youtiao() {
      const allLength = this.optionsLeft.length * 134;
      const boxLength = document.querySelector(".gundong").clientWidth;
      if (allLength < boxLength) return;
      const listEl = document.querySelector(".ul");
      const leftMove = Math.abs(
        parseInt(window.getComputedStyle(listEl, null)?.left)
      );
      console.log(leftMove, allLength);
      if (leftMove + boxLength >= allLength) {
        listEl.style.left = "-" + (allLength - boxLength) + "px";
      } else {
        listEl.style.left = "-" + (leftMove + 134) + "px";
      }
      // let a = JSON.parse(JSON.stringify(this.optionsLeft));
      // let b = a.splice(a.length - 1, 1);
      // a.unshift(b[0]);
      // this.optionsLeft = a;
    },

    // tap标签跳转功能
    tap_click(item) {
      let eagleCodeList = this.user_info["eagleCodeList"];
      console.log(item);
      if (item.title == "首页") {
        window.open("#" + item.path,"_self");
        window.location.reload()
      }
      if (item.type) {
        if (
          eagleCodeList.length == 0 ||
          eagleCodeList.indexOf(item.type) == "-1"
        ) {
          return this.$message.info("暂无操作权限!");
        }
      }
      if (item && item.path) {
        window.open("#" + item.path,"_self");
        window.location.reload()
      }
    },
    // 首页跳转功能
    handleClick() {
      // window.open('#/home', "_blank");
      this.$router.push("/home");
      // let { href } = this.$router.resolve({ path: "/home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 58px;
  height: 25px;
  position: relative;
  z-index: 100;
  left: 50%;
  margin-left: -29px;
  margin-top: 8px;
  cursor: pointer;
}

.bg1 {
  background: linear-gradient(to bottom, #000 0%, transparent 100%);
}

.bg2 {
  background: center 0px url("~@/assets/images/command/Rectangle.png") no-repeat;
  background-size: 100% 100%;
}

.cpt-home_header {
  height: 72px;
  box-sizing: border-box;
  // background: #19223d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  // background:  linear-gradient(to bottom, #000 0%, transparent 100%);
  .home-header_inner {
    height: 100%;
    position: relative;

    .landi {
      position: absolute;
      top: 0;
      left: 0;
      // background-image: url("~@/assets/newImage/landi.png");
      background-size: 100% 100%;
      z-index: -99;
      width: 100%;
      height: 100%;
    }

    .header-bg_box {
      min-width: 1920px;
      width: 100vw;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      // background: center 0px url('~@/assets/images/command/command_top.png') no-repeat;
      // background: center 0px url("~@/assets/newImage/yingkongshouye.png")
      //   no-repeat;
      background: center 0px url("~@/assets/new_yingji/headerNew.png") no-repeat;
      background-size: 100% 100%;
      .yingji {
        margin: 5px 0 0 3%;
        min-width: 7%;
        height: 80%;
        cursor: pointer;
        // background: #fff;
      }

      .list {
        display: none;
        background: rgba(0, 39, 121, 0.5);
        border: 1px solid #43deff;
        box-shadow: 0 2px 8px 0 rgba(1, 162, 255, 0.7), inset 0 0 8px 0 #019aff,
          inset 0 0 64px 0 rgba(26, 138, 227, 0.35);

        .item {
          font-family: MicrosoftYaHei-Bold;
          font-size: 18px;
          color: #bcbcbc;
          letter-spacing: 0;
          text-align: center;
          font-weight: 700;
        }

        .item:hover {
          background-image: linear-gradient(
            90deg,
            rgba(67, 222, 255, 0) 0%,
            rgba(67, 222, 255, 0.4) 52%,
            rgba(67, 222, 255, 0) 100%
          );
          color: #ffff;
        }

        .active {
          background-image: linear-gradient(
            90deg,
            rgba(67, 222, 255, 0) 0%,
            rgba(67, 222, 255, 0.4) 52%,
            rgba(67, 222, 255, 0) 100%
          );
          color: #ffff;
        }
      }

      .yingkong {
        // 鹰控文字图片
      }

      .title {
        // font-family:PangMenZhengDao;
        // font-size: 55px;
        // line-height: 72px;
        // width: 200px;
        // background-image: -webkit-linear-gradient(bottom, #f9ecd3, #e3aa77);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        padding-left: 70%;
        // font-weight: 400;
        margin: 5px 0 0 5%;
        width: 120px;
        height: 80%;
        cursor: pointer;
      }

      .titleBox:hover {
        .list {
          display: block;
        }
      }

      .zuojian {
        // 左箭头
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 0 17px 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .youjian {
        // 右箭头
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 0 17px 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .gundong {
        // tap标签栏区域
        width: 1210px;
        height: 39px;
        position: relative;
        // height: 34px;
        // border: 1px solid red;
        margin: 0 6px 9px 6px;
        padding: 0;
        overflow: hidden;
        // min-width: 535px;
        // max-width: 535px;

        ul {
          position: absolute;
          left: 0;
          list-style: none;
          // width: 100%;
          // height: 100%;
          display: flex;
          // overflow: scroll;
          overflow: hidden;

          .tap {
            width: 170px;
            height: 46px;
            position: relative;
            text-align: center;
            margin: 0 0px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-size: 24px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              line-height: 30px;
              background: linear-gradient(
                180deg,
                #ffffff 0%,
                #ffffff 36%,
                #7897dc 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }

      .gundong::-webkit-scrollbar {
        width: 0 !important;
      }

      .header-text {
        font-family: PangMenZhengDao;
        font-size: 32px;
        color: #ebf4ff;
        text-align: center;
        font-weight: 400;
        line-height: 45px;
        user-select: none;

        & > img {
          cursor: pointer;
        }
      }
    }
  }
}

.jianju {
  margin: 0 0px 0 2%;
}

.jl {
  margin: 0 0 0 15px;
}

.flex {
  display: flex;
}

.gundongtiao {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5% 0 5.5%;
  /* justify-content: space-between; */
  width: 100%;
  padding: 0 20px;
}
.tap {
  background-image: url("~@/assets/new_yingji/btn_lan.png");
  background-size: 100% 100%;
  line-height: 29px;
}
// .tap:nth-of-type(1) {
//   background-image: url('~@/assets/new_yingji/btn_jin.png');
// }
.tap:hover {
  background-image: url("~@/assets/new_yingji/btn_jin.png");
  background-size: 100% 100%;
  cursor: pointer;
}
.jindi {
  background-image: url("~@/assets/new_yingji/btn_jin.png");
  // background-image: url("~@/assets/newImage/tapJin.png");
  // background-size: 100% 100%;
}
</style>
<style lang="scss" scoped>
.mode_nameBox {
  box-sizing: border-box;
  width: 116px;
  height: 28px;
  background: url("~@/assets/images/observe/fckernel/nest/select.png") no-repeat;
  background-size: contain;
  margin-right: 5px;
  padding-left: 15px;
  display: flex;
  align-items: center;

  .mode_name {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    color: #3df1ff;
    margin: 0 6px 0 0;
  }
}

.el-dropdown-menu {
  background: rgba(9, 32, 87, 0.5) !important;
  .el-dropdown-menu__item {
  }
}

.noStyle {
  opacity: 0.3;
}

.el-dropdown {
  color: #3df1ff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;

  .el-icon-caret-bottom {
    color: #fff !important;
    line-height: 24px;
  }
}

.nones {
  opacity: 0;
}
.right {
  display: flex;
  justify-content: flex-end;
}
.line {
  width: 1px;
  height: 32px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 47%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
