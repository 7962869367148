<template>
  <div class="ovh mountControllerBox">
    <div class="wih00 h40 fix mountControllerTitle" v-interact>
      <div class="fl ml20 lh40 mr30 cfc fw700">双光</div>
      <div class="fr lh40 cfc mr20 cp" @click="$emit('close')">关闭</div>
    </div>
    <div class="jcsb">
      <div class="ml20">
        <div class="form">
          <div class="mb20">
            <span class="w200" style="line-height: 26px">控制速度：</span>
            <el-select
              v-model="speed"
              clearable
              placeholder="请选择控制速度"
              @change="cameraChangeFn"
            >
              <el-option
                v-for="item in 5"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="form_item">
            <span class="">云台模式:</span>
            <div>
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDom == 0 }"
                  @click="handleClick(2, 0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDom == 1 }"
                  @click="handleClick(2, 1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDom == 2 }"
                  @click="handleClick(2, 2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div>
          <div
            class="form-item w228 mb10"
            style="display: flex; justify-content: space-between"
          >
            <div class="label-box">视频指点:</div>
            <div class="input-box cf">
              <el-radio-group @change="handle_change_dj_mode" v-model="dj_mode">
                <el-radio
                  size="mini"
                  v-for="item in drd.dj_mode"
                  :key="item.id"
                  :label="item.id"
                >
                  <span class="cf">{{ item.label }}</span>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="mb20">
            <span class="w200 mr5" style="line-height: 26px">画 中 画：</span>
            <el-select
              v-model="selectTextsValue"
              clearable
              @change="gimbal_image_fusion"
            >
              <el-option label="主高清" value="0"> </el-option>
              <el-option label="主红外 " value="1"> </el-option>
              <el-option label="单高清" value="2"> </el-option>
              <el-option label="单红外" value="3"> </el-option>
            </el-select>
          </div>
          <div class="mb20">
            <span class="w200" style="line-height: 26px">伪　　彩：</span>
            <el-select
              v-model="selectText2Value"
              clearable
              @change="gimbal_thermal_mode"
            >
              <el-option label="白热" value="0"> </el-option>
              <el-option label="黑热 " value="1"> </el-option>
              <el-option label="彩虹" value="2"> </el-option>
              <el-option label="熔岩" value="3"> </el-option>
            </el-select>
          </div>

          <div class="mb20">
            <span class="w200" style="line-height: 26px">电子变倍：</span>
            <el-select
              v-model="selectText3Value"
              clearable
              placeholder="请选择倍数"
              @change="gimbal_thermal_zoom"
            >
              <el-option label="1倍" value="1"> </el-option>
              <el-option label="2倍 " value="2"> </el-option>
              <el-option label="4倍" value="4"> </el-option>
              <el-option label="6倍" value="6"> </el-option>
              <el-option label="8倍" value="7"> </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="w200">
        <div class="form_item">
          <div class="w200">
            <div class="mt10 mr20" style="display: flex; align-items: center">
              <span class="form_item_title cf">变焦：</span>
              <div>
                <div class="jcsb">
                  <div
                    class="symbol mr10 mt5"
                    @mousedown="backFn"
                    @mouseup="zoomStop(1)"
                    @mouseout="backFn"
                  >
                    -
                  </div>
                  <el-slider
                    class="w100"
                    :max="2"
                    :min="0"
                    :show-tooltip="false"
                    v-model="value1"
                    @change="zoomStop(1)"
                    @input="mousemoveFn"
                  ></el-slider>
                  <div
                    class="symbol ml10 mt5"
                    @mousedown="nextFn"
                    @mouseup="zoomStop(1)"
                    @mouseout="nextFn"
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="caozuoImg w150 mt10 h150 pr ml20">
            <!-- <div class="rocker">
              <div id="static"></div>
            </div> -->
            <div
              class="shangUp"
              @mousedown="fangxiang('up')"
              @mouseup="stopfxFn"
              @mouseout="stopfxFn"
            ></div>
            <div
              class="xaiUp"
              @mousedown="fangxiang('down')"
              @mouseup="stopfxFn"
              @mouseout="stopfxFn"
            ></div>
            <div
              class="zuoUp"
              @mousedown="fangxiang('right')"
              @mouseup="stopfxFn"
              @mouseout="stopfxFn"
            ></div>
            <div
              class="youUp"
              @mousedown="fangxiang('left')"
              @mouseup="stopfxFn"
              @mouseout="stopfxFn"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import data from "./data/index.js";
import methods from "./methods/index.js";
export default {
  inject: ["mountControllerThis"],
  components: {},
  data() {
    return {
      ...data,
      value1: 1,
      activeBox1: 1,
      activeBox2: 1,
      activeBox3: 0,
      speed: null,
      holderModelDom: 1,
      pitch: null,
      yaw: null,
      zoom: 10,
      focusing: 20,
      selectTextsValue: "0",
      selectText2Value: "0",
      selectText3Value: "1",
      dj_mode: 0,
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
      },
      // 控制挂载信息体
      controInfor: {
        type: 200,
        systemCode: "mmc",
        state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
        username: "",
        height: 0,
        idlingTime: 0,
        data: {
          cmdControlType: 7000, //530
          cmdFunction: null,
          cmdState: null,
          cmdValue: null,
          cmdBuffer: "",
          cmdName: "MMC_Gimbal_ZT30N",
        },
        deviceHardId: this.mountControllerThis.deviceHardId,
      },
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted() {
    this.joystickFn();
    this.controInfor.username = this.user_info && this.user_info.username;
  },
  methods: {
    ...methods,
    handle_change_dj_mode(id) {
      this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId;
      this.controInfor.username = this.user_info && this.user_info.username;
      this.$store.commit("device/SET_MOVE_YS_DATA", {
        controInfor: this.controInfor,
        mountStatus: id,
        moveType: "ys",
      });
    },
    fangxiang(type) {
      let _this = this;
      console.log(11);
      switch (type) {
        case "up":
          _this.gimbal_pitch_ctrl(1);
          break;
        case "left":
          _this.gimbal_yaw_ctrl(-1);
          break;
        case "right":
          _this.gimbal_yaw_ctrl(1);
          break;
        case "down":
          _this.gimbal_pitch_ctrl(-1);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //   _this.gimbal_pitch_ctrl(0);
      //   _this.gimbal_yaw_ctrl(0);
      // }, 500);
    },
    stopfxFn() {
      let buffer = null;
      this.gimbal_pitch_ctrl(0);
      this.gimbal_yaw_ctrl(0);
    },
    joystickFn() {
      let _this = this;
      joystick({
        zone: document.getElementById("static"),
        mode: "static",
        position: { left: "50%", top: "50%" },
        color: "#9bacbe",
        onstart: function (type) {
          switch (type) {
            case "up":
              _this.gimbal_pitch_ctrl(1);
              break;
            case "right":
              _this.gimbal_yaw_ctrl(-1);
              break;
            case "left":
              _this.gimbal_yaw_ctrl(1);
              break;
            case "down":
              _this.gimbal_pitch_ctrl(-1);
          }
        },
        onend: function () {
          _this.gimbal_pitch_ctrl(0);
          _this.gimbal_yaw_ctrl(0);
        },
      });
    },
    mousemoveFn() {
      if (this.value1 > 1) {
        this.zoomFn(2);
      } else if (this.value1 < 1) {
        this.zoomFn(0);
      }
    },
    handleClick(e, num) {
      if (e == 1) {
        this.activeBox1 = num;
        this.gimbal_mode_ctrl(num);
      } else if (e == 2) {
        this.activeBox2 = num;
        this.holderModelDom = num;
        this.gimbal_mode_ctrl(num);
      } else {
        this.activeBox3 = num;
        this.laser_mode_ctrl(num);
      }
    },
    nextFn() {
      this.value1 = 2;
      this.zoomFn(2);
    },
    backFn() {
      this.value1 = 0;
      this.zoomFn(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.mountControllerBox {
  background: rgba(0, 23, 79, 0.7);
  border-radius: 10px;
}
.form {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 40px;
  color: #dce9ff;
  letter-spacing: 1px;
  box-sizing: border-box;

  .form_item {
    display: flex;
    margin-bottom: 15px;

    .form_item_title {
      display: block;
      width: 85px;
    }

    .mono_box {
      width: 28px;
      height: 13px;
      border-radius: 3px;
      margin-top: 2px;
      cursor: pointer;
    }

    .active {
      background: url("~@/assets/images/accident/mono_box.png") no-repeat !important;
      background-size: 100%;
    }

    .mono-short,
    .mono-long,
    .slider,
    .text {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    .mono-left {
      margin-left: 2px;
    }

    .mono-right {
      margin-right: 2px;
    }

    .mono-short {
      width: 111px;
      height: 17px;
      background: url("~@/assets/images/mount_short.png") no-repeat;
      background-size: 100%;
      margin-bottom: 5px;
    }

    .mono-long {
      width: 168px;
      height: 17px;
      background: url("~@/assets/images/mount_long.png") no-repeat;
      background-size: 100%;
      margin-bottom: 5px;
    }

    .imgs {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      & > img {
        cursor: pointer;
        width: 32px;
        height: 32px;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .slider_box {
      width: 140px;
      margin: -7px 5px 0;
    }
  }

  .img_bottom {
    display: flex;
    width: 320px;
    justify-content: space-between;

    & > img {
      cursor: pointer;
      width: 130px;
      height: 130px;
    }
  }

  ::v-deep {
    .el-select,
    .el-input {
      width: 170px;
      height: 26px;
    }

    .el-input__inner {
      width: 170px;
      height: 26px;
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }

    .el-select .el-input .el-select__caret {
      line-height: 26px;
    }

    // 修改input清除按钮样式
    .el-input__suffix {
      .el-input__suffix-inner {
        .el-icon-circle-close:before {
          line-height: 16px;
          position: absolute;
          top: 5px;
          right: 3px;
        }
      }
    }

    .el-button {
      margin-left: 10px;
      padding: 0;
      background: #2aefed;
      border-radius: 2px;
      width: 43px;
      height: 24px;
      outline: none;
      color: #000000;
      border: none;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.caozuoImg {
  /* background-image: url("~@/assets/images/accident/caozuo.png"); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("~@/assets/newImage/lunpan.png");
  position: relative;

  .shangUp {
    position: absolute;
    left: 37%;
    top: 11px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 0px solid red;
    cursor: pointer;
  }

  .xaiUp {
    position: absolute;
    left: 37%;
    bottom: 11px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 0px solid red;
    cursor: pointer;
  }

  .zuoUp {
    position: absolute;
    left: 11px;
    top: 37%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 0px solid red;
    cursor: pointer;
  }

  .youUp {
    position: absolute;
    right: 11px;
    top: 37%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 0px solid red;
    cursor: pointer;
  }
}

.symbol {
  box-sizing: border-box;
  color: #ffff;
  border-radius: 50%;
  border: 1px solid #08c2d1;
  width: 25px;
  height: 25px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  padding-left: 1px;

  // &:hover {
  //   opacity: 0.7;
  // }
}

/deep/.el-slider__runway {
  background-color: #409eff;
}

.mountControllerTitle {
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
  border-radius: 10px 10px 0 0;
}
</style>