import API from "@/api";
import axios from 'axios';
import {
  Utils
} from "@/lib/cesium";
import {
  zoomTo
} from "./zoomTo";

// 识别类型 4 代表游泳 5:跌倒检测 6:异物检测7:火焰烟雾8:车辆朝向9:车辆类型检测 10:车道分割线 11:绿化带分割12:车牌识别
let urlList = {
  4: "http://183.62.225.251:9092/api/inflet/v1/tasks/a155323d-033e-4b36-8c76-641cbe6f065d/predict",
  5: "http://32.128.6.151:9090/api/inflet/v1/tasks/f1017eee-bbe2-4b24-bc16-a61c49c52451/predict",
  6: "http://183.62.225.251:9092/api/inflet/v1/tasks/b7de30e8-c4f0-4502-ae9e-58a61524f914/predict",
  7: "http://183.62.225.251:9092/api/inflet/v1/tasks/ebb19f58-a963-4e9f-aac8-2520b312ba7d/predict",
  8: "http://183.62.225.251:9092/api/inflet/v1/tasks/cc1ab590-7ddf-4937-84ac-b2f861dd6da5/predict",
  9: "http://183.62.225.251:9092/api/inflet/v1/tasks/cc1ab590-7ddf-4937-84ac-b2f861dd6da5/predict",
  10: "http://32.128.6.151:9090/api/inflet/v1/tasks/aad14687-7a17-46df-b0e4-9664e4e529d9/predict",
  11: "http://32.128.6.151:9090/api/inflet/v1/tasks/b27e6ebd-feed-4011-a794-9d8a69b20084/predict",
  12: "http://183.62.225.251:9092/api/inflet/v1/tasks/07c9ae19-e235-474a-81c9-a0a4fcb59f4c/predict"
}
export default {
  //机载拍照
  async photoNX(id, streamSelect, device, dialogFlag) {
    console.log(id, '机载拍照');
    /**
     * id代表设备id
     * streamSelect代表是否为清流
     * device代表什么设备，0入云龙1鹰巢
     * dialogFlag 为true 不提示拍照成功
     */
    let streamData = {
      data: {
        data: {
          videoID: 1,
        },
      },
      type: 528,
    }
    if (streamSelect == "QingLiu") {
      streamData.data.messageID = 1016;
    } else {
      streamData.data.messageID = 1007;
    }
    if (device == 1) {
      this.$emit("fn", streamData, "wrj");
    } else {
      this.$emit("fn", streamData);
    }
    if (!dialogFlag) {
      this.$message.success("拍照成功！");
    }
    // if (this.taskId) {
    //   let dataRun = {
    //     taskId: this.taskId,
    //     typeId: 2,
    //     deviceId: id,
    //   };
    //   let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
    // }
  },
  //机载录制
  async recordNX(id, streamSelect, device) {
    /**
     * id代表设备id
     * streamSelect代表是否为清流
     * device代表什么设备，0入云龙1鹰巢
     */
    this.record = !this.record;
    if (this.record) {
      this.$message.success("开始录像！");
    } else {
      this.$message.success("停止录像！");
    }
    let streamData = {
      data: {
        data: {
          taskID: this.taskId,
          videoID: 1,
        },
      },
      type: 528,
    };
    if (streamSelect == "QingLiu") {
      streamData.data.messageID = 1017;
      streamData.data.data.status = this.record;
    } else {
      streamData.data.messageID = 1006;
      streamData.data.data.recordControl = this.record;
    }
    if (device == 1) {
      this.$emit("fn", streamData, "wrj");
    } else {
      this.$emit("fn", streamData);
    }
    // if (this.taskId && this.record) {
    //   let dataRun = {
    //     taskId: this.taskId,
    //     typeId: 25,
    //     deviceId: id,
    //   };
    //   // try {
    //   //   let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
    //   // } catch (error) {
    //   //   console.log(error);
    //   // }
    // }
  },
  //平台截图
  async photoSelf(deviceHardId, flag) {
    /**
     * id代表设备id
     * deviceHardId代表无人机序列号
     */
    let blob = this.screenShot();
    let fd = new FormData();
    fd.append("uploadFile", blob, `拍照.png`);
    if (this.taskId) {
      fd.append("taskId", this.taskId);
    }
    fd.append("deviceHardId", deviceHardId);
    let res = await API.FCKERNEL.Upload(fd);
    // if (this.taskId) {
    //   let dataRun = {
    //     taskId: this.taskId,
    //     typeId: 1,
    //     deviceId: id,
    //   };
    //   let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
    // }
    if (flag) {
      this.$message.error("无识别结果，截图已保存到视图库！");
    } else {
      this.$message.success("截图成功！");
      let imgshowurl = process.env.VUE_APP_IMG_URL + "/uploads" + res.fileKey;
      this.$emit("imgUrl", imgshowurl);
    }
  },
  // 人脸车牌识别
  async getcanvasFaceCar(command, deviceHardId, id) {
    /**
     * command人脸or车牌，a人脸，b车牌
     * id代表设备id
     * deviceHardId代表无人机序列号
     */
    let blob = this.screenShot();
    let fd = new FormData();
    fd.append("uploadFiles", blob, `下载.jpeg`);
    let res = await API.MAPMANAGE.fileUpload(fd);
    if (command == "a") {
      this.aiuse(res[0].storage, deviceHardId, id);
    } else {
      this.plate(res[0].storage, deviceHardId, id);
    }
  },
  getPopup(flag, val) {
    let scene = viewer.scene
    this.aiPopup = new Cesium.BillboardCollection({
      scene,
      show: true,
    });
    this.aiPopup.add({
      position: Utils.transformWGS842Cartesian({
        lng: Number(this.uavData.locationCoordinate3D.longitude),
        lat: Number(this.uavData.locationCoordinate3D.latitude),
        alt: Number(this.uavData.locationCoordinate3D.altitude),
      }),
      image: require(`@/assets/images/factor/ai/${flag ? 'aisu' : 'aier'}.png`),
      scale: 1,
      width: 54,
      height: 89,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      horizontalOrigin: Cesium.VerticalOrigin.CENTER,
      id: 258, //字444444
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
        0,
        2.2e5
      ),
      scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
    });
    scene.primitives.add(this.aiPopup);
  },
  // 人脸
  async aiuseV2(url, deviceHardId, single, aiData) {
    let data = {
      imageUrl: url,
      deviceHardId: deviceHardId,
    };
    if (aiData) {
      //区域识别
      let res = await API.AIUSE.aiphoto(data);
      if (res.code == 200) {


        let datapid = {
          aiFacePid: res.pid,
        };
        let resFace = await API.AIUSE.getFaceuavvideoMsg(datapid);
        for (let i = 0; i < resFace.length; i++) {
          if (resFace[i].idCard == aiData.faceData.id) {
            this.$emit("fn", {
              data: "POSITION",
              type: 513
            });
            // this.$emit("AIDialog", true, "当前位置识别到人");
            this.getPopup(true)
            this.pid = res.pid;
            this.faceAiShow = true;

          }
        }
      } else {
        if (single) {
          this.getPopup(false)
        }
      }
    } else {
      let res = await API.AIUSE.aiphoto(data);
      if (res.code == 200) {
        this.$emit("fn", {
          data: "POSITION",
          type: 513
        });
        this.$emit("AIDialog", true, "当前位置识别到人");
        this.getPopup(true)

        this.pid = res.pid;
        this.faceAiShow = true;
      } else {
        if (single) {
          this.getPopup(false)

        }
      }
    }
  },
  // 车牌
  async plateV2(url, deviceHardId, single, aiData) {
    let data = {
      imgUrl: url,
      deviceHardId: deviceHardId,
    };
    if (aiData) {
      //区域识别
      let res = await API.AIUSE.palteId(data);
      if (res.code == 200) {
        res.data.forEach(val => {
          if (val.plateNum == aiData.carData.carnum) {
            this.$emit("fn", {
              data: "POSITION",
              type: 513
            });
            // this.$emit("AIDialog", true, "当前位置识别到车辆");
            this.getPopup(true)
            this.carList = res.data;
            this.carAiShow = true;
          }
        })
      } else {
        if (single) {
          this.getPopup(false)
        }
      }
    } else {

      let res = await API.AIUSE.palteId(data);
      if (res.code == 200) {
        this.$emit("fn", {
          data: "POSITION",
          type: 513
        });
        this.$emit("AIDialog", true, "当前位置识别到车辆");
        this.getPopup(true)
        this.carList = res.data;
        this.carAiShow = true;
      } else {
        if (single) {
          this.getPopup(false)
        }
      }
    }
  },
  //人流识别
  async flowV2(url, deviceHardId, single) {
    let data = {
      imageUrl: url,
      deviceHardId: deviceHardId,
    };
    let res2 = await API.FCKERNEL.crowdDensity(data);
    if (res2 && res2.total) {
      this.$emit("fn", {
        data: "POSITION",
        type: 513
      });
      this.$emit("AIDialog", true, "当前位置识别到人群");
      this.getPopup(true)
      this.trafficShow = true;
      this.trafficData = res2;
    } else {
      if (single) {
        this.getPopup(false)

      }
    }
  },
  //ai识别
  async aiIdentify(aiData, flag, single) {
    let {
      mqttType, //发送数据类型 true 代表无人机 false 代表鹰巢
      deviceHardId,
      id,
      aiType, // 识别类型 4 代表游泳 5:跌倒检测 6:异物检测7:火焰烟雾8:车辆朝向9:车辆类型检测 10:车道分割线
    } = aiData
    let blob = this.screenShot();
    let data = {
      image: null,
    };
    if (aiType > 3 && aiType != 5) {
      blobToBase64(blob, async (base64DataUrl) => {
        data.image = base64DataUrl;
        // console.log(aiData.aiType,"aiTypeaiTypev");
        let res = await axios.post(
          urlList[aiType],
          data
        );
        //  if(this.indexswim == 10){
        if (res.data.data.targets && res.data.data.targets.length > 0) {

          // clearInterval(this.AISetInterval);
          // this.AISetInterval = null;
          this.timerList.forEach(v => {
            clearInterval(v)
          })
          this.timerList = []
          // if (aiType == 4) {
          if (flag) {
            if (aiType != 12) {
              this.$message.success("识别成功！")
            }
          } else {
            if (mqttType) {
              this.$emit("fn", {
                data: "HOLD",
                type: 513
              }, "wrj");
            } else {
              this.$emit("fn", {
                data: "POSITION",
                type: 513
              });
            }
            if (aiType == 4) {
              this.$emit("AIDialog", true, "当前识别到有人游泳");
            } else if (aiType == 5) {
              this.$emit("AIDialog", true, "当前识别到有人员跌倒");
            } else if (aiType == 6) { //
              this.$emit("AIDialog", true, "当前区域识别到异物");
              // let deviceTyoe = null
              // if (mqttType) {
              //   deviceTyoe = 0
              // } else {
              //   deviceTyoe = 1
              // }   
              // 识别到异物后机载拍照三张
              //  stream 视频播放类型  deviceTyoe 代表无人机类型
              // let stream = this.$store.state.fckernel.stream
              // this.photoNX(id, stream, deviceTyoe, true)
              // this.photoNX(id, stream, deviceTyoe, true)
              // this.photoNX(id, stream, deviceTyoe, true)
            } else if (aiType == 7) {
              this.$emit("AIDialog", true, "当前区域识别到烟雾");
            }
            this.getPopup(true)
          }
          // }
          let fd = new FormData();
          fd.append("uploadFiles", blob, `下载.jpeg`);
          let res2 = await API.MAPMANAGE.fileUpload(fd);
          let carJson = res.data.data //出鬼了，无人机识别车辆没用，鹰巢有用，才写的
          if (aiType != 12) {
            let data2 = {
              aiType: aiType,
              imageUrl: res2[0].storage,
              deviceHardId: deviceHardId,
              jsonData: JSON.stringify(res.data.data),
            };
            let swimres = await API.FCKERNEL.insertSwimmingMonitor(data2);
          } else {
            let arr = []
            carJson.targets.forEach(val => {
              let data = {
                plate_detection: null
              }
              data.plate_detection = {
                x: val.bbox.box.left_top_x,
                y: val.bbox.box.left_top_y,
                w: val.bbox.box.right_bottom_x - val.bbox.box.left_top_x,
                h: val.bbox.box.right_bottom_y - val.bbox.box.left_top_y
              }
              data.plate_number = val.bbox.label
              data.imageUrl = res2[0].storage
              data.deviceHardId = deviceHardId
              arr.push(data)
            })
            let resCar = await API.FCKERNEL.addPlateInfoByNumber(arr);
            // console.log(Array.isArray(resCar),"dfsfaf");
            if (Array.isArray(resCar)) {
              this.carList = resCar;
              this.carAiShow = true;
            } else {
              this.$message.error(resCar)
            }
          }
          // process.env.VUE_APP_IMG_URL +
          let imgshowurl = process.env.VUE_APP_IMG_URL + res2[0].url;
          let jsondata = {
            jsonData: JSON.stringify(res.data.data)
          }
          this.$emit("imgUrl", imgshowurl, aiType, jsondata);
        } else {
          if (single) {
            this.getPopup(false)
          }
          // this.$message.error("无识别结果！")
          if (flag) {
            this.photoSelf(deviceHardId, true)
          }
        }
      });
    } else if (aiType <= 3) {
      let fd = new FormData();
      fd.append("uploadFiles", blob, `下载.jpeg`);
      let res = await API.MAPMANAGE.fileUpload(fd);
      if (aiDataLine.spaceFlag) {
        if (aiType == 1) {
          this.aiuseV2(res[0].storage, deviceHardId, single, aiDataLine.spaceFlag)
        } else if (aiType == 2) {
          this.plateV2(res[0].storage, deviceHardId, single, aiDataLine.spaceFlag)
        } else if (aiType == 3) {
          this.flowV2(res[0].storage, deviceHardId, single, aiDataLine.spaceFlag);
        }
      } else {
        if (aiType == 1) {
          this.aiuseV2(res[0].storage, deviceHardId, single)
        } else if (aiType == 2) {
          this.plateV2(res[0].storage, deviceHardId, single)
        } else if (aiType == 3) {
          this.flowV2(res[0].storage, deviceHardId, single);
        }
      }
    } else if (aiType == 5) {
      let fd = new FormData();
      fd.append("image", blob, `下载.jpeg`);
      fd.append("detectortype", "14");
      let res = await API.MAPMANAGE.boatUpload(fd);
      if (res.total > 0 && res.data.bbox) {
        let fd2 = new FormData();
        fd2.append("uploadFiles", blob, `下载.jpeg`);
        let resImg = await API.MAPMANAGE.fileUpload(fd);
        let jsData = {
          targets: []
        }
        res.data.bbox.forEach(j => {
          let once = {
            left_top_x: j[0],
            left_top_y: j[1],
            right_bottom_x: j[2],
            right_bottom_y: j[3]
          }
          jsData.targets.push(once)
        })
        let boatdata = {
          aiType: aiType,
          imageUrl: resImg[0].storage,
          deviceHardId: deviceHardId,
          jsonData: JSON.stringify(jsData),
        };
        let boat = await API.FCKERNEL.insertSwimmingMonitor(boatdata);
        let imgshowurl = process.env.VUE_APP_IMG_URL + resImg[0].url;
        let jsondata = {
          jsonData: JSON.stringify(jsData)
        }
        this.$emit("imgUrl", imgshowurl, aiType, jsondata);
      } else {
        if (flag) {
          this.photoSelf(deviceHardId, true)
        }
      }
    }

    function blobToBase64(blob, callback) {
      let reader = new FileReader();
      reader.onload = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(blob);
    }
  },
  async aiIdentify_zd(aiData, flag, single) {
    let flightMode = this.$store.state.shoudong.Mstate
    if (flightMode != '航线模式') {
      return false
    }

    let {
      mqttType, //发送数据类型 true 代表无人机 false 代表鹰巢
      deviceHardId,
      id,
      aiType, // 识别类型 4 代表游泳 5:跌倒检测 6:异物检测7:火焰烟雾8:车辆朝向9:车辆类型检测 10:车道分割线
    } = aiData
    let blob = this.screenShot();
    let data = {
      image: null,
    };
    if (aiType > 3) {
      blobToBase64(blob, async (base64DataUrl) => {
        data.image = base64DataUrl;
        // console.log(aiData.aiType,"aiTypeaiTypev");
        let res = await axios.post(
          urlList[aiType],
          data
        );
        //  if(this.indexswim == 10){
        if (res.data.data.targets && res.data.data.targets.length > 0) {

          // clearInterval(this.AISetInterval);
          // this.AISetInterval = null;
          this.timerList.forEach(v => {
            clearInterval(v)
          })
          this.timerList = []
          // if (aiType == 4) {
          if (flag) {
            if (aiType != 12) {
              this.$message.success("识别成功！")
            }
          } else {
            if (mqttType && aiType != 6) {
              this.$emit("fn", {
                data: "HOLD",
                type: 513
              }, "wrj");
            } else {
              this.$emit("fn", {
                data: "POSITION",
                type: 513
              });
            }
            if (aiType == 4) {
              this.$emit("AIDialog", true, "当前识别到有人游泳");
            } else if (aiType == 5) {
              this.$emit("AIDialog", true, "当前识别到有人员跌倒");
            } else if (aiType == 6) { //
              // this.$emit("AIDialog", true, "当前区域识别到异物");
              // let deviceTyoe = null
              // if (mqttType) {
              //   deviceTyoe = 0
              // } else {
              //   deviceTyoe = 1
              // }
              // // 识别到异物后机载拍照三张
              // //  stream 视频播放类型  deviceTyoe 代表无人机类型
              // let stream = this.$store.state.fckernel.stream
              // this.photoNX(id, stream, deviceTyoe, true)
              // this.photoNX(id, stream, deviceTyoe, true)
              // this.photoNX(id, stream, deviceTyoe, true)
            } else if (aiType == 7) {
              this.$emit("AIDialog", true, "当前区域识别到烟雾");
            }
            this.getPopup(true)
          }
          // }
          let fd = new FormData();
          fd.append("uploadFiles", blob, `下载.jpeg`);
          let res2 = await API.MAPMANAGE.fileUpload(fd);
          let carJson = res.data.data //出鬼了，无人机识别车辆没用，鹰巢有用，才写的
          if (aiType != 12) {
            let data2 = {
              aiType: aiType,
              imageUrl: res2[0].storage,
              deviceHardId: deviceHardId,
              jsonData: JSON.stringify(res.data.data),
            };
            let swimres = await API.FCKERNEL.insertSwimmingMonitor(data2);
          } else {
            let arr = []
            carJson.targets.forEach(val => {
              let data = {
                plate_detection: null
              }
              data.plate_detection = {
                x: val.bbox.box.left_top_x,
                y: val.bbox.box.left_top_y,
                w: val.bbox.box.right_bottom_x - val.bbox.box.left_top_x,
                h: val.bbox.box.right_bottom_y - val.bbox.box.left_top_y
              }
              data.plate_number = val.bbox.label
              data.imageUrl = res2[0].storage
              data.deviceHardId = deviceHardId
              arr.push(data)
            })
            let resCar = await API.FCKERNEL.addPlateInfoByNumber(arr);
            // console.log(Array.isArray(resCar),"dfsfaf");
            if (Array.isArray(resCar)) {
              this.carList = resCar;
              this.carAiShow = true;
            } else {
              this.$message.error(resCar)
            }
          }
          // process.env.VUE_APP_IMG_URL +
          let imgshowurl = process.env.VUE_APP_IMG_URL + res2[0].url;
          let jsondata = {
            jsonData: JSON.stringify(res.data.data)
          }
          this.$emit("imgUrl", imgshowurl, aiType, jsondata);
        } else {
          if (single) {
            this.getPopup(false)
          }
          // this.$message.error("无识别结果！")
          if (flag) {
            this.photoSelf(deviceHardId, true)
          }
        }
      });
    } else if (aiType <= 3) {
      let fd = new FormData();
      fd.append("uploadFiles", blob, `下载.jpeg`);
      let res = await API.MAPMANAGE.fileUpload(fd);
      if (aiDataLine.spaceFlag) {
        if (aiType == 1) {
          this.aiuseV2(res[0].storage, deviceHardId, single, aiDataLine.spaceFlag)
        } else if (aiType == 2) {
          this.plateV2(res[0].storage, deviceHardId, single, aiDataLine.spaceFlag)
        } else if (aiType == 3) {
          this.flowV2(res[0].storage, deviceHardId, single, aiDataLine.spaceFlag);
        }
      } else {
        if (aiType == 1) {
          this.aiuseV2(res[0].storage, deviceHardId, single)
        } else if (aiType == 2) {
          this.plateV2(res[0].storage, deviceHardId, single)
        } else if (aiType == 3) {
          this.flowV2(res[0].storage, deviceHardId, single);
        }
      }
    }

    function blobToBase64(blob, callback) {
      let reader = new FileReader();
      reader.onload = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(blob);
    }
  },
  // 人脸
  async aiuse(url, deviceHardId, id) {
    let data = {
      imageUrl: url,
      deviceHardId: deviceHardId,
    };
    if (id) {
      this.$el_message("正在识别中...", () => {}, "info", 0);
    }
    let res = await API.AIUSE.aiphoto(data);
    let dataRun = {
      taskId: this.taskId,
      deviceId: id,
    };
    if (res.code == 200) {
      if (this.taskId && id) {
        dataRun.typeId = 5;
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
      if (id) {
        this.$el_message("识别成功！", () => {}, "success");
      }
      this.pid = res.pid;
      this.faceAiShow = true;
    } else if (res.code == 201) {
      if (id) {
        this.$el_message(res.message, () => {}, "error");
      }
      if (this.taskId && id) {
        dataRun.typeId = 7;
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
    } else {
      if (id) {
        this.$el_message(res.message, () => {}, "error");
      }
      if (this.taskId && id) {
        dataRun.typeId = 6;
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
    }
  },
  // 车牌
  async plate(url, deviceHardId, id) {
    let data = {
      imgUrl: url,
      deviceHardId: deviceHardId,
    };
    if (id) {
      this.$el_message("正在识别中...", () => {}, "info", 0);
    }
    let res = await API.AIUSE.palteId(data);
    let dataRun = {
      taskId: this.taskId,
      deviceId: id,
    };
    if (res.code == 200) {
      if (this.taskId && id) {
        dataRun.typeId = 8;
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
      if (id) {
        this.$el_message("识别完成", () => {}, "success");
      }
      this.carList = res.data;
      this.carAiShow = true;
    } else if (res.code == 201) {
      if (id) {
        this.$el_message(res.message, () => {}, "error");
      }
      if (this.taskId && id) {
        dataRun.typeId = 10;
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
    } else {
      if (id) {
        this.$el_message(res.message, () => {}, "error");
      }
      if (this.taskId && id) {
        dataRun.typeId = 9;
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun);
      }
    }
  },
  //人流识别
  async flow(deviceHardId) {
    let blob = this.screenShot();
    let fd = new FormData();
    fd.append("uploadFiles", blob, `下载.jpeg`);
    let res = await API.MAPMANAGE.fileUpload(fd);
    let data = {
      // imageUrl:"http://32.128.6.52:9000/uploads/2023/05/26/2f183488-ad62-41c2-bb89-736ade5d8f36.png",
      imageUrl: res[0].storage,
      deviceHardId: deviceHardId,
    };
    let res2 = await API.FCKERNEL.crowdDensity(data);
    // console.log(res2,"SSSSS");
    // return
    if (res2 && res2.total) {
      this.trafficShow = true;
      this.trafficData = res2;
      let jsondata = {
        jsonData: JSON.stringify(res2.data)
      }
      // this.$emit("imgUrl", res[0].storage, 3,jsondata);
    } else {
      this.$el_message("识别失败！", () => {}, "error");
    }
  },
  async mount_angle() {
    let mount = this.$store.state.fckernel.mount
    let data = {
      "type": 514,
      "data": {
        "mountId": mount.mountId, //挂载ID
        "payload": [165, 79, 17, 85, 170, 220, 12, 26, 11, 0, 0, 64, 0, 0, 0, 0, 0, 93, 221]
      }
    }
    this.$emit("fn", data, "wrj");
    this.$emit("fn", data, "wrj");
  },
  CAMERA_ZOOM(data) {
    if (data.actions.length > 0) {
      data.actions.forEach(item => {
        if (item.actionType == "CAMERA_ZOOM") {
          let mount = this.$store.state.fckernel.mount
          let buffer = zoomTo(item.param2)
          let data = {
            "type": 514,
            "data": {
              "mountId": mount.mountId, //挂载ID
              "payload": buffer
            }
          }
          this.$emit("fn", data, "wrj");

        }
      });
    }
  },
  async lineAi(val, aiData) {
    let aiDataLine = aiData
    let progress = val.waypointIndex

    // if(val.isFinish){
    if (this.lineInfo.points) {
      let data = this.lineInfo.points[progress - 1]
      console.log(progress, data, '航点');
      // 执行变倍操作
      this.CAMERA_ZOOM(data)
      // 如果是异物识别挂载调整90°
      let yw = data.ailist.filter(item => item.id == 6)
      if (yw && yw[0].switch) {
        this.mount_angle()
        //如果是异物识别挂载调整90°
        val.isFinishAction = true
        console.log('挂载调整90°');
      }
      let timer = null
      if (val.isFinishAction) {
        console.log(data.ailist, 'data.ailist');
        if (data.ailist) {
          console.log(this.aiPopup, 'this.aiPopup-----');
          if (!this.aiPopup) {
            let scene = viewer.scene
            this.aiPopup = new Cesium.BillboardCollection({
              scene,
              show: true,
            });
            this.aiPopup.add({
              position: Utils.transformWGS842Cartesian({
                lng: Number(this.uavData.locationCoordinate3D.longitude),
                lat: Number(this.uavData.locationCoordinate3D.latitude),
                alt: Number(this.uavData.locationCoordinate3D.altitude),
              }),
              image: require("@/assets/images/factor/ai/aiing.png"),
              scale: 1,
              width: 54,
              height: 89,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              id: 258, //字444444
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                0,
                2.2e5
              ),
              scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            });
            scene.primitives.add(this.aiPopup);
            this.$emit("getAiPopup", this.aiPopup)
          }
          data.ailist.forEach(item => {
            console.log(item.check, 'item.check');
            if (item.check) {
              aiDataLine.aiType = item.id
              if (this.lineInfo.carData || this.lineInfo.faceData) {
                aiDataLine.spaceFlag = true
                aiDataLine.carData = this.lineInfo.carData
                aiDataLine.faceData = this.lineInfo.faceData
              } else {
                aiDataLine.spaceFlag = false
              }
              this.aiIdentify(aiDataLine, false, true)
            } else if (!item.check && item.switch) {
              if (this.timers.indexOf(item.id) == -1) {
                timer = setInterval(() => {
                  this.aiIdentify_zd(aiDataLine, false)
                }, 500);
                this.timers.push(item.id)
                this.timerList.push(timer)
              } else {
                aiDataLine.aiType = item.id,
                  timer = setInterval(() => {
                    this.aiIdentify_zd(aiDataLine, false)
                  }, 500);
                this.timerList.push(timer)
              }
            } else if (!item.switch && item.history) {
              console.log('结束航点');
              let num = this.timers.indexOf(item.id)
              // console.log(num,"mmmm");
              if (this.timers.indexOf(item.id) > -1) {
                this.timers.splice(num, 1)
                clearInterval(this.timerList[num])
                this.timerList.splice(num, 1)
                // console.log(this.timerList,"222");
              }
            }
          })
        }
      }
    }
  }
  // }
}