export default {
  list: [
    // {
    //   userName: '何元高',
    //   birthday: '1974-01-11',
    //   occurTime: '2022-02-25 08:44;55',
    //   address: '江苏省盐城市亭湖区人民北路66号华府景城31幢1605室',
    //   idCard: '320902197401112539',
    //   labels: ['省内人员'],
    //   similarity: '63',
    //   img: 'http://32.128.10.139:30080/components/osg-default/_/video_face_cropped/20220225-ca5ae89e-000a580ae000af-00000020-00000c10',
    //   imgs:'http://32.128.6.48/uploads/2022/02/25/b4e2793c-bba1-475d-9702-6a9c3f7a1adf.jpeg',
    //   show: false
    // },
  ]
}