export default {

    //crc校验字节生成函数
    cal_crc_table(array) {
        var crc = 0;
        var i = 1;
        var len = array[2];
        while (len--) {
            crc = this.crc_table[crc ^ array[i]];
            i++;
        }
        return crc;
    },
    gimbal_mode2_ctrl(gimbal_mode) {
        if (this.safety_switch_state) {
            var buff = new Array(0xA5, 0x20, 0x03, 0x01, 0x00);
            buff[3] = gimbal_mode;
            buff[4] = this.cal_crc_table(buff);
            let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
            this.controInfor.data.cmdBuffer = newbuf.join(',');
            this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
            this.$emit('MMCGimbalFN3Fn', this.controInfor)
            this.$message.success('操作成功')
        } else {
            this.$message.info('请关闭保险')
        }

    }

}