import API from "@/api";
export default {
    showCount(item) {
        this.$emit("closeCount");
        if (item.status) {
            this.$emit("brightTailFn", item.model, this.BrightTailId);
        } else {
            this.$emit("closeCount");
        }
    },
    handClick(data, flag) {
        if (flag) {
            let taskActions = [];
            if (data.taskActions) {
                for (let i = 0; i < data.taskActions.length; i++) {
                    taskActions.push({
                        id: data.taskActions[i].id,
                        status: data.taskActions[i].status ? 1 : 0,
                    });
                }
            }
            let item = {
                id: data.id,
                status: 1,
                sort: data.sort,
                taskActions,
            };
            this.updateTaskProcess(item);
        }
    },
    updateTaskProcess(data) {
        API.ACCIDENT.updateTaskProcess(data).then((res) => {
            if (res) {
                this.getList();
            }
        });
    },
    // 获取模板 addModel
    getList(guideId) {
        let type = this.$route.query.page;
        if (type == 0) {
            this.getTaskGuideFn(72, guideId)
        } else if (type == 1) {
            this.getTaskGuideFn();
        } else if (type == 2) {
            // 食药环
            this.getTaskGuideFn(74, guideId)
        } else if (type == 3) {
            // 网安
            this.getTaskGuideFn(76, guideId)
        } else if (type == 4) {
            // 水警
            this.getTaskGuideFn(77, guideId)
        } else if (type == 6) {
            // 技侦
            this.getTaskGuideFn(78, guideId)
        }
    },
    // 交通任务列表 廢棄
    async getTaskProcessFn() {
        let res = await API.ACCIDENT.getTaskProcess({
            taskId: this.taskId,
            modelId: 1,
        });
        if (res) {
            this.getProcessList = res;
        }
    },
    // 获取指引列表
    async getTaskGuideFn() {
        try {
            let res = await API.ACCIDENT.getTaskGuide({
                taskId: this.taskId || 1,//this.id
                guideId: this.itemLIist.id || this.itemLIist.guideId
            });
            if (res) {
                this.getProcessList = res;
            }
        } catch (error) {
            // console.log(error);
        }
    },
    brightTailFn(item, index) {
        if (this.itemIndex != index) {
            this.selectFlag = true
            this.itemIndex = index
        } else {
            this.selectFlag = !this.selectFlag
            this.itemIndex = "x"
        }
        this.itemLIist = item
        if (this.selectFlag) {
            this.getList();
        }
    },
    // 点击确认
    async confirmFn(item) {
        console.log(item,"dsaajdashbjhsa");
        if(!this.taskId){
            return this.$message.error("请选择任务")
        }
        if (item.alertList && item.alertList.length > 0) {
            this.$emit('brightTailshowDialog', item, this.taskId, this.itemLIist)
        } else {
            let res = await API.ACCIDENT.createGuideAction({
                taskId: this.taskId,//
                taskGuideActionType: 0,
                taskGuideActionStatus: 1,
                taskGuideDeatilsId: item.taskGuideDetailsId
            })
            if (res) {
                this.getList()
            }
        }
    },
    async showCount2(item, list) {
        // this.$emit("closeCount");
        // console.log("123123123132");
        // this.$refs.BrightTailRef.devicecarmeraStatus=0
        let taskGuideActionStatus = null
        if (item.deviceStatus) {
            taskGuideActionStatus = 1
            // console.log("123456",item.mountModel,this.BrightTailId);
            this.$emit("brightTailFn", item.mountModel, this.BrightTailId);
        } else {
            taskGuideActionStatus = 0
            this.$emit("closeCount");
        }
        // let res = await API.ACCIDENT.createGuideAction({
        //     taskId: this.id,//this.id
        //     taskGuideActionType: 1,
        //     taskGuideDeatilsId: list.taskGuideDetailsId,
        //     taskGuideDeatilsDeviceId: item.taskGuideDetailsDeviceId,
        //     taskGuideActionStatus,
        // })


    },

}
