<template>
  <div class="pointList">
    <div class="points" :style="infoShow ? 'height:208px':''">
      <div class="title">
        <div class="w100"></div>
        <div>{{ pointInfo ? "航点" + pointInfo.id : "" }}</div>
        <div class="right">
          <div
            class="btn"
            :class="disable && pointList.length >= 2 ? 'active':''"
            v-if="disable && pointList.length >= 2"
            @click="saveLine"
          >生成航线</div>
          <div class="btn" v-else>生成航线</div>
          <div class="close" @click="$emit('close')">关闭</div>
        </div>
      </div>
      <div class="list" v-if="!infoShow">
        <div class="item" v-for="(item, i) in pointList" :key="i">
          <img :src="item.img" alt @click="getItem(item)" />
          <img class="img" src="@/assets/images/observe/close.png" alt @click="delPoint(i)" />
          <!-- <span @click="delPoint(i)">{{ i + 1 }}</span> -->
        </div>
        <div class="box el-icon-plus" @click="getPoint()"></div>
      </div>
      <div class="info" v-else>
        <div class="return el-icon-arrow-left cp" @click="save_point"></div>
        <div class="point">
          <div class="form">
            <!-- <div class="info" :class="active == 1?'active':''" @click="getActive(1,'carmera')">
          <div class="num">2/3</div>
          <div class="name">相机动作</div>
            </div>-->
            <div class="info" :class="active == 2 ? 'active' : ''" @click="getActive(2)">
              <div class="num">{{ pointInfo.alt.toFixed(2) }}</div>
              <div class="name">高度</div>
            </div>
            <div class="info" :class="active == 3 ? 'active' : ''" @click="getActive(3)">
              <div class="num">{{ pointInfo.heading.toFixed(2) }}</div>
              <div class="name">偏航角</div>
            </div>
            <div class="info" :class="active == 4 ? 'active' : ''" @click="getActive(4)">
              <div class="num">{{ pointInfo.actions[0].param3.toFixed(2) }}</div>
              <div class="name">云台偏航</div>
            </div>
            <div class="info" :class="active == 5 ? 'active' : ''" @click="getActive(5)">
              <div class="num">{{ pointInfo.actions[0].param1.toFixed(2) }}</div>
              <div class="name">云台俯仰</div>
            </div>
            <div class="info" :class="active == 6 ? 'active' : ''" @click="getActive(6)">
              <div class="num">{{ (pointInfo.actions[1].param2).toFixed(2) + "X" }}</div>
              <div class="name">变焦</div>
            </div>
            <div class="info" :class="active == 7 ? 'active' : ''" @click="getActive(7, 'ai')">
              <div class="num">{{ checkedAI(aiList) + "/" + aiList.length }}</div>
              <div class="name">AI识别</div>
            </div>
            <div class="info" :class="active == 8 ? 'active' : ''" @click="getActive(8, 'stay')">
              <div class="num">{{ this.min + "分" + this.se + "秒" }}</div>
              <div class="name">悬停</div>
            </div>
            <div class="info" :class="active == 9 ? 'active' : ''" @click="getActive(9, 'speed')">
              <div class="num">{{this.speed}}M/S</div>
              <div class="name">速度</div>
            </div>
          </div>
          <div class="ai" v-if="aiShow">
            <!-- <div class="head">AI识别</div> -->
            <div
              class="item"
              :class="item.switch ? 'itemActive' : !item.switch && item.history ? 'itemAfter':'itemHover'"
              v-for="item in aiList"
              :key="item.id"
            >
              <div class="check">
                <div class="flexs" @click="getcheck(item)">
                  <div class="type">{{ item.type }}</div>
                  <!-- <el-switch v-model="item.switch"></el-switch> -->
                  <div class="img">
                    <img :src="item.img" alt />
                  </div>
                </div>
                <el-checkbox v-model="item.check" :disabled="!item.switch">单点</el-checkbox>
              </div>
            </div>
          </div>
          <div class="stay" v-if="stayShow">
            <!-- <el-slider v-model="stayVal" input-size="small"></el-slider> -->
            <el-input-number @change="getMinte" v-model="min" :max="30" :min="0"></el-input-number>分
            <el-input-number v-model="se" :max="maxSe" :min="0"></el-input-number>秒
          </div>
          <div class="stay" v-if="speedStatus">
            速度
            <el-input-number v-model="speed" :max="12" :min="1"></el-input-number>M/S
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import response from "./methods/response";
import { Utils } from "@/lib/cesium";
import { mapGetters } from "vuex";
import API from "@/api";
export default {
  props: {
    uavData: {
      type: Object,
      default: () => ({})
    },
    mountData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      disable: true,
      pointList: [],
      pointInfo: null,
      infoShow: false,
      eo_zoom: 0, //变焦
      picth: 0, //俯仰
      yaw: 0, //偏航
      active: 2,
      aiShow: false,
      stayShow: false,
      speedStatus: false,
      speed: 6,
      stayVal: 0,
      min: 0,
      se: 0,
      maxSe: 59,
      camShow: false,
      aiList: [
        {
          id: 1,
          type: "人脸识别",
          switch: false,
          check: false,
          img: require("./assets/face.svg")
        },
        {
          id: 2,
          type: "车牌识别",
          switch: false,
          check: false,
          img: require("./assets/car.svg")
        },
        {
          id: 3,
          type: "人流识别",
          switch: false,
          check: false,
          img: require("./assets/people.svg")
        },
        {
          id: 4,
          type: "游泳识别",
          switch: false,
          check: false,
          img: require("./assets/swim.svg")
        },
        {
          id: 7,
          type: "烟雾识别",
          switch: false,
          check: false,
          img: require("./assets/yan.svg")
        },
        {
          id: 6,
          type: "异物识别",
          switch: false,
          check: false,
          img: require("./assets/things.svg")
        }
      ],
      lineNameAI: ""
    };
  },
  computed: {
    ...mapGetters(["user_info"])
  },
  watch: {
    mountData: {
      handler(val) {
        this.set_payload_data(val.payload);
      }
    }
  },
  methods: {
    ...response,
    getMinte() {
      if (this.min == 30) {
        this.se = 0;
        this.maxSe = 0;
      } else {
        this.maxSe = 59;
      }
    },
    getcheck(item) {
      item.switch = !item.switch;
      if (!item.switch) {
        item.check = false;
      }
    },
    checkedAI() {
      let num = 0;
      this.aiList.forEach(val => {
        if (val.switch) {
          num++;
        }
      });
      return num;
    },
    save_point() {
      this.infoShow = false;
      if (this.pointInfo) {
        if (this.pointList.length > 0) {
          this.pointList.forEach(val => {
            if (val.id == this.pointInfo.id) {
              console.log(val, "val");
              val.speed = this.speed;
              // this.$set(val, "ailist", this.aiList);
              val.ailist = JSON.parse(JSON.stringify(this.aiList));
              val.actions[2].param1 = this.min * 60 + this.se;
            }
          });
        }
      }
      console.log(this.aiList, "save");
      // console.log(this.pointInfo);
      // console.log(this.pointList);
    },
    getPoint() {
      let blob = this.$parent.screenShot();
      let img = null;
      let _this = this;
      blobToBase64(blob, async base64DataUrl => {
        img = base64DataUrl;
        let data = {
          id: _this.pointList.length + 1,
          img: img,
          lat: _this.uavData?.locationCoordinate3D?.latitude || 0,
          lon: _this.uavData?.locationCoordinate3D?.longitude || 0,
          alt: _this.uavData?.gps?.relativeAlt || 0,
          speed: this.speed,
          frame: 3,
          isYawEnable: true,
          // isGimbalPitchEnable: true,
          heading: _this.uavData?.attitude?.yaw || 0
        };
        // console.log(this.mountData.payload,"mountData.payload");
        // _this.set_payload_data(_this.mountData.payload);
        let actions = [
          {
            actionType: "GIMBAL_PITCH",
            param2: 0,
            param1: 0 - _this.picth,
            param3: _this.yaw,
            param4: 2
          },
          {
            actionType: "CAMERA_ZOOM",
            param1: 2,
            param2: _this.eo_zoom
          },
          {
            actionType: "STAY",
            param1: 0
          }
        ];
        _this.$set(data, "actions", actions);
        // console.log(data, "datatattaatatat");
        _this.pointList.push(data);
        _this.pointInfo = data;
        _this.infoShow = true;
        _this.getDe();
        console.log(_this.pointList, "actitiiti");
      });
      function blobToBase64(blob, callback) {
        let reader = new FileReader();
        reader.onload = () => {
          callback(reader.result);
        };
        reader.readAsDataURL(blob);
      }

      // console.log(_this.uavData,"uavdataaaa");
      // console.log(_this.mountData,"mountData");
    },
    delPoint(i) {
      this.pointList.splice(i, 1);
      if (this.pointList.length > 0) {
        this.aiList = this.pointList[this.pointList.length - 1].ailist;
      } else {
        this.aiList = [
          {
            id: 1,
            type: "人脸识别",
            switch: false,
            check: false,
            img: require("./assets/face.svg")
          },
          {
            id: 2,
            type: "车牌识别",
            switch: false,
            check: false,
            img: require("./assets/car.svg")
          },
          {
            id: 3,
            type: "人流识别",
            switch: false,
            check: false,
            img: require("./assets/people.svg")
          },
          {
            id: 4,
            type: "游泳识别",
            switch: false,
            check: false,
            img: require("./assets/swim.svg")
          },
          {
            id: 7,
            type: "烟雾识别",
            switch: false,
            check: false,
            img: require("./assets/yan.svg")
          },
          {
            id: 6,
            type: "异物识别",
            switch: false,
            check: false,
            img: require("./assets/things.svg")
          }
        ];
      }
    },
    getItem(item) {
      this.infoShow = true;
      this.pointInfo = item;
      this.getDe();
    },
    getActive(num, val) {
      this.active = num;
      if (val == "ai") {
        this.aiShow = true;
        this.stayShow = false;
        this.camShow = false;
        this.speedStatus = false;
      } else if (val == "stay") {
        this.stayShow = true;
        this.aiShow = false;
        this.camShow = false;
        this.speedStatus = false;
      } else if (val == "carmera") {
      } else if (val == "speed") {
        this.speedStatus = true;
        this.aiShow = false;
        this.stayShow = false;
        this.camShow = false;
      } else {
        this.aiShow = false;
        this.stayShow = false;
        this.camShow = false;
        this.speedStatus = false;
      }
    },
    getDe() {
      this.active = 2;
      this.aiShow = false;
      this.stayShow = false;
      this.camShow = false;
      console.log(this.aiList, "123");

      // setTimeout(() => {
      if (this.pointInfo && this.pointInfo.ailist) {
        console.log(this.aiList, "456");
        this.aiList = JSON.parse(JSON.stringify(this.pointInfo.ailist));
        console.log(this.aiList, "789");
        this.stayVal = this.pointInfo.actions[2].param1;
        this.min = parseInt(this.pointInfo.actions[2].param1 / 60);
        this.se = this.pointInfo.actions[2].param1 - this.min;
      } else {
        this.stayVal = 0;
        this.min = 0;
        this.se = 0;
        this.aiList.forEach(val => {
          if (val.switch && !val.check) {
            val.history = 1;
          } else {
            val.switch = false;
          }
          val.check = false;
        });
      }
      // }, 2000);
    },
    async getLineName() {
      let filename = "";
      if (this.user_info.departmentId == 1) {
        filename = `巡特警支队-巡查`;
      } else {
        filename = `${
          this.user_info.parentDepName ? this.user_info.parentDepName + "-" : ""
        }${this.user_info.departmentName}-巡查`;
      }
      let res = await API.AIRSPACE.GetFlightLineCount({ flightName: filename });
      // 对res进行判断,有时候会传来对象,但是值是对的,所以手动把值提取出来
      if (typeof res == "object") {
        res = res.data;
      }
      let filename_count = res + 1;
      filename_count =
        filename_count < 10 ? "0" + filename_count : filename_count;
      this.lineNameAI = filename + filename_count;
    },
    async saveLine() {
      if (this.pointList.length <= 1)
        return this.$message.warning("生成航线至少两个航点");
      this.getLineName();
      this.disable = false;
      setTimeout(async () => {
        let distance = 0;
        let pointCount = this.pointList.length;
        //lng,lat待定
        let lng = this.pointList[this.pointList.length - 1].lon;
        let lat = this.pointList[this.pointList.length - 1].lat;
        this.pointList.forEach((item, i) => {
          delete item.img;
          item.actions.forEach(val => {
            if (val.actionType == "STAY") {
              val.param1 = val.param1 * 1000;
            }
          });
          if (this.pointList[i + 1]) {
            let cd = Cesium.Cartesian3.distance(
              Utils.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
              Utils.transformWGS842Cartesian({
                lng: this.pointList[i + 1].lon,
                lat: this.pointList[i + 1].lat
              })
            );
            console.log(cd, "cdcdcd");
            // dist = total + cd;
            distance = distance + cd;
          }
        });
        let time = parseInt(distance / 6);

        let flight = {
          filename: this.lineNameAI,
          line: {
            baseSpeed: this.speed
          },
          points: this.pointList
        };
        let waypoints = [];
        // waypoints.forEach(val=>{
        //   val.waypointActions = val.actions
        //   delete val.actions
        // })

        this.pointList.forEach(val => {
          let data = {
            ...val,
            coordinate: {
              latitude: val.lat,
              longitude: val.lon
            },
            altitude: val.alt,
            frame: 3,
            speed: flight.line.baseSpeed,
            waypointActions: val.actions
          };
          delete data.lon;
          delete data.lat;
          delete data.alt;
          delete data.actions;
          delete data.id;
          delete data.ailist;
          waypoints.push(data);
        });
        flight.waypoints = waypoints;
        // console.log(flight,"waypoints");
        // return

        let data = {
          distance,
          time,
          pointCount,
          lng,
          lat,
          sourceType: 1,
          nestId: null,
          flightName: this.lineNameAI,
          flightCourseJson: JSON.stringify(flight),
          lineType: 2
        };
        console.log(data, "dataaaa");
        let res = await API.AIRWAY.Add(data);
        this.$emit("close");
        if (res.status != 1) {
          this.$message.error(res.message);
        } else {
          this.$message.success("航线已生成！");
        }
      }, 1500);
    }
  }
};
</script>
<style lang="scss" scoped>
.pointList {
  margin-top: 50px;
  // height: 400px;
  position: absolute;
  top: 420px;
  // display: flex;
  .points {
    width: 730px;
    border-radius: 6px;
    height: 158px;
    background: #20201e;
    .title {
      height: 36px;
      background: #353434;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.59);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      .right {
        display: flex;
        align-items: center;
        .btn {
          cursor: pointer;
          padding: 6px;
          background: rgba($color: #9499a5, $alpha: 0.6);
          border-radius: 4px;
          opacity: 0.58;
          backdrop-filter: blur(6.683917952653804px);
          // &:hover{
          // background: rgba($color: #9499a5, $alpha: 1);
          // }
        }
        .active {
          background: url("~@/assets/yingkong1027/btn_lan.png") no-repeat;
          background-size: 100% 100%;
        }
        .close {
          margin-left: 33px;
          margin-right: 4px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #d2dfff;
        }
      }
    }
    .list {
      // width: 400px;
      // height: 80px;
      // border: solid 1px #fff;
      height: 132px;
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: center;
      .box {
        width: 59px;
        height: 59px;
        color: #fff;
        text-align: center;
        line-height: 30px;
        background: #02173d;
        border-radius: 2px;
        border: 1px solid #aab6b9;
        line-height: 59px;
      }
      .item {
        position: relative;
        width: 59px;
        height: 59px;
        position: relative;
        margin-right: 7px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 1px;
        img {
          width: 100%;
          height: 100%;
        }
        .img {
          position: absolute;
          right: -5px;
          top: -5px;
          width: 12px;
          height: 12px;
        }
        span {
          position: absolute;
        }
      }
    }
    .info {
      display: flex;
      .return {
        line-height: 172px;
        text-align: center;
        color: #a1a1a1;
        font-size: 24px;
        width: 36px;
        height: 172px;
        background: #20201e;
        box-shadow: 1px 0px 2px 0px rgba(255, 255, 255, 0.22);
      }
      .point {
        // margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // border: solid 1px #fff;
        width: 694px;
        height: 172px;
        // background: #333;
        .form {
          margin: 10px;
          width: 450px;
          display: flex;
          color: #fff;
          align-items: center;
          justify-content: space-between;
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .active {
            color: yellow;
          }
        }
        .ai {
          padding: 10px 0;
          display: flex;
          // background: #333;
          color: #fff;
          align-items: center;
          justify-content: space-between;
          .item {
            width: 100px;
            height: 88px;
            // border: solid 1px #fff;
            margin: 6px;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .check {
              display: flex;
              flex-direction: column;
              align-items: center;
              .flexs {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .el-checkbox {
                color: #fff;
              }
              .type {
                white-space: nowrap;
              }
              .img {
                margin: 4px 0;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .itemHover {
            &:hover {
              background: rgba(255, 255, 255, 0.6);
              .img {
                background: rgba(32, 32, 30, 1);
              }
            }
          }
          .itemAfter {
            background: rgba(255, 255, 255, 0.6);
          }
          .itemActive {
            background: rgba(253, 220, 5, 0.3);
            .img {
              border: solid 1px rgba(255, 221, 0, 1);
            }
          }
        }
        .stay {
          width: 450px;
          color: #fff;
        }
      }
    }
  }
}
</style>