<!-- 飞控中心头部 -->
<template>
  <div class="cpt-fckernel-header">
    <div class="title">飞控中心</div>
    <div class="nav-list"
         @click="go_home">
      <div class="nav-item"
           v-for="(item, index) in list"
           :key="item.id"
           :class="`${index <= list.length / 2 - 1 ? '_1' : '_2'} ${
          curr_val === item.id ? 'selected' : undefined
        }`"
           @click.stop="handle_change_nav(item)">
        <div class="text-box">{{ item.label }}</div>
      </div>
    </div>
    <div class="date-box">
      <div class="yyyy">{{ date}}</div>
      <div class="week-box">
        <div class="week">{{ week }}</div>
        <div class="hhmm">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    value: {
      type: Number | String,
      default: 0,
    },
  },
  data () {
    return {
      now: null,
      curr_val: 0,
      list: [
        { id: 1, label: "无人机应用" },
        { id: 2, label: "鹰巢应用" },
      ],
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
    };
  },
  watch: {
    value: {
      handler (value) {
        this.curr_val = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handle_change_nav (item) {
          if(item.id==1){
           this.$store.commit("device/SET_VIDEO", false);
          }else{}
      this.curr_val = item.id;
      this.$emit("input", this.curr_val);
      this.$emit("nav-click", item);
    },
    go_home () {
      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
  },
  computed: {
    date () {
      let { now } = this;
      return now ? `${now.format("YYYY/MM/DD")}` : "0000/00/00";
    },
    week () {
      let { now, week_map } = this;
      return now ? `周${week_map[now.day()]}` : "周N/A";
    },
    time () {
      let { now } = this;
      return now ? `${now.format("HH:mm")}` : "00:00";
    },
  },
  mounted () {
    setInterval(() => {
      this.now = dayjs();
    }, 500);
  },
  created () {
    this.now = dayjs();
  },
};
</script>

<style lang="scss" scoped>
.cpt-fckernel-header {
  height: 91px;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: center url("~@/assets/images/observe/fckernel/01_header_bg.png")
    no-repeat;
  background-size: cover;
  .title {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    text-align: center;
    line-height: 80px;
    background-image: linear-gradient(0deg, #e3aa77, #f9ecd3, #e3aa77);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .nav-list {
    width: 590px;
    height: 77px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    .nav-item {
      width: 223px;
      height: 77px;
      box-sizing: border-box;
      background: center url("~@/assets/images/observe/fckernel/nav-item.png")
        no-repeat;
      background-size: cover;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      &:hover,
      &.selected {
        background: center
          url("~@/assets/images/observe/fckernel/nav-item-selected.png")
          no-repeat;
        .text-box {
          color: #3df1ff;
        }
      }
      .text-box {
        line-height: 77px;
        font-family: Microsoft YaHei Bold;
        font-size: 20px;
        color: #aab8d1;
        font-weight: 700;
        transition: 0.3s;
      }
      &._2 {
        transform: scaleX(-1);
        .text-box {
          transform: scaleX(-1);
        }
      }
    }
  }
  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 0;
    height: 60px;
    box-sizing: border-box;
    .yyyy {
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 3px;
    }
    .week-box {
      display: flex;
      justify-content: space-between;
      .week {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
      .hhmm {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>