import mqtt from "./mqtt"
import uav from "./uav"
import airway from "./airway"

function prefix(meta, prefix) {
    return Object.keys(meta).reduce((obj, key) => {
        obj[`${prefix}_${key}`] = meta[key]
        return obj
    }, {})
}

export default {
    ...prefix(mqtt, "mqtt"),
    ...prefix(uav, "uav"),
    ...prefix(airway, "airway"),
}