// 创建弹窗对象的方法
var Popup = function (info) {
  this.constructor(info);
}
Popup.prototype.id = 0;
Popup.prototype.constructor = function (info) {
  // console.log(info,'infodsadasd');
  var _this = this;
  _this.viewer = info.viewer;//弹窗创建的viewer
  _this.geometry = info.geometry;//弹窗挂载的位置
  let data =null
  if(info.data&&info.data._properties &&info.data._properties._customProperty._value){
    data =info.data._properties._customProperty._value.airData
  }
  _this.data =  data;
  _this.del_real = info.del_real;
  // console.log("info.this---***>", info.arr);
  _this.vueThis = info.arr;
  // if(info.hasOwnProperty('arr')) {
  //   _this.arr = info.arr
  // }
  _this.id = "popup_" + _this.__proto__.id++;
  _this.ctn = $("<div class='bx-popup-ctn' id='" + _this.id + "'>");
  $(_this.viewer.container).append(_this.ctn);

  let contentHtml = ''

  /**
   * 增加单位名称
   */

  if (_this.data['CO(ppm)']) {
    contentHtml += `<div class="li"><div class="label">CO(ppm)：</div><div class="text" style="width: 160px;">${_this.data['CO(ppm)']}</div></div>`
  }
  if (_this.data['NO2(ppm)']) {
    contentHtml += `<div class="li"><div class="label">NO2(ppm)：</div><div class="text">${_this.data['NO2(ppm)']}</div></div>`
  }
  if (_this.data['Ox(ppm)']) {
    contentHtml += `<div class="li"><div class="label">Ox(ppm)：</div><div class="text">${_this.data['Ox(ppm)']}</div></div>`
  }
  if (_this.data['PM1.0(ug/m3)']) {
    contentHtml += `<div class="li"><div class="label">PM1.0(ug/m3)：</div><div class="text">${_this.data['PM1.0(ug/m3)']}</div></div>`
  }
  if (_this.data['PM2.5(ug/m3)']) {
    contentHtml += `<div class="li"><div class="label">PM2.5(ug/m3)：</div><div class="text">${_this.data['PM2.5(ug/m3)']}</div></div>`
  }
  if (_this.data['PM10(ug/m3)']) {
    contentHtml += `<div class="li"><div class="label">PM10(ug/m3)：</div><div class="text">${_this.data['PM10(ug/m3)']}</div></div>`
  }
  if (_this.data['SO2(ppm)']) {
    contentHtml += `<div class="li"><div class="label">SO2(ppm)：</div><div class="text">${_this.data['SO2(ppm)']}</div></div>`
  }



  //测试弹窗内容
  var testConfig = {
    header: _this.data.deviceName || _this.data.name || '',
    content: `<div>${contentHtml}</div>`,
  }
  _this.ctn.append(_this.createHtml(testConfig.header, testConfig.content));
  // 实时刷新
  _this.offsetWidth = _this.ctn.get(0).offsetWidth
  _this.offsetHeight = _this.ctn.get(0).offsetHeight

  _this.render(_this.geometry);

  _this.eventListener = () => { // 每一帧都去计算气泡的正确位置
    _this.render(_this.geometry);
  }
  _this.viewer.scene.postRender.addEventListener(_this.eventListener);

  _this.ctn.on("click", ".leaflet-popup-close-button", function (e) {
    e.stopPropagation();
    _this.close();
  });
}
Popup.prototype.render = function (geometry) {
  var _this = this;
  var position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(_this.viewer.scene, geometry)
  // _this.ctn.css("position","absolute");
  _this.ctn.css("left", position.x - _this.offsetWidth / 2);
  _this.ctn.css("top", position.y - _this.offsetHeight - 10);
}
// 动态生成内容
Popup.prototype.createHtml = function (header, content) {
  var html = '<div class="bx-popup-header-ctn">' +
    header +
    '</div>' +
    '<div class="bx-popup-content-ctn" >' +
    '<div class="bx-popup-content" >' +
    content +
    '</div>' +
    '</div>' +
    '<div class="bx-popup-tip-container" >' +
    '<div class="bx-popup-tip" >' +
    '</div>' +
    '</div>' +
    '<div class="el-icon-close leaflet-popup-close-button"></div>';
  return html;
}
// 关闭弹窗按钮
Popup.prototype.close = function () {
  var _this = this;
  const { category, deviceHardId } = _this.data
  _this.ctn.remove();
  if (category === 1) {
    _this.del_real(deviceHardId)
  }
  // _this.viewer.clock.onTick.removeEventListener(_this.eventListener);
  _this.viewer.scene.postRender.removeEventListener(_this.eventListener);
  // 防止点击关闭无法创建新的移动弹窗
  // console.log('this.data132564',this.data);
  // if (this.data.hasOwnProperty('regionList')) {
    const index = _this.vueThis.move_id_arr.indexOf('_this.data.id');
    _this.vueThis.move_id_arr.splice(index, 1)
  // }
}

export default Popup