<template>
  <div class="cpt-nest-logger">
    <div class="title-box" v-interact>
      <div class="title pl20">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">运行监控日志</div>
      </div>
      <div style="display: flex">
        <div class="icon-box" @click="$emit('clear-msg')">
          <span class="iconfont icon-qingchushuju"></span>
          <!-- <span class="icon-text pr20">清除数据</span> -->
        </div>
        <div class="cf ml20 mr10 c70d" @click="$emit('exit')">关闭</div>
      </div>
    </div>
    <div class="ctx-box">
      <div class="head mt7">
        <div class="text">消息等级</div>
        <div class="text con">消息内容</div>
        <div class="time">时间</div>
      </div>
      <div class="list-box mt7">
        <div class="item-box" v-for="(item,index) in list" :key="index">
          <div
            class="text-box"
            :style="item.grade && item.grade ==99 || item.grade && item.grade <= 2  ? 'color: #FF4040': item.grade && item.grade > 2 && item.grade < 5 ? 'color: #FFFF40': item.grade && item.grade == 5 ? 'color: #8E1DB8':''"
          >
            <div v-if="item.grade" class="type-box">
              <span
                class="type"
              >{{ item.grade <= 2 ? "[危险]" : item.grade == 3 ? "[错误]" : item.grade ==4 ? "[警告]" : item.grade == 5 ? "[通知]" : item.grade == 6 ? "[正常]" : item.grade == 7 ? "[调试]" : "AUTO" }}</span>
            </div>
            <div
              v-else
              class="type-box"
              :class="{ emerg: item.type === 1, ordinary: item.type === 8 }"
            >
              <span class="type">AUTO</span>
            </div>
            <div
              class="text"
              :style="item.grade && item.grade == 99 || item.grade && item.grade <= 2 ? 'color: #FF4040': item.grade && item.grade > 2 && item.grade < 5 ? 'color: #FFFF40': item.grade && item.grade == 5 ? 'color: #8E1DB8':''"
            >{{ item.text }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
        </div>
      </div>
      <div class="wih10 mt20 h10"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handle_clear() {
      this.list = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-nest-logger {
  position: fixed;
  width: 630px;
  height: 250px;
  top: 21%;
  left: 32%;
  z-index: 5;
  display: flex;
  background: rgba(9, 32, 87, 0.7);
  // border: 1px solid #70DAF9;
  border-radius: 10px 10px 0 0;
  flex-direction: column;

  .title-box {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 18px;
        color: #00ffff;
        margin-right: 4px;
      }

      .icon-text {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #ccedff;
        font-weight: 400;
      }
    }
  }

  .ctx-box {
    height: calc(100% - 32px);
    // flex: 1;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 1px;
      line-height: 22px;
      font-weight: 700;
      .text,
      .time {
        width: 20%;
        text-align: center;
      }
      .time {
        width: 35%;
      }
      .con {
        width: 50%;
      }
    }
    .list-box {
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      .item-box {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 11px 0;

        .text-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .type-box {
            width: 20%;
            height: 20px;
            text-align: center;
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #ebf9ff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;

            &.ordinary {
              background-color: #298ad3;
            }

            &.emerg {
              background-color: #ff3c3c;
            }

            .type {
              width: 100px;
              text-align: center;
              font-size: 10px;
              white-space: nowrap;
            }
          }

          .text {
            width: 50%;
            text-align: center;
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #ebf9ff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
          .time {
            width: 35%;
            text-align: center;
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
        }
      }
      .item-box:nth-of-type(2n - 1) {
        background: rgba(73, 135, 210, 0.2);
      }
    }
  }

  .xb {
    transform: rotate(-180deg);
    background: rgba(1, 10, 46, 0.63);
    border-radius: 10px 10px 0 0;
  }
}
.c70d {
  color: #70daf9;
}
</style>
