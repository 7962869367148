export default {
    /**
    * @brief 点击常亮
    * @param  
    * @retval 
    */
    constant_bright_ctrlFn() {
        var buf = new Array(0xa5, 0x01, 0x03);
        if (this.constant_bright_ctrlFlag) {
            buf[3] = 2;
        } else {
            buf[3] = 1
        }
        buf[4] = this.cal_crc_table(buf);
        this.controInfor.data.cmdBuffer = buf.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('SearchlightFn', this.controInfor)


    },
    very_bright_ctrl() {
        let buf = new Array(0xa5, 0x04, 0x04, 0x00);
        if (!this.checked) {
            buf[4] = 1;
        }
        else {
            buf[4] = 2;

        }
        buf[5] = this.cal_crc_table(buf);
        this.controInfor.data.cmdBuffer = buf.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId

        this.$emit('SearchlightFn', this.controInfor)
    },
    gimbal_pitch_ctrl(speed) {
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        if (speed == 1) {
            buff[3] = 0x03;
        }
        if (speed == -1) {
            buff[3] = 0xFD;
        }
        buff[4] = this.cal_crc_table(buff);
        this.controInfor.data.cmdBuffer = buff.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId

        this.$emit('SearchlightFn', this.controInfor)
    },
    //crc校验字节生成函数
    cal_crc_table(array) {
        var crc = 0;
        var i = 1;
        var len = array[2];
        while (len--) {
            crc = this.crc_table[crc ^ array[i]];
            i++;
        }
        return crc;
    },
    // 常规模式
    constant_bright_ctrl() {
        // this.checked = false
        this.flicker_status = false
        // //关闭闪光模式
        // this.flicker_ctrl()
        // // 关闭极亮模式
        // if (!this.checked) {
        //     let buf = new Array(0xa5, 0x04, 0x04, 0x00);
        //     buf[4] = 1;
        //     buf[5] = this.cal_crc_table(buf);
        //     this.controInfor.data.cmdBuffer = buf.join(',');
        //     this.$emit('SearchlightFn', this.controInfor)
        //     this.$message.success('执行成功')
        // }
        this.constant_bright_ctrlFn()
    },
    // 闪光模式
    flicker_ctrl() {
        let buf = new Array(0xa5, 0x01, 0x03);
        if (!this.flicker_status) {
            buf[3] = 1;
        }
        else {
            buf[3] = 3;
        }
        buf[4] = this.cal_crc_table(buf);
        this.controInfor.data.cmdBuffer = buf.join(',');
        console.log(buf.join(','), '闪光模式');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('SearchlightFn', this.controInfor)
        this.$message.success('操作成功')
        // flicker_switch_mode = 0;
        // setTimeout(() => {
        //     flicker_switch_mode = 1;
        // }, 6000);

    },
    middle_ctrl() {
        let buf = new Array(0xa5, 0x08, 0x03);
        if (!this.middle_status) {
            buf[3] = 1;
        }
        else {
            buf[3] = 2;
        }

        buf[4] = this.cal_crc_table(buf);
        this.controInfor.data.cmdBuffer = buf.join(',');
        this.$message.success('操作成功')
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('SearchlightFn', this.controInfor)
    }

}