<template>
  <div class="page-observe-fckernel-uav">
    <Header
      @TakeOverFlag="showTakeOver"
      :wsShow="wsShow"
      @control_ws="uav_control_ws"
      :uav-data="uav.control.data"
      :isSelectFlag="flag"
      :device="uav.control.device"
      @fn="uav_mqtt_fn"
      @ModeStatus="getModeStatus"
    ></Header>
    <div class="warn w440" v-if="warnShow">
      <div class="content" @click="loggerFlag = true">{{ warnMessage }}</div>
      <div class="close" @click="warnShow = false">关闭</div>
    </div>
    <!-- @nest_chenge="nestchange" -->
    <!-- 日志信息 -->
    <BrightTail
      v-if="uav.control.device"
      :wsShow="wsShow"
      :device="uav.control.device"
      :uavData='uav.control.data'
      @fn="(data, type) => uav_mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)"
      @take_photo="take_photoFn"
      @face_car="face_car"
      @brightTailshowDialog='brightTailshowDialog'
      @draw_photo="set_take_photoFn"
      ref="BrightTailRef"
    />
    <Logger
      @exit="loggerFlag = false"
      v-if="loggerFlag && !isAirwayEdit"
      :list="uav.msg_list"
      @clear-msg="uav.msg_list = []"
    ></Logger>
    <div class="nsetLeftBox" :class="{ collapse: collapseFlag }">
      <img
        @click="collapseFlagfn"
        v-if="collapseFlag"
        style="transform: rotate(180deg)"
        src="~@/assets/images/observe/fckernel/hs.png"
        class="icon-collapse nsetLeftBox_btn"
      />
      <img
        @click="collapseFlagfn"
        v-else
        src="~@/assets/images/observe/fckernel/collapse.png"
        class="icon-collapse nsetLeftBox_btn"
      />
      
      <div class="uav-list-header_jt"  v-if="listPage==1">
        <span  :class="listType=='wrj'? 'uav-list-header__text_left':'deflault_uav-list-header__text'" class=" cp"   @click="listType='wrj'">无人机列表</span>
          <span :class="listType=='task'? 'uav-list-header__text_right':'deflault_uav-list-header__text'"  class=" cp" @click="listType='task'" >任务列表</span>
      </div>
      <div class="uav-list-header" v-else>
        <img class="uav-list-header__icon" src="~@/assets/images/uav_list_header.png" />
        <span class="uav-list-header__text cp"  @click="listType='wrj'">无人机列表</span>
      </div>
       
     <div v-show="listType=='wrj'">
       <div class="uav-search">
        <el-input
          class="uav-search__input"
          clearable
          placeholder="请输入无人机名称/机构名称"
          v-model="uavSearchContent"
          v-on:keyup.enter.native="onUavSearch"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="color: rgba(123, 181, 213, 1);"
          ></i>
        </el-input>
        <el-button class="uav-search__btn" @click="onUavSearch">搜索</el-button>
      </div>
      <UavList
        :class="uav.TakeOverFlag ? 'top0' : ''"
        @exit="flag = false"
        v-if="flag"
        @videoChange="videoChange"
        :containerStyle="{
          width: '400PX',
          height: '275px',
        }"
        :list="uav.list"
        @fn="uav_fn"
        @refresh="onUavSearch"
      />
     </div>
     <div v-if=" listType=='task' ">
      <HistoryList/>
     </div>
      <!-- <div class="w50 ht100 pa left0 top0" style="background: rgba(0, 0, 0, 0.42)">
        <div class="iconfont icon-yingchaoliebiao ml15 cp f20 cf mt20 acitve"></div>
      </div>-->
      <div></div>
    </div>

    <component
      v-if="uav.control.mount"
      :is="uav.control.mount.component"
      :PoliceKeyCode="uav.control.device && uav.control.device.deviceHardId"
      @directive="uav_mount_directive"
      @close="uav_change_mount"
      :deviceid="uav.control.device"
      @showCenter="(val) => (showCenter = val)"
    />
    <Control
      :device="uav.control.device"
      :wsShow="wsShow"
      :ModeStatus="ModeStatus"
      :offline="uav.offline"
      :data="uav.control.channelData"
      v-if="ControlFlag || (uav.control.data && !isAirwayEdit)"
      :uav-battery="uav.control.battery"
      :uav-data="uav.control.data"
      @fn="(data, type) => uav_mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)"
    ></Control>
    <AirwayEdit
      :flag="false"
      @add="airway_add"
      v-if="isAirwayEdit"
      :isShow="false"
      @quit="AirwayQuit"
    ></AirwayEdit>
    <!-- -->
    <div class="videoBox">
      <!-- v-if="videoFlag && uav.videos.length > 0 && !isAirwayEdit" -->
      <!-- uav_move_directive -->
      <FloatPlayer
        ref="FloatPlayer"
        :pointListFlag="pointListFlag"
        @getAiPopup="getAiPopup"
        :mountDatas="uav.control.mountData"
        @directiveFn="uav_move_directive"
        @imgUrl="getimgUrl"
        @startLinePoint="startLinePoint"
        :scheduleData="scheduleData"
        :keyFlag="keyFlag"
        :NXdata="NXdata"
        @changeLableName="(e) => (lineLableName = e)"
        :lineLableName="lineLableName"
        :device="uav.control.device"
        v-for="(video, index) in uav.videos"
        :uav-data="uav.control.data"
        @videoItemFn="videoItemFn(index)"
        @close="uav_hide_video"
        @fn="uav_mqtt_fn"
        @AIDialog="(e, val) => getAIDialog(e, val)"
        :key="video.id"
        :data="video"
        :class="`_${index}`"
        :videoItem="videoItem"
        :index="index"
        :showCenter="showCenter"
      />
    </div>
    <TaskList
      :device="uav.control.device"
      :taskstyle="collapseFlag"
      :wsShow="wsShow"
      @changeLableName="(e) => (lineLableName = e)"
      @Lsdom="Lsdom"
      @taskType="taskTypeFn"
      @iconShow="iconShow = true"
      ref="TaskListRef"
      @uav-location="(e) => uav_show_location(uav.control.device, e)"
      @fn="(data, type) => uav_mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)"
      @craeteRoute="CraeteRoute"
      v-if="controlMenuFlag"
      @createTaskClick="createTaskClick"
      @getDistanceLine="(e) => $emit('getDistanceLine', e)"
      @getSelf="$emit('getSelf')"
    ></TaskList>
    <ControlMenu
      ref="ControlMenu"
      :wsShow="wsShow"
      :taskstyle="collapseFlag"
      :keyFlag="keyFlag"
      :ModeStatus="ModeStatus"
      @startLinePoint="startLinePoint"
      @showCenter="showLine"
      @imgUrl="getimgUrl"
      @clearId="clearIdFn"
      :wrjType="uav.wrjType"
      :uav-data="uav.control.data"
      :device="uav.control.device"
      :healthData="healthData"
      :batteryData="uav.control.battery"
      @Lsdom="Lsdom"
      :mount="uav.control.mounts"
      @uav-location="uav_show_location(uav.control.device)"
      @airway-display="uav_show_airway(uav.control.device)"
      :taskType="taskType"
      :uav-mounts="uav.mounts"
      v-if="uav.control.device"
      @yxrz="LoggerFn"
      :iconShow="iconShow"
      @fn="(data, type) => uav_mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)"
      @changeTask="onChangeTask"
    ></ControlMenu>
    <!-- 请求控制权限 -->
    <Notice
      @close="uav.NoticeFlag = false"
      @handle="uav_jursdiction"
      class
      @qzjg="qzjg"
      @exit="uav.NoticeFlag = false"
      v-if="uav.NoticeFlag"
      :leftText="uav.NoticeData.leftText"
      :rightText="uav.NoticeData.rightText"
      :content="uav.NoticeData.content"
      :title="uav.NoticeData.title"
      :flag="true"
    ></Notice>
    <!-- 接管记录 -->
    <TakeOver @exit="uav.TakeOverFlag = false" v-if="uav.TakeOverFlag"></TakeOver>
    <VideoTomap v-if="VideoTomapFlag && videoFlag && uav.videos.length > 0"></VideoTomap>
    <el-dialog width="40%" :visible.sync="progressT">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="jindu"></el-progress>
    </el-dialog>
    <div class="imgshowurl" v-if="imgshow">
      <div @click="imgshow = false" class="close">关闭</div>
      <div class="imgae">
        <canvas v-if="aiType >= 4 || aiType == 1 || aiType == 2 || aiType == 3" id="canvas" ref="canvas"></canvas>
          <div v-else>
            <img :src="imgshowurl" />
          </div>
          <div class="aitotal" v-if="aiType == 3">
            发现人员：{{ aiTotal }}人
          </div>
      </div>
    </div>
    <div class="swim" v-if="AIFlag">
      <div class="head">
        <div class="close" @click="AIFlag = false">关闭</div>
      </div>
      <div class="content">
        {{ aiTitle || "暂无" }}
      </div>
      <div class="btns">
        <div class="btn" @click="AIFlag = false">取消</div>
        <div class="btn rest" @click="resetSwim">继续飞行</div>
      </div>
    </div>
    <MMCGimbalGASList
      :device="uav.control.device"
      v-if="GASList_flag"
    ></MMCGimbalGASList>
    <Face :list="faceLists" v-if="faceShow" @close="faceShow = false"/>
    <Car :list="carLists" v-if="carShow" @close="carShow = false"/>
    <FaceAI v-if="faceAiShow" :uavId="device.deviceHardId" @closeface="faceAiShow = false" />
    <CarAI
      v-if="carAiShow"
      :uavId="device.deviceHardId"
      :list="carList"
      @closecar="carAiShow = false"
    />
    <div class="pointFlag" v-if="pointFlag">
      <img src="@/assets/images/observe/point_big.png" />
      开启航点动作
    </div>
<!-- 指引提示框 -->
      <el-dialog :visible.sync="deviceHardVisible" width="30%">
      <div class="cf f18 fw700 ml20 pt20 tc">
        {{ BrightTailList.itemLIist && BrightTailList.itemLIist.name }}
      </div>
      <div class="m30">
        <el-radio-group v-model="radio">
          <el-radio :label="item" class="mt10 cf" v-for="(item, index) in BrightTailList.alertList.alertList" :key="index">
            {{ item.taskGuideDetailsAlertContent }} <br /></el-radio>
        </el-radio-group>
      </div>
      <div v-show="
          BrightTailList.alertList.alertList &&
          BrightTailList.alertList.alertList.length > 0
        " class="cp w150 h40 cf f18 tc lh40" style="background: #129c9c; margin: 0 auto"
        @click="brightTailnext">
        下一步
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Face from '@/pages/observe/fckernel/uav/components/face'
import Car from '@/pages/observe/fckernel/uav/components/car'
import FaceAI from "@/components/observe/uav/faceai";
import CarAI from "@/components/observe/uav/carai";
//历史任务

import HistoryList from "./components/historyList"; //亮尾4
import MMCGimbalGASList from "@/components/MMC_GimbalList/index.vue";

import { saveAs } from "file-saver";
import BrightTail from "@/components/accident/brightTail/index"; //亮尾4
import UavList from "@/components/accident/uav/list";
import Mount from "@/components/observe/mount/MMC_Gimbal_Z40S";
// 视频播放器
import FloatPlayer from "@/pages/observe/fckernel/uav/components/float-playerfk";
import AirwayEdit from "@/pages/observe/fckernel/uav/components/airway-edit";

import data from "@/pages/observe/fckernel/uav/data";
import methods from "@/pages/observe/fckernel/uav/methods";
import Header from "@/components/accident/header/index";
// 下面的油门图标
import Control from "@/pages/observe/fckernel/uav/components/control/index.vue";
// 任务库
import TaskList from "@/pages/observe/fckernel/uav/components/taskList";
// 下面一键任务的小图标
import ControlMenu from "@/pages/observe/fckernel/uav/components/control/controlMenu";
import Notice from "@/pages/observe/fckernel/uav/components/control/components/notice";
import Logger from "@/pages/observe/fckernel/uav/components/control/logger/index";
import TakeOver from "@/pages/observe/fckernel/uav/components/control/components/takeOver";
import VideoTomap from "@/pages/observe/fckernel/uav/components/control/videoTomap";
// 引用bus
import Bus from "@/assets/ligature.js";
import API from "@/api";
export default {
  data() {
    return {
      ...data,
      aiPopup:null,//识别图标
      pointFlag: false,
      pointListFlag: false,
      singleList:[],//异常区域列表
      faceShow:false,
      faceLists:[],
      carShow:false,
      carLists:[],
      GASList_flag: false, //气体检测 历史记录列表w
      NXdata: {},
      scheduleData: {}, //航线执行进度
      listType:"wrj",//wrj列表 /task 任务列表
      listPage:null,
      healthData: {},
      healthWaring: [],
      message:{},
      level: null, //M300信号等级
      warnShow: false, //异常消息弹窗
      warnMessage: "", //异常消息
      showCenter: false,
      imgshow: false,//拍照图片展示
      imgshowurl: null,
      AIFlag: false, //识别弹窗
      aiTitle: "", //ai信息
      aiTotal: 0,
      aiType: 0,
      lineLableName: "", //当前航线标签

      faceAiShow: false,
      carAiShow: false,
      videoFlag: true,
      // 头部状态是否为展示状态
      flag: true,
      controlMenuFlag: false,
      loggerFlag: false,
      // 运行监测
      powerFlag: true,
      // 创建航线窗口
      isAirwayEdit: false,
      // 创建任务
      CreateTaskFlag: false,
      // 左下角切换视频
      VideoTomapFlag: false,
      // 下方展示数据面板
      ControlFlag: false,
      iconShow: false,
      taskType: "",
      videoItem: 0,
      collapseFlag: false,
      linevisible: false,
      takeOff: false,
      uavId: null, //无人机id
      progressT: false, //进度条弹框
      jindu: 0, //进度条进度
      uavSearchContent: "", //无人机搜索内容
      wsShow: false ,//判断是走飞控中心链路还是地面站链路,
      ModeStatus: "", //网络公专网离线状态
      keyFlag: false, //NX状态
      ShoutFlag: true, //喊话器状态

      // 指引弹窗
      deviceHardVisible:false,
      radio:0,
      BrightTailList:{
        taskId:null,
        alertList:{alertList:{}},
        itemLIist:null

      }
    };
  },
  provide() {
    return {
      uav_This: this,
      findList: search => {
        if (search) {
          this.uav_list_yj(search);
        } else {
          this.uav_list_yj(search);
          // this.uav_mountList(data.name);
        }
      },
      uav_mqtt_fn: this.uav_mqtt_fn,
      uav: this.uav
    };
  },
  components: {
    Face,
    Car,
    MMCGimbalGASList,
    BrightTail,
    UavList,
    Mount,
    FloatPlayer,
    Header,
    Control,
    TaskList,
    ControlMenu,
    AirwayEdit,
    Notice,
    TakeOver,
    Logger,
    VideoTomap,
    FaceAI,
    CarAI,
    HistoryList
  },
  watch: {
    "$store.state.device.GASList_flag": {
      handler(value) {
        this.GASList_flag = value;
      },
      deep: true,
    },
    healthData: function (newval, old) {
      this.healthWaring = [];
      if (JSON.stringify(old) == "{}") {
        for (let i in newval) {
          if (newval[i].warningLevel != "NORMAL") {
            if (this.taskId) {
              this.message.taskId = this.taskId;
            }
            this.message = {
              deviceId: this.uav.control.device.id,
              exceptionTitle: newval[i].title,
              exceptionTypeSign: i,
            };
            if (
              newval[i].warningLevel == "WARNING" ||
              newval[i].warningLevel == "SERIOUS_WARNING"
            ) {
              this.healthWaring.push(newval[i].title);
              let arr = [];
              for (let i in newval) {
                arr.push(newval[i].warningLevel);
              }
              let headwaring = arr.some((val) => val != "NORMAL");
              this.$emit("healthWaringShow", this.healthWaring, headwaring);
            }
            this.addMessage();
          }
        }
      }
      let arrKey = [];
      for (let i in newval) {
        arrKey.push(i);
        if (newval[i].warningLevel != "NORMAL") {
          if (old[i] && newval[i].code != old[i].code) {
            if (this.taskId) {
              this.message.taskId = this.taskId;
            }
            this.message = {
              deviceId: this.uav.control.device.id,
              exceptionTitle: newval[i].title,
              exceptionTypeSign: i,
            };
            this.addMessage();
          }
          if (
            newval[i].warningLevel == "WARNING" ||
            newval[i].warningLevel == "SERIOUS_WARNING"
          ) {
            this.healthWaring.push(newval[i].title);
          }
        }
        let arr = [];
        for (let i in newval) {
          arr.push(newval[i].warningLevel);
        }
        let headwaring = arr.some((val) => val != "NORMAL");
        // console.log(this.healthWaring,"传");
        this.$emit("healthWaringShow", this.healthWaring, headwaring);
      }
      let flage = arrKey.some((val) => val == "NX");
      if (flage && newval["NX"].warningLevel == "NORMAL") {
        this.keyFlag = true;
      } else {
        this.keyFlag = false;
      }
    },
    data: function(vla, old) {
    },
    "uav.control.device": function (val) {
      this.lineLableName = "";
      this.uav.msg_list = [];
    },
    "uav.offline": function (val) {
      if (this.ModeStatus == "离线" && val) {
        this.closeVideo();
      }
    },
    ModeStatus: function (val) {
      if (this.uav.offline == true && val == "离线") {
        this.closeVideo();
      }
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    taskId() {
      return this.$store.state.fckernel.YJtaskId;
    },
    lineInfo() {
      return this.$store.state.fckernel.lineInfo;
    },
    uav_mounts() {
      let { mounts } = this.uav.control;
      return mounts
        .map(item => {
          let find_item = this.mount.list.find(m => m.name === item.gimbalName);
          return find_item || undefined;
        })
        .filter(item => item);
    }
  },
  created() {
    this.listPage= this.$route.query.page
    Bus.$on("remove_model", () => {
      // 删除飞控 无人机 模型和航线
      // 搜索关键字：删除飞控模型
      this.uav_remove_model(this.uav.control.device);
    });
    // 等待航线上传成功在执行
    // 判断是否以一键任务
    Bus.$on("off_take", () => {
      this.takeOff = true;
    });
  },
  methods: {
    ...methods,
    getAiPopup(val){
      this.aiPopup = val
    },
    startLinePoint(){
      this.pointListFlag = !this.pointListFlag
      if(this.pointListFlag){
        this.pointFlag = true
        setTimeout(() => {
          this.pointFlag = false;
        }, 1000);
      }
    },
    closeVideo() {
      this.$message.warning("无人机已离线！");
      this.healthData = {};
      this.uav.control.mounts = [];
      // this.VideoTomapFlag = false
      // this.uav_hide_control(this.uavId)
      this.$emit("healthWaringShow", [], false);
      this.uav_hide_video(this.uavId);
      // this.controlMenuFlag = false
      // this.ControlFlag = false
      // this.uav_list()
    },
    getAIDialog(e, val) {
      this.AIFlag = e;
      this.aiTitle = val;
      setTimeout(() => {
        this.resetSwim()
      },3000);
      // this.$refs.P3.$refs.ItemA.text="盐城公安提醒您不私自下水游泳不擅自与他人结伴游泳不在无成年人带领的情况下游泳不到无安全设施无救援人员的水域游泳不到不熟悉的水域游泳不擅自下水施救"
      // this.$refs.P3.$refs.ItemA.handleSendTTSText()
    },
    //检测
    resetSwim() {
      let data = {
        data: "AUTO_MISSION",
        type: 513,
      };
      this.uav_mqtt_fn(data);
      // console.log(this.$refs["FloatPlayer"]);
      this.AIFlag = false;
      setTimeout(() => {
        this.$refs["FloatPlayer"][0].setAll();
      }, 1000);
    },
    getModeStatus(val) {
      // console.log(val,"ModeStatus");
      this.ModeStatus = val;
    },
    async addMessage() {
      let res = await API.FCKERNEL.exceptionAdd(this.message);
    },
    showLine(val) {
      this.showCenter = val;
    },
      // 指引列表弹窗选项
    brightTailshowDialog(alertList, taskId, itemLIist) {
        this.BrightTailList = {
          taskId:taskId,
          alertList:alertList,
          itemLIist:itemLIist
        }
        this.deviceHardVisible = true
    },
        // 指引列表弹窗提交
    async brightTailnext() {
        let res = await API.ACCIDENT.createGuideAction({
            guideId: this.BrightTailList.itemLIist.guideId,
            taskId: this.BrightTailList.taskId,//this.accident.taskId
            taskGuideActionType: 2,
            taskGuideDeatilsId: this.BrightTailList.alertList.taskGuideDetailsId,
            taskGuideActionStatus: 1,
            taskGuideDetailsAlertId: this.radio.taskGuideDetailsAlertId,
            startSort: this.BrightTailList.alertList.guideSort,
            endSort: this.radio.jumpDetailsSort
        })
        if (res) {
            // 更新列表zhuangt
            this.deviceHardVisible = false
            this.radio=""
            this.$refs.BrightTailRef.getList()
        }
    },
    async getimgUrl(val, aiType, item) {
      this.aiType = aiType;
      this.imgshow = true;
      this.imgshowurl = val;
      //人流
      if (aiType == 3) {
        this.aiTotal = item.detectPersonCount;
        this.getcanvas(val, item);
      }
      //游泳
      if (aiType >= 4) {
        this.getcanvas(val, item);
      }
      //人脸
      if(aiType == 1 || aiType == 2){
        let data = {
          fileKey:item.visitUrl,
          aiType: aiType
        }
        let res = await API.FCKERNEL.getFaceResultByFileKey(data)
        if(aiType == 1){
          this.faceLists = res.faceImageInfo?.faceResults
          this.faceShow = true
        this.getcanvas(val, res.faceImageInfo);
        }else{
          this.carLists = res.aiPlateShiJuResultInfoDtoList
          this.carShow = true
        this.getcanvas(val, res);
        }
      }
    },
    getcanvas(val, item) {
      let data = null;
      if (item.jsonData) {
        data = JSON.parse(item.jsonData);
      } else {
        data = item;
      }
      // let targets = data.targets;
      setTimeout(() => {
        this.canvas = this.$refs.canvas;
        this.canvas.width = 1000;
        this.canvas.height = 500;
        this.image = new Image();
        this.image.src = val;
        this.image.onload = () => {
          if (this.aiType >= 4) {
            this.drawImage(data.targets);
          }else if(this.aiType == 1){
            this.drawImage(data.detections)
          }else if(this.aiType == 2){
            this.drawImage(data.aiPlateShiJuResultInfoDtoList)
          }else if(this.aiType == 3){
            this.drawImage(data)
          }
        };
      }, 50);
    },
    drawImage(list) {
      this.ctx = this.canvas.getContext("2d");
      this.ctx.drawImage(
        this.image,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "red";
      if (this.aiType >= 4) {
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.bbox.box.left_top_x) /
            this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.bbox.box.left_top_y) /
            this.image.height;
          plate_detection.h =
            (this.canvas.height *
              (targets.bbox.box.right_bottom_y - targets.bbox.box.left_top_y)) /
            this.image.height;
          plate_detection.w =
            (this.canvas.width *
              (targets.bbox.box.right_bottom_x - targets.bbox.box.left_top_x)) /
            this.image.width;

          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle = "rgba(0,0,0,0.8)";
          let text = targets.bbox.label +
                ":" +
                (targets.bbox.prob * 100).toFixed(2) +
                "%（相似度）"
          let offX = 0
          let offY = 0
          if(plate_detection.x+text.length*11 > 1000){
            offX = 1000-text.length*11
          }else{
            offX = plate_detection.x - 5
          }
          if(plate_detection.y - 20 < 0){
            offY = plate_detection.y+plate_detection.h
          }else{
            offY = plate_detection.y - 22
          }
          this.ctx.fillRect(
            // plate_detection.x - 5,
            offX,
            offY,
            text.length*11,
            20
          );
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "16px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText(
            text,
            // plate_detection.x - 5,
            offX,
            offY + 15
          ); // 绘制文本
        });
      } else if (this.aiType == 1) {
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.x) / this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.y) / this.image.height;
          plate_detection.h =
            (this.canvas.height * targets.h) / this.image.height;
          plate_detection.w =
            (this.canvas.width * targets.w) / this.image.width;

          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle = "rgba(0,0,0,0.5)";
          this.ctx.fillRect(
            plate_detection.x - 5,
            plate_detection.y - 22,
            20,
            20
          );
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "12px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText("人", plate_detection.x - 3, plate_detection.y - 8); // 绘制文本
        });
      }else if(this.aiType == 2){
        
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.yiSaPlateDetection.x) / this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.yiSaPlateDetection.y) /
            this.image.height;
          plate_detection.h =
            (this.canvas.height *
              (targets.yiSaPlateDetection.h)) /
            this.image.height;
          plate_detection.w =
            (this.canvas.width *
              (targets.yiSaPlateDetection.w)) /
            this.image.width;
  
          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle="rgba(0,0,0,0.5)"
        this.ctx.fillRect(
          plate_detection.x - 5,
          plate_detection.y - 22,
          60,
          20
        )
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "12px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText(
            targets.plateNum,
            plate_detection.x - 3,
            plate_detection.y - 8
          ); // 绘制文本
        });
      }else if(this.aiType == 3){
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.bbox[0]) / this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.bbox[1]) / this.image.height;
          plate_detection.h =
            (this.canvas.height * (targets.bbox[3] - targets.bbox[1])) /
            this.image.height;
          plate_detection.w =
            (this.canvas.width * (targets.bbox[2] - targets.bbox[0])) /
            this.image.width;

          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle = "rgba(0,0,0,0.5)";
          this.ctx.fillRect(
            plate_detection.x - 5,
            plate_detection.y - 22,
            20,
            20
          );
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "16px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText("人", plate_detection.x - 3, plate_detection.y - 8); // 绘制文本
        });
      }
    },
    async qzjg() {
      let res = await API.DEVICE.forceTakeOver({
        deviceHardId: this.uav.NoticeData.deviceHardId
      });
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.uav.NoticeFlag = false;
      }
    },
    fly_take_off() {
      // 等待航线上传成功在执行
      console.log(123456);
      this.$refs.TaskListRef.upload_complete();
      // Bus.$emit("take_off");
    },
    getYd() {
      this.controlMenuFlag = !this.controlMenuFlag;
      if (this.collapseFlag == true) {
        // 修改飞控 无人机 左边'任务库'的位置
        Bus.$emit("ydh", false);
      } else {
        Bus.$emit("ydh", true);
      }
    },
    collapseFlagfn() {
      this.collapseFlag = !this.collapseFlag;
      if (this.collapseFlag == true) {
        // 修改飞控 无人机 左边'任务库'的位置
        Bus.$emit("ydh", false);
      } else {
        Bus.$emit("ydh", true);
      }
    },
    Lsdom(item) {
      this.$emit("Lsdom", item);
    },
    clearIdFn() {
      if (this.$refs.TaskListRef) {
        this.$refs.TaskListRef.close();
        try {
          let deviceHardId = this.uav.control.device.deviceHardId;
          this.uav_hide_airway(this.uav.control.device);
          this.uav.online[deviceHardId].positions = [
            this.uav.online[deviceHardId].position
          ];
        } catch (error) {}
      }
    },
    videoItemFn(index) {
      this.videoItem = index;
      this.$store.commit("mapmanage/SET_MAP_IS_VIDEOITEM", index);
    },
    LoggerFn() {
      this.loggerFlag = !this.loggerFlag;
      // this.collapseFlag = false;
    },
    videoChange() {
      this.videoFlag = !this.videoFlag;
    },
    nestchange(flag) {
      this.flag = flag;
      this.controlMenuFlag = false;
      this.uav.TakeOverFlag = false;
    },
    showTakeOver() {
      this.uav.TakeOverFlag = !this.uav.TakeOverFlag;
      if (this.uav.TakeOverFlag) {
        this.collapseFlag = true;
      }
    },
    // 关闭航线创建列表
    AirwayQuit() {
      this.isAirwayEdit = false;
      // 运行监测关闭
      this.powerFlag = true;
      this.Videoflag = true;
      this.ControlFlag = true;
      this.collapseFlag = false;
    },
    taskTypeFn(item) {
      this.taskType = item;
    },
    // 创建航线
    CraeteRoute() {
      this.isAirwayEdit = true;
      // // 运行监测关闭
      this.powerFlag = false;
      this.ControlFlag = false;
      this.collapseFlag = true;
    },
    createTaskClick() {
      this.CreateTaskFlag = !this.CreateTaskFlag;
      this.controlMenuFlag = false;
    },
    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    /**
     * 无人机搜索
     */
    onUavSearch() {
      this.uav_list_yj({
        search: this.uavSearchContent,
      });
    },
    //点击任务按钮
    onChangeTask(open) {
      if (open === undefined) {
        this.controlMenuFlag = !this.controlMenuFlag;
      } else {
        this.controlMenuFlag = open;
      }
    },
    take_photoFn() {
      // console.log(this.$refs.FloatPlayer.player,'this.$refs.FloatPlayer');
      if (this.$refs.FloatPlayer) {
        console.log( this.$refs.FloatPlayer,' this.$refs.FloatPlayer');
        this.$refs.FloatPlayer[0].photoGuide();
      }
    },
    face_car() {
      if (this.$refs.FloatPlayer) {
        // this.$refs.FloatPlayer[0].handle("b");
        this.$refs.FloatPlayer[0].handle("a");
      }
    },
    set_take_photoFn() {
      if (this.$refs.ControlMenu && this.$refs.ControlMenu.$refs.Traffic) {
        this.$refs.ControlMenu.$refs.Traffic.onActiveApp();
      }
    },
    //地图飞入
    cesium_layer_change_mode(fn, modes) {
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          120.218241,
          33.165767,
          21812.321476599747
        ),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14
        },
        time: 5
      });
    }
  },
  mounted() {
    this.uav_list_yj();
    this.mqtt_init();
    this.uav_init_ws();
  },
  beforeDestroy() {
    this.uav.control.device = null;
    this.uav.control.device = null;
    this.uav.control.data = null;
    this.uav.control.battery = null;
    this.uav.control.mount = null;
    this.uav.control.mounts = [];
    this.uav.control.mounts = [];
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: false,
      type: "wrj"
    });
    this.uav.videos = [];
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    Bus.$emit("uav_flag", false);
  },
  inject: ["g_cesium_layer"]
};
</script>

<style lang="scss" scoped>
.collapse {
  transform: translateX(-100%);
  transition: 0.3s;
}
.page-observe-fckernel-uav {
  .LsdomStyle {
    position: fixed;
    top: 50%;
    z-index: 50;
  }

  height: 100%;

  .leftBox {
    width: 508px;
    height: calc(100% - 58px);
    position: fixed;
    left: 0;
    top: 58px;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 10px;
  }
  .videoBox {
    /* width: 382px; */
    // border: 1px solid #D8D8D8;
    position: absolute;
    top: 60px;
    padding-bottom: 10px;
  }
}
.nsetLeftBox {
  position: absolute;
  width: 460px;
  left: 0;
  top: 80px;
  // height: 86vh;
  transition: 0.3s;
  z-index: 99;
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px 10px 0px 0px;
  // border: 1px solid #70daf9;
  .nsetLeftBox_btn {
    position: absolute;
    cursor: pointer;
    right: -22px;
    top: 50%;
    margin-top: -90px;
    z-index: 99;
  }
}
.acitve {
  color: #70daf9;
}

.uav-search {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 7px;
  margin-top: 14px;

  .uav-search__input ::v-deep {
    width: 317px;
    height: 32px;
    box-shadow: 0px 2px 4px 0px rgba(28, 94, 124, 0.5),
      inset 0px 0px 3px 0px #00b6ff;
    border-radius: 4px;

    .el-input__icon {
      line-height: 32px;
    }

    input {
      height: 32px;
      background: #02173d;
      color: #43deff;
      border: 1px solid #315ec7;

      &::placeholder {
        font-size: 12px;
        color: #7bb5d5;
      }
    }
  }

  .uav-search__btn {
    padding: 7px 25px 6px 26px;
    // background: rgba(38, 71, 238, 0.71);
    background: url("~@/assets/images/observe/twobg.png");
    border-radius: 4px;
    border: 1px solid;
    font-size: 14px;
    color: #fff;
  }
}

.uav-list-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 460px;
  height: 33px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .uav-list-header__text {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #f7b67d 38%,
      #f9eacb 58%,
      #f5d2a6 79%,
      #f59743 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .uav-list-header__icon {
    width: 26px;
    margin-left: 9px;
  }
}

.uav-list-header_jt {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 460px;
  height: 43px;
  .uav-list-header__text_left {
    width:calc(100% / 2);
    font-size: 20px;
    text-align: center;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 43px;
    background: linear-gradient(
      135deg,
      #f7b67d 38%,
      #f9eacb 58%,
      #f5d2a6 79%,
      #f59743 100%
    );

  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 0px 0px 0px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .uav-list-header__text_right {
    width:calc(100% / 2);
    font-size: 20px;
    text-align: center;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 43px;
    background: linear-gradient(
      135deg,
      #f7b67d 38%,
      #f9eacb 58%,
      #f5d2a6 79%,
      #f59743 100%
    );

  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 0px 10px 0px 0px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .deflault_uav-list-header__text{
    width:calc(100% / 2);
   font-size: 20px;
    font-family: YouSheBiaoTiHei;
    cursor: pointer;
    color: #14faff;
    text-align: center;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background:#fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
  .uav-list-header__icon {
    width: 26px;
    margin-left: 9px;
  }
}
.imgshowurl {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba($color: #000000, $alpha: 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .image {
    position: relative;
  }
  .aitotal {
    // margin-left: 16px;
    // width: 1000px;
    padding: 4px 8px;
    line-height: 30px;
    position: absolute;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
  }
  img {
    width: 1000px;
    height: 600px;
  }
  .close {
    color: #fff;
    margin-left: 1000px;
    margin-bottom: 16px;
    // position: absolute;
    // right: 10px;
    // top: 10px;
  }
}
.swim {
  padding: 10px;
  width: 531px;
  min-height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: rgba(50, 2, 2, 0.7);
  background: url("~@/assets/images/warn/yj.png") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .head {
    display: flex;
    justify-content: end;
    color: #fff;
    .close {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .btns {
    align-self: center;
    display: flex;

    .btn {
      background: rgba(53, 3, 3, 0.25);
      box-shadow: inset 0 0 20px 0 rgba(255, 101, 56, 0.65);
      border-radius: 5px;
      color: #fff;
      padding: 10px;
    }
    .rest {
      margin-left: 20px;
    }
  }
}
.warn {
  position: absolute;
  left: calc(50% - 220px);
  top: 10%;
  padding: 10px 14px;
  // height: 36px;
  background: rgba(249, 42, 42, 0.6);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content {
    word-break: break-all;
    width: 400px;
    color: #fff;
  }
  .close {
    cursor: pointer;
    color: #fff;
  }
}
.kedu {
  bottom: 0;
  left: 100px;
  width: 300px;
  height: 500px;
  position: fixed;
  background: #000;
  overflow: hidden;
  .line {
    // transform: translateY(-250px);
    width: 100%;
    height: 100%;
  }
  .center {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }
}
.singleList {
  position: fixed;
  top: 400px;
  left: 60%;
  color: #fff;
  background: rgba(9, 32, 87, 0.7);
  padding: 10px;
  border-radius: 10px;
  line-height: 24px;
  .change {
    color: #14faff;
  }
}
.pointFlag {
  position: fixed;
  width: 82px;
  height: 82px;
  background: rgba(32, 32, 30, 1);
  font-size: 12px;
  color: #fff;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/deep/.el-dialog{
  background: rgba(0, 39, 121, 0.5)
}
</style>