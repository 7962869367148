var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"livenvr pr",class:_vm.className},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoflag),expression:"infoflag"}],staticClass:"pa top0 livenvr_title",staticStyle:{"z-index":"999"}},[_vm._v(_vm._s(_vm.deviceName))]),_c('LivePlayer',{ref:"livePlayer",class:{
      small: !_vm.videoFlag,
      'livenvr-player': _vm.videoFlag,
      vUrl: _vm.isStatus && !_vm.videoFlag,
      vUrlDeffault: !_vm.isStatus && !_vm.videoFlag,
    },attrs:{"dblclick-fullscreen":false,"aspect":"fulllscreen","videoUrl":_vm.data.vUrl}}),(_vm.fpvUrl.vUrl)?_c('LivePlayer',{ref:"fpvPlayer",class:{
      vUrl: _vm.isStatus,
      vUrlDeffault: !_vm.isStatus,
      small: _vm.videoFlag,
      'livenvr-player': !_vm.videoFlag,
    },attrs:{"dblclick-fullscreen":false,"controls":false,"videoUrl":_vm.fpvUrl.vUrl}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }