import API from "@/api";
export default {
  lookBig(item) {
    this.$emit("imgUrl", this.imgApi + '/uploads' + item.fileKey, item.aiType, item)
  },
  download(item, i) {
    fetch(this.imgApi + '/uploads' + item.fileKey)
      .then(res => res.blob())
      .then(blob => {
        const a = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        a.download = item.fileName;
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      })
  },
  downloadjz(item) {
    fetch(process.env.VUE_APP_LOOK_URL + item.filePath)
      .then(res => res.blob())
      .then(blob => {
        const a = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        // a.download = item.deviceId + "jpg";
        a.download = item.deviceId + ".jpg";
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      })
    // let res = process.env.VUE_APP_LOOK_URL + item.filePath
    // // console.log(res);
    // window.open(res)
  },
  stk() {
    this.allshow = true
    this.stkshow = !this.stkshow;
    this.HeaFlag = false
    this.MountFlag = false
    this.isfk = 1;
    this.police = false
    if (this.stkshow && this.isfk == 1) {
      this.getst();
    }
  },
  changeAiType(val) {
    this.aiType = val
    this.getst()
  },
  //视图
  async getst() {
    this.photoList = []
    let data = {
      deviceHardId: this.uavId,
      pageNo: this.pageNo,
      pageSize: 10000,
      type: this.filetypev2
    };
    if (this.aiType != 0) {
      data.aiType = this.aiType
    } else {
      delete(data.aiType)
    }
    let res = await API.FCKERNEL.photoAndvideo(data);

    res.data.forEach(val => {
      if (val.list.length > 0) {
        // console.log(val,"val");
        this.photoList.push(val)
        this.photoList.forEach(val2 => {
          if (val2.type == this.filetypev2) {
            let list = []
            list.push(val2)
            val2.list.push(list)
          }
        })
      }
    })
  },
  //机载
  async getjz() {
    console.log(process.env.VUE_APP_LOOK_URL, "process.env.VUE_LOOK_URL");
    this.photoList = []
    let data = {
      deviceId: this.uavId,
      // taskId: this.taskId ? Number(this.taskId) : null,
      finish: 1,
      pageNo: this.pageNo,
      pageSize: 10000,
      type: this.filetype,
      sort: 1
    };
    let res = await API.FCKERNEL.devicephoto(data);
    this.photoList = res.list;
    let blob = null
    this.photoList.forEach(async (val) => {
      // let res = await API.FCKERNEL.fileUrl('http://32.128.6.70:20251'+ val.filePath)//48
      let res = await API.FCKERNEL.fileUrl(process.env.VUE_APP_LOOK_URL + val.filePath) //52
      if (this.filetype == 0) {
        // blob = new Blob([res],{type: "image/jpeg"})
        val.jsonFilePath = process.env.VUE_APP_LINK_URL + val.filePath
      } else {
        blob = new Blob([res], {
          type: "video/mp4"
        })
        val.jsonFilePath = window.URL.createObjectURL(blob)
      }
    })
  },
  async reset() {
    if (this.isfk == 1) {
      this.getst()
    } else {
      this.getjz()
    }
  },

  isflyk(val) {
    this.checkList = []
    this.moreshow = false
    this.isfk = val;
    if (this.isfk == 1) {
      this.getst();
    } else {
      this.getjz();
    }
  },
  changephotoType(val) {
    this.checkList = []
    if (this.isfk == 1) {
      this.filetypev2 = val;
      this.getst()
    } else {
      this.filetype = val;
      this.getjz()
    }
  },
  handleCheckAllChange() {
    if (this.isfk == 1) {
      this.checkList = this.morelist
    } else if (this.isfk == 1 && this.filetypev2 == 2) {
      this.checkList = this.photoList
    } else {
      this.checkList = this.photoList
    }
    this.allshow = false
  },
  handleCheckunChange() {
    this.checkList = []
    this.allshow = true
  },
  async submit() {
    if (this.checkList.length == 0) return this.$message.warning("暂无选中的图片或视频！")
    if (this.checkList.length > 0) {
      let arr = []
      this.checkList.forEach(async (val) => {
        // let blob = await API.FCKERNEL.fileUrl('http://32.128.6.70:20251'+ val.filePath)
        let blob = await API.FCKERNEL.fileUrl(process.env.VUE_APP_LOOK_URL + val.filePath)
        let fd = new FormData();
        if (this.filetype == 1) {
          fd.append("uploadFiles", blob, `转存.mp4`);
        } else {
          fd.append("uploadFiles", blob, `转存.png`);
        }
        fd.append("deviceHardId", this.uavId);
        let res = await API.FCKERNEL.Uploads(fd);
      })
    }
    this.$message.success("转存成功！")

    this.checkList = []
  },
  async handleDeletel() {
    if (this.checkList.length == 0) return this.$message.warning("暂无选中的图片或视频！")
    if (this.isfk == 1) {
      let arr = []
      this.checkList.forEach(val => {
        arr.push(val.id)
      })
      let data = {
        ids: arr.join(',')
      }
      let res = await API.FCKERNEL.deletephotoAndvideo(data)
      this.$message.success("删除成功！")
      this.getst()
      this.moreshow = false
    } else {
      let arr = []
      this.checkList.forEach(val => {
        let data = "id=" + val.id
        arr.push(data)
      })
      let params = arr.join("&")
      console.log(params);
      let res = await API.FCKERNEL.deletelphoto(params)
      this.$message.success("删除成功！")
      this.getjz()
    }
    this.allshow = true
    this.checkList = []
  },
  moreImg(list) {
    this.moreshow = true
    this.morelist = list
  }
};