<template>
  <div class="brightTailBox">
    <div class v-if="brightTailFlag">
      <div
        class="h40 fr cp tc ml5 mb10 lh40"
        @click="brightTailFn(item, index)"
        v-for="(item, index) in titleList"
        :key="index"
        :class="itemIndex == index ? 'brightTailSelectBtn' : 'brightTailBtn'"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      v-if="selectFlag && brightTailFlag"
      class="brightTailList mt10 w470 fr"
    >
      <div class="brightTailListTitle" v-interact>
        <div class="h3">{{ itemLIist.name || "暂无模板" }}</div>
        <div class="focus">
          <!-- <div class="dib">
            高清变焦相机
          </div>-->
        </div>
      </div>
      <div class="mt23 pl10 pr30 brightTailListArea">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in getProcessList.list"
            placement="top"
            :key="index"
            :class="item.actionStatus == 1 ? 'selectbright' : ''"
          >
            <div
              class="wih100 ht100 brightTail_card f16"
              :class="item.actionStatus == 1 ? ' defaultBg' : 'selectBg'"
            >
              <div class="wih100 ht100 brightTail_card f16">
                <div class="dib brightTail_title">
                  {{ item.taskGuideDetailsDescribe }}
                  <div class="dib fr" v-if="item.showHight == 1">
                    <span class="cf">实时高度:</span>
                    {{
                      uavData&&uavData.gps && uavData.gps.relativeAlt
                        ? uavData.gps.relativeAlt.toFixed(2)
                        : 0
                    }}
                    <span class="cf">m</span>
                  </div>
                  <div class="mt10">
                    <span
                      class="fun mr15"
                      v-for="(item3, index) in item.buttonList"
                      :key="index"
                    >
                      <span v-if="item3.buttonName.indexOf('上传') != -1">
                        <el-upload
                          class="dib"
                          accept=".png, .jpg, .image, .jpeg"
                          :action="upLoadApi + '/upload/v2_uploads'"
                          :on-preview="handlePreview"
                          :on-success="handchange"
                          multiple
                          name="uploadFiles"
                          :show-file-list="false"
                        >
                          <span>{{ item3.buttonName }}</span>
                        </el-upload>
                        <!-- <span>{{ item3.buttonName }}</span> -->
                      </span>
                      <span v-else @click="fun(item3)">{{
                        item3.buttonName
                      }}</span>
                    </span>
                  </div>
                  <div style class="mt10 selectColor jcsb pr20">
                    <div
                      class="dib"
                      v-for="(item2, index2) in item.mountList"
                      :key="index2"
                    >
                      {{ item2.mountName }}
                    </div>
                  </div>
                </div>
                <div
                  class="tc cp mr5 brightTail_Btnz"
                  v-if="item.actionStatus == 0"
                  @click="confirmFn(item)"
                >
                  <!-- :class="
                    getProcessList.count == index + 1 ? '' : 'preventClick'
                  " -->
                  确认
                </div>
                <div class="tc mr5 cp brightTail_BtnD" v-else>
                  {{ item.actionStatus == "1" ? "已确认" : "已执行" }}
                </div>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import methods from "./methods";
import API from "@/api";
import { mapGetters } from "vuex";
import Lockr from "lockr";
import dayjs from "dayjs";
const { VUE_APP_IMG_URL, VUE_APP_UPLOADS_URL } = process.env;
export default {
  props: {
    wsShow: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      devicecarmeraStatus: 0,
      itemIndex: null,
      selectFlag: false,
      value: true,
      value1: true,
      value4: false,
      value3: false,
      BrightTailId: "",
      id: "72", //类型
      brightTailFlag: true,
      getProcessList: [],
      realTimeHight: 0,
      titleList: [],
      itemLIist: {
        id: 121,
      },
      upLoadApi: process.env.VUE_APP_UPLOADS_URL,
      flylink: process.env.VUE_APP_LINK_URL,
      imageUrl: [],
      imageName: [],
      baseList: [],
    };
  },
  inject: ["accident_This"],
  watch: {
    // closekg: function (val) {
    //   // console.log("valaaa", val);
    //   this.devicecarmeraStatus = val
    // },
    taskId: function (val, old) {
      if (val != null) {
        let type = this.$route.query.page;
        let id = null;
        if (type == 0) {
          id = 72;
        } else if (type == 1) {
          id = 1;
        } else if (type == 2) {
          // 食药环
          id = 74;
        } else if (type == 3) {
          id = 76;
        } else if (type == 4) {
          id = 77;
        } else if (type == 6) {
          id = 78;
        }
        // this.brightTailFlag = true;
        this.getTaskGuideKeyFn(id);
      } else {
        // this.brightTailFlag = false
      }
    },
    // async taskId(val) {
    //   console.log("----------+++++-??", val);
    //   let res = await API.ACCIDENT.getTaskGuide({
    //     taskId: val, //this.id
    //     guideId: this.itemLIist.id || this.itemLIist.guideId,
    //   });
    //   this.getProcessList = res;
    //   // console.log("Res", res);
    //   console.log("this.getProcessList", this.getProcessList);
    // },
  },
  computed: {
    ...mapGetters(["user_info"]),
    taskId() {
      return this.$store.state.fckernel.YJtaskId;
    },
  },
  mounted() {
    this.getLists();
    // setInterval(() => {
    //   // console.log(this.deviceId)
    // }, 1000);
    // this.$emit("take_photo");
  },
  methods: {
    ...methods,
    getLists() {
      let type = this.$route.query.page;
      let id = null;
      if (type == 0) {
        id = 72;
      } else if (type == 1) {
        id = 1;
      } else if (type == 2) {
        // 食药环
        id = 74;
      } else if (type == 3) {
        id = 76;
      } else if (type == 4) {
        id = 77;
      } else if (type == 6) {
        id = 78;
      }
      // this.brightTailFlag = true;
      this.getTaskGuideKeyFn(id);
    },
    async getTaskGuideKeyFn(id) {
      let res = await API.ACCIDENT.getTaskGuideKey({
        taskTypeId: id,
      });
      this.titleList = res;
    },
    showcamera() {
      this.$emit("showcamera", this.devicecarmeraStatus);
      // bus.$emit('testA')
    },
    async fun(val) {
      // val.buttonName
      console.log(val, "val");
      if (val.buttonName == "保存" || val.buttonName == "拍照") {
        this.$emit("take_photo");
      } else if (val.buttonName == "激活事故绘制工具") {
        //  跳转 事故处理软件
        // window.open(`TrafficAccident://`, "_blank");
        const FLYINGSESSIONID = window.btoa(Lockr.get("FLYINGSESSIONID"));
        const IDENTITY = window.btoa(Lockr.get("IDENTITY"));
        const taskId = this.taskId;
        window.open(
          `${this.flylink}/hawk_traffic/?FLYINGSESSIONID=${FLYINGSESSIONID}&IDENTITY=${IDENTITY}&taskId=${taskId}`,
          "_blank"
        );
      } else if (val.buttonName.indexOf("报告") != -1) {
        if (this.taskId) {
          if (this.itemLIist.name == "交通事故处置指引") {
            let newId = this.taskId;
            let time = dayjs().format("YYYY-MM-DD hh:mm:ss");
            API.ACCIDENT.TrafficWord(newId).then((res) => {
              const url = window.URL.createObjectURL(new Blob([res]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${time}-事故处理报告.doc`); // 设置你想要的文件名和扩展名
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              if (res.status == 0) {
                this.$message.warning(res.message);
              }
            });
          } else {
            let newId = this.taskId;
            let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/task/exportTaskWord?id=${newId}&FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`;
            const fileName = url;
            window.open(fileName);
          }
        } else {
          this.$message.error("暂无任务！");
        }
      } else if (val.buttonName.indexOf("上传") != -1) {
        this.$emit("take_photo");
      } else if (val.buttonName.indexOf("识别") != -1) {
        this.$emit("face_car");
      } else if (val.buttonName == "一键返航") {
        if (!this.wsShow) {
          this.$emit("fn", { type: 513, data: "AUTO_RTL" });
        } else {
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.user_info.username,
            data: {
              cmdFunction: 2112,
            },
            deviceHardId: this.device.deviceHardId,
          });
        }
        this.$message.success("操作成功");
      } else if (val.buttonName.indexOf("350") != -1) {
        // this.$emit('fn')
      } else if (val.buttonName.indexOf("绘制") != -1) {
        this.$emit("draw_photo");
      }
    },
    handlePreview(file) {
      // console.log(file);
    },
    handchange(file) {
      this.imageUrl.push(file.data[0].url);
      this.imageName.push(file.data[0].name);
      this.baseList.push(file.data[0].encode);
      setTimeout(() => {
        let res = API.ACCIDENT.createTaskWord({
          imageUrl: this.imageUrl,
          imageName: this.imageName,
          taskId: this.taskId,
          baseList: this.baseList,
        });
        if (res) {
          this.imageName = [];
          this.imageUrl = [];
          this.baseList = [];
          this.$message.success("上传成功");
        }
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.brightTailBox {
  width: 700px;
  top: 75px;
  position: absolute;
  right: 0px;
  display: flex;
  z-index: 143;
  flex-direction: column;
  align-content: space-between;
  align-items: center;

  .brightTailBtn {
    color: #43f0df;
    min-width: 100px;
    padding: 0 10px;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/accident/brightTail/brightTail.png");
    background-size: 100% 100%;
  }

  .brightTailSelectBtn {
    min-width: 100px;
    padding: 0 10px;
    color: #43f0df;
    background-image: url("~@/assets/images/accident/brightTail/brightTailSelect.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .brightTailList {
    position: absolute;
    right: 100px;
    top: 50px;
    height: 550px;
    min-width: 110px;
    background: rgba(0, 39, 121, 0.5);
    border: 1px solid #43deff;
    box-shadow: 0 2px 8px 0 rgba(1, 162, 255, 0.7), inset 0 0 8px 0 #019aff,
      inset 0 0 64px 0 rgba(26, 138, 227, 0.35);

    .brightTail_card {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .brightTail_title {
        width: 300px;
      }
    }
  }

  .brightTailListTitle {
    padding: 0 8px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    background-image: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.22) 40%,
      #05091a 100%
    );
    border: 1px solid #43deff;
    box-shadow: inset 0 0 10px 2px #3f9dff;
    line-height: 32px;

    .focus {
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #93bbd1;
      letter-spacing: 0;
      font-weight: 400;
    }

    .h3 {
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
    }
  }
}

.fun {
  cursor: pointer;
  padding: 5px;
  background: rgba(0, 34, 140, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 8px 0 #00b6ff;
  border-radius: 4px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/.el-timeline-item__node--normal {
  width: 24px;
  height: 24px;
  background: center url("~@/assets/images/accident/brightTail/icon2.png")
    no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.selectbright {
  /deep/.el-timeline-item__node--normal {
    width: 24px;
    height: 24px;
    background: center url("~@/assets/images/accident/brightTail/icon.png")
      no-repeat;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

/deep/.el-timeline-item__tail {
  left: 10px;
  border-left: 2px solid #30656b;
}

/deep/.el-timeline-item {
  background-image: url("~@/assets/bg.svg") 100% 100%;
  padding-bottom: 4px;
}

.selectBg {
  color: #00ffff;
  padding: 20px 0;
  padding-left: 10px;
  background-image: url("~@/assets/images/accident/brightTail/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.defaultBg {
  color: #bcffff;
  padding: 20px 0;
  padding-left: 10px;
  background-image: url("~@/assets/images/accident/brightTail/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.brightTail_Btnz {
  background-color: #012528;
  border: 1px solid #36ebff;
  border-radius: 4px;
  width: 56px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #99f5ff;
}

.el-button--primary {
  color: #88bcbc;
  background: #07142f;
  border: 1px solid #bcffff;
}

.el-button--primary.is-disabled:hover {
  color: #88bcbc;
  background: #07142f;
  border: 1px solid #bcffff;
}

.brightTail_BtnD {
  border-radius: 4px;
  width: 56px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #06294d;
  border: 1px solid #b5e5ff;
  box-shadow: inset 0 0 5px 0 #00aeff;
  border-radius: 4px;
}

.selectColor {
  color: #d8d29c;
}

.defaultColor {
  color: #88bdbc;
}

.brightTailListArea {
  margin-right: 4px;
  height: 470px;
  overflow-y: auto;
}

/deep/.el-timeline-item__timestamp.is-top {
  margin-bottom: 0px;
  padding-top: 0px;
}
</style>
