<!-- 鹰视 -->
<!-- 飞行应用 -->
<!-- 挂载功能 -->
<template>
  <div class="mountController_Box2" v-if="mountName">
    <!-- @CameraFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_Z40'" -->
    <Camera @close='close' @CameraFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_Z40'" />
    <Shout @close='close' @ShoutFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_P0_Pro'" />
    <Searchlight @close='close' @SearchlightFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_L50'" />
    <Infrared @close='close' @InfraredFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_ZT1'" />
    <Throw @close='close' @ThrowFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_S1'" />
    <Light @close='close' @LightFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_ZT30N'" />
    <MountGun @close='close' @mountGunFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_S79'" />
    <MMCGimbalFF6 @close='close' @MMCGimbalFF6Fn="webscoketFn" v-if="mountName == 'MMC_Gimbal_FF6'" />
    <MMCGimbalFE8 @close='close' @MMC_Gimbal_FE8Fn="webscoketFn" v-if="mountName == 'MMC_Gimbal_FE8'" />
    <MMCGimbalFN3 @close='close' @MMCGimbalFN3Fn="webscoketFn" v-if="mountName == 'MMCGimbalFN3'" />
    <MMCGimbalFB1 @close='close' @MMCGimbalFB1Fn="webscoketFn" v-if="mountName == 'MMCGimbalFB1'" />
    <MMC_Gimbal_Z40S @close='close' @CameraFn="webscoketFn" v-if="mountName == 'MMC_Gimbal_Z40S'" />
  </div>
</template>

<script>
  import Camera from "@/components/observe/mountController/mountControllerList/camera"; //高清变焦
  import Shout from "@/components/observe/mountController/mountControllerList/shout"; //喊话器
  import Searchlight from "@/components/observe/mountController/mountControllerList/searchlight"; //探照灯
  import Infrared from "@/components/observe/mountController/mountControllerList/infrared"; //红外热成像
  import Throw from "@/components/observe/mountController/mountControllerList/throw"; //飞鹰抛投器
  import Light from "@/components/observe/mountController/mountControllerList/light"; //双光
  import MountGun from "@/components/observe/mountController/mountControllerList/mountGun"; //霹雳火s79
  import MMCGimbalFF6 from "@/components/observe/mountController/mountControllerList/MMCGimbalFF6"; //喷火器MMCGimbalFE8
  import MMCGimbalFE8 from "@/components/observe/mountController/mountControllerList/MMCGimbalFE8"; //喷火器
  import MMCGimbalFN3 from "@/components/observe/mountController/mountControllerList/MMC_Gimbal_FN3"; //飞鹰抓捕网
  import MMCGimbalFB1 from "@/components/observe/mountController/mountControllerList/MMCGimbalFB1";
  import MMC_Gimbal_Z40S from "@/components/observe/mountController/mountControllerList/MMC_Gimbal_Z40S";



  export default {
    props: {
      moutedObj: {
        type: Object,
        default: {},
      },
    },
    components: {
      Camera,
      Shout,
      Searchlight,
      Infrared,
      Throw,
      Light,
      MountGun,
      MMCGimbalFF6,
      MMCGimbalFE8,
      MMCGimbalFN3,
      MMCGimbalFB1,
      MMC_Gimbal_Z40S
    },
    provide() {
      return {
        mountControllerThis: this,
      };
    },
    data() {
      return {
        mountObj: {
          mountName: "",
          deviceHardId: "",
        },
        mountName: "",
        cenarisoName: "",
        deviceHardId: "",
      };
    },
    methods: {
      close() {
        this.mountName = ''
      },
      webscoketFn(data) {
        this.$emit("webscoketFn", data);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mountController_Box2 {
    // 原本宽度540px
    width: 524px;
    position: absolute;
    top: 365px;
    z-index: 20;
    right: 75px;
    min-height: 290px;
    /* background: rgba(0, 23, 79, 0.7); */
    /* border: 1px solid #43deff;
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px; */

  }
</style>