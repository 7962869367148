export default {
    //crc校验字节生成函数
    cal_crc_table(array) {
        let crc = 0;
        let i = 1;
        let len = array[2];
        while (len--) {
            crc = this.crc_table[crc ^ array[i]];
            i++;
        }
        return crc;
    },
    gimbal_mode_ctrl(mode) {
        var buff = new Array(0xA5, 0xA4, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = this.cal_crc_table(buff);
        this.controInfor.data.cmdBuffer = buff.join(',');
        console.log(this.controInfor, "云台模式");
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('InfraredFn', this.controInfor)
    },

    // 控制速度
    cameraChangeFn(yaw_speed) {
        let buf = new ArrayBuffer(4);
        let float_value = new Float32Array(buf);
        let byte_array = new Uint8Array(buf);
        float_value[0] = yaw_speed;
        buf[9] = byte_array[0];
        buf[10] = byte_array[1];
        buf[11] = byte_array[2];
        buf[12] = byte_array[3];
        let newbuf = Array.prototype.slice.call(new Uint8Array(buf));
        this.controInfor.data.cmdBuffer = newbuf.join(',');
        console.log(this.controInfor, "控制速度");
      this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('InfraredFn', this.controInfor)

    },
    zoomFn(zoom_ctrl) {
        let buff = new Array(0xA5, 0xb0, 0x03, 0x00, 0x00);
        buff[3] = (zoom_ctrl >>> 0) & 0xff;
        buff[4] = this.cal_crc_table(buff);
        this.controInfor.data.cmdBuffer = buff.join(',');
        
        this.$emit('InfraredFn', this.controInfor)
    },
    take_photo() {
        // var photoEl = document.getElementById('take_photo_audio')
        // photoEl.play()
        let buff = new Array(0xA5, 0xB7, 16, /*index*/ 0x00, 0x00, /*altitude*/ 0x00, 0x00, 0x00, 0x00,
            /*latitude*/
            0x00, 0x00, 0x00, 0x00, /*longitude*/ 0x00, 0x00, 0x00, 0x00, 0x00);

        buff[5] = (this.altitude) & 0xff;
        buff[6] = (this.altitude >> 8) & 0xff;
        buff[7] = (this.altitude >> 16) & 0xff;
        buff[8] = (this.altitude >> 24) & 0xff;

        buff[9] = (this.latitude) & 0xff;
        buff[10] = (this.latitude >> 8) & 0xff;
        buff[11] = (this.latitude >> 16) & 0xff;
        buff[12] = (this.latitude >> 24) & 0xff;

        buff[13] = (this.longitude) & 0xff;
        buff[14] = (this.longitude >> 8) & 0xff;
        buff[15] = (this.longitude >> 16) & 0xff;
        buff[16] = (this.longitude >> 24) & 0xff;

        buff[17] = this.cal_crc_table(buff);
        let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
        this.controInfor.data.cmdBuffer = newbuf.join(',');
        console.log( newbuf.join(','));

        this.$emit('InfraredFn', this.controInfor)
    },
    // 录像
    record() {
        // var recordEl = document.getElementById('record_audio')
        // recordEl.play()
        var buff = new Array(0xA5, 0xB1, 0x03, 0x00, 0x00);
        if (this.record_status) {
            buff[3] = 0;
            this.record_status = false
        } else {
            buff[3] = 2;
            this.record_status = true
        }
        buff[4] = this.cal_crc_table(buff);
        let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
        this.controInfor.data.cmdBuffer = newbuf.join(',');
     this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('InfraredFn', this.controInfor)
    },
    // 俯仰
    change_pitch_angle(num) {
        this.pitch = num
        let angle = this.pitch
        if (this.pitch > 30 || this.pitch < -90) {
            return this.$message.info('最大值为30，最小值为-90')
        }
        let buff = new Array(0xA5, 0xA2, 0x03);
        if (angle > 30 || angle < -90) {
            return;
        }
        buff[3] = angle & 0xff;
        buff[4] = this.cal_crc_table(buff);
        this.controInfor.data.cmdBuffer = buff.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
    

        this.$emit('InfraredFn', this.controInfor)

    },
    //航向
    change_yaw_angle() {
        if (this.yaw > 145 || this.yaw < -145) {
            return this.$message.info('最大值为145，最小值为-145')
        }
        var angle = this.yaw
        let buff = new Array(0xA5, 0xA3, 0x04, 0x00, 0x00, 0x00);
        buff[3] = angle & 0xff;
        buff[4] = (angle >> 8) & 0xff;
        buff[5] = this.cal_crc_table(buff);
        this.controInfor.data.cmdBuffer = buff.join(',');
     this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        
        this.$emit('InfraredFn', this.controInfor)
    },
    gimbal_yaw_ctrl(speed) {
        var realspeed = ((speed * this.control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0xA1, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = this.cal_crc_table(buff);
        let controInfor = JSON.parse(JSON.stringify(this.controInfor))
        controInfor.data.cmdBuffer = buff.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId

        this.$emit('InfraredFn', controInfor)
    },
    //上下
    gimbal_pitch_ctrl(speed) {
        var realspeed = ((speed * this.control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0xA0, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = this.cal_crc_table(buff);
        
        let controInfor = JSON.parse(JSON.stringify(this.controInfor))
        controInfor.data.cmdBuffer = buff.join(',');
        controInfor.deviceHardId = this.mountControllerThis.deviceHardId

        console.log( buff.join(','));

        this.$emit('InfraredFn', controInfor)
    },
    /**
 * 
 * @param {*} click_mode ： 1 for move ， 6 for focus 聚焦
 */
    /**
 * @brief  Traditional floating point numbers use 4 bytes to express, but in communication application we should 
 *         preserve bandwidth, so we use 2 byte to express a floating point number. this function can convert native
 *         float to "float16" whitch is a 2 byte floating point number.
 * @param  value: a traditional/native floating point number.
 * @retval the convert result witch is a "uint16_t" type 16-bits number.
 */
    g_convert_native_float_to_float16(value) {
        let buffer1 = new ArrayBuffer(4);
        let f32inf = new Uint32Array(buffer1);
        let f32inff = new Float32Array(buffer1);

        let buffer2 = new ArrayBuffer(4);
        let f16inf = new Uint32Array(buffer2);
        let f16inff = new Float32Array(buffer2);

        let buffer3 = new ArrayBuffer(4);
        let magic = new Uint32Array(buffer3);
        let magicf = new Float32Array(buffer3);

        let buffer4 = new ArrayBuffer(4);
        let _in = new Uint32Array(buffer4);
        let _inf = new Float32Array(buffer4);

        f32inf[0] = 255 << 23;
        f16inf[0] = 31 << 23;
        magic[0] = 15 << 23;
        const sign_mask = 0x80000000;
        const round_mask = ~0xFFF;

        _inf[0] = value;
        let sign = _in[0] & sign_mask;
        _in[0] ^= sign;

        let out = 0;

        if (_in[0] >= f32inf) {
            out = (_in[0] > f32inf) ? 0x7FFF : 0x7C00;
        } else {
            _in[0] &= round_mask;
            _inf[0] *= magicf[0];
            _in[0] -= round_mask;
            if (_in[0] > f16inf[0]) {
                _in[0] = f16inf[0];
            }
            out = _in[0] >> 13;
        }

        out |= (sign >> 16);

        return Number(out);
    },
    // 接收到数据，开始处理
    on_receive(buff) {
        let dataArray = new Uint8Array(buff);
        if (!this.gotversion) {
            this.get_version();
        }

        if (dataArray[0] === 0xA5 && this.cal_crc_table(dataArray) === dataArray[dataArray.length - 1]) {
            switch (dataArray[1]) {
                case 0x01:
                    this.msg_key_status1(dataArray);
                    break;
                case 0x02:
                    this.msg_euler(dataArray);
                    break;
                case 0x04:
                    msg_photo_feedback(dataArray);
                    break;
                case 0x05:
                    msg_zoom_info(dataArray);
                    break;
                case 0x0a:
                    msg_control_feedback(dataArray);
                    break;

                case 0x0b:
                    msg_updata_time(dataArray);
                    break;
                case 0x0c:
                    msg_euler2(dataArray);
                    break;
                case 0x0d:
                    msg_key_status2(dataArray);
                    break;
                case 0xff:
                    msg_id(dataArray);
                    break;
                case 0xf7:
                    gcs_click_coordinates(dataArray);
                    break;
                case 0xf8:
                    gcs_button_ctrl(dataArray);
                    break;
                case 0xf9:
                    gcs_pos_info(dataArray);
                    break;
                case 0xf6:
                    gcs_attitude_ctrl(dataArray);
                    break;

                case 0xfe:
                    msg_id(dataArray);
                    break;

                default:
                    break;
            }

        } else {
            console.error("crc check error");
        }
    },
    msg_euler(data) {
        if (!this.goteuler2msg) {
            let pitch = this.get_float_from_4byte(data.slice(3, 7));
            let roll = this.get_float_from_4byte(data.slice(7, 11));
            let yaw = this.get_float_from_4byte(data.slice(11, 15));
            this.set_euler_angle(pitch, roll, yaw);
        }
    },
    set_euler_angle(pitch, roll, yaw) {
        // 俯仰  pitch.toFixed(2); 
        //横滚   roll.toFixed(2);
        //  航向 yaw.toFixed(2);
    },
    get_float_from_4byte(srcbuf) {
        let buffer = new ArrayBuffer(4);
        let byte = new Uint8Array(buffer);
        let float = new Float32Array(buffer);
        byte[0] = srcbuf[0];
        byte[1] = srcbuf[1];
        byte[2] = srcbuf[2];
        byte[3] = srcbuf[3];

        return Number(float);

    },
    msg_key_status1(data) {
        if (!gotstatus2) {
            var buffer = new ArrayBuffer(4);
            var byte = new Uint8Array(buffer);
            var version_number = new Uint32Array(buffer);
            byte[0] = data[3];
            byte[1] = data[4];
            byte[2] = data[5];
            byte[3] = data[6];

            this.set_gimbal_mode(data[7] & 0x3)
            this.set_record_mode((data[7] >> 6) & 0x1);

            this.set_zoom_times(data[8]);
        }
    },
    set_gimbal_mode(mode) {
        //实际控制状态与实时反馈状态存在一个时差，避免控制来回跳动，需要在控制后延时一段时间再同步反馈状态
        if (this.update_status_gimbal_mode == 1) {
            // 锁定0 跟随 1 回中2
            switch (mode) {
                case 0:
                    this.holderModelDom = 0
                    break;
                case 1:
                    this.holderModelDom = 1
                    break;
                case 2:
                    this.holderModelDom = 2
                    break;
            }
        }
    },
    set_record_mode(mode) {
        // var recordStatusDom = document.getElementById("recordStatus")
        // switch (mode) {
        //     case 0:
        //         record_status = false;
        //         recordStatusDom.style.background = "#1E1E1E url(./images/shoot.png) center no-repeat"
        //         recordStatusDom.style.backgroundSize = "100% 100%"
        //         break;

        //     case 1:
        //         if (record_status == false) {
        //             var music = document.getElementById("record_audio");
        //             music.play();
        //         }
        //         record_status = true;
        //         recordStatusDom.style.background = "#1E1E1E url(./images/shoot-active.png) center no-repeat"
        //         recordStatusDom.style.backgroundSize = "100% 100%"
        //         break;
        // }
    },
    // 设置变焦倍数
    set_zoom_times(times) {
        this.zoom = times
    },
    // 
    get_version() {
        let buff = new Array(0xA5, 0xff, 0x03, 0x00, 0x00);

        buff[3] = 0;
        buff[4] = this.cal_crc_table(buff);
    }
}
