import { render, staticRenderFns } from "./itemB.vue?vue&type=template&id=755f74ce&scoped=true&"
import script from "./itemB.vue?vue&type=script&lang=js&"
export * from "./itemB.vue?vue&type=script&lang=js&"
import style0 from "./itemB.vue?vue&type=style&index=0&id=755f74ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755f74ce",
  null
  
)

export default component.exports