<template>
  <div class="videoModelChange_box">
    <div class="videoModelChange_list" v-if="isShow">
      <div v-for="(item,index) in imgList" :key="index" >
        <el-image
        @click="change(item,true)"
          v-if="item.id != 5"
          class="w30 h36 ml10 mr10"
          :src="require(`./img/select/${item.id}.svg`)"
        ></el-image>
        <div v-else class="tc lh34 ml11" @click="handclickFn" title="主画面切换">切换</div>
      </div>
    </div>
    <div class="current mt13" @click="isShow = !isShow">
      <el-image class="w20 h20" :src="getImg(key)"></el-image>
    </div>
  </div>
</template>
<script>
import API from "@/api";
import { clearTimeout } from "highcharts";
export default {
  data() {
    return {
      imgList: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 5 },
        { id: 12 },
        { id: 13 },
        { id: 23 },
        { id: 0 },
      ],
      key: 1,
      flag: false,
      isShow: false,
      controlflag:true
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["NXdata", "uav_This"],
  watch: {
    "uav_This.NXdata": {
      handler(value) {
        // console.log(value,'status----');
        if (value.data && value.data.message == "ok") {
          this.key = value.data.modeType || 1;
          if (this.timeOut) {
            clearTimeout(this.timeOut);
          }
        }
      },
      deep: true,
    },
  },
  created() {
    // 初始化视频
    this.change({ id: 1 });
    // this.handclickFn()
  },
  methods: {

    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({ deviceHardId: device });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    getImg(key) {
      let arr = this.imgList.filter((item) => item.id == key);
      if (arr) {
        let img = require(`./img/default/${arr[0].id}.svg`);
        return img;
      }
      return "";
    },
    async change(item, status) {
      if (item.id == 5) return false;
      if (status) {
        let res = await this.checkUseOperateFn(this.data.deviceHardId);
        if (!res) {
          return false;
        }
      }
      let streamData = {
        data: {
          data: {
            modeType: item.id,
          },
          messageID: 1033,
        },
        type: 528,
      };
      this.$emit("fn", streamData);
      this.flag = true;
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        // status 判断是否 是主动触发
        if (status) {
          this.$message.error("请联系运维人员升级固件!");
        }
      }, 2000);
    },
  async handclickFn() {
      let res = await this.checkUseOperateFn(this.data.deviceHardId);
        if (!res) {
          return false;
        }
      this.controlflag =!this.controlflag
      // fuseFlag 是否进行视频融合 true为融合
      // controlflag 是否需要画中画主次画面切换 true 为切换
      let streamData = {
        data: {
          data: {
            fuseFlag:true,
            controlflag:this.controlflag,
          },
          messageID: 1018,
        },
        type: 528,
      };
    console.log(streamData,'streamData');
      this.$emit("fn", streamData);
    },
  },
};
</script>
<style scoped lang='scss'>
.videoModelChange_box {
  height: 32px;
  position: absolute;
  .videoModelChange_list {
    // display: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: absolute;
    bottom: 32px;
    right: -26px;
    width: 200px;
    height: 64px;
    padding: 10px;
    z-index: 20;
    background: #000000a3;
    border: 1px solid rgba(26, 92, 246, 0.5);
  }
  &.current {
    margin-left: 5px;
    margin-top: 30px;
    line-height: 30px;
  }
}
// .videoModelChange_box:hover {
//   .videoModelChange_list {
//     display: flex;
//   }
// }
</style>