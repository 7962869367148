import axios from 'axios';
import API from "@/api";

export default {
  interfaceAI(url) {
    let blob = this.screenShot();
    let data = {
      image: null
    }
    blobToBase64(blob, async (base64DataUrl) => {
      data.image = base64DataUrl
      let res = await axios.post("http://32.128.6.151:9090/api/inflet/v1/tasks/3b2c435f-0652-418c-82d0-37efed27e9c1/predict", data)
      // console.log(res);
      console.log(res.data.data,"swiming");
      //  if(this.indexswim == 10){
      if (res.data.data.targets && res.data.data.targets.length > 0) {
        clearInterval(this.AISetInterval)
        this.AISetInterval = null
        //  this.$emit("fn", {data: "POSITION",type: 513});
        // if (this.lineLableName.includes("河道")) {
          this.$emit("AIDialog", true, "当前区域发现疑似游泳人员")
        // }
        let fd = new FormData();
        fd.append("uploadFiles", blob, `下载.jpeg`);
        let res2 = await API.MAPMANAGE.fileUpload(fd);
        let data2 = {
          imageUrl: res2[0].storage,
          deviceHardId: this.device.deviceHardId,
          jsonData: JSON.stringify(res.data.data)
        }
        let swimres = await API.FCKERNEL.insertSwimmingMonitor(data2)
        // process.env.VUE_APP_IMG_URL +
      let imgshowurl =   process.env.VUE_APP_IMG_URL + res2[0].url;
      this.$emit("imgUrl", imgshowurl,4,JSON.stringify(res.data.data));
    }
    });

    function blobToBase64(blob, callback) {
      let reader = new FileReader()
      reader.onload = () => {
        callback(reader.result)
      }
      reader.readAsDataURL(blob)
    }
  },
  //人流识别
  async setIntervalFrame() {
    let blob = this.screenShot();
    let fd = new FormData();
    fd.append("uploadFiles", blob, `下载.jpeg`);
    let res = await API.MAPMANAGE.fileUpload(fd);
    let data = {
      // imageUrl:"http://32.128.6.52:9000/uploads/2023/05/26/2f183488-ad62-41c2-bb89-736ade5d8f36.png",
      imageUrl: res[0].storage,
      deviceHardId: this.device.deviceHardId,
    };
    let res2 = await API.FCKERNEL.crowdDensity(data);
    if (res2.total && res2.total > 20) {
      clearInterval(this.AISetInterval)
      this.AISetInterval = null
      this.$emit("fn", {
        data: "POSITION",
        type: 513
      });
      this.$emit("AIDialog", true, "当前区域人流聚集")
    }
  },
}