const crc_table = new Array(
    0x00, 0x31, 0x62, 0x53, 0xc4, 0xf5, 0xa6, 0x97, 0xb9, 0x88, 0xdb, 0xea, 0x7d, 0x4c, 0x1f, 0x2e,
    0x43, 0x72, 0x21, 0x10, 0x87, 0xb6, 0xe5, 0xd4, 0xfa, 0xcb, 0x98, 0xa9, 0x3e, 0x0f, 0x5c, 0x6d,
    0x86, 0xb7, 0xe4, 0xd5, 0x42, 0x73, 0x20, 0x11, 0x3f, 0x0e, 0x5d, 0x6c, 0xfb, 0xca, 0x99, 0xa8,
    0xc5, 0xf4, 0xa7, 0x96, 0x01, 0x30, 0x63, 0x52, 0x7c, 0x4d, 0x1e, 0x2f, 0xb8, 0x89, 0xda, 0xeb,
    0x3d, 0x0c, 0x5f, 0x6e, 0xf9, 0xc8, 0x9b, 0xaa, 0x84, 0xb5, 0xe6, 0xd7, 0x40, 0x71, 0x22, 0x13,
    0x7e, 0x4f, 0x1c, 0x2d, 0xba, 0x8b, 0xd8, 0xe9, 0xc7, 0xf6, 0xa5, 0x94, 0x03, 0x32, 0x61, 0x50,
    0xbb, 0x8a, 0xd9, 0xe8, 0x7f, 0x4e, 0x1d, 0x2c, 0x02, 0x33, 0x60, 0x51, 0xc6, 0xf7, 0xa4, 0x95,
    0xf8, 0xc9, 0x9a, 0xab, 0x3c, 0x0d, 0x5e, 0x6f, 0x41, 0x70, 0x23, 0x12, 0x85, 0xb4, 0xe7, 0xd6,
    0x7a, 0x4b, 0x18, 0x29, 0xbe, 0x8f, 0xdc, 0xed, 0xc3, 0xf2, 0xa1, 0x90, 0x07, 0x36, 0x65, 0x54,
    0x39, 0x08, 0x5b, 0x6a, 0xfd, 0xcc, 0x9f, 0xae, 0x80, 0xb1, 0xe2, 0xd3, 0x44, 0x75, 0x26, 0x17,
    0xfc, 0xcd, 0x9e, 0xaf, 0x38, 0x09, 0x5a, 0x6b, 0x45, 0x74, 0x27, 0x16, 0x81, 0xb0, 0xe3, 0xd2,
    0xbf, 0x8e, 0xdd, 0xec, 0x7b, 0x4a, 0x19, 0x28, 0x06, 0x37, 0x64, 0x55, 0xc2, 0xf3, 0xa0, 0x91,
    0x47, 0x76, 0x25, 0x14, 0x83, 0xb2, 0xe1, 0xd0, 0xfe, 0xcf, 0x9c, 0xad, 0x3a, 0x0b, 0x58, 0x69,
    0x04, 0x35, 0x66, 0x57, 0xc0, 0xf1, 0xa2, 0x93, 0xbd, 0x8c, 0xdf, 0xee, 0x79, 0x48, 0x1b, 0x2a,
    0xc1, 0xf0, 0xa3, 0x92, 0x05, 0x34, 0x67, 0x56, 0x78, 0x49, 0x1a, 0x2b, 0xbc, 0x8d, 0xde, 0xef,
    0x82, 0xb3, 0xe0, 0xd1, 0x46, 0x77, 0x24, 0x15, 0x3b, 0x0a, 0x59, 0x68, 0xff, 0xce, 0x9d, 0xac
);

export function cal_crc_table(array) {
    var crc = 0;
    var i = 1;
    var len = array[2];
    while (len--) {
        crc = crc_table[crc ^ array[i]];
        i++;
    }
    console.log(crc, 'crc');
    return crc;
}

function g_convert_native_float_to_float16(value) {
    var buffer1 = new ArrayBuffer(4);
    var f32inf = new Uint32Array(buffer1);
    var f32inff = new Float32Array(buffer1);

    var buffer2 = new ArrayBuffer(4);
    var f16inf = new Uint32Array(buffer2);
    var f16inff = new Float32Array(buffer2);

    var buffer3 = new ArrayBuffer(4);
    var magic = new Uint32Array(buffer3);
    var magicf = new Float32Array(buffer3);

    var buffer4 = new ArrayBuffer(4);
    var _in = new Uint32Array(buffer4);
    var _inf = new Float32Array(buffer4);

    f32inf[0] = 255 << 23;
    f16inf[0] = 31 << 23;
    magic[0] = 15 << 23;
    const sign_mask = 0x80000000;
    const round_mask = ~0xFFF;

    _inf[0] = value;
    var sign = _in[0] & sign_mask;
    _in[0] ^= sign;

    var out = 0;

    if (_in[0] >= f32inf) {
        out = (_in[0] > f32inf) ? 0x7FFF : 0x7C00;
    } else {
        _in[0] &= round_mask;
        _inf[0] *= magicf[0];
        _in[0] -= round_mask;
        if (_in[0] > f16inf[0]) {
            _in[0] = f16inf[0];
        }
        out = _in[0] >> 13;
    }

    out |= (sign >> 16);

    return Number(out);
}
export const MMC_Gimbal_Z40S = {
    // 云台模式 --- (0,127,[165,8,3,0,127])(1,78,[165,8,3,1,78])(2,29,[165,8,3,2,29])
    gimbal_mode_ctrl(mode) {
        console.log(mode, '云台模式');
        var buff = new Array(0xA5, 0x08, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        console.log(buff, '云台模式');
        return buff
    },
    // 点击

    zoom(zoom_ctrl) {
        var buff = new Array(0xA5, 0x04, 0x03, 0x00, 0x00);
        buff[3] = (zoom_ctrl >>> 0) & 0xff;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    // 聚焦事件
    ju(focus_ctrl) {
        var buff = new Array(0xA5, 0x11, 0x04, 0x00, 0x00, 0x00);
        buff[3] = (focus_ctrl >>> 0) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    change_pitch_angle(angle) {
        var buff = new Array(0xA5, 0x22, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    change_yaw_angle(angle) {
        var buff = new Array(0xA5, 0x23, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    camera_mode_ctrl(camera_mode) {
        var buff = new Array(0xA5, 0x09, 0x03, 0x00, 0x00);
        buff[3] = camera_mode;
        buff[4] = cal_crc_table(buff);
        return buff;
    },
    /**
     * 拍照
     * @param {*} param0 
     * @returns 
     */
    take_photo({
        longitude = 0,
        latitude = 0,
        altitude = 0
    }) {
        var buff = new Array(0xA5, 0x01, 16, /*index*/ 0x00, 0x00, /*altitude*/ 0x00, 0x00, 0x00, 0x00,
            /*latitude*/
            0x00, 0x00, 0x00, 0x00, /*longitude*/ 0x00, 0x00, 0x00, 0x00, 0x00);

        buff[5] = (altitude) & 0xff;
        buff[6] = (altitude >> 8) & 0xff;
        buff[7] = (altitude >> 16) & 0xff;
        buff[8] = (altitude >> 24) & 0xff;

        buff[9] = (latitude) & 0xff;
        buff[10] = (latitude >> 8) & 0xff;
        buff[11] = (latitude >> 16) & 0xff;
        buff[12] = (latitude >> 24) & 0xff;

        buff[13] = (longitude) & 0xff;
        buff[14] = (longitude >> 8) & 0xff;
        buff[15] = (longitude >> 16) & 0xff;
        buff[16] = (longitude >> 24) & 0xff;

        buff[17] = cal_crc_table(buff);

        return buff
    },
    /**
     * 
     * @param {*} status: 1 开始录像, 0 停止录像
     */
    record(status) {
        var buff = new Array(0xA5, 0x05, 0x03, 0x00, 0x00);
        if (status) {
            buff[3] = 2;
        } else {
            buff[3] = 0;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed) {
        console.log(speed,'wtm');
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        console.log(realspeed,'左右');
        var buff = new Array(0xA5, 0x07, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        console.log("左右---:" + buff);
        return buff
    }
}
