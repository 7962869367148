<template>
  <div class="h745">
    <div class="tb-box">
      <!-- 鹰击历史任务 -->
      <div class="tb-hd-box">
        <div class="  tb-hd dib cf tc fw700 lh36">任务名称</div>
        <div class="tb-hd">任务类型</div>
        <div class="tb-hd">结束时间</div>
        <div class="tb-hd">操作</div>
      </div>
      <div class="tb-bd-box">
        <div class="tb-tr device-fns" v-for="item in list" :key="item.id">
          <el-tooltip :content="item.task_title" placement="top">
            <div class="td cf lh36 td-checkbox">
              {{ item.task_title || "暂无" }}
            </div>
          </el-tooltip>
          <div class="td lh36">
            <div v-if="item.taskCateId == '1'" style="color: #fb4a2d">
              警情任务
            </div>
            <div v-else-if="item.taskCateId == '2'" style="color: #00d45c">
              常态任务
            </div>
            <div v-else style="color: #ffbd36">临时紧急任务</div>
          </div>
          <el-tooltip :content="item.task_title" placement="top">
            <div class="td cf lh36 td-checkbox">
              {{ item.endTime || "暂无" }}
            </div>
          </el-tooltip>
          <div class="jcc operation tc lh36 " style="    margin: 0 auto;">
            <el-tooltip effect="dark" content="图片" placement="top">
              <div @click="handleImage(item)" class="iconfont icon-lishizhaopian"></div>
            </el-tooltip>
            <div :class="{ deng: item.task_status == 1 }" class="iconfont icon-a-xiazai2px lh36 cp budeng ml10 "
              title="导出报告" @click="shengchengbaogaoFn(item)"></div>
          </div>
        </div>
      </div>
    </div>
    <ImageMedia v-if="visible" :historyImage="flightTask_imageList"></ImageMedia>
  </div>
</template>

<script>
import API from "@/api";
import ImageMedia from "@/components/command/flight-task/image-media/index.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      list: [],
      visible: false
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  components: { ImageMedia },
  created() {
    this.getList();
  },
  provide() {
    return {
      flight_task_chage_view: this.chage_view,
    };
  },
  methods: {
    chage_view() {
      this.visible = false
    },
    // 查看历史图片
    async handleImage(item) {
      try {
        let res = await API.TASK.photoAndvideo({
            pageNo: 1,
            pageSize: 10000,
            taskId: item.id
          })
          console.log(res.data,'res.datares.data');
        if (res.data && res.data.length) {
          this.flightTask_imageList = res.data
          this.visible = true
        } else {
          this.$el_message('无历史图片', () => { }, 'info')
        }
      } catch (error) {
        this.$el_message('无历史图片', () => { }, 'info')
      }
    },
    async getList() {
      this.list = [];
      let res = await API.ACCIDENT.List();
      this.list = res;
      console.log(this.list, " this.list");
    },
    // 导出报告
    async shengchengbaogaoFn(item, id) {
      console.log(item, id);
      let newId = null;
      if (item) {
        newId = item.id;
      } else {
        newId = id;
      }
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/task/exportTaskWord?id=${newId}&FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`;
      console.log(url, 'url');
      const fileName = url;
      if ("download" in document.createElement("a")) {
        let downloadElement = document.createElement("a");
        downloadElement.href = url;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.tb-box {
  box-sizing: border-box;
  height: 100%;
  padding: 0 16px;

  .tb-hd-box {
    display: flex;
    justify-content: space-between;
    height: 32px;

    .tb-hd {
      height: 100%;
      width: calc(100% / 4);

      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #e5f9ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }
  }

  .tb-bd-box {
    height: calc(100% - 36px);
    overflow: auto;

    .tb-tr {
      display: flex;
      box-sizing: border-box;
      background: url("~@/assets/images/webhome/tablebg.png") no-repeat;
      background-size: content;
      background-size: 100% 100%;
      margin-top: 5px;
      cursor: pointer;

      &:hover {
        background: url("~@/assets/images/webhome/tablebgs.png") no-repeat;
        background-size: 100% 100%;
      }

      .td {
        position: relative;
        width: calc(100% /4);

        text-align: center;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #b3bbc5;
        word-break: break-word;
        box-sizing: border-box;

        .checkbox {
          position: absolute;
          left: 0;
          cursor: pointer;
        }
      }

      .td-checkbox {
        // padding-left: 14px;
        /* width: 150px; */
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis !important;
        overflow: hidden;
      }

      .operation {
        display: flex;
        justify-content: space-around;
        color: #08c2d1;

        .iconfont {
          font-size: 18px;
        }

        .videoStatus {
          color: #4fbc6a;
        }

        .active {
          color: #00ffff;
        }
      }
    }
  }
}
</style>