<template>
<!-- class="lsdom" -->
  <div class="lsdom">
    <!-- <div class="head" @click="$emit('close')">
    <div>健康管理预警</div>
    关闭
    </div> -->
    <div class="healists">
        <div class="healist" v-for="(item,i) in list" :key="i">
            {{ item }}
            <div class="close" @click="close(i,item)">
              关闭
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      delList:[],
      list:[]
    };
  },
  props: {
    healthWaringList: {
      type: Array,
      default(){
        return [];
      }
    },
    
  },
  watch:{
    healthWaringList: function(val){
      this.getList()
    }
  },
  methods:{
    close(i,item){
      this.list.splice(i,1)
      this.delList.push(item)
    },
    getList(){
      this.healthWaringList.forEach((val,i)=>{
        this.delList.forEach(val2=>{
          if(val == val2){
            this.healthWaringList.splice(i,1)
          }
        })
      })
      this.list = this.healthWaringList
    }
  },
  mounted(){
    this.getList()
    // console.log(this.healthWaringList,"收")
  }
};
</script>

<style lang='scss' scoped>
.lsdom {
  // width: 531px;
  // min-height: 10px;
  position: absolute;
  top: 13%;
  right: 5%;
  // transform: translate(-50%, -50%);
  // background: rgba(50, 2, 2, 0.7);
  // background: url('~@/assets/images/warn/yj.png') no-repeat center center;
  // background-size:cover;
  // box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  .head{
    //   width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 10px;
      color: #fff;
      cursor: pointer;
  }
  .healists{
      padding-bottom:15px;
      width: 95%;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      height: 75%;
      // overflow-y: auto;
      .healist{
        position: relative;
        width: 300px;
        margin-bottom: 10px;
        padding: 10px 0;
        text-align: center;
        background: url('~@/assets/images/warn/yj.png') no-repeat center center;
        background-size:cover;
        box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
        border-radius: 10px;
          word-break: break-all;
        //   width: 100%;
          .close{
            position: absolute;
            top: 9px;
            right: 10px;
          }
      }
  }
}
</style>