<template>
  <div
    class="itemCMaxBox pl24 pr24"
    v-loading="loading"
    element-loading-text="音频上传中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
  >
    <div class="jcsb cf mt24">
      <span>音频列表:({{ PagerP1.music.list.length || 0 }}条)</span>
      <!-- <div class="w15 h15 iconfont c40d icon-shanchu cp"></div> -->
    </div>
    <div class="wih100 h213 itemCList mt15">
      <div
        v-for="(item, index) in PagerP1.music.list"
        :key="index"
        :class="activeBg == index ? 'bg148' : ''"
        @click="handClick(item, index)"
        class="h26 lh26 jcsb pl10 pr10 cf cp"
      >
        <div class="List">
          <span class="mr15">{{ index + 1 }}</span
          ><span>{{ item }}</span>
        </div>
        <div class="del" @click="del(item)">
          <span class="iconfont icon-shanchu"></span>
        </div>
      </div>
    </div>
    <div class="bottomBtnBox tc jcsb mt10 pl10 pr10">
      <div class="cf f14" style="transform: scale(0.7)">
        <div
          class="w35 h35 btnStyle lh35 tc"
          :class="scflag ? 'pointerEvents' : ''"
        >
          <div class="w15 h15 ml10 iconfont pr icon-a-shangchuan2px">
            <input
              class="pa top0 cp w30 h30"
              @change="handleUploadMusicFile"
              type="file"
              accept="audio/*"
              ref="btn_file"
            />
          </div>
        </div>
        <div v-if="!scflag">上传</div>
        <div v-else>上传中...</div>
      </div>
      <div class="cf f14" style="transform: scale(0.7)">
        <div class="w35 h35 btnStyle cp tc" @click="handlePlayMusic">
          <div
            class="w5 h5 mt9 ml9 iconfont icon-a-bofang2px"
            v-if="videoFlag"
          ></div>
          <div class="w20 h20 mt9 ml9 iconfont icon-a-zanting2px" v-else></div>
        </div>
        <div>播放</div>
      </div>
      <div class="cf f14" v-if="itemName" @click="delSong">
        <div class="w35 h35 btnStyle cp">
          <div class="w5 h5 mt10 ml9 iconfont icon-shanchu"></div>
        </div>
        <div>删除</div>
      </div>
      <div class="cf f14 mt20 jcsb w200">
        <span> 播放模式:</span>
        <el-select
          size="mini"
          class="dib w100"
          v-model="recodeCycle"
          @change="onChange"
          placeholder="请选择"
          popper-append-to-body
        >
          <el-option
            v-for="item in options"
            :key="item.recodeCycle"
            :label="item.type"
            :value="item.recodeCycle"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import MavLink20Processor from "@/utils/PagerP1/mavlink20_processor";
import CmdTtsText from "@/utils/PagerP1/cmd_tts_text";
import CmdReadFileList from "@/utils/PagerP1/cmd_read_file_list";
import CmdRecordName from "@/utils/PagerP1/cmd_record_name";
import CmdSetSystemVolume from "@/utils/PagerP1/cmd_set_system_volume";
import CmdStreamFunction from "@/utils/PagerP1/cmd_stream_function";
import CmdStreamUpData from "@/utils/PagerP1/cmd_stream_up_data";
import CmdSaveFile from "@/utils/PagerP1/cmd_save_file";
import CmdRecordStateA from "@/utils/PagerP1/cmd_record_state";

export default {
  data() {
    return {
      activeBg: null,
      itemName: "",
      videoFlag: true,
      status: 11,
      scflag: false,
      oldName: "",
      loading: false,
      options: [
        {
          recodeCycle: 10,
          type: "单曲",
        },
        {
          recodeCycle: 11,
          type: "单曲循环",
        },
        // {
        //   recodeCycle: 12,
        //   type: "列表",
        // },
        // {
        //   recodeCycle: 13,
        //   type: "列表循环",
        // },
        // {
        //   recodeCycle: 14,
        //   type: "随机",
        // },
        // {
        //   recodeCycle: -1,
        //   type: "未初始化",
        // },
      ],
      // recodeState: 10,
      recodeCycle: 10,
      action: null,
      result: null,
      types: null,
      e: null,
    };
  },
  created() {
    // // 获取列表
    this.handleGetMusicList();
  },
  mounted() {
    setInterval(() => {
      this.recodeCycle = this.PagerP1.recodeCycle;
      if (this.PagerP1.recodeState == 11) {
        this.videoFlag = false;
      } else {
        this.videoFlag = true;
      }
    }, 100);
  },
  watch: {
    PagerP1: {
      handler(val) {
        if (val.recodeState == 11) {
          this.videoFlag = false;
        } else {
          this.videoFlag = true;
        }
      },
    },
    action: {
      handler(val) {
        this.upload(this.e);
      },
    },
  },
  methods: {
    del(item) {
      this.$confirm("是否删除此音乐？", "确认", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        // customClass: "uav_controlPane",
        showClose: false,
      })
        .then(() => {
          let message = {
            data: item,
            type: 2324,
          };
          this.mqtt_publish(message);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onChange(val) {
      let message = {
        data: val,
        type: 2310,
      };
      this.mqtt_publish(message);
    },
    handClick(item, index) {
      this.activeBg = index;
      this.itemName = item;
      // this.stopMusic();
      // this.startMusic()
      this.videoFlag = true;
    },
    startMusic() {
      let message = {
        data: this.itemName,
        type: 2308,
      };
      this.mqtt_publish(message);
      this.oldName = this.itemName;
      this.videoFlag = false;
    },
    handlePlayMusic() {
      /**
       * 10 停止
       * 11 播放
       * 12 暂停
       * 13 询问状态
       * 14
       */
      if (!this.itemName) return this.$message.info("未选择曲目");
      if (this.itemName != this.oldName) {
        this.startMusic();
      } else {
        if (this.videoFlag) {
          this.status = 11;
          // this.videoFlag = false;
        } else {
          this.status = 12;
          // this.videoFlag = true;
        }
        let message = {
          data: this.status,
          type: 2309,
        };
        this.videoFlag = !this.videoFlag;
        this.oldName = this.itemName;
        this.mqtt_publish(message);
      }
    },
    stopMusic() {
      let message = {
        data: 12,
        type: 2309,
      };
      this.mqtt_publish(message);
      this.videoFlag = true;
    },
    handleGetMusicList() {
      // let cmdReadFileList = new CmdReadFileList();
      // let message = cmdReadFileList.pack();
      // message = Uint8Array.from(message);
      // this.mqtt_publish(message);
    },
    //删除单个文件
    delSong() {
      let data = {
        data: this.itemName,
        type: 2323,
      };
      this.mqtt_publish(data);
    },
    // 上传文件
    handleUploadMusicFile(e) {
      this.e = e;
      let start = {
        data: 10,
        type: 2322,
      };
      this.mqtt_publish(start);
      // console.log("流上传开始:", start);
    },
    async upload(e) {
      let file = e.target.files[0];
      let name = file.name.replace(" ", "");
      try {
        if (this.PagerP1.music.list.length > 0) {
          this.PagerP1.music.list.forEach((val) => {
            if (val == name) {
              throw new Error("退出循环");
            }
          });
        }

        let arrayBuffer = await this.blob2arraybuffer(file);
        let fileBuffer = Array.from(new Uint8Array(arrayBuffer));
        let slice_size = 2500;
        let total_count = Math.ceil(fileBuffer.length / slice_size);
        const s = (buffer) => {
          let message = {
            data: buffer,
            type: 2318,
          };
          this.mqtt_publish(message);
        };
        async function sleep(time) {
          return new Promise((res) => setTimeout(res, time));
        }
        // 状态更改
        this.scflag = true;
        this.loading = true;
        async function main() {
          for (let i = 0; i < total_count; i++) {
            await sleep(10);
            // console.log("流上传:", i);
            let buffer = fileBuffer.splice(0, slice_size);
            s(buffer);
          }
        }

        if (this.action == 10 && this.result == 0 && this.types == 1) {
          await main();
          let end = {
            data: 11,
            type: 2322,
          };
          this.mqtt_publish(end);
        }

        // console.log("流上传结束:", end);
        // console.log(file.name.replace(" ", ""), "file.namefile.name");

        // 保存
        if (this.action == 11 && this.result == 0 && this.types == 1) {
          let message = {
            data: file.name.replace(" ", ""),
            type: 2320,
          };
          await this.mqtt_publish(message);
          this.$message.success("上传成功!");
          this.scflag = false;
          this.loading = false;
        }
      } catch (e) {
        if (e.message === "退出循环") {
          this.$message.error("上传的歌曲与列表中歌曲存在相同名字，请重试！");
        }
      }
    },
    async mqtt_publish(message) {
      if (this.PoliceKey()) {
        let key = await this.PoliceKey();

        this.PagerP1.mqtt.client.publish(
          `SPEAK/OBTAIN/${key}-01`,
          new TextEncoder().encode(JSON.stringify(message)),
          {
            qos: 0,
          },
          (e) => {
            console.log("发送成功", message);
          }
        );
        this.PagerP1.mqtt.client.on("message", (topic, payload, packet) => {
          const data = JSON.parse(packet.payload.toString());
          this.PagerP1.recodeState = data.recodeState;
          this.action = data.action;
          this.result = data.result;
          this.types = data.type;
        });
      }
    },

    async blob2arraybuffer(blob) {
      if (!blob) {
        return Promise.reject(blob);
      }
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (e) => reject(e);
        fileReader.readAsArrayBuffer(blob);
      });
    },
  },
  inject: ["PagerP1", "PoliceKey"],
};
</script>

<style lang="scss" scoped>
.itemCMaxBox {
  input[type="file"] {
    color: transparent;
    opacity: 0;
    top: 0px;
    left: -7px;
    width: 20px;
    cursor: pointer !important;
    text-decoration: none;
  }
  .itemCList {
    border: 1px solid #3360a6;
    overflow-y: auto;
    border-radius: 10px;
    .List {
      width: 360px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .bottomBtnBox {
    .btnStyle {
      border-radius: 50%;

      background: #9bacbe;
      color: #3e495b;
      border: 10px solid #000;
      .bf {
        transform: rotate(90deg);
      }
    }
    .btnStyle:hover {
      color: #43deff;
    }
  }
}
/deep/el-select {
  .el-input--mini {
    background: #3768b3;
  }
}
.c40d {
  color: #40d4f5;
}
.bg148 {
  background: #148bff;
  border-radius: 5px;
}
.pointerEvents {
  pointer-events: none;
}
/deep/.el-select {
  .el-input__inner {
    border: 1px solid #3768b3;
    background: #132550;
    border-radius: 4px 4px 0 0;
    color: #fff;
  }
}
</style>
