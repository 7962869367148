<!-- 高清变焦相机 -->
<template>
  <div class="ofh waibi">
    <div class="wih00 pr mountControllerTitle" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机</div>
      </div>
      <div @click="$emit('close')" class="close">关闭</div>
    </div>
    <div class="jcsb mt10">
      <div class="ml20">
        <div class="form">
          <div class="form_item">
            <span class="form_item_title" style="line-height: 26px; margin: 0 0px 0 -11px">控制速度:</span>
            <!-- <el-select
              v-model="speed"
              clearable
              placeholder="请选择控制速度"
              @change="cameraChangeFn"
            >
              <el-option
                v-for="item in 5"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select> -->
            <div class="sudu">
              <div v-for="(item, index) in num" :key="index" class="num" @click="cameraChangeFn(item, index)"
                :class="{ act: index == activeId }">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="form_item">
            <span class="form_item_title">云台模式：</span>
            <div>
              <div class="mono-long">
                <div class="mono-left mono_box" :class="{ active: holderModelDom == 0 }" @click="handleClick(2, 0)">
                </div>
                <div class="mono-middle mono_box" :class="{ active: holderModelDom == 1 }" @click="handleClick(2, 1)">
                </div>
                <div class="mono-right mono_box" :class="{ active: holderModelDom == 2 }" @click="handleClick(2, 2)">
                </div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div>
          <div class="form-item w228  mb10" style="display: flex;justify-content: space-between;">
            <div class="label-box">视频指点:</div>
            <div class="input-box cf">
              <el-radio-group @change="handle_change_dj_mode" v-model="dj_mode">
                <el-radio size="mini" v-for="item in drd.dj_mode" :key="item.id" :label="item.id">
                  <span class="cf">{{ item.label }}</span>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="form_item">
            <span class="form_item_title mt5">变焦：</span>
            <div class="jcsb">
              <div class="symbol mr10 mt5" @mousedown="backFn" @mouseup="zoomStop(1)">
                -
              </div>
              <el-slider class="w10" :max="2" :min="0" :show-tooltip="false" v-model="value1" @change="zoomStop(1)"
                @input="mousemoveFn"></el-slider>
              <div class="symbol ml10 mt5" @mousedown="nextFn" @mouseup="zoomStop(1)">
                +
              </div>
            </div>
          </div>
          <div class="form_item">
            <span class="form_item_title mt5">聚焦：</span>
            <div class="jcsb">
              <div class="symbol mr10 mt5" @mousedown="camerafocusFn(0)" @mouseup="camerafocus(1)">
                -
              </div>
              <el-slider class="w10" :max="2" :min="0" :show-tooltip="false" v-model="value2" @change="camerafocus(1)"
                @input="mousemoveFn"></el-slider>
              <div class="symbol ml10 mt5" @mousedown="camerafocusFn(2)" @mouseup="camerafocus(1)">
                +
              </div>
            </div>
          </div>
          <div class="form_item">
            <span class="form_item_title">俯仰：</span>
            <div class="speedC">
              <div
                class="pitch"
                :style="
                  pitch == item.num
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                v-for="(item, index) in regList"
                :key="index"
                @click="change_pitch_angle(item.num)"
              >
                {{ item.val }}
              </div>
            </div>
            <!-- <el-input v-model="pitch"></el-input>
            <div class="fuhao" @click="fuyangjianFa">-</div>
            <div class="fuhao" @click="fuyangjiaFa">+</div>
            <el-button @click="change_pitch_angle">设置</el-button> -->
          </div>
          <!-- <div class="form_item">
            <span class="form_item_title">航向：</span>
            <el-input v-model="yaw"></el-input>
            <div class="fuhao" @click="hangxiangjianFa">-</div>
            <div class="fuhao" @click="hangxiangjiaFa">+</div>
            <el-button @click="change_yaw_angle">设置</el-button>
          </div> -->
        </div>
      </div>
      <div class="xingji">
        <div class="form">
          <div class="mb15 f14 dib">相机模式：</div>
          <div class="form_item">
            <div>
              <div class="mono-short">
                <div class="mono-left mono_box" :class="{ active: activeBox3 == 0 }" @click="handleClick(3, 0)"></div>
                <div class="mono-right mono_box" :class="{ active: activeBox3 == 2 }" @click="handleClick(3, 2)"></div>
              </div>
              <div class="jcsb cf">
                <div class="text-left">录像</div>
                <div class="text-right">拍照</div>
              </div>
            </div>
          </div>
        </div>

        <div class="form_item mb20">
          <div class="">
            <div class="ml20 mt10">
              <img class="dib cp w32 h32 mr40" :class="recordFlag ? 'recordBG' : ''"
                src="~@/assets/images/mount/record.png" @click="record()" />
              <img class="dib cp w32 h32" src="~@/assets/images/mount/photo.png" @click="take_photo()" />
            </div>
          </div>
          <div class="caozuoImg w150 mt10 h150 pr">
            <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="zuoUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="youUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
            <!-- <div class="rocker">
              <div id="static"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import data from "./data/index.js";
  import methods from "./methods/index.js";

  export default {
    inject: ["mountControllerThis"],
    data() {
      return {
        activeId: 0,
        ...data,
        value1: 1,
        value2: 1,
        count: 50,
        recordFlag: true,
        dj_mode: 0,
        regList:[
        {
          val:"复位",
          num:0
        },
        {
          val:"30",
          num:-30
        },
        {
          val:"60",
          num:-60
        },
        {
          val:"90",
          num:-90
        },
      ],
        drd: {
          dj_mode: [
            { id: 0, label: "关闭" },
            { id: 1, label: "打开" },
          ]
        },
        // 控制挂载信息体
        controInfor: {
          type: 200,
          systemCode: "mmc",
          state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
          username: "",
          height: 0,
          idlingTime: 0,
          data: {
            // this.accident_This.mountList.mountType>1024?
            cmdControlType: 7000,
            cmdFunction: null,
            cmdState: null,
            cmdValue: null,
            cmdBuffer: "",
            cmdName: "MMC_Gimbal_Z40",
            //
          },
          deviceHardId: "",
        },
        num: [1, 2, 3, 4, 5],
        pitch: 0,
        yaw: 0,
        timer: null, //定时器
      };
    },
    mounted() {
      this.controInfor.username = this.user_info && this.user_info.username;
      this.joystickFn();
    },
    beforeDestroy() {
      this.$store.commit("device/SET_MOVE_YS_DATA", {controInfor:this.controInfor,mountStatus:0});
    },
    computed: {
      ...mapGetters(["user_info"]),
    },
    methods: {
      ...methods,
      handle_change_dj_mode(id) {
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$store.commit("device/SET_MOVE_YS_DATA", {controInfor:this.controInfor,mountStatus:id,moveType:'ys'});
      },
      abc(id) {
        this.activeId = id;
      },
      // 俯仰 加减法
      fuyangjianFa() {
        if (this.pitch < -90) {
          this.change_pitch_angle();
        } else {
          this.pitch = this.pitch - 1;
        }
      },
      fuyangjiaFa() {
        if (this.pitch > 30) {
          this.change_pitch_angle();
        } else {
          this.pitch++;
        }
      },
      // 航向 加减法
      hangxiangjianFa() {
        if (this.yaw < -145) {
          this.change_yaw_angle();
        } else {
          this.yaw = this.yaw - 1;
        }
      },
      hangxiangjiaFa() {
        if (this.yaw > 145) {
          this.change_yaw_angle();
        } else {
          this.yaw++;
        }
      },
      mousemoveFn() {
        if (this.value1 > 1) {
          this.zoomFn(2);
        } else if (this.value1 < 1) {
          this.zoomFn(0);
        }
      },
      fangxiang(type) {
        let _this = this;
        switch (type) {
          case "up":
            _this.gimbal_pitch_ctrl(1);
            break;
          case "right":
            _this.gimbal_yaw_ctrl(-1);
            break;
          case "left":
            _this.gimbal_yaw_ctrl(1);
            break;
          case "down":
            _this.gimbal_pitch_ctrl(-1);
        }
        // this.timer = setTimeout(() => {
        //   clearTimeout(this.timer);
        //   _this.gimbal_pitch_ctrl(0);
        //   _this.gimbal_yaw_ctrl(0);
        // }, 500);
      },
        stopfxFn() {
    this.gimbal_pitch_ctrl(0);
          this.gimbal_yaw_ctrl(0);
    },
      joystickFn() {
        let _this = this;
        joystick({
          // zone: document.getElementById("static"),
          // mode: "static",
          // position: { left: "50%", top: "50%" },
          // color: "#9bacbe",
          onstart: function (type) {
            switch (type) {
              case "up":
                _this.gimbal_pitch_ctrl(1);
                break;
              case "right":
                _this.gimbal_yaw_ctrl(1);
                break;
              case "left":
                _this.gimbal_yaw_ctrl(-1);
                break;
              case "down":
                _this.gimbal_pitch_ctrl(-1);
            }
          },
          onend: function () {
            _this.gimbal_pitch_ctrl(0);
            _this.gimbal_yaw_ctrl(0);
          },
        });
      },
      nextFn() {
        this.value1 = 2;
        this.zoomFn(2);
      },
      backFn() {
        this.value1 = 0;
        this.zoomFn(0);
      },
      set_take_photo() {
        this.$emit("set_take_photoFn");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mountControllerTitle {
    height:32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
.speedC {
  display: flex;
  width: 173px;
  height: 24px;
  background: rgba(13,34,79,0.65);
border-radius: 12px;
border: 1px solid #427DFF;

  .speed {
    width: 34px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
  .pitch{
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
height: 24px;
border-radius: 12px;
text-align: center;
    line-height: 24px;
    color: #fff;
  }
}
  .recordBG {
    opacity: 7 !important;
  }

  .form {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #dce9ff;
    letter-spacing: 1px;
    box-sizing: border-box;

    .form_item {
      display: flex;
      margin-bottom: 15px;
      text-align: end;

      .form_item_title {
        width: 85px;
      }

      .mono_box {
        width: 28px;
        height: 13px;
        border-radius: 3px;
        margin-top: 2px;
        cursor: pointer;
      }

      .active {
        background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
        background-size: 100% 100%;
      }

      .mono-short,
      .mono-long,
      .slider,
      .text {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }

      .mono-left {
        margin-left: 2px;
      }

      .mono-right {
        margin-right: 2px;
      }

      .mono-short {
        width: 111px;
        height: 17px;
        background: url("~@/assets/images/mount_short.png") no-repeat;
        background-size: 100%;
        margin-bottom: 5px;
      }

      .mono-long {
        width: 168px;
        height: 17px;
        background: url("~@/assets/images/mount_long.png") no-repeat;
        background-size: 100%;
        margin-bottom: 5px;
      }

      .imgs {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        &>img {
          cursor: pointer;
          width: 32px;
          height: 32px;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .slider_box {
        width: 140px;
        margin: -7px 5px 0;
      }

      .symbol {
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid #004FFF;
        width: 24px;
        height: 24px;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
        padding-left: 1px;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .img_bottom {
      display: flex;
      width: 320px;
      justify-content: space-between;

      &>img {
        cursor: pointer;
        width: 130px;
        height: 130px;
      }
    }

    ::v-deep {

      .el-select,
      .el-input {
        width: 84px;
        height: 24px;
      }

      .el-input__inner {
        width: 84px;
        height: 24px;
        background: rgba(13, 34, 79, 0.65);
        border: 1px solid #004FFF;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }

      .el-select .el-input .el-select__caret {
        line-height: 26px;
      }

      // 修改input清除按钮样式
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-icon-circle-close:before {
            line-height: 16px;
            position: absolute;
            top: 5px;
            right: 3px;
          }
        }
      }

      .el-button {
        margin-left: 10px;
        padding: 0;
        background: #2aefed;
        border-radius: 2px;
        width: 43px;
        height: 24px;
        outline: none;
        color: #000000;
        border: none;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .caozuoImg {
    // background-image: url("~@/assets/images/accident/caozuo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("~@/assets/newImage/lunpan.png");
    position: relative;

    .shangUp {
      position: absolute;
      left: 37%;
      top: 11px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 0px solid red;
      cursor: pointer;
    }

    .xaiUp {
      position: absolute;
      left: 37%;
      bottom: 11px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 0px solid red;
      cursor: pointer;
    }

    .zuoUp {
      position: absolute;
      left: 11px;
      top: 37%;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 0px solid red;
      cursor: pointer;
    }

    .youUp {
      position: absolute;
      right: 11px;
      top: 37%;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 0px solid red;
      cursor: pointer;
    }
  }

  /deep/.el-slider__runway {
    background-color: #409eff;
  }

  .waibi {
    width: 524px;
    // margin: 114px 0 0 118px;
    // background: rgba(0, 39, 121, 0.5);
    // box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    //   inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    //   inset 0px 0px 8px 0px #019aff;
    // border: 1px solid #004FFF;
    // backdrop-filter: blur(2px);
    pointer-events: auto;
  }

  .flexs {
    display: flex;
    align-items: center;
  }

  .xian {
    width: 3px;
    height: 15px;
    background: #ffbd36;
    margin: 0 5px 0 16px;
  }

  .sudu {
    width: 203px;
    height: 24px;
    background: rgba(13, 34, 79, 0.65);
    border-radius: 12px;
    border: 1px solid #004FFF;
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    padding: 0 20px 0 20px;
    margin: 0 0 0 10px;
  }

  .num {
    cursor: pointer;
  }

  .act {
    width: 41px;
    height: 22px;
    background: #ffffff;
    border-radius: 12px;
    color: #000000;
    text-align: center;
    margin: 1px 0 0 0;
  }

  .fuhao {
    width: 24px;
    height: 24px;
    background: #000000;
    border: 1px solid #004FFF;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
    cursor: pointer;
    margin: 0 0 0 8px;
  }

  .xingji {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;
  }

  // 变焦功能
  /deep/ .el-slider__runway {
    visibility: hidden;
  }
/deep/.el-slider__runway {
  background-color: #409eff;
}
.waibi {
  width: 524px;
  // margin: 114px 0 0 118px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  border: 1px solid #004FFF;
  backdrop-filter: blur(2px);
  pointer-events: auto;
}
.flexs {
  display: flex;
  align-items: center;
}
.xian {
  width: 3px;
  height: 15px;
  background: #ffbd36;
  margin: 0 5px 0 16px;
}
.sudu {
  width: 203px;
  height: 24px;
  background: rgba(13, 34, 79, 0.65);
  border-radius: 12px;
  border: 1px solid #004FFF;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  padding: 0 20px 0 20px;
  margin: 0 0 0 10px;
}
.num {
  cursor: pointer;
}
.act {
  width: 41px;
  height: 22px;
  background: #ffffff;
  border-radius: 12px;
  color: #000000;
  text-align: center;
  margin: 1px 0 0 0;
}
.fuhao {
  width: 24px;
  height: 24px;
  background: #000000;
  border: 1px solid #004FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 0 0 8px;
}
.xingji {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
}
// 变焦功能
/deep/ .el-slider__runway {
  visibility: hidden;
}
</style>