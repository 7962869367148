<template>
  <div class="w142 h48 mountBox">
    <div v-if="mounts.length > 0" class="list">
      <div class="mount-item pr mt6" v-for="(item, index) in mount" :key="index">
        <div class="icon-box" @click="fn(item)">
          <img class="cp dib w30 h30" :src="item.icon" />
        </div>
      </div>
    </div>
    <template v-if="selected_mount">
      <!-- fixed -->
      <component v-if="modeFlag" v-show="flag" :ref="mode_mount.name" :payload_data='payload_data' :moveType="moveType" :selected_mount="mode_mount" :device="device" :is="mode_mount.component" :title="mode_mount.title"
        @directive="handle_directivev2" @controlMode="controlMode" :taskId="taskId" @take_photo="take_photo" @record="record" @playAudio="playAudio" @showCenter="showCenter" @close="mode_mount = null"
        :PoliceKeyCode="uav_This.uav.control.device.deviceHardId" :payload="payload_data" />
        <component :keyFlag="keyFlag"  :payload_data='payload_data' :uavMounts="mount" :moveType="moveType" :selected_mount="selected_mount" :device="device" :is="selected_mount.component" :title="selected_mount.title"
        @directive="handle_directive" @controlMode="controlMode" @controlCenter="controlCenter" @controlOther="controlOther" @controlStop="controlStop" :taskId="taskId" @take_photo="take_photo" @record="record" @playAudio="playAudio" @showCenter="showCenter" @close="selected_mount = null"
        :PoliceKeyCode="uav_This.uav.control.device.deviceHardId" :payload="payload_data" />
    </template>
  </div>
</template>
<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selected_mount: {},
      moveType: "wrj",
      payload_data:null,
      modeFlag:false,
      mode_mount:{},
      flag: false
    };
  },
  props: {
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      }
    },
    iconShow: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Boolean,
      default: ""
    },
    uavMounts: {
      type: Array,
      default: () => []
    },
    uavMountsPayload: {
      type: Array,
      default: () => []
    },

    mount: {
      type: Array,
      default: () => []
    },
    device: {
      type: Object,
      default: () => ({})
    },
    keyFlag:{
      type:Boolean,
      default: () => false
    }
  },
  inject: ["uav_This"],
    watch: {
    'uav_This.uav.control.mountData.payload': {
      handler(value) {
        this.payload_data= value
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //联控模式start
    controlMode(mode){
      this.modeFlag = mode
      this.mount.forEach(val=>{
        if(val.title != this.selected_mount.title && val.name.includes("Z")){
          console.log(val);
          this.mode_mount = val
        }
      })
    },
    controlCenter(val){
      this.$refs[this.mode_mount.name].handle_change_ptz_mode(val)
    },
    controlOther(type){
      this.$refs[this.mode_mount.name].fangxiang(type)
    },
    controlStop(){
      this.$refs[this.mode_mount.name].stopfxFn()
    },
    //联控模式end
    fn(item) {
      this.selected_mount = item;
      console.log(this.selected_mount,"selected_mount");
      this.$store.commit("fckernel/SET_SELECT_MOUNT", item);
    },
    handle_directive(buffer) {
      let { selected_mount } = this;
      if (selected_mount) {
        if (!this.wsShow) {
          this.$emit(
            "fn",
            {
              type: 514,
              data: {
                mountId: selected_mount.mountId,
                payload: buffer
              }
            },
            "wrj"
          );
        } else {
          let buff = buffer.join(",");
          let data = {
            type: 200,
            systemCode: "mmc",
            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
            username: this.user_info.username,
            height: 0,
            idlingTime: 0,
            data: {
              cmdControlType: 7000, //520
              cmdFunction: null,
              cmdState: null,
              cmdValue: null,
              cmdBuffer: buff,
              cmdName: this.selected_mount.name
            },
            deviceHardId: this.device.deviceHardId
          };
          this.$emit("fun", data);
        }
      }
    },
    handle_directivev2(buffer) {
      let { mode_mount } = this;
      if (mode_mount) {
        if (!this.wsShow) {
          this.$emit(
            "fn",
            {
              type: 514,
              data: {
                mountId: mode_mount.mountId,
                payload: buffer
              }
            },
            "wrj"
          );
        } else {
          let buff = buffer.join(",");
          let data = {
            type: 200,
            systemCode: "mmc",
            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
            username: this.user_info.username,
            height: 0,
            idlingTime: 0,
            data: {
              cmdControlType: 7000, //520
              cmdFunction: null,
              cmdState: null,
              cmdValue: null,
              cmdBuffer: buff,
              cmdName: this.mode_mount.name
            },
            deviceHardId: this.device.deviceHardId
          };
          this.$emit("fun", data);
        }
      }
    },
    showCenter(val){
      this.$emit("showCenter",val)
    },
    async playAudio(val){
      if(this.taskId){
        let typeId = val
        let dataRun = {
          taskId: this.taskId,
          typeId: typeId,
          deviceId: this.device.id
        }
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
      }else{
        // console.log(val);
      }
    },

    async take_photo(data){
      // if(!this.taskId || this.taskId == "") return this.$message.error("暂无任务！")
      if(data.data){
        // data.data.data.taskID = this.taskId
        console.log(data,"photo拍照");
        this.$emit("fn",data)  
        this.$message.success("拍照成功！") 
      }
      if(this.taskId){
        let dataRun = {
          taskId: this.taskId,
          typeId: 2,
          deviceId: this.device.id
        }
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
      }
    },
    async record(data){
      // if(!this.taskId  || this.taskId == "") return this.$message.error("暂无任务！")
      // if(data.data){
      //   data.data.data.taskID = this.taskId
      // }
      if(data.data.data.recordControl || data.data.data.status){
        this.$message.success("开始录像！")
        if(this.taskId){
          let dataRun = {
          taskId: this.taskId,
          typeId: 25,
          deviceId: this.device.id
        }
        let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
        }
      }else{
        this.$message.success("停止录像！")
      }
        console.log(data,"record录像");
      this.$emit("fn",data)
     }
  },
  beforeDestroy() {
    this.$store.commit("device/SET_MOVE_DATA", {});
  },
  computed: {
    ...mapGetters(["user_info"]),
    mounts() {
      let { uavMounts, mount } = this;
      // console.log(uavMounts, "uavMounts");
      // console.log(mount, "mount");
      
      // if (uavMounts && uavMounts.length) {
      //     let mounts = [];
      //     uavMounts.forEach((item) => {
      //         let m = mount.list.find((i) => i.name === item.gimbalName);
      //         if (m) {
      //             m.mountId = item.mountId;
      //             mounts.push(m);
      //         }
      //     });
      //     console.log(mounts);
      return mount;
      // }
      setTimeout(() => {
        this.tips = "暂无挂载";
      }, 5000);
      return [];
    }
  },
  created() {
    this.tips = "正在识别使用中的挂载....";
  },
  mounted(){
  }
};
</script>
<style scoped lang="scss">
.mountBox {
  background: rgba(9, 32, 87, 0.7);
  border: 1px solid #70daf9;
  position: absolute;
  // top: -152px;
  top: -90px;
  right: 20px;

  .list {
    display: flex;
    justify-content: space-around;

    .mount-item {
      width: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #70daf9;
    }
  }
}
</style>
