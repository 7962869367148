<!-- 手动规划 -->
<template>
  <div>
    <div class="right-box mt10">
      <div
        class="before-point"
        :class="{ disabled: action.curr_index <= 0 }"
        @click="handle_before_point"
      >
        上一航点
      </div>
      <div class="no">
        <span class="current">{{ action.curr_index }}</span>
        /
        <span class="total"
          >{{ airline.points.length == 0 ? 0 : airline.points.length }}
        </span>
      </div>
      <div
        class="after-point"
        :class="{
          disabled: action.curr_index >= airline.points.length,
        }"
        @click="handle_after_point"
      >
        下一航点
      </div>
    </div>
    <div class="ctx-box">
      <div class="form-box">
        <el-form ref="form" :model="form" :rules="rules" label-width="96px">
          <el-form-item label="航线名称">
            <el-input :disabled="true" v-model="airline.filename" />
          </el-form-item>
          <el-form-item label="航线速度">
            <div class="item-group">
              <el-input
                oninput="value=value.replace(/[^0-9.]/g,'')"
                v-model="airline.line.baseSpeed"
              />
              <span class="unit">( m/s )</span>
            </div>
          </el-form-item>

          <!-- <el-form-item label="目标位置" prop="destination">
            <div class="item-group" :title="form.destination">
              <el-autocomplete
                :popper-append-to-body="false"
                v-model="destination.name"
                :fetch-suggestions="handle_query_address_async"
                placeholder="请输入目标位置"
                :trigger-on-focus="false"
                @select="handle_select_address"
                clearable
              >
                <template slot-scope="{ item }">
                  <div>
                    <span style="font-size: 14px">{{ item.name }}</span>
                    <span
                      style="font-size: 12px; color: #999; margin-left: 12px"
                      >{{ item.address }}</span
                    >
                  </div>
                </template>
              </el-autocomplete>
              <div @click="handle_pick" class="el-icon-location-outline"></div>
            </div>
          </el-form-item> -->
          <el-form-item label="选择鹰巢" v-if="flag" prop="nestId">
            <el-select v-model="form.nestId" :popper-append-to-body="false">
              <el-option
                v-for="item in getNestListForLineList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="场景" prop="scene">
            <el-select
              v-model="scene"
              :popper-append-to-body="false"
              @change="handle_change"
            >
              <el-option
                v-for="item in scene_list"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="纬度" prop="lat">
            <el-input
              v-model="form.lat"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            />
          </el-form-item>
          <el-form-item label="经度" prop="lon">
            <el-input
              v-model="form.lon"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            />
          </el-form-item>

          <el-form-item label="高度" prop="alt">
            <div class="item-group">
              <el-input
                @change="(val) => handle_change_field('alt', val)"
                v-model.number="form.alt"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              />
              <span class="unit">( m )</span>
            </div>
          </el-form-item>

          <!-- <el-form-item label="偏航角">
            <div class="item-group">
              <el-input-number
                @change="(val) => handle_change_field('pitch', val)"
                v-model="form.pitch"
                :min="-90"
                :max="90"
                label="描述文字"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              ></el-input-number>
              <span class="unit">( ° )</span>
            </div>
          </el-form-item>
          <el-form-item label="俯仰角">
            <div class="item-group">
              <el-input-number
                @change="(val) => handle_change_field('gimbalPitch', val)"
                v-model="form.gimbalPitch"
                :min="-90"
                :max="90"
                label="描述文字"
                oninput.native="value=value.replace(/[^0-9.]/g,'')"
              ></el-input-number>
              <span class="unit">( ° )</span>
            </div>
          </el-form-item> -->
          <!-- <el-form-item label="航线标签" prop="lat">
            <el-select v-model="labvalue" multiple placeholder="请选择航线标签">
              <el-option
                v-for="item in LablistL"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="航点动作" prop="actions">
            <el-link @click="handle_show_action" style="color: #409eff"
              >{{ form.actions.length }}个动作<i
                class="el-icon-d-arrow-right"
              ></i>
            </el-link>
          </el-form-item>
          <div class="disTime ml15 mb16">
            <div class="dis">
              <div class="title">航线总里程</div>
              <div class="fs ml12">{{distances}}m</div>
            </div>
            <div class="dis ml60">
              <div class="title">预计飞行时间</div>
              <div class="fs ml12">{{times.toFixed(2)}}s</div>
            </div>
          </div>
        </el-form>
      </div>
    </div>

    <div class="bottom-box h60">
      <!-- <div class="iconfont icon-zengjia" title="添加"></div> -->
      <div
        @click="handle_save_line"
        class="manual1027Btn tc cp"
        title="保存航线"
      >
        保存
      </div>
      <div
        @click="handle_remove_point"
        class="manual1027BtnDel tc cp"
        title="删除航点"
      >
        删除航点
      </div>
    </div>

    <!-- <el-dialog
      center
      title="新增航点动作"
      :visible.sync="action.visible"
      width="500px"
      top="20em"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="
        action.visible = false;
        action.list = [];
      "
    > -->
    <div class="w500 action-list" v-if="action.visible">
      <div class="header" v-interact>
        <div class="hd-box">航点动作</div>
        <div class="hd-box cp">
          <div
            class="el-icon-plus mr20"
            title="添加"
            @click="typesShow = !typesShow"
          ></div>
          <div
            class="cp mr20"
            @click="
              action.visible = false;
              action.list = [];
            "
          >
            关闭
          </div>
        </div>
      </div>
      <div class="pa w140 types" v-if="typesShow">
        <div
          class="pr5 pl5 mt5 mb5 cp"
          v-for="(item, index) in action.types"
          :key="index"
        >
          <div @click="handle_add_action(item)">{{ item.label }}</div>
        </div>
      </div>
      <div class="actions-box">
        <div class="list-box">
          <div
            class="action-item"
            v-for="(item, index) in action.list"
            :key="index"
          >
            <div class="item-label">{{ item.label }}</div>
            <div class="lsit">
              <div v-if="item.actionType == 'CAMERA_ZOOM'" class="item-input">
                <el-input-number
                  :min="item.min"
                  :max="item.max"
                  v-model="item.param2"
                ></el-input-number
                ><span class="unit">（倍）</span>
              </div>
              <div v-if="item.input && item.input != 'div' && item.actionType != 'CAMERA_ZOOM'" class="item-input">
                <component
                  :is="item.input"
                  :min="item.min"
                  :max="item.max"
                  v-model="item.param1"
                /><span class="unit" v-if="item.actionType != 'CAMERA_ZOOM'">{{
                  item.actionType == "STAY"
                    ? "（秒）"
                    : item.actionType == "TRIGGER"
                    ? "（米）"
                    : "（倍）"
                }}</span>
              </div>
              <div v-if="item.input && item.input == 'div'">
                <div class="ml8">
                  <label for="">
                    云台俯仰
                    <el-input-number
                      placeholder="-90到30"
                      :min="-90"
                      :max="30"
                      v-model="item.param1"
                    ></el-input-number
                    ><span class="unit">(度)</span>
                  </label>
                </div>
                <div class="ml8 mt8">
                  <label for="">
                    云台偏航
                    <el-input-number
                      placeholder="-145到145"
                      :min="-145"
                      :max="145"
                      v-model="item.param3"
                    ></el-input-number
                    ><span class="unit">(度)</span>
                  </label>
                </div>
                <!-- <div class="ml8 mt8">
                  <label for="">
                    云台状态
                    <el-select class="w180" v-model="item.param4">
                      <el-option label="云台回中" :value="0"></el-option>
                      <el-option label="航向锁定" :value="1"></el-option>
                      <el-option label="航向跟随" :value="2"></el-option>
                      <el-option label="下视" :value="3"></el-option>
                    </el-select>
                  </label>
                </div> -->
              </div>
            </div>
            <div
              @click="handle_delete_action(index, item)"
              class="iconfont icon-shanchu"
              title="删除"
            ></div>
          </div>
        </div>
      </div>
      <span class="footer">
        <el-button
          size="small"
          @click="
            action.visible = false;
            action.list = [];
          "
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="handle_save_action"
          >确 定</el-button
        >
      </span>
    </div>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
import API from "@/api";
import { Utils } from "@/lib/cesium";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { isNull } from "@antv/util";

let positions = [];
let handler = null;
let data_srouce = null;
let location_icon = null;

let noFlyEntities = [];
let electricFenceEntities = [];

let point_index = null;
let point_uuid = null;
let point_uuid_next = null;
let isEditting = false;

export default {
  components: {},
  data() {
    let validateLon = (rule, value, callback) => {
      // let reg =
      //   /^[\-\+]?(0?\d{1,2}\.\d{1,5}|1[0-7]?\d{1}\.\d{1,5}|180\.0{1,5})$/;
      if (value < -180 || value > 180) {
        callback(new Error("请输入正确经度值（-180.0-180.0）"));
      } else {
        callback();
      }
    };
    let validateLat = (rule, value, callback) => {
      // let reg = /^[\-\+]?([0-8]?\d{1}\.\d{1,5}|90\.0{1,5})$/;
      if (value < -90 || value > 90) {
        callback(new Error("请输入正确纬度值（-90.0-90.0）"));
      } else {
        callback();
      }
    };
    let validateAlt = (rule, value, callback) => {
      if (value > 150) {
        callback(new Error("航点高度不可超过150m"));
      } else {
        callback();
      }
    };
    let validateNestId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择鹰巢"));
      } else {
        callback();
      }
    };
    return {
      labvalue: [],
      LablistL: [],
      getNestListForLineList: [],
      rules: {
        lat: [{ validator: validateLat, trigger: "change" }],
        lon: [{ validator: validateLon, trigger: "change" }],
        alt: [{ validator: validateAlt, trigger: "change" }],
        nestId: [{ validator: validateNestId, trigger: "change" }],
      },
      time: null,
      visibleFlag: true,
      isGetAddress: false,
      form: {
        lon: 0,
        lat: 0,
        alt: 100,
        pitch: 0, //-180~180
        gimbalPitch: 0, //0~-90
        actions: [],
        nestId: null,
      },
      destination: {
        name: "",
        lng: 0,
        lat: 0,
      },
      airline: {
        filename: null,
        line: {
          // baseSpeed: 10,
          baseSpeed: 6,
        },
        points: [],
      },
      typesShow: false,
      action: {
        visible: false,
        types: [
          {
            label: "无动作",
            actionType: "",
            param1: 1,
          },
          {
            label: "间隔拍照",
            input: "el-input-number",
            actionType: "TRIGGER",
            param1: 1,
          },
          {
            label: "悬停",
            input: "el-input-number",
            min: 0,
            // max: 32000,
            actionType: "STAY",
            param1: 10,
          },
          {
            label: "云台控制",
            input: "div",
            actionType: "GIMBAL_PITCH",
            param2: 0,
            param1: 0,
            param3: 0,
            param4: 2,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            param1: 1,
          },

          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            param1: 1,
          },
          {
            label: "变焦",
            input: "el-input-number",
            min: 0,
            // max: 32000,
            actionType: "CAMERA_ZOOM",
            param1: 2,
            param2: 0,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            param3: 1,
          },
        ],
        list: [],
        curr_index: 0,
      },
      scene: "巡查",
      scene_list: [
        { name: "巡查", id: 1 },
        { name: "预警", id: 2 },
        { name: "侦察", id: 3 },
        { name: "服务", id: 4 },
        { name: "救援", id: 5 },
        { name: "处置", id: 6 },
        { name: "打击", id: 7 },
        { name: "宣传", id: 8 },
      ],
      distances:0,
      times: 0
    };
  },
  props: {
    flag: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    "airline.points" (points, value) {
      console.log("val", points)
      this.distances = points.reduce((total, item, index) => {
        let dist = 0;
        if (points[index + 1]) {
          let cd = Cesium.Cartesian3.distance(
            Utils.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
            Utils.transformWGS842Cartesian({
              lng: points[index + 1].lon,
              lat: points[index + 1].lat,
            })
          );
          // dist = total + cd;
          dist = cd;
        }
        return total + parseInt(dist);
      }, 0);
      this.times = this.distances / this.airline.line.baseSpeed
      console.log(this.distances,"distance");
    },
    deep: true
  },
  async mounted() {
    this.getlable();
    this.init_cesium_handler();
    let filename = "";
      filename = `${
        this.user_info.parentDepName ? this.user_info.parentDepName + "-" : ""
      }${this.user_info.departmentName}-${this.scene}`;
    let res = await API.AIRSPACE.GetFlightLineCount({ flightName: filename });
    // 对res进行判断,有时候会传来对象,但是值是对的,所以手动把值提取出来
    if (typeof res == "object") {
      res = res.data;
    }
    let filename_count = res + 1;
    filename_count =
      filename_count < 10 ? "0" + filename_count : filename_count;
    this.airline.filename = filename + filename_count;
    // this.get_air_space_list();
    // this.get_electric_fence_list();
    // this.get_institutional_fence_list();
    this.getNestListForLine();
  },
  beforeDestroy() {
    this.destroy_cesium_handler();
    this.clear_entities();
  },
  methods: {
    async getlable() {
      this.LablistL = await API.AIRWAY.labelList();
    },
    change_airway(val) {
      this.$emit("change_airway", val);
    },
    async getNestListForLine() {
      this.getNestListForLineList = await API.AIRWAY.getNestListForLine();
    },
    async handle_change() {
      let filename = "";
      if (this.user_info.departmentId == 1) {
        filename = `巡特警支队-${this.scene}`;
      } else {
        filename = `${
          this.user_info.parentDepName ? this.user_info.parentDepName + "-" : ""
        }${this.user_info.departmentName}-${this.scene}`;
      }
      let res = await API.AIRSPACE.GetFlightLineCount({ flightName: filename });
      // 对res进行判断,有时候会传来对象,但是值是对的,所以手动把值提取出来
      if (typeof res == "object") {
        res = res.data;
      }
      let filename_count = res + 1;
      filename_count =
        filename_count < 10 ? "0" + filename_count : filename_count;
      this.airline.filename = filename + filename_count;
    },
    init_point(data) {
      Object.keys(data).map((key) => {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = data[key];
        }
      });
    },

    testEmit(item) {
      this.$emit("add", item);
    },

    reset_form() {
      let alt = this.form.alt;
      let nestId = this.form.nestId;

      this.$refs["form"] && this.$refs["form"].resetFields();
      //继承上一个点的高度
      this.form.alt = alt;
      //继承上一个点的鹰巢id
      this.form.nestId = nestId;
    },
    // 上一航点
    handle_before_point() {
      let points = this.airline.points;
      if (this.action.curr_index - 1 > 0) {
        this.action.curr_index -= 1;
        this.init_point(points[this.action.curr_index - 1]);
      }
    },
    // 下一航线点
    handle_after_point() {
      try {
        let points = this.airline.points;
        if (this.action.curr_index + 1 <= points.length) {
          this.action.curr_index += 1;
          this.init_point(points[this.action.curr_index - 1]);
        }
      } catch (error) {}
    },
    handler_current_point(index) {
      let points = this.airline.points;
      this.action.curr_index = index + 1;
      this.init_point(points[this.action.curr_index - 1]);
    },

    handle_save_point() {
      this.airline.points.push({ ...this.form });
    },
    handle_remove_point() {
      let index = this.action.curr_index - 1;
      let points = this.airline.points;
      let point = points[index];
      if (point) {
        points.splice(index, 1);
        positions.splice(index, 1);
        this.remove_point(point);
        this.remove_label(point);

        /**
         * 如果后面没有点
         */
        let after = points[index];
        if (!after) {
          if (index - 1 < 0) {
            this.action.curr_index = 0;
          } else {
            this.action.curr_index -= 1;
          }
        }

        points.length
          ? this.init_point(points[this.action.curr_index - 1])
          : this.reset_form();
      }
    },

    /**
     * 显示动作弹窗
     */
    handle_show_action() {
      this.action.list = [];
      this.action.list.push(...this.form.actions);
      console.log(this.action.list);
      this.action.visible = true;
    },

    handle_add_action(item) {
      this.action.list.push({ ...item });
    },
    handle_delete_action(index, item) {
      this.action.list.splice(index, 1);
    },

    itemChange(e, item) {
      // console.log(this.form,"this");
      if (e) {
        let data = { ...item };
        delete data.checked;
        delete data.options;
        delete data.label;
        delete data.input;
        delete data.max;
        delete data.min;
        this.form.actions.push(data);
      } else {
        this.form.actions.forEach((val, i) => {
          if ((val.actionType = item.actionType)) {
            this.form.actions.splice(i, 1);
          }
        });
      }
      console.log(this.form);
    },

    /**
     * 关闭动作弹窗
     */
    handle_save_action() {
      this.action.list.forEach((val) => {
        // delete val.input;
        // delete val.label;
        // delete val.min;
        // delete val.max;
        // if (val.actionType == "STAY") {
        //   val.param1 = val.param1 * 1000;
        // }
      });
      this.form.actions = [];
      this.form.actions.push(...this.action.list);
      this.action.visible = false;
      let point = this.airline.points[this.action.curr_index - 1];
      point.actions = [];
      point.actions.push(...this.action.list);
      // console.log(this.form.actions, "this.action.list");
    },

    handle_change_field(key, value) {
      let point = this.airline.points[this.action.curr_index - 1];
      if (point) {
        point[key] = value * 1;
      }
    },

    remove_point(point) {
      data_srouce.entities.removeById(point.uuid);
    },
    create_point(uuid, position) {
      let wgs84 = Utils.transformCartesian2WGS84(position);
      let points = this.airline.points;
      if (data_srouce && this.visibleFlag) {
        let entities = data_srouce.entities;
        entities.add({
          name: "airline_point",
          id: uuid,
          position: Utils.transformWGS842Cartesian({
            lng: wgs84.lng,
            lat: wgs84.lat,
            alt: wgs84.alt + 10,
          }),
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            fillColor: Cesium.Color.RED,
            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
          },
          label: {
            text: new Cesium.CallbackProperty(() => {
              let index = points.findIndex((item) => item.uuid === uuid);
              return String(index + 1);
            }, false),
            scale: 0.5,
            font: "bold 24px Microsoft YaHei",
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: false,
            outlineWidth: 0,
          },
        });
      }
    },

    remove_label(point) {
      data_srouce.entities.removeById(`label_${point.uuid}`);
    },
    create_label(uuid, before, after, alt) {
      if (data_srouce) {
        let entities = data_srouce.entities;
        let before_wgs84 = Utils.transformCartesian2WGS84(before);
        let after_wgs84 = Utils.transformCartesian2WGS84(after);

        let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
        let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
        let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

        let position = Utils.transformWGS842Cartesian({
          lng: center_lng,
          lat: cetner_lat,
          alt: alt + 10,
        });

        let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

        entities.add({
          id: `label_${uuid}`,
          position: position,
          label: {
            text: text,
            scale: 0.5,
            font: "bold 30px Microsoft YaHei",
            fillColor: Cesium.Color.fromCssColorString("#fff"),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: true,
            backgroundPadding: new Cesium.Cartesian2(10, 10),
          },
        });
      }
    },
    update_label(uuid, before, after) {
      let entities = data_srouce.entities;
      let id = `label_${uuid}`;
      let entity = entities.getById(id);

      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });
      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      entity.position = position;
      entity.label.text = text;
    },
    // 延时调用
    debounce(fn, wait) {
      // 问题:卡顿时页面不进行跳转,进而可以存储多次
      // 关闭定时器
      clearTimeout(this.time);
      // 定时器置空
      this.time = null;
      // 启动定时器
      this.time = setTimeout(() => {
        fn.apply(this, arguments);
        // 解决思路:因为程序里有跳转功能,所以可以置空必填项,这样即使卡顿也不会多次存储
        this.destination.name = "";
      }, wait);
    },
    save_line() {
      let sourceType = null;
      let nestId = null;
      if (this.flag) {
        sourceType = 2;
        nestId = this.form.nestId;
      } else {
        sourceType = 1;
      }
      let points = this.airline.points;
      let distance = points.reduce((total, item, index) => {
        let dist = 0;
        if (points[index + 1]) {
          let cd = Cesium.Cartesian3.distance(
            Utils.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
            Utils.transformWGS842Cartesian({
              lng: points[index + 1].lon,
              lat: points[index + 1].lat,
            })
          );
          // dist = total + cd;
          dist = cd;
        }
        return total + parseInt(dist);
      }, 0);
      this.airline.baseSpeed = Number(this.airline.baseSpeed);
      this.airline.points.forEach((val) => {
        val.actions.forEach((val2, i) => {
          console.log(val2,"val2val2");
          delete val2.input;
          delete val2.label;
          delete val2.min;
          delete val2.max;
          if (val2.actionType == "STAY") {
            val2.param1 = val2.param1 * 1000;
          }
          if (!val2.actionType) {
            val.actions.splice(i, 1);
          }
        });
      });
      let waypoints = []
      this.airline.points.forEach(val=>{
        let data = {
          coordinate:{
            latitude: val.lat,
            longitude: val.lon
          },
          altitude: val.alt,
          frame:3,
          speed:this.airline.line.baseSpeed,
          waypointActions: val.actions,
        }
        waypoints.push(data)
      })
      // console.log(waypoints,"waypoints");
      this.airline.waypoints = waypoints

      let options = {
        distance,
        flightCourseJson: JSON.stringify(this.airline),
        flightName: this.airline.filename,
        pointCount: points.length,
        time: parseInt(distance / this.airline.line.baseSpeed),
        destination: this.destination.name,
        lng: this.destination.lng,
        lat: this.destination.lat,
        labelIds: this.labvalue,
        sourceType,
        nestId,
      };
      this.$emit("add", options);
    },
    // 提交表格
    handle_save_line() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // 调用提交
          this.debounce(this.save_line, 1000);
        } else {
          return false;
        }
      });
    },

    async init_cesium_handler() {
      let viewer = window.viewer;
      let currentPoint;
      // 修复规划航线时
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
        positions = [];
      }
      data_srouce = new Cesium.CustomDataSource("airway_edit");

      await viewer.dataSources.add(data_srouce);

      let entities = data_srouce.entities;
      let polyline = entities.add({
        polyline: {
          material: Cesium.Color.RED,
          width: 3,
          positions: new Cesium.CallbackProperty(() => {
            return positions.map((item) => {
              let wgs84 = Utils.transformCartesian2WGS84(item);
              return Utils.transformWGS842Cartesian({
                lng: wgs84.lng,
                lat: wgs84.lat,
                alt: wgs84.alt + 10,
              });
            });
          }, false),
        },
      });

      // viewer.scene.globe.depthTestAgainstTerrain = true;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

      handler.setInputAction(async (movement) => {
        let windowPosition = movement.position;
        let pickedObject = viewer.scene.pick(windowPosition);

        // if (this.isGetAddress) {
        //   let position = Utils.getCartesian3FromPX(viewer, movement.position);
        //   let wgs84 = Utils.transformCartesian2WGS84(position);
        //   console.log(wgs84);
        //   let address = await API.MAP.Regeo({
        //     location: `${wgs84.lng},${wgs84.lat}`,
        //   });

        //   if (address) {
        //     this.destination.name = address;
        //     this.destination.lng = wgs84.lng;
        //     this.destination.lat = wgs84.lat;
        //   } else {
        //     this.$el_message("地址解析失败", () => {}, "error");
        //   }

        //   this.isGetAddress = false;
        //   return;
        // }

        if (
          Cesium.defined(pickedObject) &&
          pickedObject.id?.name === "airline_point"
        ) {

          // 航点编辑
          let entity = pickedObject.id;
          point_uuid = entity.id;
          let points = this.airline.points;
          let index = points.findIndex((item) => item.uuid === entity.id);

          if (index > -1) {
            point_index = index;
            if (index + 1 < points.length) {
              point_uuid_next = points[index + 1].uuid;
            }
            this.handler_current_point(index);
          }

          currentPoint = entity;
          isEditting = true;
          viewer.scene.screenSpaceCameraController.enableRotate = false;
          viewer.scene.screenSpaceCameraController.enableZoom = false;
        }
      }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

      handler.setInputAction(async (movement) => {
        let windowPosition = movement.position;
        let pickedObject = viewer.scene.pick(windowPosition);
        // if (pickedObject) {
        // }

        if (this.isGetAddress) {
          let position = Utils.getCartesian3FromPX(viewer, movement.position);
          let wgs84 = Utils.transformCartesian2WGS84(position);
          let address = await API.MAP.Regeo({
            location: `${wgs84.lng},${wgs84.lat}`,
          });

          if (address) {
            this.destination.name = address;
            this.destination.lng = wgs84.lng;
            this.destination.lat = wgs84.lat;
          } else {
            this.$el_message("地址解析失败", () => {}, "error");
          }

          this.isGetAddress = false;
          return;
        }

        if (
          Cesium.defined(pickedObject) &&
          pickedObject.id?.name === "airline_point"
        ) {
          // 航点编辑
          // let entity = pickedObject.id;
          // point_uuid = entity.id;
          // let points = this.airline.points;
          // let index = points.findIndex((item) => item.uuid === entity.id);
          // if (index > -1) {
          //   point_index = index;
          //   if (index + 1 < points.length) {
          //     point_uuid_next = points[index + 1].uuid;
          //   }
          //   this.handler_current_point(index);
          // }
          // currentPoint = entity;
          // isEditting = true;
          // viewer.scene.screenSpaceCameraController.enableRotate = false;
          // viewer.scene.screenSpaceCameraController.enableZoom = false;
        } else {
          // 航点添加
          let position = Utils.getCartesian3FromPX(viewer, movement.position);
          if (Cesium.defined(position) && this.visibleFlag) {
            this.reset_form();
            if (positions.length + 1) {
              let points = this.airline.points;
              if (this.action.curr_index <= points.length + 1) {
                this.action.curr_index = points.length + 1;
              } else {
                this.action.curr_index += 1;
              }
            }

            positions.push(position);
            let wgs84 = Utils.transformCartesian2WGS84(position);

            this.form.lon = wgs84.lng;
            this.form.lat = wgs84.lat;
            let uuid = (this.form.uuid = nanoid());
            this.handle_save_point();

            if (positions.length > 1) {
              let before = positions[positions.length - 2];
              let after = positions[positions.length - 1];

              this.create_label(uuid, before, after);
            }
            this.create_point(uuid, position);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      // 对鼠标移动事件的监听
      handler.setInputAction((event) => {
        if (isEditting && currentPoint) {
          // let windowPosition = event.startPosition;
          // let ellipsoid = viewer.scene.globe.ellipsoid;
          // let cartesian = viewer.camera.pickEllipsoid(windowPosition, ellipsoid);
          let cartesian = Utils.getCartesian3FromPX(
            viewer,
            event.startPosition
          );

          if (!cartesian) {
            return;
          }
          currentPoint.position = cartesian;

          if (!(typeof point_index == undefined) && positions?.length > 1) {
            positions[point_index] = cartesian;
            let current_wgs84 = Utils.transformCartesian2WGS84(cartesian);
            this.airline.points[point_index].lon = current_wgs84.lng;
            this.airline.points[point_index].lat = current_wgs84.lat;
            if (point_index === this.action.curr_index - 1) {
              this.form.lon = current_wgs84.lng;
              this.form.lat = current_wgs84.lat;
            }

            if (point_index > 0) {
              this.update_label(
                point_uuid,
                cartesian,
                positions[point_index - 1]
              );
            }
            if (point_uuid_next) {
              this.update_label(
                point_uuid_next,
                cartesian,
                positions[point_index + 1]
              );
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // 对鼠标抬起事件的监听
      handler.setInputAction((event) => {
        point_index = null;
        point_uuid_next = null;
        isEditting = false;
        currentPoint = undefined;
        // viewer.render()
        setTimeout(() => {
          viewer.scene.screenSpaceCameraController.enableRotate = true;
          viewer.scene.screenSpaceCameraController.enableZoom = true;
        }, 300);
      }, Cesium.ScreenSpaceEventType.LEFT_UP);
    },

    destroy_cesium_handler() {
      try {
        let viewer = window.viewer;
        viewer.scene.globe.depthTestAgainstTerrain = false;
        if (handler) {
          // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
          handler && handler.destroy() && (handler = null);
          handler = null;
        }

        if (data_srouce) {
          let points = this.airline.points;
          points.forEach(point=>{
            this.remove_point(point);
            this.remove_label(point)
          })
          // data_srouce.entities.removeAll();
          // viewer.dataSources.remove(data_srouce, true);
          data_srouce = null;
          positions = [];
        }
      } catch (error) {}
    },

    handle_pick() {
      this.isGetAddress = true;
    },

    handle_query_address_async(address, cb) {
      API.MAP.Geo({ keywords: address })
        .then((res) => {
          cb(res.data.data.rows);
        })
        .catch((e) => cb([]));
    },

    handle_select_address(item) {
      if (item) {
        let location = item.location.split(",");
        this.destination.name = item.name;
        this.destination.lng = Number(location[0]);
        this.destination.lat = Number(location[1]);

        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: this.destination.lng,
          lat: this.destination.lat,
          alt: 1000,
        });
        viewer.camera.flyTo({
          destination: potiion,
        });
        this.init_location_icon(potiion);
      }
    },

    init_location_icon(position) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      if (location_icon) {
        location_icon.position = position;
      } else {
        location_icon = viewer.entities.add({
          position,
          billboard: {
            width: 36,
            height: 36,
            image: require("@/assets/images/icons/icon_location.png"),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
        });
      }
    },

    destroy_location_icon() {
      if (!location_icon) {
        return location_icon;
      }
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.entities.remove(location_icon);
      location_icon = null;
    },

    async get_air_space_list() {
      let res = await API.AIRSPACE.GetNotFlyAreas();
      res.forEach((item) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (positions.length > 0) {
        }
        let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
        let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
        if (item.regionType === "POLYGON") {
          if (positions.length % 2 !== 0) return;
          if (item.typeId === 1) {
            var entity = viewer.entities.add({
              name: "Red polygon on surface",
              position,
              label: {
                text: item.name,
                font: "14px monospace",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(5, 5),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              },
              polygon: {
                hierarchy,
                material: Cesium.Color.RED.withAlpha(0.5),
                outline: true,
                outlineColor: Cesium.Color.BLACK,
              },
            });
            noFlyEntities.push(entity);
          }
        }
      });
    },
    async get_electric_fence_list() {
      let res = await API.AIRSPACE.GetAreaList();
      res.forEach((item) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (item.regionType === "POLYGON") {
          if (positions.length % 2 !== 0) return;
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (item.typeId === 2) {
            var entity = viewer.entities.add({
              name: "Red polygon on surface",
              position,
              label: {
                text: item.name,
                font: "14px monospace",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(5, 5),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              },
              polygon: {
                hierarchy,
                material: Cesium.Color.GREEN.withAlpha(0.5),
                outline: true,
                outlineColor: Cesium.Color.BLACK,
              },
            });
            electricFenceEntities.push(entity);
          }
        }
      });
    },
    async get_institutional_fence_list() {
      let res = await API.AIRSPACE.GetCurrentAreaList();
      res.forEach((item) => {
        let positions = item.region.split(" ").join(",").split(",");
        if (item.regionType === "POLYGON") {
          if (positions.length % 2 !== 0) return;
          let hierarchy = Cesium.Cartesian3.fromDegreesArray(positions);
          let position = Cesium.BoundingSphere.fromPoints(hierarchy).center;
          if (item.typeId === 2) {
            var entity = viewer.entities.add({
              name: "Red polygon on surface",
              position,
              label: {
                text: item.name,
                font: "14px monospace",
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(5, 5),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              },
              polygon: {
                hierarchy,
                material: Cesium.Color.CYAN.withAlpha(0.5),
                outline: true,
                outlineColor: Cesium.Color.BLACK,
              },
            });
            electricFenceEntities.push(entity);
          }
        }
      });
    },
    clear_entities() {
      noFlyEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      electricFenceEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      noFlyEntities = [];
      electricFenceEntities = [];
    },
    change_close() {
      let viewer = window.viewer;
      viewer.scene.globe.depthTestAgainstTerrain = false;
      this.change_page();
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    cesium_layer() {
      return this.g_cesium_layer();
    },
  },

  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
@import "~@/styles/mixins.scss";
@import "~@/styles/form.scss";

// /deep/.el-scrollbar {
//   width: 355px !important;
// }
// /deep/.el-select-dropdown {
//   /deep/.el-scrollbar {
//     /deep/.el-select-dropdown__wrap {
//       width: 355px !important;
//     }
//   }
// }

.manual1027Btn {
  width: 84px;
  height: 32px;
  line-height: 32px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 5px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  color: #fff;
}
.manual1027BtnDel {
  width: 84px;
  height: 32px;
  line-height: 32px;
  background: #06517d;
  border-radius: 5px;
  border: 1px solid #417595;
  backdrop-filter: blur(1px);
  color: #fff;
}
.active {
  background-color: #1f3bc0;
}
::v-deep {
  .el-select {
    width: 355px;

    .el-input {
      height: 100%;
    }
  }
}

.cpt-command-airway-edit {
  position: absolute;
  left: 100px;
  top: 140px;
  width: 600px;
  height: 770px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate_bg_01.png") no-repeat;
  background-size: 100% 100%;

  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .title-box {
    height: 56px;
    box-sizing: border-box;
    padding-left: 112px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-right: 80px;

    .title {
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #dce9ff;
      font-weight: 400;
      // line-height: 3.2;
      margin-right: 30px;
    }

    .select {
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #00ffff;
      text-align: center;
      font-weight: 400;
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      height: 1px;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        270deg,
        rgba(0, 117, 221, 0) 0%,
        #3ea4d5 50%,
        rgba(0, 117, 221, 0) 100%
      );
    }
  }

  .ctx-box {
    margin-top: 10px;
    // height: 600px;
    height: calc(100% - 136px);
    box-sizing: border-box;
    // overflow: auto;

    .form-box {
      ::v-deep .el-form {
        .divider {
          @include linear_gradient_border(0, auto, 0, 0);
          height: 12px;
        }

        .el-form-item {
          margin-bottom: 16px;
          .unit {
            font-family: MicrosoftYaHeiUI;
            font-size: 18px;
            color: rgb(179, 201, 203);
            font-weight: 400;
            margin-left: 12px;
          }
          .item-group {
            display: flex;
            align-items: center;

            .unit {
              font-family: MicrosoftYaHeiUI;
              font-size: 18px;
              color: rgb(179, 201, 203);
              font-weight: 400;
              margin-left: 12px;
            }

            .iconfont {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }

            .el-icon-location-outline {
              color: rgb(179, 201, 203);
              font-size: 30px;
              margin-left: 0.3em;
              cursor: pointer;
            }
          }

          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #ccedff;
            text-align: right;
            font-weight: 400;
          }

          .el-select-dropdown {
            min-width: 356px !important;
          }

          .el-input {
            width: 356px;
            height: 40px;

            .el-input__inner {
              width: 100%;
              font-family: MicrosoftYaHeiUI;
              font-size: 15px;
              color: #08c2d1;

              background: #02173d;
              border: 1px solid #2e59bc;
            }
          }

          .el-input-number {
            width: 356px;

            .el-input-number__decrease,
            .el-input-number__increase {
              bottom: 1px;
              background: #183574;
              color: #fff;
              border-radius: 0;
              border: none;
            }

            .el-input-number__decrease {
              left: 1px;
            }

            .el-input-number__increase {
            }
          }
        }
        .action-item {
          position: relative;
          .el-form-item {
            .end {
              position: absolute;
              right: 5px;
            }
            .el-form-item__content {
              display: flex;
            }
          }
        }
      }
    }
  }

  .bottom-box {
    box-sizing: border-box;
    // padding-bottom: 20px;
    // height: 88px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include linear_gradient_border(0, auto, 0, 0);

    .iconfont {
      cursor: pointer;
      font-size: 44px;
      color: #08c2d1;
    }
  }

  // 动作列表
  .action-list {
    position: fixed;
    left: 40%;
    top: 30%;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 13px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 44px;
      background: rgba(16, 65, 215, 0.2);
      box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
      border-radius: 10px 10px 0 0;
    }

    .hd-box {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
      margin-left: 20px;
      display: flex;
    }

    .types {
      background: rgba(0, 23, 79, 0.7);
      box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
        inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
      border-radius: 13px;
      top: 10px;
      right: -170px;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      div:hover {
        color: #00ffff;
      }
    }

    .actions-box {
      .title-box {
        display: flex;
        align-items: center;

        .title {
          font-size: 24px;
          width: 120px;
        }
      }

      .list-box {
        max-height: 280px;
        overflow: auto;
        margin-top: 12px;
        padding: 0 20px;

        ::v-deep {
          .el-input-number {
            // margin-left: 180px;

            .el-input {
              width: 180px;
            }
          }
        }

        .action-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #fff;
          box-sizing: border-box;
          padding: 12px;
          margin: 8px 0;

          &:hover {
            background-color: #ccc;
          }

          .item-label {
            white-space: nowrap;
          }
          .lsit {
            display: flex;
            align-items: center;
          }
          .item-input {
            display: flex;
            align-items: center;
            white-space: nowrap;
          }

          .item-inner {
            display: flex;
            align-items: center;
          }

          .iconfont {
            color: red;
            cursor: pointer;
            font-size: 20px;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .right-box {
    display: flex;
    // justify-content: center;
    justify-content: space-around;

    align-items: center;
    text-align: center;
    font-weight: 400;
    // line-height: 3.8;
    margin-top: 10px;

    font-family: PangMenZhengDao;
    font-size: 18px;
    color: #00ffff;
    text-align: right;
    font-weight: 400;

    .before-point {
      margin-right: 12px;
    }

    .before-point,
    .after-point {
      cursor: pointer;
      user-select: none;
      margin-left: 15px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .no {
      margin: 0 10px;
    }
  }
}
.disTime{
  display: flex;
  .dis{
    display: flex;
    .fs{
      color: #00ffff;
    }
    .title{
      color: #fff;
    }
  }
}
</style>

<style lang="scss"></style>
