import API from "@/api"
import { Utils } from "@/lib/cesium";
import Popup from "./popupSingle";


let data_source = null
let uav_data = null
let single = null
class Parser {
    static uav_data_parser(metadata) {
        let data = JSON.parse(metadata)
        return data
    }
}

function tree2list(tree, list = []) {
    tree.forEach(item => {
        if (item.devices && item.devices.length) {
            item.devices.forEach(d => list.push(d))
        }
        if (item.child && item.child.length) {
            let arr = tree2list(item.child)
            arr.forEach(d => list.push(d))
        }
    })
    return list
}

function tree2list_org(tree, list = []) {
    tree.forEach(item => {
        list.push(item)
        if (item.child && item.child.length) {
            // 为了不失去引用，所以写成遍历然后 push
            let arr = tree2list_org(item.child)
            arr.forEach(_item => list.push(_item))
        }
    })
    return list
}

export default {
    //请求无人机控制权限
    async jursdiction() {
        let res = await API.FCKERNEL.checkUavControl({
            deviceHardId: this.uav.NoticeData.deviceHardId
        })
        if (res.code == 200) {
            this.$message.success(res.msg)
            this.uav.NoticeFlag = false
        }else if (res.code == 201) {
            this.$message.warning(res.msg)
            return false
        }
    },


    async init_data_source() {
        data_source = new Cesium.CustomDataSource("FCKERNEL_LAYER")
        await viewer.dataSources.add(data_source)
    },
    destroy_data_source() {
        if (data_source) {
            data_source.entities.removeAll()
            viewer.dataSources.remove(data_source, true)
            data_source = null
        }
    },
    async list_yj(search) {
        /*  if (this.uav.list && this.uav.list.length) {
             this.accident_list_filter(search, 'list')
             return
         } */
        let index = this.$route.query.page;
        let type = ''
        if (index == 0) {
            type = 'patrol'
        } else if (index == 1) {
            type = 'traffic'

        } else if (index == 2) {
            type = 'food'
        } else if (index == 3) {
            type = 'net'
        } else if (index == 4) {
            type = 'water'
        } else if (index == 6) {
            type = 'crimes'
        }
        let list = await API.ACCIDENT.strikeOrganTree({
            type
        })
        try {
            if (list[0].child) {
                list[0].child.sort(function (a, b) {
                    return a.sort - b.sort
                })
            }
        } catch (error) {

        }
        function each(tree, flag) {
            return tree.map((item) => ({
                ...item,
                collapse: flag,
                // display: true,
                devices:
                    item.devices && item.devices.length
                        ? item.devices.map((device) => ({
                            ...device,
                            _location: false,
                            _video: false,
                            _airway: false,
                            _control: false,
                            org_id: item.id,
                            // display: true
                        }))
                        : [],
                child: item.child && item.child.length ? each(item.child, true) : [],
            }));
        }

        if (list && list.length) {
            list = each(list)
        }
        this.uav.list = list || []
    },

    async list(search, show) {
        // let list = await API.FCKERNEL.ListUAV({stationType: 1});
        let list = await API.DEVICE.List({
            ...search,
            cate: 1
        });
        // let list = require("./uavListData").default.data;
        // if (list && list.length && list[0].child) {
        //     list[0].child.sort(function (a, b) {
        //         return a.sort - b.sort
        //     })
        // }
        console.log(list, "listlist");

        function each(tree, flag) {
            if (search.search) {
                flag = show
            }
            return tree.map((item) => {
                return {
                    ...item,
                    collapse: flag,
                    devices: item.devices && item.devices.length ?
                        item.devices.map((device) => {
                            return {
                                ...device,
                                _location: false,
                                _video: false,
                                _airway: false,
                                _control: false,
                                org_id: item.id,
                                // reportStatus: 1
                            };
                        }) :
                        [],
                    child: item.child && item.child.length ? each(item.child, true) : [],
                }
            });
        }

        if (list && list.length) {
            list = each(list)
        }
        this.uav.list = list || []
    },

    subscribe(topic, callbck) {
        this.mqtt_subscribe(topic, callbck)
    },
    init_ws() {
        let {
            user_info
        } = this
        let _this = this
        let username = user_info.username
        let token = user_info["mmc-identity"] + user_info["FLYINGSESSIONID"]
        let appId = user_info.appid
        let ws_url = process.env.VUE_APP_WS_URL
        let ws = this.uav.ws = new WebSocket(ws_url);

        ws.onopen = () => {
            ws.send(
                JSON.stringify({
                    type: 100,
                    systemCode: "mmc",
                    state: 1,
                    username,
                    token,
                    appId,
                })
            );
        };
        ws.onmessage = (e) => {
            // 鹰视监听禁飞区
            let metadata = JSON.parse(e.data)

            if (metadata.msgnum === 4600) {

            }
            if (metadata && metadata.msgnum === 4120) {
                _this.$el_message('admin账号控制中，请稍后控制！', () => { }, 'error')
            }
            if (metadata && metadata.type === 300) {
                // _this.$el_message('执行成功！')
            }
            // console.log(metadata, "meta_ws");
            if (metadata && metadata.type === 300 && metadata.cmdFunction == 9000) {
                _this.$el_message('航线上传成功！')
                // if (this.takeOff == true) {
                this.fly_take_off()
                // }
            }
            if (metadata && metadata.type === 300 && metadata.cmdFunction == 9001) {
                let deviceHardId = this?.uav?.control?.device?.deviceHardId
                if (deviceHardId == metadata.deviceHardId) {
                    // console.log(metadata,"sdsadsad");
                    this.level = metadata.data.RSRPLEVEL
                }
                // this.uav.airway = metadata
                // let {
                //     data: {
                //         points
                //     }
                // } = this.uav.airway
                // this.uav_handleAirLinePreview(points)
            }
            // 监听无人机上锁
            if (metadata && metadata.type === 810) {
                let {
                    uav: {
                        control
                    }
                } = this

                let deviceHardId = control.deviceHardId
                if (deviceHardId == metadata.deviceHardId) {
                    // this.uav.taskStatus = true
                }
            }
            // // 视频推流状态更新 弃用
            // if (metadata && metadata.type === 999) {
            //     if (metadata.pushStatus) {
            //         this.uav_update_device_video_status(metadata.pushStatus, metadata.deviceHardId)
            //     }
            // }

            if (metadata && metadata.msgnum) {
                // 设备上线
                if (metadata.msgnum === 4132) {
                    let deviceHardId = metadata && metadata.deviceHardId;
                    this.uav_update_device_report_status(1, deviceHardId, 'list')
                    this.uav_update_device_report_status(1, deviceHardId, 'mountList')
                }
                // 设备离线
                if (metadata.msgnum === 4121) {
                    let deviceHardId = metadata && metadata.deviceHardId;
                    this.uav_update_device_report_status(0, deviceHardId, 'list')
                    this.uav_update_device_report_status(0, deviceHardId, 'mountList')
                }
            } else {
                // 无人机上报数据
                if (metadata && metadata.deviceData) {

                    uav_data = JSON.parse(metadata.deviceData)
                    // if("00000_d80a8f" == uav_data.deviceHardId ){
                    // console.log(uav_data.data.uavInfo);
                    // }
                    //模拟飞机上升
                    // uav_data.data.uavInfo.altitude += (height += 0.1);
                    if (uav_data && uav_data.deviceHardId) {
                        if (uav_data.data && uav_data.data.uavInfo) {
                            if (this?.uav?.control?.device?.deviceHardId == uav_data.deviceHardId && this.wsShow) {
                                this.uav_update_control(this.uav.control.device, uav_data.data.uavInfo)
                                this.uav_update_mounts(this.uav.control.device, uav_data.data.mountInfo);
                                this.uav_update_control_battery(this.uav.control.device, uav_data.data.uavInfo);
                                // this.uav_update_control(uav_data)
                                if (uav_data.data.uavInfo.push_status) {
                                    let push_status = uav_data.data.uavInfo.push_status
                                    // 视频推流数据刷新
                                    // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'mountList')
                                    // this.uav_update_device_video_status(push_status, uav_data.deviceHardId, 'list')
                                }

                                if (this.wsShow) {
                                    this.uav_update_locations(uav_data);
                                }
                            }
                        }

                    }
                }
                if (metadata && metadata.type === 800) {
                    // this.uav.imgUrl = metadata.url
                    // this.uav.imgVisible = true
                }
            }
        };

        ws.onerror = (error) => {
            // console.log("error", error);
        };

        ws.onclose = (data) => { };
    },
    async control_ws(cate) {
        let deviceHardId = this.uav.control.device.deviceHardId
        let username = this.user_info.username
        if (cate == 11) {
            // 安全降落
            this.$confirm('请确认是否执行安全降落操作?', '安全确认', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                customClass: "uav_controlPane",
                showClose: false
            }).then(() => {
                this.uav_send_ws({
                    type: 200,
                    systemCode: 'mmc',
                    state: 1,
                    username,
                    data: {
                        cmdFunction: 2116,
                    },
                    deviceHardId,
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        } else if (cate == 12) {
            // 一键返航
            this.$confirm('请确认是否进行一键返航操作?', '返航确认', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                customClass: "uav_controlPane",
                showClose: false
            }).then(() => {
                this.uav_send_ws({
                    type: 200,
                    systemCode: 'mmc',
                    state: 1,
                    username,
                    data: {
                        cmdFunction: 2112,
                    },
                    deviceHardId,
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });
            });
        } else if (cate == 13) {
            // 航线模式
            this.uav_send_ws({
                type: 200,
                systemCode: 'mmc',
                state: 1,
                username,
                data: {
                    cmdFunction: 2115,
                },
                deviceHardId,
            })

        } else if (cate == 17) {
            // 暂停飞行
            this.uav_send_ws({
                type: 200,
                systemCode: 'mmc',
                state: 1,
                username,
                data: {
                    cmdFunction: 2117,
                },
                deviceHardId,
            })
        }
    },

    send_ws(data) {
        console.log(data, "send_ws");
        this.uav.ws && this.uav.ws.send(JSON.stringify(data))
    },
    async fn(cate, device, orgName) {
        this.uavId = device
        // if (device.reportStatus !== 1) {
        //     this.$message('无人机不在线');
        //     return
        // }
        if (!data_source) {
            await this.uav_init_data_source()
        }
        this.controlMenuFlag = !this.controlMenuFlag
        // 将列表缩进左边
        this.collapseFlagfn()
        let uavCate = device.cateName == 'px4' || 'PX4' ? 'PX4' : 'APM'
        let topic = `${uavCate}/RECEIVE/${device.deviceHardId}`
        // 气体检测

        if (cate === 1) {
            // 定位
            device._location = !device._location
            if (device._location) {
                this.uav_show_location(device)
            } else {
                this.uav_hide_location(device)
            }
        } else if (cate === 2) {
            // 航线
            device._airway = !device._airway
            if (device._airway) {
                this.uav_show_airway(device)
            } else {
                this.uav_hide_airway(device)
            }
        } else if (cate === 3) {
            // 视频
            device._video = !device._video
            if (device._video) {
                device._video = true
                this.uav_show_video(device, orgName)
                this.VideoTomapFlag = true
                if (device.stationType == 1) {
                    this.wsShow = false
                    this.uav_subscribe(topic, () => {
                        console.log(`订阅无人机数据：topic:${topic}`);
                    })




                } else {
                    if (this.mqtt.client) {
                        this.mqtt.client.end();
                        this.mqtt.client = null;
                    }
                    this.wsShow = true
                    this.uav_send_ws({
                        type: 1126,
                        deviceHardId: device.deviceHardId
                    })
                }
                if (this.$store.state.fckernel.taskId) {
                    let dataRun = {
                        taskId: this.$store.state.fckernel.taskId,
                        typeId: 4,
                        deviceId: device.id
                    }
                    let run = API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
                }
            } else {
                this.VideoTomapFlag = false
                device._video = false
                this.uav_hide_video(device)
            }
        } else if (cate === 4) {
            // let list = await API.FCKERNEL.ListUAV()
            // 控制面板
            // 清理地图上旧数据
            this.uav_hide_control()
            this.uav_hide_location_all();
            this.uav_hide_airways_all();
            device._control = !device._control

            this.healthWaring = []
            this.healthData = {}
            this.$emit("healthWaringShow", this.healthWaring, false)
            this.controlMenuFlag = true
            if (device._control) {
                device._video = true
                this.uav_show_video(device, orgName)
                this.uav_show_control(device)
                this.ControlFlag = true
                // this.flag = false
                this.VideoTomapFlag = true
                // 打开 任务面板
                if (device.stationType == 1) {
                    console.log("该无人机连接mqtt");
                    this.wsShow = false
                    this.uav_subscribe(topic, () => {
                        console.log(`订阅无人机数据：topic:${topic}`);
                    })
                    let GAStopic = `GAS/RECEIVE/${device.deviceHardId}`
                    this.$store.commit("device/SET_GASTOPIC", GAStopic);
                } else {
                    console.log("该无人机连接地面站");
                    if (this.mqtt.client) {
                        this.mqtt.client.end();
                        this.mqtt.client = null;
                    }
                    this.wsShow = true
                    this.uav_send_ws({
                        type: 1126,
                        deviceHardId: device.deviceHardId
                    })
                }
                device._location = false;
                this.linevisible = false;
                //延时加载，避免显示航线有影响
                setTimeout(() => {
                    this.uav_show_location(device);
                }, 2000);
                // this.collapseFlag = true
            } else {
                this.healthData = {}
                this.controlMenuFlag = false
                this.VideoTomapFlag = false
                // if (this.mqtt.client) {
                //     this.mqtt.client.end()
                //     this.mqtt.client = null
                // }
                // // 取消订阅
                device._video = false
                this.ControlFlag = false
                // this.mqtt_unsubscribe(topic)
                this.uav_hide_control(device)
                this.uav_hide_video(device)
            }

        } else if (cate === 5) {
            // 查看是否有接管权限
            let res = await API.FCKERNEL.getTakeOverInfo({
                deviceHardId: device.deviceHardId
            })
            if (res.code == 201) {
                this.$message.warning(res.msg)
                return false
            } else if (res.code == 200) {
                let res = await API.FCKERNEL.checkUseOperate({
                    deviceHardId: device.deviceHardId
                })
                if (res.code == 201) {
                    let text = `是否请求接管 ${orgName} 下的 ${device.deviceName}`
                    this.uav.NoticeData.deviceHardId = device.deviceHardId
                    this.uav.NoticeData.content = text
                    this.uav.NoticeFlag = true
                } else if (res.code == 200) {
                    return this.$message.warning(res.msg);
                }

            }

        }
    },
    trans_location_data(device, uav_data) {
        if (!uav_data) {
            return uav_data
        }

        let uavInfo = uav_data.uavInfo
        let position = null;
        let positions = [];
        let orientation = null
        try {
            position = Cesium.Cartesian3.fromDegrees(
                uavInfo.longitude * 1,
                uavInfo.latitude * 1,
                (uavInfo.altitude * 1)
            );
            let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
                pitch = Cesium.Math.toRadians(uavInfo.pitch),
                roll = Cesium.Math.toRadians(uavInfo.roll),
                hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll)
            orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );


            if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
                positions.push(position);
            }
        } catch (e) {
            this.$message({
                type: 'warning',
                message: '定位失败'
            })
        }

        return {
            position,
            positions,
            orientation,
            device
        };
    },
    trans_location_datas(device, uav_data) {
        if (!uav_data) {
            return uav_data
        }

        let uavInfo = uav_data.data.uavInfo
        uavInfo.altitude += 10;
        let position = Cesium.Cartesian3.fromDegrees(
            uavInfo.longitude * 1,
            uavInfo.latitude * 1,
            (uavInfo.altitude * 1)
        );
        let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
            pitch = Cesium.Math.toRadians(uavInfo.pitch),
            roll = Cesium.Math.toRadians(uavInfo.roll),
            hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
            orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );

        let positions = [];
        if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
            positions.push(position);
        }

        return {
            position,
            positions,
            orientation,
            device
        };
    },


    trans_mqtt_location_data(device, uav_data) {
        if (!uav_data || !uav_data.locationCoordinate3D) {
            return uav_data;
        }
        // 获取航线
        let location = uav_data.locationCoordinate3D
        if (!location) {
            return;
        }

        let position = Cesium.Cartesian3.fromDegrees(
            location.longitude * 1,
            location.latitude * 1,
            location.altitude * 1
        );

        let positions = [];
        let attitude = uav_data.attitude
        let yaw = Cesium.Math.toRadians(attitude.yaw + 90),
            pitch = Cesium.Math.toRadians(attitude.pitch),
            roll = Cesium.Math.toRadians(attitude.roll),
            hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
            orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );
        if (this.uav.model) {
            let model = this.uav.model
            const curPos = model.position.getValue(
                Cesium.JulianDate.now()
            );
            if (curPos) {
                const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z);
                const afterCart = new Cesium.Cartesian3(position.x, position.y, position.z);
                if (curCart.equals(afterCart)) { } else {
                    // 航线数据
                    positions.push(position);
                }
            } else { }
        }

        return {
            position,
            positions,
            orientation,
            device
        };
    },
    Cartesian3_to_WGS84(point) {
        var cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z);
        var cartographic = Cesium.Cartographic.fromCartesian(cartesian33);
        var lat = Cesium.Math.toDegrees(cartographic.latitude);
        var lng = Cesium.Math.toDegrees(cartographic.longitude);
        var alt = cartographic.height;
        return {
            lng: lng,
            lat: lat,
            alt: alt
        };

    },
    // 鹰视  航线 高度 加10
    async show_location(device, e) {
        if (e) {
            console.log(e, device, "show_location_eeeee");
            this.linevisible = false
            this.uav_remove_model(device)
            this.uav_hide_airways(device);
        } else {
            if (!this.wsShow) {
                if (!this.linevisible) {
                    this.linevisible = true
                    viewer.trackedEntity = null
                    let uav_data = await API.FCKERNEL.GetLastUavData({
                        deviceHardId: device.deviceHardId
                    })
                    if (!uav_data || uav_data.latitude == '' || uav_data.longitude == '') {
                        this.$el_message("暂未找到位置信息", () => { }, "error")
                        return uav_data
                    }
                    if (!data_source) {
                        await this.uav_init_data_source()
                    }
                    let entities = data_source.entities
                    let cesium_layer = this.g_cesium_layer()
                    try {
                        //由于3D地图悬浮于2D地图表面，需要增加无人机模型的海拔高度，不然会被3D地图遮挡
                        uav_data.uavInfo.altitude += 10;
                    } catch (e) { }
                    // console.log('海拔高度增加10米', uav_data.uavInfo.altitude);
                    let data = this.uav_trans_location_data(device, uav_data)
                    let deviceHardId = device.deviceHardId
                    let model = entities.getById(`fckernel_model_${deviceHardId}`);
                    this.uav.online[deviceHardId] = data

                    if (!model) {
                        this.uav.online[deviceHardId].entity_id = `fckernel_model_${deviceHardId}`
                        if (device.goodsName == "小旋风") {
                            model = this.uav.model  = cesium_layer.add_model_X85(entities,this.uav.online[deviceHardId]);
                        } else {
                            model = this.uav.model = cesium_layer.add_model(entities, this.uav.online[deviceHardId])
                        }
                        // console.log(this.uav.online[deviceHardId],"this.uav.online[deviceHardId]");

                        //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
                        var ellipsoid = viewer.scene.globe.ellipsoid;
                        var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
                        var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
                        var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
                        var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);

                        viewer.camera.flyTo({
                            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000), //model.position.getValue(Cesium.JulianDate.now()),
                            maximumHeight: 100,
                            complete: () => {
                                viewer.trackedEntity = model
                            }
                        })
                        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000)

                        this.uav.model = model
                    }

                    let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
                    if (!polyline) {
                        let model = cesium_layer.add_polyline(entities, {
                            ...data,
                            entity_id: `fckernel_polyline_${deviceHardId}`,
                            options: {
                                show: true
                            }
                        })
                    }
                } else {
                    this.linevisible = false
                    this.uav_remove_model(device)
                }
            } else {
                if (!this.linevisible) {
                    this.linevisible = true
                    let uav_data = Parser.uav_data_parser(device.latestData)
                    this.uav_show_airways(device, uav_data)
                    let item = this.uav.online[device.deviceHardId];
                    let entity = viewer.entities.getById(item.entity_id);
                    entity.show = true
                } else {
                    this.linevisible = false
                    // this.uav_hide_location_all(device, uav_data)
                    this.uav_hide_airways(device);
                }
            }
        }
    },
    hide_location_all() {
        let cesium_layer = this.g_cesium_layer()
        let viewer = cesium_layer.viewer()
        viewer.trackedEntity = null
        Object.keys(this.uav.online).forEach(key => {
            let item = this.uav.online[key];
            if (data_source) {
                let entities = data_source.entities
                entities.removeById(item.entity_id)
            }
            //   let entity = viewer.entities.getById(item.entity_id);
            // item.positions = [];
            // viewer.entities.remove(entities);
            //   entity.show = false;
        })
        // this.uav.online = [];

    },
    show_airways(device, uav_data) {
        // console.log(device,uav_data);
        let entities = window.viewer.entities
        if (!device._location) {
            device._location = true
            this.uav_show_locations(device, uav_data)
        }
        let entity = entities.getById(`observe_polylineUav_${device.deviceHardId}`)
        /* let entity = entities.getById(
          this.uav.online[device.deviceHardId].entity_id
        ); */
        entity && (entity.polyline.show = true)
    },
    hide_airways(device) {
        let entity = viewer.entities.getById(
            `observe_polylineUav_${device.deviceHardId}`
        );
        /* let entity = entities.getById(
                  this.uav.online[device.deviceHardId].entity_id
                ); */
        entity && (entity.polyline.show = false);
    },
    hide_airways_all() {
        Object.keys(this.uav.online).forEach(key => {
            console.log(key, "key");
            let entity = viewer.entities.getById(`observe_polylineUav_${key}`);
            let entity2 = viewer.entities.getById(`fckernel_polyline_${key}`);

            if (entity) {
                entity.polyline.show = false;
            }
            if (entity2) {
                // entity2.polyline.show = false;
                window.viewer.entities.removeById(`fckernel_polyline_${key}`)
            }
        });
    },
    show_locations(device, uav_data) {
        // if (!data_source) {
        //     return false
        // }
        if (!uav_data) {
            // this.$el_message("暂未找到位置信息", () => { }, "error")
            return uav_data
        }

        // let devices = tree2list(this.uav.list)
        // devices.map(item => item.deviceHardId != device.deviceHardId && (item._location = false))
        let {
            deviceHardId
        } = device
        let cesium_layer = this.g_cesium_layer()
        let viewer = window.viewer
        let data = this.uav_trans_location_datas(device, uav_data)
        let model = window.viewer.entities.getById(`observe_model_${deviceHardId}`)
        if (!model) {
            this.uav.online[deviceHardId] = data
            this.uav.online[deviceHardId].entity_id = `observe_model_${data.device.deviceHardId}`
            model = cesium_layer.add_model(window.viewer.entities, this.uav.online[deviceHardId])
        }


        let polyline = window.viewer.entities.getById(`observe_polylineUav_${deviceHardId}`)
        if (!polyline) {
            cesium_layer.add_polyline(window.viewer.entities, {
                ...data,
                entity_id: `observe_polylineUav_${data.device.deviceHardId}`,
                options: {
                    show: false
                }
            })
        }

        viewer.camera.flyTo({
            destination: model.position.getValue(Cesium.JulianDate.now()),
            maximumHeight: 10,
            complete: () => {
                viewer.trackedEntity = model;
            }
        })
        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000)
    },
    remove_model(device) {
        // 删除飞控 无人机 模型航线
        if (data_source) {
            let entities = data_source.entities
            entities.removeById(`fckernel_model_${device.deviceHardId}`)
        }
        // this.linevisible = false
        this.uav_hide_airway(device)
    },
    hide_location(device, uav_data) {
        viewer.trackedEntity = null
    },
    update_location(device, uav_data) {
        // console.log(this.lineInfo);
        // && this.lineInfo.points && this.lineInfo.points[0].ailist

        let online = this.uav.online[device.deviceHardId]
        if (online) {
            let data = this.uav_trans_mqtt_location_data({
                device
            }, uav_data)
            if (data.position) {
                online.orientation = data.orientation;
                online.position = data.position;
                if (data.positions.length > 0) {
                    online.positions.push(data.position);
                }
            }
            let flightMode = this.$store.state.shoudong.Mstate

            if (this.aiPopup && flightMode == '航线模式') {
                this.aiPopup.removeAll()
                let scene = viewer.scene
                this.aiPopup = new Cesium.BillboardCollection({
                    scene,
                    show: true,
                });
                this.aiPopup.add({
                    position: Utils.transformWGS842Cartesian({
                        lng: Number(uav_data.locationCoordinate3D.longitude),
                        lat: Number(uav_data.locationCoordinate3D.latitude),
                        alt: Number(uav_data.locationCoordinate3D.altitude),
                    }),
                    image: require("@/assets/images/factor/ai/aiing.png"),
                    scale: 1,
                    width: 54,
                    height: 89,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                    id: 258,//字444444
                    distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                        0,
                        2.2e5
                    ),
                    scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                });
                scene.primitives.add(this.aiPopup);
            }
        }
    },
    update_locations(uav_data) {
        let online = this.uav.online[uav_data.deviceHardId]
        // this.uav.datalist = uav_data.data
        if (online) {
            let data = this.uav_trans_location_datas({}, uav_data)
            online.orientation = data.orientation
            online.position = data.position
            online.positions.push(online.position)
        }
    },
    async show_airway(device) {
        let polyline = entities.getById(`fckernel_polyline_${deviceHardId}`)
        if (!polyline) {
            cesium_layer.add_polyline(entities, {
                ...data,
                entity_id: `fckernel_polyline_${data.device.deviceHardId}`,
                options: {
                    show: true
                }
            })
        }
    },
    hide_airway(device) {
        console.log("cesium error look at!");
        if (!data_source) {
            return false
        }

        let entities = data_source.entities
        let entity = entities.getById(`fckernel_polyline_${device.deviceHardId}`)
        entity && (entity.polyline.show = false)
        if (device.deviceHardId) {
            try {
                // entity2.polyline.show = false;
                // 删除飞机模型
                window.viewer.entities.removeById(`fckernel_polyline_${device.deviceHardId}`)
            } catch (error) { }
            // if(this.aiPopup){
            //     this.aiPopup.removeAll()
            // }
        }
    },

    // 显示视频
    async show_video(device, orgName) {
        // 先清空所有无人机控制的状态
        try {
            let device_list = tree2list(this.uav.list)
            device_list.forEach(item => {
                if ((item.deviceHardId !== device.deviceHardId) && item._control) {
                    item._video = false
                    this.uav_hide_control(item)
                }
            })

            this.$set(this.uav.control, "device", device)
        } catch (error) {

        }
        // onLine
        let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({
            deviceHardId: device.deviceHardId
        })
        if (!data.list) {
            device._video = false;
            return this.$message.info('暂无视频')
        } else {
            let index = this.uav.videos.findIndex(item => item === undefined)
            if (index !== -1) {
                this.uav.videos.splice(index, 1, {
                    ...device,
                    orgName,
                    videos: data,
                })
            } else {
                if (this.uav.videos.length < 4) {
                    this.uav.videos = [{
                        ...device,
                        orgName,
                        videos: data
                    }]
                    // this.uav.videos.push({
                    //     ...device,
                    //     orgName,
                    //     videos: data,
                    // })
                } else {
                    this.uav_hide_video(this.uav.videos[0])
                    this.uav_show_video(device, uav_data)
                }
            }
        }

    },
    hide_video(device) {

        let {
            deviceHardId
        } = device
        let index = this.uav.videos.findIndex(item => item && (item.deviceHardId === deviceHardId))

        let devices = tree2list(this.uav.list)
        device = devices.find(item => item.deviceHardId === deviceHardId)

        if (index !== -1) {
            device._video = false
            this.uav.videos.splice(index, 1)
        }
    },

    show_control(device) {
        // 先清空所有无人机控制的状态
        let device_list = tree2list(this.uav.list)
        device_list.forEach(item => {
            if ((item.deviceHardId !== device.deviceHardId) && item._control) {
                item._control = false
                this.uav_hide_control(item)
            }
        })

        this.$set(this.uav.control, "device", device)

    },
    hide_control(device) {
        this.uav.control.device = null
        this.uav.control.device = null
        this.uav.control.data = null
        this.uav.control.battery = null
        this.uav.control.mount = null
        this.uav.control.mounts = []
        this.uav.control.mounts = []

    },
    update_control(device, uav_data) {
        let control_device = this.uav.control.device
        if (control_device && control_device.deviceHardId === device.deviceHardId) {
            this.$set(this.uav.control, "data", uav_data)
        }
    },
    update_mountData(device, uav_data) {
        let control_device = this.uav.control.device
        if (control_device && control_device.deviceHardId === device.deviceHardId) {
            this.$set(this.uav.control, "mountData", uav_data)
        }
    },
    update_control_battery(device, battery_data) {
        let control_device = this.uav.control.device
        if (control_device && control_device.deviceHardId === device.deviceHardId) {
            this.$set(this.uav.control, "battery", battery_data)
        }
    },
    update_channelData(device, channelData) {
        // if (this.uav.control.device.deviceHardId === device.deviceHardId) {
        this.$set(this.uav.control, "channelData", channelData)
        // }
    },
    // 无人机提示信息
    update_control_info(device, battery_data) {
        let control_device = this.uav.control.device
        if (control_device && control_device.deviceHardId === device.deviceHardId) {
            this.uav.control.info.push(battery_data)
        }
    },
    update_mount(device, data) {
        let control_device = this.uav.control.device
        if (control_device && control_device.deviceHardId === device.deviceHardId) {
            let mount = this.mount.list.find(item => item.name === data.gimbalName)
            if (mount) {
                let find_item = this.uav.control.mounts.find(item => item.name === data.gimbalName)
                if (!find_item) {
                    this.uav.control.mounts.push({
                        ...data,
                        ...mount
                    })

                }
            }
        }
    },
    update_mounts(device, data) {
        this.uav.control.mounts = data
        this.mount.list.forEach((item, i) => {
            this.uav.control.mounts.forEach((val, j) => {
                if (val.mountName === item.name) {
                    val.name = item.name
                    val.icon = item.icon
                    val.component = item.component
                }
            })
        })
    },
    change_mount(mount) {
        this.$set(this.uav.control, "mount", mount)
    },
    mount_directive(data) {
        let mount = this.uav.control.mount

        if (mount) {
            this.uav_mqtt_fn({
                type: 514,
                data: {
                    mountId: mount.mountId,
                    payload: data
                }
            })
        }
    },
    move_directive(data) {
        if (data.mountId && data.data) {
            if (this.wsShow) {
                let sendData = {
                    "type": 200,
                    "systemCode": "mmc",
                    "state": 1,
                    "username": this.user_info && this.user_info.username,
                    "height": 0,
                    "idlingTime": 0,
                    "data": {
                        "cmdControlType": 7000,
                        "cmdFunction": null,
                        "cmdState": null,
                        "cmdValue": null,
                        "cmdBuffer": data.data.join(','),
                        "cmdName": data.name
                    },
                    "deviceHardId": data.deviceHardId
                }
                this.uav_send_ws(sendData)
            } else {
                this.uav_mqtt_fn({
                    type: 514,
                    data: {
                        mountId: data.mountId,
                        payload: data.data
                    }
                })
            }

        }
    },

    /**
     * 切换无人机模式
     * { type: 513, data: mode }
     * @param {*} data 
     */
    change_mode(mode) {
        this.uav_mqtt_fn({
            type: 513,
            data: mode.data
        })
    },

    update_org_status(cate, id) {
        let list = tree2list_org(this.uav.list)
        let org_id = id
        while (org_id) {
            let org = list.find(x => x.id === org_id)
            if (org) {
                if (cate === 1) {
                    org.offline -= 1;
                    org.online += 1;
                }
                if (cate === 0) {
                    org.offline += 1;
                    org.online -= 1;
                }
                org_id = org.pid
            } else {
                org_id = null
            }
        }
    },

    /**
     * cate { -1: 离线, 1: 上线 }
     * @param {*} cate 
     * @param {*} deviceHardId 
     */
    update_device_report_status(cate, deviceHardId) {
        let devices = tree2list(this.uav.list)
        let did = this.uav.control.device && this.uav.control.device.deviceHardId
        devices.map(item => {
            if (item.deviceHardId == deviceHardId) {
                if (item.reportStatus !== cate) {
                    item.reportStatus = cate
                    if (cate === 1) {
                        this.uav_update_org_status(1, item.org_id)
                    } else if (cate == 0) {
                        this.uav_update_org_status(0, item.org_id)
                        item._control = false
                        // // 视频推流状态关闭
                        // item._videoStatus = 0
                        if (did && (did == deviceHardId)) {
                            this.uav.control = {}
                        }
                    }
                }
            }
        })
    },

    /**
     * 无人机 mqtt 发送指令
     * @param {*} data 
     */
    mqtt_fn(data, type, device = this.uav.control.device) {
        if (device) {
            let uavCate = device.cateName == 'px4' ? 'PX4' : 'APM'
            // let uavCate = device.cateName == 'PX4'
            let topic = `${uavCate}/OBTAIN/${device.deviceHardId}`
            try {
                window.$log('mqtt指令---------', topic, '----------', data);
            } catch (e) { }
            this.mqtt_publish(topic, data, e => {
                console.log('发送指令', data, 'topic', topic);
            })
        }
    },
    async getSingle() {
        if (this.singleList.length > 0 && single) {
            this.singleList = []
            viewer.dataSources.remove(single, true);
            single = null
        } else {
            let move_id_arr = []
            let data = {
                pageSize: 100,
                pageNo: 1
            }
            let res = await API.FCKERNEL.singleList(data)
            res.data.forEach((v) => {
                if (!v.deleted) {
                    this.singleList.push(v)
                }
            })
            single = new Cesium.CustomDataSource("single");
            this.singleList.forEach(async (item) => {
                let address = await API.MAP.AiRegeo({
                    location: `${item.lon},${item.lat}`
                })
                //   address.province.value + address.city.value + address.dist.value + address.road.roadname +
                this.$set(item, "addr", address.poi)
                single.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Number(item.lon), Number(item.lat)),
                    ellipse: {
                        semiMajorAxis: 6,
                        semiMinorAxis: 6,
                        material: Cesium.Color.fromCssColorString(item.signalStrength > -115 && item.signalStrength < -110 ? 'yellow' : 'red').withAlpha(0.3)
                        //   material: Cesium.Color.fromCssColorString('red').withAlpha(0.3)
                        /* item.typeId == 2
                          ? Cesium.Color.GREEN.withAlpha(0.5)
                          : Cesium.Color.RED.withAlpha(0.5), */
                    },
                    show: true,
                    id: JSON.stringify(item),
                    name: 'single'
                })
            })
            viewer.dataSources.add(single);
            let position = Utils.transformWGS842Cartesian({
                lng: Number(this.singleList[0].lon),
                lat: Number(this.singleList[0].lat),
                alt: 100,
            })
            this.changeSingle = this.singleList[0]
            viewer.camera.flyTo({
                destination: position,
            })
            let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(async (movement) => {
                let pickedLabel = viewer.scene.pick(movement.position);
                console.log(pickedLabel, "pickedLabel");
                var pick = new Cesium.Cartesian2(
                    movement.position.x,
                    movement.position.y
                );
                if (Cesium.defined(pickedLabel)) {
                    // 获取点击位置坐标
                    let cartesian = viewer.scene.globe.pick(
                        viewer.camera.getPickRay(pick),
                        viewer.scene
                    );
                    if (pickedLabel.id.name == "single") {
                        try {
                            let data = JSON.parse(pickedLabel.id.id);
                            let arr = move_id_arr;
                            let singleData = {
                                arr: move_id_arr
                            }
                            if (arr.indexOf(data.id) < 0) {
                                move_id_arr.push(data.id);
                                // 调用弹窗方法
                                let popup = new Popup({
                                    viewer: viewer,
                                    geometry: cartesian,
                                    data: data,
                                    arr: singleData,
                                });
                            }
                        } catch (error) { }
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
    },
    getsinglespace(val) {
        this.changeSingle = val
        let position = Utils.transformWGS842Cartesian({
            lng: Number(val.lon),
            lat: Number(val.lat),
            alt: 100,
        })
        viewer.camera.flyTo({
            destination: position,
        })
    }
}