var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"car dialog1027"},[_c('div',{directives:[{name:"interact",rawName:"v-interact"}],staticClass:"dialog-header"},[_c('div',{staticClass:"dialog-header__icon"}),_c('div',{staticClass:"dialog-header__title"},[_vm._v(" 快速建模 ")]),_c('div',{staticClass:"dialog-header_close",staticStyle:{"color":"#fff"},on:{"click":function () { return _vm.$emit('close'); }}},[_vm._v("关闭")])]),_c('div',{staticClass:"dialog-content w267"},[_c('el-cascader',{attrs:{"size":"mini","filterable":"","popper-class":"cpt-observe-mspace-dashboard-airway_popper","options":_vm.airway_list,"clearable":"","show-all-levels":false,"placeholder":"请选择任务","props":{
        children: 'children',
        label: 'taskTitle',
        value: 'id',
        emitPath: false,
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
return [_c('el-tooltip',{staticClass:"item",attrs:{"disabled":data.taskTitle.length < 11,"effect":"dark","content":data.taskTitle,"placement":"top-start"}},[_c('span',[_vm._v(_vm._s(data.taskTitle))])])]}}]),model:{value:(_vm.taskCateId),callback:function ($$v) {_vm.taskCateId=$$v},expression:"taskCateId"}}),_c('el-select',{staticClass:"mt10",attrs:{"size":"mini","placeholder":"请选择类型"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('el-option',{attrs:{"label":"图片","value":0}}),_c('el-option',{attrs:{"label":"视频","value":1}})],1),_c('div',{staticClass:"jcsb w267 h30 mt30"},[_c('div',{staticClass:"dec mt5"},[_vm._v("1")]),_c('span',{staticClass:"dib cf ml10 lh30"},[_vm._v(" 快速建模")]),_c('div',{staticClass:"btn fr cf h30 lh30 w80 cp",on:{"click":function($event){return _vm.jmFn(1)}}},[_vm._v("启动")])]),_c('div',{staticClass:"jcsb mt18 w267 h30"},[_c('div',{staticClass:"dec mt5"},[_vm._v("2")]),_c('span',{staticClass:"dib cf lh30 ml10"},[_vm._v("数据处理")]),_c('div',{staticClass:"btn fr cf h30 lh30 w80 cp",on:{"click":function($event){return _vm.jmFn(2)}}},[_vm._v("启动")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }