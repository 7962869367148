<template>
  <div class="ovh mountControllerBox">
    <div class="wih00 pr mountControllerTitle" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机</div>
      </div>
      <div @click="$emit('close')" class="close">关闭</div>
    </div>
    <div class="jcsb" v-if="itemStatus == 0">
      <div class="ml20">
        <div class="form w290">
          <div class="form_item mt10">
            <span class="form_item_title " style="line-height: 26px"
              >控制速度：</span
            >
            <el-select
            class="w200"
              v-model="speed"
              clearable
              placeholder=""
              @change="cameraChangeFn"
            >
              <el-option
                v-for="item in 5"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="form_item">
            <span class="w107">云台模式：</span>
            <div>
              <div class="mono-long w120">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDom == 0 }"
                  @click="handleClick(2, 0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDom == 1 }"
                  @click="handleClick(2, 1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDom == 2 }"
                  @click="handleClick(2, 2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div>
          <div class="form_item">
            <img
              class="dib w150 h150"
              src="~@/assets/images/accident/hongwai.png"
            />
          </div>
          <!-- <div class="form_item">
            <div class="fix w200 jcsb">
              <img
                class="fl w32 ml60 mr100 cp"
                @click="take_photo()"
                src="~@/assets/images/accident/photo.png"
              />
              <img
                class="fr w32 cp"
                @click="record()"
                src="~@/assets/images/accident/video.png"
              />
            </div>
          </div>
          <div class="form_item">
            <span class="">变焦：</span>
            <div class="slider fl">
              <div class="symbol" @mousedown="zoomFn(0)" @mouseup="zoomFn(1)">
                -
              </div>
              <div class="symbol ml20" @mousedown="zoomFn(2)" @mouseup="zoomFn(1)">
                +
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="">
        <div class="form_item">
          <div class="pr40">
            <div class="form_item">
              <div class="form_item_title cf mb10 mt10">俯仰：</div>
              <div class="speedC">
              <div
                class="pitch"
                :style="
                  pitch == item.num
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                v-for="(item, index) in regList"
                :key="index"
                @click="change_pitch_angle(item.num)"
              >
                {{ item.val }}
              </div>
            </div>
              <!-- <el-input v-model="pitch" class="dib"></el-input>
              <el-button class="dib" @click="change_pitch_angle"
                >设置</el-button
              > -->
            </div>
            <!-- <div class="form_item">
              <div class="form_item_title cf mb10 mt10">航向：</div>
              <el-input v-model="yaw"></el-input>
              <el-button @click="change_yaw_angle">设置</el-button>
            </div> -->
          </div>
          <div class="caozuoImg w150 mt10 h150 pr mb30 cp">
            <div class="rocker">
              <div id="static"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import data from "./data/index.js";
import methods from "./methods/index.js";

export default {
  // inject: ["accident_This"],
  inject: ["mountControllerThis"],
  data() {
    return {
      itemStatus: 0,
      activeBox1: 1,
      holderModelDom: 1,
      activeBox3: 1,
      speed: 1,
      pitch: null,
      yaw: null,
      zoom: 0,
      count: 30,
      focusing: 20,
      record_status: false,
      ...data,
      // 控制挂载信息体
      controInfor: {
        type: 200,
        systemCode: "mmc",
        state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
        username: "",
        height: 0,
        idlingTime: 0,
        data: {
          cmdControlType: 7000, //520
          cmdFunction: null,
          cmdState: null,
          cmdValue: null,
          cmdBuffer: "",
          cmdName: "MMC_Gimbal_ZT1",
        },
        deviceHardId:""
      },
    };
  },
  mounted() {
    this.controInfor.username = this.user_info && this.user_info.username;
    this.joystickFn();
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    ...methods,
    nextFn() {
      if (this.zoom < this.count) {
        this.zoom++;
      }
    },
    backFn() {
      if (this.zoom > 0) {
        this.zoom--;
      }
    },
    joystickFn() {
      let _this = this;
      joystick({
        zone: document.getElementById("static"),
        mode: "static",
        position: { left: "50%", top: "50%" },
        color: "#9bacbe",
        onstart: function (type) {
          switch (type) {
            case "up":
              _this.gimbal_pitch_ctrl(1);
              break;
            case "right":
              _this.gimbal_yaw_ctrl(1);
              break;
            case "left":
              _this.gimbal_yaw_ctrl(-1);
              break;
            case "down":
              _this.gimbal_pitch_ctrl(-1);
          }
        },
        onend: function () {
          _this.gimbal_pitch_ctrl(0);
          _this.gimbal_yaw_ctrl(0);
        },
      });
    },
    setItem(item) {
      this.itemStatus = item;
    },
    handleClick(e, num) {
      if (e == 1) {
        this.activeBox1 = num;
      } else if (e == 2) {
        this.holderModelDom = num;
        this.gimbal_mode_ctrl(num);
      } else {
        this.activeBox3 = num;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
      .mountControllerBox{
    background: rgba(0, 23, 79, 0.7);
    border-radius: 10px;
  }
.form {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 40px;
  color: #dce9ff;
  letter-spacing: 1px;
  box-sizing: border-box;
  .form_item {
    display: flex;
    margin-bottom: 15px;
    .form_item_title {
      display: block;
      width: 150px;
    }

    .mono_box {
      width: 28px;
      height: 13px;
      border-radius: 3px;
      margin-top: 2px;
      cursor: pointer;
    }

    .active {
      background: url("~@/assets/images/accident/mono_box.svg") no-repeat !important;
      background-size: 100%;
    }

    .mono-short,
    .mono-long,
    .slider,
    .text {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    .mono-left {
      margin-left: 2px;
    }

    .mono-right {
      margin-right: 2px;
    }

    .mono-short {
      width: 111px;
      height: 17px;
      background: url("~@/assets/images/mount_short.png") no-repeat;
      background-size: 100%;
      margin-bottom: 5px;
    }

    .mono-long {
      width: 168px;
      height: 17px;
      background: url("~@/assets/images/mount_long.png") no-repeat;
      background-size: 100%;
      margin-bottom: 5px;
    }

    .imgs {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      & > img {
        cursor: pointer;
        width: 32px;
        height: 32px;

        &:hover {
          opacity: 0.7;
        }
      }
    }
.speedC {
  display: flex;
  width: 173px;
  height: 24px;
  background: rgba(13,34,79,0.65);
border-radius: 12px;
border: 1px solid #427DFF;

  .speed {
    width: 34px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
  .pitch{
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
height: 24px;
border-radius: 12px;
text-align: center;
    line-height: 24px;
    color: #fff;
  }
}
    .slider_box {
      width: 140px;
      margin: -7px 5px 0;
    }

    .symbol {
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid #08c2d1;
      width: 24px;
      height: 24px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      font-size: 20px;
      padding-left: 1px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .img_bottom {
    display: flex;
    width: 320px;
    justify-content: space-between;

    & > img {
      cursor: pointer;
      width: 130px;
      height: 130px;
    }
  }
}
::v-deep {
  .el-select,
  .el-input {
    width: 100px;
    height: 26px;
  }

  .el-input__inner {
    width: 100px;
    height: 26px;
    background: #000000;
    border: 1px solid #08c2d1;
    color: #dce9ff;
  }

  .el-select .el-input .el-select__caret {
    line-height: 26px;
  }

  // 修改input清除按钮样式
  .el-input__suffix {
    .el-input__suffix-inner {
      .el-icon-circle-close:before {
        line-height: 16px;
        position: absolute;
        top: 5px;
        right: 3px;
      }
    }
  }

  .el-button {
    margin-left: 10px;
    padding: 0;
    background: #2aefed;
    border-radius: 2px;
    width: 43px;
    height: 24px;
    outline: none;
    color: #000000;
    border: none;

    &:hover {
      opacity: 0.7;
    }
  }
}
.caozuoImg {
  background-image: url("~@/assets/images/accident/caozuo.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.infraredTitleBox {
  display: flex;
  justify-content: center;

  align-content: flex-start;
  height: 80px;
  .selectTiTle {
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    color: #08c2d1;
    text-align: center;
    font-weight: 700;
    border-bottom: 2px solid #08c2d1;
  }
  .defaultTitle {
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
  }
}
.mountControllerTitle {
    height:32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
</style>