export default {
    //crc校验字节生成函数
    cal_crc_table(array) {
        let crc = 0;
        let i = 1;
        let len = array[2];
        while (len--) {
            crc = this.throwData.crc_table[crc ^ array[i]];
            i++;
        }
        return crc;
    },
    // 俯仰操作
    gimbal_pitch_ctrl(speed) {
        let buff = new Array(0xa5, 0x05, 0x03, 0x00, 0x00)
        if (speed == 1) {
            buff[3] = 0x03
        }
        if (speed == -1) {
            buff[3] = 0xfd
        }
        buff[4] = this.cal_crc_table(buff)

        this.controInfor.data.cmdBuffer = buff.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId

        this.$emit('ThrowFn', this.controInfor)
    },
    //   切换模式
    lntelligent_mode_ctrl(data) {
        let buf = new Array(0xa5, 0x02, 0x03)
        if (!this.lntelligent_switch_status) {
            buf[3] = 0
        } else {
            buf[3] = 1
        }
        buf[4] = this.cal_crc_table(buf)
        this.controInfor.data.cmdBuffer = buf.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('ThrowFn', this.controInfor)
        this.$message.success('操作成功')
    },
    // 执行动作
    motor_weight() {
        if (this.slider_boxFlag) {
            let buf = new Array(0xa5, 0x01, 0x03, 0x01)
            buf[4] = this.cal_crc_table(buf)
            this.controInfor.data.cmdBuffer = buf.join(',');
            this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
            this.$emit('ThrowFn', this.controInfor)
            this.$message.success('执行成功')
        }
    },
    // 预设重量
    weightinput(inp) {
        let buff = new Array(0xa5, 0x04, 0x04, 0x00, 0x00)
        buff[3] = inp & 0xff
        buff[4] = (inp >> 8) & 0xff
        buff[5] = this.cal_crc_table(buff)
        this.controInfor.data.cmdBuffer = buff.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('ThrowFn', this.controInfor)
        this.value3 = this.value2;
        this.$message.success('操作成功')
    }
}