export default {

    //crc校验字节生成函数
    cal_crc_table(array) {
        let crc = 0;
        let i = 1;
        let len = array[2];
        while (len--) {
            crc = this.crc_table[crc ^ array[i]];
            i++;
        }
        return crc;
    },
    laser_shine() {
        // let el = document.getElementById('setZSTime')
        // console.log(el.value)
        // 保险
        if (this.safety_switch_state == 1) {
            let buff = new Array(0xa5, 0x20, 0x03, 0x01, 0x00)
            buff[3] = 1;
            buff[4] = this.cal_crc_table(buff)
            let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
            this.controInfor.data.cmdBuffer = newbuf.join(',');
            this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
            this.$emit('MMC_Gimbal_FE8Fn', this.controInfor)
            this.$message.success('操作成功')
        } else {
            this.$message.info('请关闭保险')
        }
    },
    // 
    gimbal_pitch_ctrl(speed) {
        let buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        if (speed == 1) {
            buff[3] = 0x03;
        }
        if (speed == -1) {
            buff[3] = 0xFD;
        }
        buff[4] = this.cal_crc_table(buff);
        let newbuf = Array.prototype.slice.call(new Uint8Array(buff));
        this.controInfor.data.cmdBuffer = newbuf.join(',');
        this.controInfor.deviceHardId = this.mountControllerThis.deviceHardId
        this.$emit('MMC_Gimbal_FE8Fn', this.controInfor)
    }

}